import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Tab, Tabs } from "@material-ui/core";
import { ReactComponent as Bat } from "../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../assets/images/SportIcon/ball.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { IntlContext } from "src/App";
import NoDataComp from "../../UI/NoData";

const summaryTabData = [
  {
    id: 1,
    name: "Fixtures"
  },
  {
    id: 2,
    name: "Results"
  }
];

const MatchDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [matchData, setMatchData] = useState([]);
  const [matchLoading, setMatchLoading] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const rowPerPage = 20;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    fetchMatchData(0);
  }, [params, tabvalue]);

  const fetchMatchData = async offset => {
    setMatchLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/event/tournament/${
          params?.tournamentId
        }?type=${
          tabvalue === 1 ? "fixtures" : "result"
        }&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        let count = data?.result?.count / 20;
        setMatchCount(Math.ceil(count));
        setMatchData(data?.result?.result);
        setMatchLoading(false);
      } else {
        setMatchLoading(false);
      }
    } catch (err) {
      setMatchLoading(false);
    }
  };

  const handleOnScrollBottomMatch = async offset => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/event/tournament/${
          params?.tournamentId
        }?type=${
          tabvalue === 1 ? "fixtures" : "result"
        }&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setOffset(offset);
        setMatchData([...matchData, ...data?.result?.result]);
      } else {
      }
    } catch (err) {}
  };

  const getFooterText = (data, toss, choose) => {
    // if (toss == 0 && choose == 0 && data?.ScoreBoard?.Epr === 8) {
    //   return "";
    // } else
    if (toss == 0 && choose == 0) {
      return (
        <Typography className="match-status">
          {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
        </Typography>
      );
    } else if (toss == 1 && choose == 1) {
      return (
        <Typography className="match-status">
          {`${data?.homeTeam?.name} win the Toss and choose to bat`}
        </Typography>
      );
    } else if (toss == 1 && choose == 2) {
      return (
        <Typography className="match-status">
          {`${data?.homeTeam?.name} win the Toss and choose to bowling`}
        </Typography>
      );
    } else if (toss == 2 && choose == 1) {
      return (
        <Typography className="match-status">
          {`${data?.awayTeam?.name} win the Toss and choose to bat`}
        </Typography>
      );
    } else if (toss == 2 && choose == 2) {
      return (
        <Typography className="match-status">
          {`${data?.awayTeam?.name} win the Toss and choose to bowling`}
        </Typography>
      );
    }
  };

  const handleNavigate = data => {
    const eventSlug = data?.eventName
      ? data?.eventName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "event";
    navigate(
      `/teamsports/cricket/${
        data?.CricketTournament?.Scd
          ? data?.CricketTournament?.Scd.trim()
              .toLowerCase()
              .replaceAll(" ", "-")
          : "tournament"
      }/${eventSlug}/info/${data?.SportId}/${data?.CricketTournamentId}/${
        data?.id
      }`
    );
  };

  return (
    <>
      <Box className="match-wrap">
        <Tabs
          value={tabvalue}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          className="scorecard-tab-detail"
        >
          {summaryTabData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  label={
                    <Box className="d-flex align-center match-tab">
                      <span>{item?.name}</span>
                    </Box>
                  }
                  value={item?.id}
                  className={item?.id === tabvalue ? "active " : ""}
                  onChange={(event, newValue) =>
                    handleTabChange(event, item?.id)
                  }
                />
              </Box>
            );
          })}
        </Tabs>
        {tabvalue === 1 ? (
          matchLoading ? (
            <div className="allsport-loader-center mt-18">
              <Loader />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={matchData?.length}
              next={() => handleOnScrollBottomMatch(offset + 20)}
              hasMore={
                matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
              }
              loader={
                <div className="allsport-loader-center ">
                  <Loader />
                </div>
              }
            >
              <Box className="mt-18">
                {matchData?.length > 0 ? (
                  matchData?.map((data, index) => {
                    return (
                      <>
                        <Box
                          className="match-details-wrap mb-18 cursor-pointer"
                          key={index}
                          onClick={() => handleNavigate(data)}
                        >
                          {data?.ScoreBoard?.Epr === 1 && (
                            <Box className="divider-indicator"></Box>
                          )}
                          <Box className="d-flex align-center content-space-between">
                            <Typography className="match-title">
                              {data?.ScoreBoard?.EtTx && data?.ScoreBoard?.EtTx}{" "}
                              {data?.ScoreBoard?.ErnInf?.trim() &&
                                `(${data?.ScoreBoard?.ErnInf})`}
                            </Typography>
                            <Typography className="match-title">
                              {" "}
                              {data?.ScoreBoard?.Esd &&
                              data?.ScoreBoard?.Exd > "1"
                                ? data?.ScoreBoard?.Esd &&
                                  `${moment(data?.ScoreBoard?.Esd).format(
                                    "DD MMM"
                                  )} - ${moment(data?.ScoreBoard?.Esd)
                                    .add(
                                      Number(data?.ScoreBoard?.Exd) - 1,
                                      "days"
                                    )
                                    .format("DD MMM")}`
                                : data?.ScoreBoard?.Esd &&
                                  moment(data?.ScoreBoard?.Esd).format(
                                    "DD MMM"
                                  )}
                            </Typography>
                          </Box>
                          <Typography className="match-status">
                            {data?.ScoreBoard?.Epr === 1
                              ? ""
                              : data?.ScoreBoard?.Esd &&
                                `${moment(data?.ScoreBoard?.Esd).format(
                                  "HH:mm"
                                )} -`}{" "}
                            {data?.ScoreBoard?.EpsL &&
                              `${data?.ScoreBoard?.EpsL}`}
                          </Typography>
                          <Box className="d-flex align-center content-space-between">
                            <Typography className="team-name d-flex col-gap-5">
                              {data?.ScoreBoard?.Epr === 1 ? (
                                <span>
                                  {data?.ScoreBoard?.Ebat === 1 ? (
                                    <Bat />
                                  ) : (
                                    <Ball />
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                              {data?.homeTeam?.name}
                            </Typography>
                            {data?.ScoreBoard?.Epr === 1 &&
                              (data?.ScoreBoard?.Exd > "1" ? (
                                <Typography className="team-score">
                                  {data?.ScoreBoard?.Tr1C1 ?? "-"}
                                  {data?.ScoreBoard?.Tr1CW1 &&
                                  data?.ScoreBoard?.Tr1CW1 === 10
                                    ? ""
                                    : (data?.ScoreBoard?.Tr1CW1 ||
                                        data?.ScoreBoard?.Tr1CW1 === 0) &&
                                      `/${data?.ScoreBoard?.Tr1CW1}${
                                        data?.ScoreBoard?.Tr1CD1 &&
                                        data?.ScoreBoard?.Tr1CD1 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                  {(data?.ScoreBoard?.Tr1C2 ||
                                    data?.ScoreBoard?.Tr1C2 === 0) &&
                                    `& ${data?.ScoreBoard?.Tr1C2}`}
                                  {data?.ScoreBoard?.Tr1CW2 &&
                                  data?.ScoreBoard?.Tr1CW2 === 10
                                    ? ""
                                    : (data?.ScoreBoard?.Tr1CW2 ||
                                        data?.ScoreBoard?.Tr1CW2 === 0) &&
                                      `/${data?.ScoreBoard?.Tr1CW2}${
                                        data?.ScoreBoard?.Tr1CD2 &&
                                        data?.ScoreBoard?.Tr1CD2 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                </Typography>
                              ) : (
                                <Typography className="team-score">
                                  {data?.ScoreBoard?.Tr1C1 ?? "-"}
                                  {data?.ScoreBoard?.Tr1CW1 &&
                                  data?.ScoreBoard?.Tr1CW1 === 10
                                    ? ""
                                    : (data?.ScoreBoard?.Tr1CW1 ||
                                        data?.ScoreBoard?.Tr1CW1 === 0) &&
                                      `/ ${data?.ScoreBoard?.Tr1CW1}`}{" "}
                                  <span>
                                    {" "}
                                    {data?.ScoreBoard?.Tr1CO1
                                      ? `(${data?.ScoreBoard?.Tr1CO1})`
                                      : ""}
                                  </span>{" "}
                                </Typography>
                              ))}
                          </Box>
                          <Box className="d-flex align-center content-space-between">
                            <Typography className="team-name d-flex col-gap-5">
                              {data?.ScoreBoard?.Epr === 1 ? (
                                <span>
                                  {data?.ScoreBoard?.Ebat === 1 ? (
                                    <Ball />
                                  ) : (
                                    <Bat />
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                              {data?.awayTeam?.name}
                            </Typography>
                            {data?.ScoreBoard?.Epr === 1 &&
                              (data?.ScoreBoard?.Exd > "1" ? (
                                <Typography className="team-score">
                                  {data?.ScoreBoard?.Tr2C1 ?? "-"}
                                  {data?.ScoreBoard?.Tr2CW1 &&
                                  data?.ScoreBoard?.Tr2CW1 === 10
                                    ? ""
                                    : (data?.ScoreBoard?.Tr2CW1 ||
                                        data?.ScoreBoard?.Tr2CW1 === 0) &&
                                      `/${data?.ScoreBoard?.Tr2CW1}${
                                        data?.ScoreBoard?.Tr2CD1 &&
                                        data?.ScoreBoard?.Tr2CD1 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                  {(data?.ScoreBoard?.Tr2C2 ||
                                    data?.ScoreBoard?.Tr2C2 === 0) &&
                                    `& ${data?.ScoreBoard?.Tr2C2}`}
                                  {data?.ScoreBoard?.Tr2CW2 &&
                                  data?.ScoreBoard?.Tr2CW2 === 10
                                    ? ""
                                    : (data?.ScoreBoard?.Tr2CW2 ||
                                        data?.ScoreBoard?.Tr2CW2 === 0) &&
                                      `/${data?.ScoreBoard?.Tr2CW2}${
                                        data?.ScoreBoard?.Tr2CD2 &&
                                        data?.ScoreBoard?.Tr2CD2 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                </Typography>
                              ) : (
                                <Typography className="team-score">
                                  {data?.ScoreBoard?.Tr2C1 ?? "-"}
                                  {data?.ScoreBoard?.Tr2CW1 &&
                                  data?.ScoreBoard?.Tr2CW1 === 10
                                    ? ""
                                    : (data?.ScoreBoard?.Tr2CW1 ||
                                        data?.ScoreBoard?.Tr2CW1 === 0) &&
                                      `/ ${data?.ScoreBoard?.Tr2CW1}`}{" "}
                                  <span>
                                    {data?.ScoreBoard?.Tr2CO1
                                      ? `(${data?.ScoreBoard?.Tr2CO1})`
                                      : ""}
                                  </span>
                                </Typography>
                              ))}
                          </Box>
                          {data?.ScoreBoard?.Epr === 1 ? (
                            <Typography className="match-status">
                              {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
                            </Typography>
                          ) : (
                            getFooterText(
                              data,
                              data?.ScoreBoard?.TPa,
                              data?.ScoreBoard?.TCho
                            )
                          )}
                        </Box>
                      </>
                    );
                  })
                ) : (
                  <>
                    <Box className="no-match no-match-boxshadow">
                      {localesData?.NO_DATA?.NO_FIXTURES}
                    </Box>
                    {/* <NoDataComp /> */}
                  </>
                )}
              </Box>
            </InfiniteScroll>
          )
        ) : matchLoading ? (
          <div className="allsport-loader-center mt-18">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={matchData?.length}
            next={() => handleOnScrollBottomMatch(offset + 20)}
            hasMore={
              matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
            }
            loader={
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            }
          >
            <Box className="mt-18">
              {matchData?.length > 0 ? (
                matchData?.map((data, index) => {
                  return (
                    <>
                      <Box
                        className="match-details-wrap mb-18 cursor-pointer"
                        key={index}
                        onClick={() => handleNavigate(data)}
                      >
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="match-title">
                            {data?.ScoreBoard?.EtTx && data?.ScoreBoard?.EtTx}{" "}
                            {data?.ScoreBoard?.ErnInf?.trim() &&
                              `(${data?.ScoreBoard?.ErnInf})`}
                          </Typography>
                          <Typography className="match-title">
                            {data?.ScoreBoard?.Esd &&
                            data?.ScoreBoard?.Exd > "1"
                              ? data?.ScoreBoard?.Esd &&
                                `${moment(data?.ScoreBoard?.Esd).format(
                                  "DD MMM"
                                )} - ${moment(data?.ScoreBoard?.Esd)
                                  .add(
                                    Number(data?.ScoreBoard?.Exd) - 1,
                                    "days"
                                  )
                                  .format("DD MMM")}`
                              : data?.ScoreBoard?.Esd &&
                                moment(data?.ScoreBoard?.Esd).format("DD MMM")}
                          </Typography>
                        </Box>
                        <Typography className="match-status">
                          {data?.ScoreBoard?.EpsL && data?.ScoreBoard?.EpsL}
                        </Typography>
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="team-name">
                            {data?.homeTeam?.name}
                          </Typography>
                          {data?.ScoreBoard?.Exd > "1" ? (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr1C1 ?? "-"}
                              {data?.ScoreBoard?.Tr1CW1 &&
                              data?.ScoreBoard?.Tr1CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr1CW1 ||
                                    data?.ScoreBoard?.Tr1CW1 === 0) &&
                                  `/${data?.ScoreBoard?.Tr1CW1}${
                                    data?.ScoreBoard?.Tr1CD1 &&
                                    data?.ScoreBoard?.Tr1CD1 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                              {(data?.ScoreBoard?.Tr1C2 ||
                                data?.ScoreBoard?.Tr1C2 === 0) &&
                                `& ${data?.ScoreBoard?.Tr1C2}`}
                              {data?.ScoreBoard?.Tr1CW2 &&
                              data?.ScoreBoard?.Tr1CW2 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr1CW2 ||
                                    data?.ScoreBoard?.Tr1CW2 === 0) &&
                                  `/${data?.ScoreBoard?.Tr1CW2}${
                                    data?.ScoreBoard?.Tr1CD2 &&
                                    data?.ScoreBoard?.Tr1CD2 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                            </Typography>
                          ) : (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr1C1 ?? "-"}
                              {data?.ScoreBoard?.Tr1CW1 &&
                              data?.ScoreBoard?.Tr1CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr1CW1 ||
                                    data?.ScoreBoard?.Tr1CW1 === 0) &&
                                  `/ ${data?.ScoreBoard?.Tr1CW1}`}{" "}
                              <span>
                                {" "}
                                {data?.ScoreBoard?.Tr1CO1
                                  ? `(${data?.ScoreBoard?.Tr1CO1})`
                                  : ""}
                              </span>{" "}
                            </Typography>
                          )}
                        </Box>
                        <Box className="d-flex align-center content-space-between">
                          <Typography className="team-name">
                            {data?.awayTeam?.name}
                          </Typography>
                          {data?.ScoreBoard?.Exd > "1" ? (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr2C1 ?? "-"}
                              {data?.ScoreBoard?.Tr2CW1 &&
                              data?.ScoreBoard?.Tr2CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr2CW1 ||
                                    data?.ScoreBoard?.Tr2CW1 === 0) &&
                                  `/${data?.ScoreBoard?.Tr2CW1}${
                                    data?.ScoreBoard?.Tr2CD1 &&
                                    data?.ScoreBoard?.Tr2CD1 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                              {(data?.ScoreBoard?.Tr2C2 ||
                                data?.ScoreBoard?.Tr2C2 === 0) &&
                                `& ${data?.ScoreBoard?.Tr2C2}`}
                              {data?.ScoreBoard?.Tr2CW2 &&
                              data?.ScoreBoard?.Tr2CW2 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr2CW2 ||
                                    data?.ScoreBoard?.Tr2CW2 === 0) &&
                                  `/${data?.ScoreBoard?.Tr2CW2}${
                                    data?.ScoreBoard?.Tr2CD2 &&
                                    data?.ScoreBoard?.Tr2CD2 === 1
                                      ? "d"
                                      : ""
                                  }`}{" "}
                            </Typography>
                          ) : (
                            <Typography className="team-score">
                              {data?.ScoreBoard?.Tr2C1 ?? "-"}
                              {data?.ScoreBoard?.Tr2CW1 &&
                              data?.ScoreBoard?.Tr2CW1 === 10
                                ? ""
                                : (data?.ScoreBoard?.Tr2CW1 ||
                                    data?.ScoreBoard?.Tr2CW1 === 0) &&
                                  `/ ${data?.ScoreBoard?.Tr2CW1}`}{" "}
                              <span>
                                {data?.ScoreBoard?.Tr2CO1
                                  ? `(${data?.ScoreBoard?.Tr2CO1})`
                                  : ""}
                              </span>
                            </Typography>
                          )}
                        </Box>
                        <Typography className="match-status">
                          {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
                        </Typography>
                      </Box>
                    </>
                  );
                })
              ) : (
                <>
                  <Box className="no-match no-match-boxshadow">
                    {" "}
                    {localesData?.NO_DATA?.NO_RESULTS}
                  </Box>
                  {/* <NoDataComp /> */}
                </>
              )}
            </Box>
          </InfiniteScroll>
        )}
      </Box>
    </>
  );
};

export default MatchDetails;
