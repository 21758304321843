import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Breadcrumbs,
  Tabs,
  Tab,
  Button
} from "@material-ui/core";
import AdvertisingBannner from "../../../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../../AdBanner/AdBanner";
import ViewPage from "../../viewPage";
import "./teamData.scss";
import TeamSummaryDetails from "./teamSummaryDetails";
import ListPage from "../../listPage";
import TeamCoachDetails from "./teamCoachDetails";
import TeamPlayersDetails from "./teamPlayersDetails";
import TeamVenueDetails from "./teamVenueDetails";

// const newsTitle = [
//   { value: 0, label: "Summary" },
//   { value: 1, label: "Coaches" },
//   { value: 2, label: "Players" },
//   { value: 3, label: "Referees" },
//   { value: 4, label: "Results" },
//   { value: 5, label: "rounds" },
//   { value: 6, label: "Venues" },
//   { value: 7, label: "Data" }
// ];

const newsTitle = [
  { value: 0, label: "Summary" },
  { value: 1, label: "Coaches" },
  { value: 2, label: "Players" },
  { value: 3, label: "Venues" }
];

const TeamDataPage = () => {
  const params = useParams();
  const tabsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [teamTabvalue, setTeamTabValue] = useState(0);
  const [viewPage, setViewPage] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [teamSummaryDetails, setTeamSummaryDetails] = useState({});
  const [viewListPage, setViewListPage] = useState("");
  const [listPage, setListPage] = useState(false);
  const [listDataPage, setListDataPage] = useState("");
  const [playerName, setPlayerName] = useState("");

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const routeName =
    params?.sportId === "4"
      ? "cricket"
      : params?.sportId === "12"
      ? "rugbyleague"
      : params?.sportId === "13"
      ? "rugbyunion"
      : params?.sportId === "10"
      ? "basketball"
      : params?.sportId === "15"
      ? "americanfootball"
      : params?.sportId === "9"
      ? "australianrules"
      : params?.sportId === "16"
      ? "golf"
      : params?.sportId === "7"
      ? "tennis"
      : params?.sportId === "11"
      ? "baseball"
      : params?.sportId === "17"
      ? "icehockey"
      : params?.sportId === "6"
      ? "boxing"
      : params?.sportId === "5"
      ? "mma"
      : params?.sportId === "8"
      ? "soccer"
      : "";

  const handleChange = (id, value) => {
    setTeamTabValue(id);
    const stateToPass = {
      viewListPage: "",
      viewPage: false,
      listDataPage: "",
      listPage: false
    };
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${params?.teamId}/team-data?seasonId=${
        SeasonId ? SeasonId : ""
      }`,
      {
        state: stateToPass
      }
    );
  };

  useEffect(() => {
    fetchBreacrumnData();
    fetchTeamSummarydeatils();
  }, [params, SeasonId]);

  useEffect(() => {
    setViewPage(location?.state?.viewPage ? location?.state?.viewPage : false);
    setListPage(location?.state?.listPage ? location?.state?.listPage : false);
    setViewListPage(
      location?.state?.viewListPage
        ? location?.state?.viewListPage
        : viewListPage
    );
    setListDataPage(
      location?.state?.ListDataPage
        ? location?.state?.ListDataPage
        : listDataPage
    );
  }, [location]);

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header/?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&teamId=${params?.teamId}&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchTeamSummarydeatils = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/team/details/${params?.teamId}?SportId=${
          params?.sportId
        }&tournamentId=${params?.tournamentId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`
      );
      if (status === 200) {
        setTeamSummaryDetails(data?.result);
      } else {
      }
    } catch (err) {}
  };

  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" to="#">
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/${routeName}/odds/0/false`}
                  >
                    {headerData?.sport?.sportName}
                  </Link>
                  {/* <Link underline="hover" color="inherit" to="#">
                    [team Sport Data]
                  </Link> */}
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/sport/statistics/${params?.sportId}/${
                      params?.tournamentId
                    }/league-data?seasonId=${SeasonId ? SeasonId : ""}`}
                  >
                    {SeasonId
                      ? headerData?.season?.name
                      : headerData?.tournament?.name}
                  </Link>
                  <Typography>{headerData?.team?.name}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">{headerData?.team?.name}</Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={teamTabvalue}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {newsTitle.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == teamTabvalue ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
            {teamTabvalue === 0 ||
            teamTabvalue === 1 ||
            teamTabvalue === 2 ||
            teamTabvalue === 3 ? (
              <>
                <Box className="tab-deatils">
                  <Box className="team-tab-content">
                    <Typography className="team-name">
                      {headerData?.team?.name} -
                    </Typography>
                    <Button
                      variant="outlined"
                      className={`${
                        viewPage || listPage ? "" : "active"
                      } details-btn`}
                      onClick={() => {
                        setViewPage(false);
                        setListPage(false);
                        const stateToPass = {
                          viewListPage: "",
                          viewPage: false,
                          listDataPage: "",
                          listPage: ""
                        };
                        navigate(
                          `/sport/statistics/${params?.sportId}/${
                            params?.tournamentId
                          }/league-data/${params?.teamId}/team-data?seasonId=${
                            SeasonId ? SeasonId : ""
                          }`,
                          {
                            state: stateToPass
                          }
                        );
                      }}
                    >
                      Summary
                    </Button>

                    {(viewPage || listPage) && (
                      <Button
                        variant="outlined"
                        className={`${
                          viewPage || listPage ? "active" : ""
                        } details-btn`}
                      >
                        Details
                      </Button>
                    )}
                  </Box>

                  <Box className="tab-details-grid">
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Coach:
                      </Typography>
                      <Typography className="details-text">
                        {teamSummaryDetails?.coachName
                          ? teamSummaryDetails?.coachName
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Captain:
                      </Typography>
                      <Typography className="details-text">
                        {teamSummaryDetails?.captain
                          ? teamSummaryDetails?.captain
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Biggest home crowd:
                      </Typography>
                      <Typography className="details-text">-</Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Average home crowd:
                      </Typography>
                      <Typography className="details-text">-</Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Top pointscorer:
                      </Typography>
                      <Typography className="details-text">
                        {teamSummaryDetails?.topPointer
                          ? teamSummaryDetails?.topPointer
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Top tryscorer:
                      </Typography>
                      <Typography className="details-text">
                        {teamSummaryDetails?.topTryScoreer
                          ? teamSummaryDetails?.topTryScoreer
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box className="team-deatils-wrap">
                    <Typography className="team-deatils">
                      TC Robati was originally part of Brisbane's 2023 squad,
                      but was stood down from all club activities on the 30th of
                      December, 2022, as a result of being charged for an
                      incident that took place on the 24th of December.
                    </Typography>
                  </Box> */}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>

          {/* All tab deatils add here condition add like teamTabvalue === 0 ? "" : "" */}
          {teamTabvalue === 0 ? (
            !viewPage && !listPage ? (
              <>
                <TeamSummaryDetails
                  setViewPage={setViewPage}
                  headerData={headerData}
                  setViewListPage={setViewListPage}
                  setListDataPage={setListDataPage}
                  setListPage={setListPage}
                  setPlayerName={setPlayerName}
                />
              </>
            ) : (
              <>
                {viewPage && <ViewPage viewListPage={viewListPage} />}
                {listPage && (
                  <ListPage
                    listDataPage={listDataPage}
                    playerName={playerName}
                    setListPage={setListPage}
                  />
                )}
              </>
            )
          ) : teamTabvalue === 1 ? (
            <TeamCoachDetails headerData={headerData} />
          ) : teamTabvalue === 2 ? (
            <TeamPlayersDetails headerData={headerData} />
          ) : teamTabvalue === 3 ? (
            <TeamVenueDetails headerData={headerData} />
          ) : (
            <Box className="no-data-wrap">
              <Typography className="text-align-center no-data">
                No data Available
              </Typography>
            </Box>
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default TeamDataPage;
