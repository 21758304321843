import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Tooltip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Placeholder from "../../../../../assets/images/placeOrder.png";
import NewaSlider1 from "../../../../../assets/images/newsSlider1.png";
import NewaSlider2 from "../../../../../assets/images/newsSlider2.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import OurPartner from "src/views/component/ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import BelowSlider from "../../../../../assets/images/ad-placeholder/sports2.webp";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/homebelow-ourpartner.webp";
import BannerIndia from "../../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import DefaultImg from "../../../../../assets/images/smartb_default.png";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoDataComp from "src/views/component/UI/NoData";
import he from "he";
import { Config, fetchFromStorage } from "src/helpers/context";
import NewsComp from "src/views/component/NewsComponent/NewsComp";
import { ReactComponent as SeeAllRightArrow } from "../../../../../assets/images/icons/rightArrow.svg";
import { setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import { ReactComponent as BookmarkIcon } from "src/assets/images/icons/bookmark-icon.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CategoryNews = ({
  categoryData,
  isCategoryLoading,
  fetchNewsCategoryData,
  categoryList,
  searchValue
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const silderRef = useRef(null);
  const URL = window?.location?.origin;
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [AdData, setAdData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [categoryCount, setcategoryCount] = useState(0);
  const [SliderData, setSliderData] = useState([]);
  const [isSliderLoading, setIsSliderLoading] = useState(false);
  const rowPerPage = 10;
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);

  useEffect(() => {
    if (categoryList?.length > 0) {
      fetchCategoryName(categoryList);
    }
  }, [categoryList, params?.categoryId]);

  useEffect(() => {
    if (categoryData) {
      setcategoryCount(categoryData?.count);
    }
  }, [categoryData]);
  useEffect(() => {
    fetchAllSliderData();
    fetchNewsCategoryData(
      params?.categoryId === "1000" ? 0 : params?.categoryId,
      0,
      searchValue
    );
    setcurrentPage(1);
  }, [params]);

  const fetchCategoryName = categoryList => {
    let categoryName = categoryList?.filter(
      item => item?.value == params?.categoryId
    );
    setCategoryName(categoryName?.[0]?.label);
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };
  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    setoffset((Number(page) - 1) * rowPerPage);
    fetchNewsCategoryData(
      params?.categoryId === "1000" ? 0 : params?.categoryId,
      (Number(page) - 1) * rowPerPage,
      searchValue
    );
  };

  const pageNumbers = [];

  if (categoryCount > 0) {
    for (let i = 1; i <= Math.ceil(categoryCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }
  const fetchAllSliderData = async () => {
    try {
      setIsSliderLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/feature/articles?NewsCategoryId=${params?.categoryId}`
      );
      if (status === 200) {
        setSliderData(data?.result);
        setIsSliderLoading(false);
      } else {
        setIsSliderLoading(false);
      }
    } catch (err) {
      setIsSliderLoading(false);
    }
  };
  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=14&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: SliderData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    prevArrow: (
      <Box className="arrow arrow-prev">
        <SeeAllRightArrow />
      </Box>
    ),
    nextArrow: (
      <Box className="arrow arrow-next">
        <SeeAllRightArrow />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 610,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const fetchNewsSubtitle = data => {
    const parseHTMLContent = body => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = content => {
      return he.decode(content);
    };
    const removeHtmlTags = htmlString => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = content => {
      return (
        <Typography className="sub-details">
          <span
            dangerouslySetInnerHTML={{
              __html:
                screenWidth >= 1023
                  ? content?.slice(0, 100)
                  : content?.slice(0, 50)
            }}
          ></span>
          {/* {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)} */}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        item =>
          item?.type === "editor_block" && item?.data?.type === "paragraph"
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  const handleNewsSave = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted"
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.NewsArticleId}`,
          payload
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.NewsArticle?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!"
          );

          const updateNews = SliderData?.map(article =>
            article.NewsArticleId === item?.NewsArticleId
              ? {
                  ...article,
                  NewsArticle: {
                    ...article.NewsArticle,
                    isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0
                  }
                }
              : article
          );
          setSliderData(updateNews);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
    }
  };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  return (
    <>
      <Box className="all-news category-news">
        <Box className="news-slider news-feature-slider">
          {isSliderLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : SliderData?.length > 0 ? (
            <>
              <Typography variant="h4">Featured News</Typography>
              <Slider
                {...settings}
                ref={silderRef}
                className="news-slider-comp"
              >
                {SliderData.length > 0 &&
                  SliderData?.map((item, index) => (
                    <Grid
                      key={index}
                      container
                      className="slide-cont"
                      // onClick={() =>
                      //   // navigate(
                      //   //   `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
                      //   // )
                      // }
                    >
                      <NavLink
                        to={`/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`}
                      >
                        <Grid
                          item
                          lg={6}
                          key={item?.NewsArticle?.id}
                          className="slide-single-box"
                        >
                          <Box
                            className={
                              SliderData?.length === 1
                                ? "single-silder single-silder-object"
                                : "single-silder"
                            }
                            // style={{
                            //   backgroundImage: `url(${item?.NewsArticle?.mainMedia?.gallery?.url})`
                            // }}
                          >
                            <Box
                              className="background-overlay"
                              style={{
                                content: "''",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url('${
                                  item?.NewsArticle?.mainMedia?.gallery
                                    ? item?.NewsArticle?.mainMedia?.gallery
                                        ?.url &&
                                      item?.NewsArticle?.mainMedia?.gallery
                                        ?.url !== ""
                                      ? item?.NewsArticle?.mainMedia?.gallery
                                          ?.url
                                      : DefaultImg
                                    : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url &&
                                      item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url !== ""
                                      ? item?.NewsArticle?.mainMedia?.[0]
                                          ?.gallery?.url
                                      : DefaultImg
                                    : DefaultImg
                                }')`,
                                backgroundSize: "cover",
                                backgroundPosition: "center top",
                                filter: "blur(10px)",
                                zIndex: -1,
                                borderRadius: "8px"
                              }}
                            ></Box>
                            <Box className="slider-img">
                              <img
                                src={
                                  item?.NewsArticle?.mainMedia?.gallery
                                    ? item?.NewsArticle?.mainMedia?.gallery
                                        ?.url &&
                                      item?.NewsArticle?.mainMedia?.gallery
                                        ?.url !== ""
                                      ? item?.NewsArticle?.mainMedia?.gallery
                                          ?.url
                                      : DefaultImg
                                    : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url &&
                                      item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url !== ""
                                      ? item?.NewsArticle?.mainMedia?.[0]
                                          ?.gallery?.url
                                      : DefaultImg
                                    : DefaultImg
                                }
                                alt={
                                  item?.NewsArticle?.mainMedia?.gallery
                                    ? item?.NewsArticle?.mainMedia?.gallery?.alt
                                    : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        .alt
                                    : ""
                                }
                              />
                            </Box>
                            <span className="news-tag">{categoryName}</span>
                            <Box className="details-back">
                              <Box className="slider-news-deatils">
                                <Link
                                  to="#"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.NewsArticle?.title
                                  }}
                                ></Link>
                                <Box className="news-time-comment-wrapper">
                                  <Typography className="news-time">
                                    {fetchNewsTime(
                                      item?.NewsArticle?.rapidCreatedAt
                                    )}
                                  </Typography>
                                  <Box className="bookmark-chat-wrapper">
                                    <Box onClick={e => handleNewsSave(e, item)}>
                                      <Tooltip
                                        placement="bottom"
                                        arrow
                                        title='Save to "Saved articles"'
                                        classes={{
                                          tooltip: "news-tooltip"
                                        }}
                                      >
                                        <BookmarkIcon
                                          className={
                                            item?.NewsArticle?.isSaved === 0
                                              ? "unsaved-bookmark-icon"
                                              : "saved-bookmark-icon"
                                          }
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Tooltip
                                      placement="bottom"
                                      arrow
                                      title="Comments"
                                      classes={{
                                        tooltip: "news-tooltip"
                                      }}
                                    >
                                      <Box className="chat-details">
                                        <ChatIcon />
                                        <Typography className="news-time">
                                          {item?.NewsArticle?.totalComments}
                                        </Typography>
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Slider>
            </>
          ) : (
            <></>
          )}
        </Box>
        {fetchAds(
          1,
          "100%",
          Config?.release == "IN" ? BannerIndia : BelowSlider
        )}
        {isCategoryLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : categoryData?.raw?.length > 0 ? (
          <>
            <Typography variant="h4">{categoryName}</Typography>
            <Grid container className="individual-news-cont">
              <NewsComp newsData={categoryData?.raw} />
            </Grid>
            <Box className="news-Pagination">
              {/* {params?.categoryId != 1000 ? ( */}
              <Pagination
                // hideNextButton
                // hidePrevButton
                disabled={categoryCount / rowPerPage > 1 ? false : true}
                page={currentPage}
                onChange={(e, value) => handlePaginationClick(e, value)}
                count={pageNumbers[pageNumbers?.length - 1]}
                siblingCount={2}
                boundaryCount={1}
                // count={10}
                size="small"
              />
              {/* ) : (
                <></>
              )} */}
            </Box>
          </>
        ) : (
          <>
            <Box style={{ textAlign: "center" }}>
              {/* No Data Available */}
              <NoDataComp />
            </Box>
          </>
        )}
      </Box>
      {fetchAds(
        2,
        "100%",
        Config?.release == "IN" ? BannerIndia : AboveOurPartner
      )}
      <OurPartner />
      {Config?.release == "AU" && fetchAds(3, "102px", BelowOurPartner)}
    </>
  );
};

export default CategoryNews;
