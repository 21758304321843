import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TextareaAutosize
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Config } from "src/helpers/context";
import Avatar from "src/assets/images/user-avatar.png";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as OrangeDelete } from "src/assets/images/orange-delete.svg";
import "./wall.scss";
import TipperChatModal from "../../UI/tipperChatModal";
import DialogBox from "../../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { MentionsInput, Mention } from "react-mentions";
import moment from "moment-timezone";
import ReplyComp from "./replyComp";
import Loader from "src/components/Loader";
import _ from "lodash";
import NoDataComp from "../../UI/NoData";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
function WallTab({ params, mentionUsersList, tippperRatingInfo }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isPost, setIsPost] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [userCommentBox, setUserCommentBox] = useState("");
  const [userReplyBox, setReplyCommentBox] = useState("");

  const [postData, setPostData] = useState([]);
  const [replyPostData, setReplyPostData] = useState(null);

  const defaultStyle = {
    width: "97%",
    // height: "18px",
    control: {
      backgroundColor: "#FAFAFA",
      fontSize: 16,
      fontWeight: "normal",
      border: 0
    },
    highlighter: {
      overflow: "hidden"
    },
    input: {
      margin: 0,
      overflow: "auto",
      maxHeight: 100,
      color: "#231F20"
    },
    "&multiLine": {
      control: {
        fontFamily: "Roboto",
        border: "1px solid #EFEFEF",
        borderRadius: "10px"
      },
      highlighter: {
        padding: 9
      },
      input: {
        padding: 9,
        maxHeight: 100,
        outline: 0,
        border: 0
      }
    },
    suggestions: {
      bottom: "0",
      top: "unset",
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#cee4e5"
        }
      }
    }
  };

  const defaultMentionStyle = {
    // backgroundColor: "gray",
    fontWeight: "600"
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    getAllPostByUserID();
    // getMentionUsersList();
  }, []);

  const handleDelete = id => {
    // setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    // setSelectedType("");
    setSelectedId("");
  };

  const handlePostSubmit = async (isReply, parentID) => {
    let payload = {
      toUserId: Number(params?.id),
      text: isReply ? userReplyBox : userCommentBox,
      // text: `<p><a href="${userID}" class="wysiwyg-mention" data-mention data-value="parita Dave">@parita Dave</a>&nbsp;</p> nice`,
      MediaId: null
    };
    let replyPayload = {
      toUserId: Number(params?.id),
      text: isReply ? userReplyBox : userCommentBox,
      // text: `<p><a href="${userID}" class="wysiwyg-mention" data-mention data-value="parita Dave">@parita Dave</a>&nbsp;</p> nice`,
      MediaId: null,
      parentPostId: parentID
    };

    try {
      const { status, data } = await axiosInstance.post(
        `/post/create`,
        isReply ? replyPayload : payload
      );
      if (status === 200) {
        setUserCommentBox("");
        setReplyCommentBox("");
        getAllPostByUserID();
        toast.success(
          isReply ? "Reply Added Successfuly" : "Post Added Successfuly",
          {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          }
        );
      } else {
      }
    } catch (err) {
      console.log("error", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  // const handlePostEdit = async isReply => {
  //   let payload = {
  //     toUserId: Number(params?.id),
  //     text:
  //       '<p><a href="user/10" class="wysiwyg-mention" data-mention data-value="parita Dave">@parita Dave</a>&nbsp;</p>nice',
  //     MediaId: null
  //   };

  //   try {
  //     const { status, data } = await axiosInstance.post(
  //       `/post/update/1(postId)`,
  //       payload
  //     );
  //     if (status === 200) {
  //       toast.success(
  //         isReply ? "Reply Updated Successfuly" : "Post Updated Successfuly",
  //         {
  //           position: "bottom-center",
  //           autoClose: 2000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           theme: "colored"
  //         }
  //       );
  //     } else {
  //     }
  //   } catch (err) {
  //     console.log("error", err);
  //     toast.error(err?.response?.data?.message, {
  //       position: "bottom-center",
  //       autoClose: 2000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "colored"
  //     });
  //   }
  // };
  const handlePostDelete = async replyId => {
    //POST ID
    try {
      const { status, data } = await axiosInstance.delete(
        `/post/delete/${replyId ? replyId : selectedId}`
      );
      if (status === 200) {
        getAllPostByUserID();
        toast.success("Deleted Successfuly", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      } else {
      }
    } catch (err) {
      console.log("error", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  const getAllPostByUserID = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/post/getAll/${Number(params?.id)}`
      );
      if (status === 200) {
        setLoading(false);

        // if (id) {
        //   const dataMap = data?.result?.find(item => item?.id === id);
        //   var NFPL = postData;
        //   setReplyPostData(Math.random());
        //   var index = _.findIndex(NFPL, { id: id });
        //   NFPL.splice(index, 1, dataMap);
        //   // setNewsFeedPostList(NFPL);
        //   console.log("index", NFPL, dataMap);
        //   setPostData(NFPL);
        // } else {
        setPostData(data?.result);
        // }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };

  // useEffect(() => {
  //   console.log("inside useeffef", postData);
  //   setPostData(postData);
  // }, [postData, replyPostData]);

  // const getOnePostByPostID = async isReply => {
  //   try {
  //     const { status, data } = await axiosInstance.get(`/post/get/16 (postId)`);
  //     if (status === 200) {
  //     } else {
  //     }
  //   } catch (err) {
  //     console.log("error", err);
  //     toast.error(err?.response?.data?.message, {
  //       position: "bottom-center",
  //       autoClose: 2000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "colored"
  //     });
  //   }
  // };

  const onInputChange = (isReply, event) => {
    const value = event.target.value;
    const regex = /[^{}]+(?=})/g;
    const mentions = value.match(regex);
    if (isReply) {
      setReplyCommentBox(event.target.value);
    } else {
      setUserCommentBox(event.target.value);
    }
  };

  return (
    <>
      <Box className="post-sec">
        <Box className="post-component">
          <Box className="user-img">
            <img src={Avatar} alt="avatar" />
          </Box>
          {/* <TextareaAutosize
            id="outlined-basic"
            variant="outlined"
            className="details-textarea-field"
            placeholder="Write on Chloe Royle's wall..."
            multiline
            maxRows={6}
            name="message"
            style={{ width: "97%", height: "18px" }}
            // onChange={e => setComment(e.target.value)}
            // value={comment}
          /> */}
          <MentionsInput
            value={userCommentBox}
            onChange={e => {
              onInputChange(false, e);
            }}
            style={defaultStyle}
            id="outlined-basic"
            variant="outlined"
            className="details-textarea-field mention-input"
            placeholder={`Write on ${tippperRatingInfo?.firstName} ${tippperRatingInfo?.lastName}'s wall...`}
            forceSuggestionsAboveCursor={true}
            rows={5}
            cols={1}
          >
            <Mention
              markup="<a href=__id__ class=`wysiwyg-mention` data-mention>__display__</a>"
              // displayTransform={id => `@${id}`}
              // markup="<a data-value='__id__' href='#'>__display__</a>"
              data={mentionUsersList}
              // trigger="@"
              // onAdd={this.onAdd}
              // style={defaultMentionStyle}
            />
          </MentionsInput>
          {screenWidth > 1160 ? (
            <Button
              className="post-btn"
              onClick={() => handlePostSubmit(false, "")}
              disabled={!userCommentBox}
            >
              Post
            </Button>
          ) : (
            <></>
          )}
        </Box>
        {screenWidth <= 1160 ? (
          <Box className="right-btn">
            <Button
              className="post-btn"
              onClick={() => handlePostSubmit(false, "")}
              disabled={!userCommentBox}
            >
              Post
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {loading ? (
        <div className="allsport-loader-center app-loader">
          <Loader />
        </div>
      ) : (
        <>
          {postData && postData?.length > 0 ? (
            postData?.map((item, index) => {
              return (
                <Box className="wall-sec">
                  <Box className="post-message">
                    <Box className="user-img">
                      <img src={Avatar} alt="avatar" />
                    </Box>
                    <Box className="post-details">
                      <Box className="name-delete-sec">
                        <Typography className="name">
                          {item?.fromUser?.firstName +
                            " " +
                            item?.fromUser?.lastName}
                        </Typography>
                        <Box
                          className="delete-box"
                          onClick={() => {
                            handleDelete(item?.post_id);
                          }}
                        >
                          <OrangeDelete />
                        </Box>
                      </Box>
                      <Typography
                        className="comment"
                        dangerouslySetInnerHTML={{
                          __html: item?.text
                        }}
                      ></Typography>
                      <Typography className="time">
                        {moment
                          .utc(item?.createdAt)
                          .tz(timezone)
                          .format("dddd")}
                        ,{" "}
                        {moment
                          .utc(item?.createdAt)
                          .tz(timezone)
                          .format("DD MMM")}{" "}
                        at{" "}
                        {moment
                          .utc(item?.createdAt)
                          .tz(timezone)
                          .format("hh:mm A")}
                      </Typography>
                      <ReplyComp
                        postList={item?.replyPost}
                        handlePostDelete={handlePostDelete}
                      />
                      {isPost === item?.id ? (
                        <>
                          <Box className="bottom-comp">
                            <Box className="user-img">
                              <img src={Avatar} alt="avatar" />
                            </Box>
                            <MentionsInput
                              value={userReplyBox}
                              onChange={e => {
                                onInputChange(true, e);
                              }}
                              style={defaultStyle}
                              id="outlined-basic"
                              variant="outlined"
                              className="details-textarea-field mention-input"
                              placeholder={`Reply...`}
                              forceSuggestionsAboveCursor={true}
                              rows={5}
                              cols={1}
                            >
                              <Mention
                                markup="<a href=__id__ class=`wysiwyg-mention` data-mention>__display__</a>"
                                // displayTransform={(id) => `@${id}`}
                                // data={mentionList}
                                data={mentionUsersList}
                                // trigger="@"
                                // onAdd={this.onAdd}
                                // style={defaultMentionStyle}
                              />
                            </MentionsInput>
                            {screenWidth > 530 ? (
                              <Button
                                className="post-btn"
                                onClick={() => {
                                  handlePostSubmit(true, item?.id);
                                  setIsPost(false);
                                }}
                                disabled={!userReplyBox}
                              >
                                Reply
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          {screenWidth <= 530 ? (
                            <Box className="right-btn">
                              <Button
                                className="post-btn"
                                onClick={() => {
                                  handlePostSubmit(true, item?.id);
                                  setIsPost(false);
                                }}
                                disabled={!userReplyBox}
                              >
                                Reply
                              </Button>
                            </Box>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <Box className="reply-btn-sec">
                          <Button
                            className="post-btn reply-btn"
                            onClick={() => setIsPost(item?.id)}
                          >
                            Reply
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <>
              <Box style={{ border: "transparent" }}>
                <NoDataComp />
              </Box>
            </>
          )}
        </>
      )}
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handlePostDelete();
                    handleDeleteClose();
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
}

export default WallTab;
