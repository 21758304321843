import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import racingBanner from "../../../assets/images/horse-racing-banner.png";
import NoDataComp from "../UI/NoData";
import "./internationalRacing.scss";

const raceRecord = [
  {
    id: 1,
    field: "Win",
    field1: "Select a winner"
  },
  {
    id: 2,
    field: "Place",
    field1: "Select any horse of the first three horses"
  },
  {
    id: 3,
    field: "Quinella (QIN)",
    field1: "Select the 1st and 2nd horses in any order"
  },
  {
    id: 4,
    field: "Quinella Place (QPL)",
    field1: "Select any two of the first three horses in any order"
  }
];

const UKHorseRacing = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <Box className="img-sec hw-100">
        <img src={racingBanner} alt="website-img" className="inner-img" />
      </Box>
      <Box className="view-tips-btn-sec">
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          fullWidth
          className="skip-now-btn"
          // onClick={() => {
          //   handleNavigation();
          // }}
          // endIcon={<RightIcon />}
        >
          View UK racing tips
        </Button>
      </Box>
      <NoDataComp intRaceType={"intRaceType"} />
    </>
  );
};

export default UKHorseRacing;
