import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  makeStyles,
  withStyles,
  TableContainer,
  Tab,
  Tabs,
  TabPanel,
  TextField,
  TextareaAutosize
} from "@material-ui/core";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import PropTypes from "prop-types";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import TipperProfile from "src/assets/images/tipper-profile.png";
import Avatar from "src/assets/images/chat-user.png";
import AdBannner from "../AdBanner/AdBanner";
import moment from "moment-timezone";
import "./userMessages.scss";

const userData = [
  {
    id: 0,
    name: "Chloe Royle",
    lastMessage:
      "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in velit. ",
    date: "2023-12-28T10:13:00.000Z"
  },
  {
    id: 1,
    name: "Netta Kimberleigh",
    lastMessage: "Etiam ac hendrerit lacus, vel vulputate libero.",
    date: "2023-12-28T10:13:00.000Z"
  },
  {
    id: 2,
    name: "Cameron Davis",
    lastMessage:
      "Ut hendrerit sed augue id finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in velit. ",
    date: "2023-12-28T10:13:00.000Z"
  },
  {
    id: 3,
    name: "Eric Cole",
    lastMessage: "aliquet id rhoncus at, ultrices in velit. ",
    date: "2023-12-28T10:13:00.000Z"
  },
  {
    id: 4,
    name: "Harris English",
    lastMessage:
      "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus. ",
    date: "2023-12-28T10:13:00.000Z"
  }
];

const useStyles = makeStyles(theme => ({
  tabs: {
    width: "350px",
    "& .MuiTab-root": {
      display: "none",
      backgroundColor: "#fff",
      textColor: "#0832A7",
      padding: "0px 20px",
      alignItems: "start"
    },

    "& .Mui-selected": {
      color: "white",
      backgroundColor: "#0832A7",
      border: "1px solid 0832A7",
      borderRadius: "50px"
    },

    "& .MuiTabs-flexContainer": {
      flexWrap: "wrap"
    }
  }
}));

const UserMessagesPage = () => {
  const params = useParams();
  const classes = useStyles();
  const tabRef = useRef();
  const navigate = useNavigate();
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  let [stepperCount, setStepperCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = sender => {
    if (newMessage.trim() !== "") {
      setMessages([...messages, { text: newMessage, sender: sender }]);
      setNewMessage("");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
  }

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleUserMessageNavigation = name => {
    navigate(`/user-messages/${name}`);
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="messages-wrap">
          <Box className="messages-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Typography>Messages</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Messages</Typography>
          </Box>
          <Box className="page-deatils-wrap">
            {screenWidth > 980 ? (
              <Box className="tab-sec">
                <Box className="tabs-titles">
                  <Tabs
                    ref={tabRef}
                    orientation="vertical"
                    value={value}
                    // className={classes.tabs}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className="tabs"
                  >
                    {userData?.map(item => {
                      return (
                        <Tab
                          label={
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "12px"
                              }}
                            >
                              <Box className="avatar-sec">
                                <img
                                  src={item?.id === 0 ? TipperProfile : Avatar}
                                  alt="avatar"
                                  className="avatar"
                                />
                              </Box>
                              <span className="tab-title">{item?.name}</span>
                            </Box>
                          }
                          className="tab"
                          {...a11yProps(item?.id)}
                        />
                      );
                    })}
                  </Tabs>
                </Box>

                {userData?.map((item, index) => {
                  return (
                    <TabPanel value={value} index={index} className="tab-panel">
                      <Box className="tab-panel-box">
                        <Box
                          className={
                            messages?.length > 3
                              ? "messages-sec"
                              : "d-flex messages-sec"
                          }
                        >
                          <Box className="messages-container">
                            {messages?.length > 0 && (
                              <Box className="day-devider">
                                <Box className="devider"></Box>
                                <Typography className="day">
                                  {moment(Date()).format("dddd, DD MMM")}
                                </Typography>
                                <Box className="devider"></Box>
                              </Box>
                            )}

                            {messages &&
                              messages.map((message, index) => (
                                <Box
                                  key={index}
                                  className={`message ${
                                    message?.sender === "user"
                                      ? "user-message"
                                      : "bot-message"
                                  }`}
                                >
                                  <Box
                                    className={
                                      message?.sender === "user"
                                        ? "message-sec"
                                        : "message-sec bot-message-sec"
                                    }
                                  >
                                    <Box className="profile-pic">
                                      <img
                                        src={
                                          message?.sender === "user"
                                            ? TipperProfile
                                            : Avatar
                                        }
                                        alt="profile"
                                      />
                                    </Box>
                                    <Box className="msg-time">
                                      <Box
                                        className={
                                          message?.sender === "user"
                                            ? "user-message-txt chat"
                                            : "bot-message-txt chat"
                                        }
                                      >
                                        {message?.text}
                                      </Box>
                                      <Box
                                        className={
                                          message?.sender === "user"
                                            ? "time"
                                            : "time align-end"
                                        }
                                      >
                                        10:49 am
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              ))}
                          </Box>
                        </Box>
                        <Box className="send-box">
                          {/* <TextField
                          placeholder="type..."
                          variant="outlined"
                          type="text"
                          name="sportoreventother"
                          className="text-field"
                          value={newMessage}
                          onChange={e => setNewMessage(e.target.value)}
                        /> */}
                          <TextareaAutosize
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textarea-field"
                            placeholder="type..."
                            multiline
                            maxRows={6}
                            name="message"
                            style={{ width: "97%", height: "18px" }}
                            value={newMessage}
                            onChange={e => setNewMessage(e.target.value)}
                          />
                          <Button
                            className="send-btn"
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            onClick={() =>
                              newMessage && handleSendMessage("bot")
                            }
                          >
                            Send
                          </Button>
                        </Box>
                      </Box>
                    </TabPanel>
                  );
                })}

                {/* <TabPanel value={value} index={1} className="tab-panel">
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2} className="tab-panel">
                Item Three
              </TabPanel>
              <TabPanel value={value} index={3} className="tab-panel">
                Item Four
              </TabPanel>
              <TabPanel value={value} index={4} className="tab-panel">
                Item Five
              </TabPanel>
              <TabPanel value={value} index={5} className="tab-panel">
                Item Six
              </TabPanel>
              <TabPanel value={value} index={6} className="tab-panel">
                Item Seven
              </TabPanel> */}
              </Box>
            ) : (
              <>
                {userData?.map(item => {
                  return (
                    <Box
                      className="mobile-message-sec"
                      onClick={() => handleUserMessageNavigation(item?.name)}
                    >
                      <Box className="avatar-sec">
                        <img
                          src={item?.id === 0 ? TipperProfile : Avatar}
                          alt="avatar"
                          className="avatar"
                        />
                      </Box>
                      <Box className="user-msg-info-sec">
                        <Typography className="tipper-name">
                          {item?.name}
                        </Typography>
                        <Typography className="last-msg">
                          {item?.lastMessage}
                        </Typography>
                        <Typography className="date">
                          <span>
                            {item?.date ? fetchDayName(item?.date) : ""},{" "}
                            {item?.date
                              ? moment
                                  .utc(item?.date)
                                  .local()
                                  .format("DD MMM")
                              : ""}{" "}
                          </span>{" "}
                          at{" "}
                          <span>
                            {item?.date
                              ? moment
                                  .utc(item?.date)
                                  .local()
                                  .format("hh:mm A")
                              : ""}{" "}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>

        <OurPartner />
      </Box>
    </>
  );
};
export default UserMessagesPage;
