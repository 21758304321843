import React from "react";
import { Box, Typography } from "@material-ui/core";
import Imgcutout from "../../../../../assets/images/imgcutout.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function NewsDetails() {
  return (
    <>
      <Box className="news-details-wrap">
        <Box>
          <Typography className="news-head">
            Lorem ipsum dolor sit amet.
          </Typography>
          <Box className="news-details news-details-border">
            <Box className="news-img-wrap">
              <img src={Imgcutout} alt="news-img" />
            </Box>
            <Box className="news-text-wrap">
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography className="news-head">
            Lorem ipsum dolor sit amet.
          </Typography>
          <Box className="news-details">
            <Box className="news-img-wrap">
              <img src={Imgcutout} alt="news-img" />
            </Box>
            <Box className="news-text-wrap">
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NewsDetails;
