import React, { useContext, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import PlayUp from "../../../assets/images/new-bookmakers/playup_logo.webp";
import DraftStars from "../../../assets/images/new-bookmakers/draftstars_logo.webp";
import UniBet from "../../../assets/images/new-bookmakers/unibet_logo.webp";
import BetFair from "../../../assets/images/new-bookmakers/betfair_logo.webp";
import TopSport from "../../../assets/images/new-bookmakers/topsport_logo.webp";
import BlueBet from "../../../assets/images/new-bookmakers/bluebet_logo.webp";
import BoomBet from "../../../assets/images/new-bookmakers/boombet_logo.webp";
import SouthernCrossBet from "../../../assets/images/new-bookmakers/southern-cross-bet_logo.webp";
import SEN from "../../../assets/images/new-bookmakers/SEN_logo.webp";
import SEN_Track from "../../../assets/images/new-bookmakers/SEN-track_logo.webp";
import LadsBroke from "../../../assets/images/new-bookmakers/ladbrokes_logo.webp";
import PuntonDogs from "../../../assets/images/bookmakers/Puntondogs_logo.webp";
import Bet365 from "../../../assets/images/new-bookmakers/BET365-ourpartner.webp";
import BetRight from "../../../assets/images/new-bookmakers/BETRIGHT-long.webp";
import EliteBet from "../../../assets/images/new-bookmakers/ELITEBET-long.webp";
import GSB from "../../../assets/images/new-bookmakers/GSB-long.webp";
import NedsLogo from "../../../assets/images/new-bookmakers/Neds_Logo.webp";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./partner.scss";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import Loader from "src/components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";

const OurPartner = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const reduxBookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [topBookmakerData, setTopBookmaker] = useState([]);
  const [isTopBookmakerLoading, setIsTopBookmakerLoading] = useState(true);
  const [isFeaturedBookmakerLoading, setIsFeaturedBookmakerLoading] = useState(
    true
  );

  const fetchBookKeeper = async (topBookmakerIds) => {
    const filteredBookmaker = reduxBookkeeperData
      ?.filter((ele) => ele?.isPartner === true)
      ?.filter((item) => !topBookmakerIds?.includes(item?.id));
    setBookKeeperData(filteredBookmaker);
  };

  const fetchTopBookKeeper = async () => {
    const topBookkeeper = reduxBookkeeperData?.filter(
      (item) => item?.isFeatured === true
    );
    setTopBookmaker(topBookkeeper);
    const topBookmakerIds = [];
    const topbookmakerArray = topBookkeeper?.map((item) =>
      topBookmakerIds?.push(item?.id)
    );
    fetchBookKeeper(topBookmakerIds);
  };

  const handleBookkeeperCounter = async (BookKeeperId) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: "ourpartner",
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    // fetchBookKeeper();
  }, []);

  useEffect(() => {
    if (reduxBookkeeperData) {
      fetchTopBookKeeper();
      setIsTopBookmakerLoading(false);
      setIsFeaturedBookmakerLoading(false);
    }
  }, [reduxBookkeeperData]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  const topBookmakerSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: screenWidth > 799 ? 6 : 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <>
      {release[Config.release]?.featuredBookmakers && (
        <Box className="ourpartner-container">
          <Box className="top-bookmaker">
            <h3 style={{ textAlign: "center" }} className="our-partner">
              Our Top bookmakers
            </h3>
            {isTopBookmakerLoading ? (
              <div className="allsport-loader-center news-loader">
                <Loader />
              </div>
            ) : (
              <Slider {...topBookmakerSettings} className="our-partner-slider">
                {topBookmakerData?.map((item) => {
                  return (
                    <Box className="partner-logo">
                      <a
                        href={item?.affiliate_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          className="slider-img"
                          src={
                            item?.logo?.includes("uploads")
                              ? Config.mediaURL + item?.logo
                              : item?.logo
                          }
                          onClick={() => handleBookkeeperCounter(item?.id)}
                          alt="Our Partner"
                        />
                      </a>
                    </Box>
                  );
                })}
              </Slider>
            )}
          </Box>
          <Box className="featured-bookmaker">
            <h3 style={{ textAlign: "center" }} className="our-partner">
              {localesData?.partner}
            </h3>
            {isFeaturedBookmakerLoading ? (
              <div className="allsport-loader-center news-loader">
                <Loader />
              </div>
            ) : screenWidth > 799 ? (
              <Box className="our-partner-logo">
                {BookkeeperData?.map((item) => {
                  return (
                    <Box className="partner-logo">
                      <a
                        href={item?.affiliate_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          src={
                            item?.logo?.includes("uploads")
                              ? Config.mediaURL + item?.logo
                              : item?.logo
                          }
                          onClick={() => handleBookkeeperCounter(item?.id)}
                          alt="Our Partner"
                        />
                      </a>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Slider {...settings} className="our-partner-slider">
                {BookkeeperData?.map((item) => {
                  return (
                    <Box className="partner-logo">
                      <a
                        href={item?.affiliate_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          className="slider-img"
                          src={
                            item?.logo?.includes("uploads")
                              ? Config.mediaURL + item?.logo
                              : item?.logo
                          }
                          onClick={() => handleBookkeeperCounter(item?.id)}
                          alt="Our Partner"
                        />
                      </a>
                    </Box>
                  );
                })}
              </Slider>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
export default OurPartner;
