import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import banner from "../../../assets/images/banner/smart-b-banner-secodary-white.webp";
// import AdvertisingBannner from "../advertisingBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import "./subscriptionandpayment.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as SubscriptionActive } from "../../../assets/images/icons/sub_plan_active.svg";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/oddsComparison/upArrow.svg";
// import { ReactComponent as Cvvimage } from "src/assets/images/cvvimage.svg";
import Cvvimage from "../../../assets/images/cvvimage.png";
import MasterCard from "../../../assets/images/mastercard.png";
import Visa from "../../../assets/images/visa.png";
import AmericanExpress from "../../../assets/images/americanExpress.png";
import PayPal from "../../../assets/images/payPal.png";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import OurPartner from "../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import PaymentModal from "../UI/paymentModal";
import AdBannner from "../AdBanner/AdBanner";
import { differenceInYears } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Select from "react-select";
import _ from "lodash";
import DialogBox from "../UI/dialogBox";
import { loginUser } from "src/helpers/store/Actions/auth";
import { setApiMessage } from "src/helpers/commonFunctions";

const subData = [
  {
    id: 0,
    type: "Freemium",
    price: "$10",
    mostPopular: true,
    features: [
      {
        id: 0,
        des:
          "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
        disabled: false
      },
      {
        id: 1,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: false
      },
      {
        id: 2,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: false
      },
      {
        id: 3,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: true
      },
      {
        id: 4,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: true
      }
    ],
    btn: "Subscribe Now!",
    loginBtn: "Sign Up Now"
  }
  // {
  //   id: 1,
  //   type: "Most Popular",
  //   price: "$120",
  //   mostPopular: true,
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     }
  //   ],
  //   btn: "Subscribe Now!"
  // },
  // {
  //   id: 2,
  //   type: "Premium",
  //   price: "$120",
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     }
  //   ],
  //   btn: "Try Premium Now!"
  // },
  // {
  //   id: 3,
  //   type: "Liftime Basic",
  //   price: "$120",
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     }
  //   ],
  //   btn: "Try Now!"
  // },
  // {
  //   id: 4,
  //   type: "Freemium",
  //   price: "$0",
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     }
  //   ],
  //   btn: "Sign Up for Free!"
  // }
];
const planData = [
  {
    id: 0,
    des:
      "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
    disabled: false
  },
  {
    id: 1,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: false
  },
  {
    id: 2,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: false
  },
  {
    id: 3,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: true
  },
  {
    id: 4,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: true
  }
];
const planData1 = [
  {
    id: 0,
    des: "Stay updated with racing fields and sports fixtures effortlessly.",
    disabled: false
  },
  {
    id: 1,
    des: "Utilise our racing form guide for informed decisions.",
    disabled: false
  },
  {
    id: 2,
    des: "Access historical racing and sports data.",
    disabled: false
  },
  {
    id: 3,
    des: "Get a free tip of the day.",
    disabled: false
  },
  {
    id: 4,
    des: "Compare odds across 5 featured bookmakers.",
    disabled: false
  },
  {
    id: 5,
    des: "Access detailed information guides on bookmakers.",
    disabled: false
  },
  {
    id: 6,
    des: "Explore betting trends for American Sports.",
    disabled: false
  },
  {
    id: 7,
    des: "Plan ahead with our event calendar view.",
    disabled: false
  },
  {
    id: 8,
    des: "Track average odds fluctuations.",
    disabled: false
  },
  {
    id: 9,
    des: "Benefit from user reviews of bookmakers.",
    disabled: false
  },
  {
    id: 10,
    des: "Save your preferences across the app seamlessly.",
    disabled: false
  },
  {
    id: 11,
    des: "Create and manage your own Blackbook.",
    disabled: false
  }
];
const planData2 = [
  {
    id: 0,
    des: "All of free tier sign up benefits and;",
    disabled: false
  },
  {
    id: 1,
    des: "New features updated every month",
    disabled: false
  },
  {
    id: 2,
    des:
      "Racing (Horse, Harness & Greyhounds) Odds Comparison for Win & Place featuring over 50 corporate bookmakers, plus Betfair and the 3 Totes.",
    disabled: false
  },
  {
    id: 3,
    des: "Filterable odds fluctuation graphs.",
    disabled: false
  },
  {
    id: 4,
    des:
      "Tailorable to your own bookmakers and race meetings/races of interest.",
    disabled: false
  },
  {
    id: 5,
    des: "Access race, runner, jockey, trainer, form & silks data directly.",
    disabled: false
  },
  {
    id: 6,
    des:
      "Set price watch notifications for odds on your runners or races of interest. Snipe the top early odds ahead of the rest.",
    disabled: false
  },
  {
    id: 7,
    des: "Choose between win or place odds separately.",
    disabled: false
  },
  {
    id: 8,
    des: "Import personal ratings sheet prices and staking.",
    disabled: false
  },
  {
    id: 9,
    des:
      "Multiple customisable layouts as per your preferences. Just set your layout and let smart odds comparison tell you your best bets.",
    disabled: false
  }
];
const SubscriptionAndPaymentPage = ({ navigationFrom }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const reduxGetUserData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [value, setValue] = useState("Monthly");
  const [formvalues, setformvalues] = useState();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [planId, setPlanId] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [planName, setPlanName] = useState("");
  const [getUserData, setGetUserData] = useState({});
  const [selectPlan, setSelectPlan] = useState({});
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
  const [showPassword, setshowPassword] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState([]);
  const [count, setcount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [searchCountryCount, setsearchCountryCount] = useState("");
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState("");
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [otpVerifyModal, setOtpVerifyModal] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [isOtp, setOtp] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [customerUserLoader, setCustomerUserLoader] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponApply, setCouponApply] = useState(false);
  const [couponCodeDetails, setCouponCodeDetails] = useState({});

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleRadioChange = e => {
    setValue(e?.target?.value);
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleModal = () => {
    if (handleRestrictedUser()) {
      createCustomerUpdate();
    } else {
      createCustomerUser();
    }
  };

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  const createCustomerUpdate = async () => {
    const customerPayload = {
      email: formvalues?.email,
      firstName: formvalues?.firstname,
      lastName: formvalues?.lastname,
      phone: formvalues?.phonenumber
    };
    try {
      const { status, data } = await axiosInstance.post(
        "/subscription/create-customer",
        customerPayload
      );
      if (status === 200) {
        if (selectedPlanDetails?.key === "free") {
          const isUpgrade = planDetails?.some(
            item => item?.purchasedPlan === true
          );
          const payload = {
            plan_id: planId,
            plateform: "web"
          };
          try {
            let passApi = isUpgrade
              ? "/subscription/upgrade-plan"
              : "/subscription/subscribe-plan";
            let method = isUpgrade ? "put" : "post";
            const { status, data } = await axiosInstance[method](
              passApi,
              payload
            );
            if (status === 200) {
              toast.success(data?.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored"
              });
              navigate("/mySubscription");
              fetchGetPlanDetails();
              fetchSubscriptionPaymentData();
            } else {
            }
          } catch (error) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });
          }
        } else {
          setPaymentModalOpen(true);
        }
      } else {
      }
    } catch (error) {}
  };

  const createCustomerUser = async () => {
    setCustomerUserLoader(true);
    const customerUserPayload = {
      firstName: formvalues?.firstname,
      lastName: formvalues?.lastname,
      phone: formvalues?.phonenumber,
      address: {
        country: formvalues?.country,
        state: formvalues?.state
      },
      username: formvalues?.email,
      password: formvalues?.password,
      dob: moment(formvalues?.dob).format("YYYY-MM-DD")
    };
    try {
      const { status, data } = await axiosInstance.post(
        "/subscription/createUser",
        customerUserPayload
      );
      if (status === 200) {
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        setOtpVerifyModal(true);
        setCustomerUserLoader(false);
        // setPaymentModalOpen(true);
      } else {
        setCustomerUserLoader(false);
        toast.error(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    } catch (error) {
      setCustomerUserLoader(false);
      toast.error(error?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };

  const handleOtpVerifyModalClose = () => {
    setOtpVerifyModal(false);
  };

  const scrollToBottomSection = data => {
    const targetSection = document.getElementById("details-section");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth"
      });
    }
    setPlanId(data?.id);
    setPlanPrice(data?.amount);
    setPlanName(data?.name);
    setSelectedPlanDetails(data);
    setCouponCode("");
    setCouponApply(false);
  };

  const scrollToTopSection = () => {
    const targetSection = document.getElementById("subscription-plan");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleClose = () => {
    setPaymentModalOpen(false);
  };

  useEffect(() => {
    fetchGetPlanDetails();
    fetchCountry(0);
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(countryId, pageState + 20, false, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);

  const fetchGetPlanDetails = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/plan-list`
      );
      if (status === 200) {
        setLoading(false);
        const selectedPlan = data?.getPlans?.filter(
          plan => plan?.purchasedPlan == true
        );
        if (selectedPlan?.length > 0) {
          // setPlanId(selectedPlan?.[0]?.id);
          setPlanPrice(selectedPlan?.[0]?.amount);
          setPlanName(selectedPlan?.[0]?.name);
          setSelectPlan(selectedPlan?.[0]);
          setSelectedPlanDetails(selectedPlan?.[0]);
        } else {
          // setPlanId("");
          setPlanPrice("");
          setPlanName("");
          setSelectPlan([]);
        }
        const planDetails = data?.getPlans;
        setPlanDetails(planDetails);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleloginPage = () => {
    localStorage.setItem("prev_authroute", window.location.pathname);
    setTimeout(() => {
      navigate("/sign-in");
    }, 2000);
  };

  useEffect(() => {
    if (reduxGetUserData && reduxGetUserData !== undefined) {
      setGetUserData(reduxGetUserData);
    }
  }, [reduxGetUserData]);

  const isLogin = handleRestrictedUser();

  const passwordValidation =
    isLogin === false
      ? Yup.string()
          .required(localesData?.validation?.required_message)
          .min(6, localesData?.validation?.password_length)
          .label("Password")
      : Yup.string().nullable();

  const dobValidation =
    isLogin === false
      ? Yup.date()
          .nullable()
          .typeError("")
          .test("dob", "You have to be at least 18", function(value) {
            return differenceInYears(new Date(), new Date(value)) >= 18;
          })
          .required(localesData?.validation?.required_message)
      : Yup.string().nullable();

  const countryStateValidation =
    isLogin === false
      ? Yup.string().required(localesData?.validation?.required_message)
      : Yup.string().nullable();

  // country select box api

  const fetchCountry = async page => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        const topCountries = [
          {
            label: "Australia",
            value: 13
          },
          {
            label: "United Kingdom",
            value: 230
          },
          {
            label: "United States",
            value: 231
          },
          {
            label: "India",
            value: 101
          }
        ];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          country => !topCountries?.includes(country?.value)
        );

        let track = nonPrioritizedCountries?.map(item => {
          newdata.push({
            label: item?.country,
            value: item?.id
          });
        });
        const orderedPriorityCountries = _.concat(topCountries, newdata);
        const orderedCountries = newdata;
        setCountry(
          _.unionBy(
            country,
            page == 0 ? orderedPriorityCountries : orderedCountries
          )
        );
        let filterData = _.unionBy(
          country,
          page == 0 ? orderedPriorityCountries : orderedCountries
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...topCountries);

        setCountry(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );

        //Old Code

        // let track = data?.result?.rows?.map((item) => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id,
        //   });
        // });

        // setCountry(_.unionBy(country, newdata));
      }
    } catch (err) {}
  };

  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20);
        setpageCountry(pageCountry + 20);
      }
    }
  };

  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.country,
              value: item?.id
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
          setisCountrySearch(value);
        }
      });
  };

  const fetchState = async (id, page, type, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.state,
            value: item?.id
          });
        });
        // setState(_.unionBy(state, newdata))
        if (type) {
          // setState(_.unionBy(newdata));
          let filterData = _.unionBy(newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function(e) {
              return e.value;
            })
          );
        } else {
          // setState(_.unionBy(state, newdata));
          let filterData = _.unionBy(state, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function(e) {
              return e.value;
            })
          );
        }
      }
    } catch (err) {}
  };

  const handleOnScrollBottomState = () => {
    // if (statecount !== Math.ceil(pageState / 20)) {
    //   fetchState(countryId, pageState + 20, false);
    //   setpageState(pageState + 20);
    // }
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(countryId, pageState + 20, false, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };

  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${countryId}?limit=20&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.state,
              value: item?.id
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
          setisStateSearch(value);
        }
      });
  };

  const handleVerifyOtp = async value => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        // `user/verifyOtp/${formvalues?.email}/${value?.otp}`
        `user/verifyOtp/${formvalues?.email}/${value?.otp}?isRegister=1`
      );
      if (status === 200) {
        setisLoading(false);
        let { access_token } = data;
        setIsToken(access_token);
        let Type = localStorage.getItem("type");
        let Url = localStorage.getItem("url");
        if (Type && Url) {
          window.location.href = Url;
          localStorage.removeItem("type");
          localStorage.removeItem("url");
          localStorage.setItem("smartb_user", formvalues?.email);
          localStorage.setItem("auth_token", JSON.stringify(access_token));
          dispatch(loginUser(access_token));
          dispatch(fetchRestrictedRoute(false));
        } else {
          let prev_auth_route = localStorage.getItem("prev_authroute");
          localStorage.setItem("auth_token", JSON.stringify(access_token));
          dispatch(loginUser(access_token));
          // setIsFinish(true);
          setOtp(false);
          setOtpError("");
          createCustomerUpdate();
          setTimeout(async () => {
            setOtpVerifyModal(false);
            if (selectedPlanDetails?.key === "free") {
              const isUpgrade = planDetails?.some(
                item => item?.purchasedPlan === true
              );
              const payload = {
                plan_id: planId,
                plateform: "web"
              };
              try {
                let passApi = isUpgrade
                  ? "/subscription/upgrade-plan"
                  : "/subscription/subscribe-plan";
                let method = isUpgrade ? "put" : "post";
                const { status, data } = await axiosInstance[method](
                  passApi,
                  payload
                );
                if (status === 200) {
                  toast.success(data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored"
                  });
                  navigate("/mySubscription");
                  fetchGetPlanDetails();
                  fetchSubscriptionPaymentData();
                } else {
                }
              } catch (error) {
                toast.error(error?.response?.data?.message, {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "colored"
                });
              }
            } else {
              setPaymentModalOpen(true);
            }
          }, 2000);
        }
      }
    } catch (err) {
      console.log("err", err?.response);
      setOtpError(err?.response?.data?.message);
      setisLoading(false);
    }
  };
  const handleResendOtp = async () => {
    setisLoading(true);
    setOtpError("");
    try {
      const { status, data } = await axiosInstance.get(
        `user/resendOtp/${formvalues?.email}`
      );
      if (status === 200) {
        setisLoading(false);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    } catch (err) {
      console.log("err", err?.response);
      setisLoading(false);
    }
  };
  const handleSignUpForFree = () => {
    dispatch(fetchRestrictedRoute(true));
    localStorage.setItem("prev_authroute", window.location.pathname);
  };

  const handleApplyCouponCode = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/coupon/verify/${couponCode}?plateform=web&plan_id=${planId}`
      );
      if (status === 200) {
        setCouponApply(true);
        setCouponCodeDetails(data?.data);
        setApiMessage("success", data?.data?.message);
      } else {
        setCouponCodeDetails({});
        setCouponApply(false);
        setCouponCode("");
        setApiMessage("error", data?.data?.message);
      }
    } catch (err) {
      setCouponCodeDetails({});
      setCouponApply(false);
      setCouponCode("");
      setApiMessage("error", err?.response?.data?.data?.message);
    }
  };

  return (
    <>
      <Box className="content-wrap">
        {navigationFrom !== "profile" && (
          <>
            <Box className="mobile-banner">
              {/* <AdvertisingBannner src={banner} className="banner" /> */}
              {fetchPageHeaderAds("218px", banner)}
            </Box>
            <Box className="subscriptionandpayment-data-header">
              <Box className="bredcrumn-details">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" to="/">
                      Home
                    </Link>
                    <Typography>Subscription</Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
              <Typography variant="h1">Subscription</Typography>
            </Box>
          </>
        )}
        <Box className="sub-card-wrap" id="subscription-plan">
          {loading && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!loading &&
            (planDetails?.length > 0 ? (
              planDetails?.map((card, index) => {
                {
                  /* const finalData =
                  index == 0 ? planData1 : index == 1 ? planData2 : planData; */
                }
                return (
                  <Box
                    key={index}
                    className={
                      card?.mostPopular
                        ? `subscriptionandpayment-data`
                        : `subscriptionandpayment-data most-popular-plan-data`
                    }
                  >
                    <Box
                      className={
                        card?.mostPopular
                          ? `subscription-plan most-popular-plan`
                          : `subscription-plan`
                      }
                    >
                      <Box className="plan-title-box">
                        <Box className="plan-text-flex">
                          <Typography className="plan-title">
                            {card?.name ? card?.name : "Dummy"}
                          </Typography>
                          {card?.key === "free" &&
                          handleRestrictedUser() &&
                          !card?.purchasedPlan ? (
                            <Typography className="sign-up-indicator">
                              <span className="dots"></span>Signed up
                            </Typography>
                          ) : card?.purchasedPlan ? (
                            <>
                              <Button
                                variant="contained"
                                className="current-plan"
                              >
                                Your Current Plan
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* {card?.purchasedPlan ? (
                            <Button
                              variant="contained"
                              className="current-plan"
                            >
                              Your Current Plan
                            </Button>
                          ) : (
                            <></>
                          )} */}
                        </Box>
                        <Typography className="plan-text-details">
                          {card?.subTitle ?? ""}
                        </Typography>
                      </Box>
                      <Box className="plan-devider"></Box>

                      <Box className="plan-info">
                        <Box className="price-box">
                          <Typography className="price">
                            {"$" + Number(card?.amount)?.toFixed(2)}
                            <span className="suffix">/{card?.duration}</span>
                          </Typography>
                        </Box>
                        <Box className="des-wrap">
                          {card?.featured?.map(item => {
                            return (
                              <Box className="des-box">
                                <Box className="tic-box">
                                  <span
                                  // className={
                                  //   item?.disabled ? `sub-disable` : ``
                                  // }
                                  >
                                    <SubscriptionActive />
                                  </span>
                                </Box>
                                <Box className="des">{item}</Box>
                              </Box>
                            );
                          })}
                        </Box>
                        {card?.purchasedPlan ? (
                          <Button className="blank-btn"></Button>
                        ) : card?.key === "free" ? (
                          handleRestrictedUser() ? (
                            <Button
                              disableElevation
                              disableFocusRipple
                              disableRipple
                              className="sub-btn"
                              onClick={() => scrollToBottomSection(card)}
                            >
                              {selectPlan &&
                              Number(selectPlan?.amount) > Number(card?.amount)
                                ? "Downgrade Plan"
                                : selectPlan &&
                                  Number(selectPlan?.amount) <
                                    Number(card?.amount)
                                ? "Upgrade Plan"
                                : "Select Plan"}
                            </Button>
                          ) : (
                            <Button
                              disableElevation
                              disableFocusRipple
                              disableRipple
                              className="sub-btn"
                              onClick={() => handleSignUpForFree()}
                            >
                              Sign up for Free!
                            </Button>
                          )
                        ) : (
                          <Button
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            // type="submit"
                            className="sub-btn"
                            onClick={() => scrollToBottomSection(card)}
                            // onClick={() => handleModal(card)}
                          >
                            {selectPlan &&
                            Number(selectPlan?.amount) > Number(card?.amount)
                              ? "Downgrade Plan"
                              : selectPlan &&
                                Number(selectPlan?.amount) <
                                  Number(card?.amount)
                              ? "Upgrade Plan"
                              : "Select Plan"}
                          </Button>
                        )}
                        {/* {card?.purchasedPlan || card?.amount == 0 ? (
                          <Button className="blank-btn"></Button>
                        ) : (
                          <Button
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            // type="submit"
                            className="sub-btn"
                            onClick={() => scrollToBottomSection(card)}
                            // onClick={() => handleModal(card)}
                          >
                            {selectPlan &&
                            Number(selectPlan?.amount) > Number(card?.amount)
                              ? "Downgrade Plan"
                              : selectPlan &&
                                Number(selectPlan?.amount) <
                                  Number(card?.amount)
                              ? "Upgrade Plan"
                              : "Select Plan"}
                          </Button>
                        )} */}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box> No data available</Box>
            ))}
        </Box>
        <Box className="your-detalis-wrap" id="details-section">
          <Box className="details-wrap">
            <Box className="left-your-selection">
              <Box className="your-selection-wrap">
                <Box className="selection-header">
                  <Typography className="header ">Apply coupon code</Typography>
                </Box>
                <Box className="subscription-type-details">
                  <Box className="coupon-code-wrap">
                    <TextField
                      placeholder="Coupon code (optional)"
                      variant="outlined"
                      type="text"
                      className="text-field details-textfield"
                      label="Coupon Code"
                      name="couponCode"
                      onChange={e => {
                        setCouponCode(e?.target?.value);
                      }}
                      value={couponCode}
                    />

                    <Button
                      variant="contained"
                      className="apply-btn"
                      disabled={!Boolean(couponCode?.length > 0) || !planId}
                      onClick={() =>
                        handleRestrictedUser()
                          ? handleApplyCouponCode()
                          : handleSignUpForFree()
                      }
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box className="your-selection-wrap">
                <Box className="selection-header">
                  <Typography className="header ">Your Selection</Typography>
                </Box>
                <Box className="subscription-type-details">
                  <Typography className="subscription-type subscription-text fw-600">
                    Subscription type
                  </Typography>
                  <Box
                    className={
                      selectedPlanDetails?.key === "odds"
                        ? "plan-deatils plan-deatils-mb-9"
                        : "plan-deatils plan-deatils-mb-19"
                    }
                  >
                    <Typography className="plan-name subscription-text">
                      {selectedPlanDetails?.name
                        ? selectedPlanDetails?.name
                        : "-"}
                    </Typography>
                    <Typography className="plan-price subscription-text">
                      {selectedPlanDetails?.key === "odds"
                        ? "14 days free"
                        : selectedPlanDetails?.key === "free"
                        ? "Free"
                        : selectedPlanDetails?.amount
                        ? `${"$" + selectedPlanDetails?.amount}/${
                            selectedPlanDetails?.duration
                          }`
                        : "-"}
                    </Typography>
                  </Box>
                  {selectedPlanDetails?.key === "odds" && (
                    <Box className="plan-deatils plan-deatils-mb-19">
                      <Typography className="plan-name subscription-text"></Typography>
                      <Typography className="plan-price subscription-text">
                        {selectedPlanDetails?.amount
                          ? `${"$" + selectedPlanDetails?.amount}/${
                              selectedPlanDetails?.duration
                            } after`
                          : "-"}
                      </Typography>
                    </Box>
                  )}
                  {/* <Box className="plan-select">
                  <RadioGroup
                    aria-label="plan"
                    name="plan"
                    className="plan-radioGroup"
                    value={value}
                    onChange={e => handleRadioChange(e)}
                  >
                    <FormControlLabel
                      value="Monthly"
                      control={
                        <Radio
                          size="small"
                          icon={<UnChecked className="radio-icon" />}
                          checkedIcon={<Checked className="radio-icon" />}
                          disableRipple
                        />
                      }
                      label="Billed Monthly"
                    />
                    <FormControlLabel
                      value="Annually"
                      control={
                        <Radio
                          size="small"
                          icon={<UnChecked className="radio-icon" />}
                          checkedIcon={<Checked className="radio-icon" />}
                          disableRipple
                        />
                      }
                      label="Billed Annually (Save 25%)"
                    />
                  </RadioGroup>
                </Box> */}
                  {couponApply && (
                    <Box className="total-details-wrap coupon-code-details-wrap">
                      <Box className="total-details ">
                        <Typography className="total-name subscription-text fw-400">
                          Subtotal
                        </Typography>
                        <Typography className="total-price subscription-text fw-400">
                          {selectedPlanDetails?.amount
                            ? `${"$" +
                                Number(selectedPlanDetails?.amount)?.toFixed(
                                  2
                                )}`
                            : "-"}
                        </Typography>
                      </Box>

                      <Box className="total-details ">
                        <Typography className="total-name subscription-text fw-400">
                          Discount
                        </Typography>
                        <Typography className="total-price subscription-text discount-amount fw-400">
                          {couponCodeDetails?.data?.discountAmount === 0 ||
                          couponCodeDetails?.data?.discountAmount === ""
                            ? couponCodeDetails?.data?.extraDays +
                              " " +
                              (couponCodeDetails?.data?.extraDays > 1
                                ? "days free"
                                : "day free")
                            : couponCodeDetails?.discountAmountPrize
                            ? "- " +
                              `${"$" +
                                Number(
                                  couponCodeDetails?.discountAmountPrize
                                )?.toFixed(2)}`
                            : "-"}
                          <span>
                            <UpArrow />
                          </span>
                        </Typography>
                      </Box>
                      <Box className="coupon-code-details">
                        <Box className="details-code">
                          <Typography className="amount-off">
                            {couponCodeDetails?.data?.discountAmount === 0 ||
                            couponCodeDetails?.data?.discountAmount === ""
                              ? `${couponCodeDetails?.data?.extraDays} ${
                                  couponCodeDetails?.data?.extraDays > 1
                                    ? "days free trial"
                                    : "day free trial"
                                }`
                              : `${
                                  couponCodeDetails?.data?.isPercentage
                                    ? couponCodeDetails?.data?.discountAmount +
                                      "% off"
                                    : couponCodeDetails?.data?.discountAmount +
                                      "$ off"
                                }` +
                                (couponCodeDetails?.data?.extraDays
                                  ? ` + ${couponCodeDetails?.data?.extraDays} ${
                                      couponCodeDetails?.data?.extraDays > 1
                                        ? "days free"
                                        : "day free"
                                    }`
                                  : "")}{" "}
                          </Typography>
                          {couponCodeDetails?.data?.months && (
                            <Typography className="card-text mb-7 duration-text">
                              {couponCodeDetails?.data?.isPercentage
                                ? couponCodeDetails?.data?.discountAmount + "%"
                                : couponCodeDetails?.data?.discountAmount +
                                  "$"}{" "}
                              of on subscription for the first{" "}
                              {couponCodeDetails?.data?.months}{" "}
                              {couponCodeDetails?.data?.months > 1
                                ? "months"
                                : "month"}
                            </Typography>
                          )}
                          <Typography className="card-text mb-7 coupon-details-text">
                            {couponCodeDetails?.data?.description
                              ? couponCodeDetails?.data?.description
                              : "-"}
                          </Typography>
                          <Typography className="card-text copon-vaild-date">
                            Valid:{" "}
                            {moment(couponCodeDetails?.data?.startAt).format(
                              "DD/MM/YYYY"
                            ) ?? ""}{" "}
                            {couponCodeDetails?.data?.expireAt
                              ? " - " +
                                  moment(
                                    couponCodeDetails?.data?.expireAt
                                  ).format("DD/MM/YYYY") ?? ""
                              : ""}
                          </Typography>
                        </Box>
                        <Box className="coupon-number">
                          <Box className="coupon-card border-element">
                            <span className="circle-bottom"></span>
                            {couponCodeDetails?.data?.code}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box className="total-details-wrap">
                    {selectedPlanDetails?.key === "odds" && (
                      <Box className="total-details ">
                        <Typography className="total-name subscription-text fw-400">
                          Total after trial
                        </Typography>
                        <Typography className="total-price subscription-text fw-400">
                          {couponApply
                            ? "$" +
                              (
                                Number(selectedPlanDetails?.amount ?? 0) -
                                Number(
                                  couponCodeDetails?.discountAmountPrize ?? 0
                                )
                              )?.toFixed(2)
                            : selectedPlanDetails?.amount
                            ? `${"$" +
                                Number(selectedPlanDetails?.amount)?.toFixed(
                                  2
                                )}`
                            : "-"}
                        </Typography>
                      </Box>
                    )}
                    <Box className="total-details ">
                      <Typography className="total-name subscription-text">
                        Today's total
                      </Typography>
                      <Typography className="total-price subscription-text">
                        {selectedPlanDetails?.key === "odds"
                          ? "$0.00"
                          : selectedPlanDetails?.amount
                          ? `${"$" +
                              Number(selectedPlanDetails?.amount)?.toFixed(2)}`
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="update-subscription">
                  <Typography
                    className="update-subscription-type"
                    onClick={() => scrollToTopSection()}
                  >
                    Update subscription type
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="your-details-wrap">
              <Typography className="details">
                {isLogin ? "Enter your billing details" : "Enter your details"}
              </Typography>
              <Box className="personal-details">
                {!isLogin && (
                  <Typography className="your-account">
                    If already have an account. Subscribe with your{" "}
                    <span
                      className="existing-account"
                      onClick={() => handleloginPage()}
                    >
                      existing account
                    </span>
                    .
                  </Typography>
                )}

                <Formik
                  enableReinitialize
                  initialValues={{
                    email: getUserData ? getUserData?.username : "",
                    firstname: getUserData ? getUserData?.firstName : "",
                    lastname: getUserData ? getUserData?.lastName : "",
                    password: "",
                    dob: null,
                    country: "",
                    state: "",
                    phonenumber: getUserData ? getUserData?.phone : "",
                    documentsRead: false
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .nullable()
                      .email(localesData?.validation?.valid_email)
                      .max(255)
                      .required(localesData?.validation?.required_message),
                    firstname: Yup.string()
                      .nullable()
                      .required(localesData?.validation?.required_message),
                    lastname: Yup.string()
                      .nullable()
                      .required(localesData?.validation?.required_message),
                    password: passwordValidation,
                    dob: dobValidation,
                    country: countryStateValidation,
                    state: countryStateValidation,
                    phonenumber: Yup.string()
                      .nullable()
                      .matches(
                        phoneRegExp,
                        localesData?.validation?.number_valid
                      )
                      .required(localesData?.validation?.required_message)
                      .min(9, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
                      .max(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
                    documentsRead: Yup.boolean().oneOf(
                      [true],
                      "Checkbox is required"
                    )
                  })}
                  onSubmit={() => handleModal()}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.email}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="email"
                            error={Boolean(touched?.email && errors?.email)}
                            helperText={touched?.email ? errors?.email : ""}
                            onChange={handleChange}
                            value={values?.email}
                          />
                        </Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.first_name}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="firstname"
                            error={Boolean(
                              touched?.firstname && errors?.firstname
                            )}
                            helperText={
                              touched?.firstname ? errors?.firstname : ""
                            }
                            onChange={handleChange}
                            value={values?.firstname}
                          />
                        </Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.last_name}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="lastname"
                            error={Boolean(
                              touched?.lastname && errors?.lastname
                            )}
                            helperText={
                              touched?.lastname ? errors?.lastname : ""
                            }
                            onChange={handleChange}
                            value={values?.lastname}
                          />
                        </Box>
                        {!isLogin && (
                          <>
                            <Box className="personal-info">
                              <Typography className="textfield-text">
                                Create password<span className="star">*</span>:
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                className="details-textfield"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                InputProps={{
                                  endAdornment: !showPassword ? (
                                    <UnVisiblePassword
                                      onClick={() =>
                                        setshowPassword(!showPassword)
                                      }
                                    />
                                  ) : (
                                    <VisiblePassword
                                      onClick={() =>
                                        setshowPassword(!showPassword)
                                      }
                                    />
                                  )
                                }}
                                error={Boolean(
                                  touched?.password && errors?.password
                                )}
                                helperText={
                                  touched?.password ? errors?.password : ""
                                }
                                onChange={handleChange}
                                value={values?.password}
                              />
                            </Box>
                            <Box className="personal-info">
                              <Typography className="textfield-text">
                                DOB<span className="star">*</span>:
                              </Typography>
                              <Box className="date-picker-wrap">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    autoOk
                                    // disableToolbar
                                    disableFuture
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    placeholder="DD/MM/YYYY"
                                    margin="dense"
                                    id="date-picker-inline"
                                    // openTo="date month year"
                                    inputVariant="outlined"
                                    // value={selectedDate}
                                    // onChange={(e) => handleDateChange(e)}
                                    value={values?.dob}
                                    views={["year", "month", "date"]}
                                    onChange={e => {
                                      setFieldValue("dob", e);
                                      setformvalues(prevFormValues => ({
                                        ...prevFormValues,
                                        dob: e
                                      }));
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                    // style={{ marginRight: 5 }}
                                    className="details-search-picker"
                                  />
                                </MuiPickersUtilsProvider>
                                <span className="text-danger">
                                  {touched?.dob && errors?.dob
                                    ? errors?.dob
                                    : ""}
                                </span>
                              </Box>
                            </Box>
                            <Box className="personal-info">
                              <Typography className="textfield-text">
                                Country<span className="star">*</span>:
                              </Typography>
                              <Box className="select-box-wrap ">
                                <Select
                                  className="select"
                                  classNamePrefix="select"
                                  onMenuScrollToBottom={e =>
                                    handleOnScrollBottomCountry(e)
                                  }
                                  onInputChange={e =>
                                    handleCountryInputChange(0, e)
                                  }
                                  placeholder="-Select-"
                                  value={
                                    isCountrySearch
                                      ? searchCountry?.find(item => {
                                          return item?.value == country;
                                        })
                                      : country?.find(item => {
                                          const countryValue = values?.country;
                                          return item?.value == countryValue;
                                        })
                                  }
                                  onChange={e => {
                                    setFieldValue("country", e?.value);
                                    fetchState(e?.value, 0, true, 0);
                                    setCountryId(e?.value);
                                    setFieldValue("state", "");
                                    setState([]);
                                    setStateApiCount(0);
                                    setpageState(0);
                                    setisCountrySelectOpen(false);
                                    // setstateValue("");
                                    setformvalues(prevFormValues => ({
                                      ...prevFormValues,
                                      country: e?.value
                                    }));
                                  }}
                                  onFocus={() => setisCountrySelectOpen(true)}
                                  onBlur={() => setisCountrySelectOpen(false)}
                                  options={
                                    isCountrySearch ? searchCountry : country
                                  }
                                />

                                <span className="text-danger">
                                  {touched?.country && errors?.country
                                    ? errors?.country
                                    : ""}
                                </span>
                              </Box>
                            </Box>
                            <Box className="personal-info">
                              <Typography className="textfield-text">
                                State<span className="star">*</span>:
                              </Typography>
                              <Box className="select-box-wrap ">
                                <Select
                                  className="select"
                                  classNamePrefix="select"
                                  onMenuScrollToBottom={e =>
                                    handleOnScrollBottomState(e)
                                  }
                                  onInputChange={e =>
                                    handleStateInputChange(0, e)
                                  }
                                  placeholder="-Select-"
                                  value={
                                    isStateSearch
                                      ? searchState?.find(item => {
                                          return item?.value == state;
                                        })
                                      : values?.state !== "" &&
                                        state?.find(item => {
                                          const stateValue = values?.state;
                                          return item?.value == stateValue;
                                        })
                                  }
                                  onChange={e => {
                                    setFieldValue("state", e?.value);
                                    setisStateSelectOpen(false);
                                    setStateApiCount(0);
                                    // setstateValue(e?.value);
                                    setformvalues(prevFormValues => ({
                                      ...prevFormValues,
                                      state: e?.value
                                    }));
                                  }}
                                  onFocus={() => setisStateSelectOpen(true)}
                                  onBlur={() => setisStateSelectOpen(false)}
                                  options={isStateSearch ? searchState : state}
                                />
                                <span className="text-danger">
                                  {touched?.state && errors?.state
                                    ? errors?.state
                                    : ""}
                                </span>
                              </Box>
                            </Box>
                          </>
                        )}
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.phonenumber}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="number"
                            name="phonenumber"
                            error={Boolean(
                              touched?.phonenumber && errors?.phonenumber
                            )}
                            helperText={
                              touched?.phonenumber ? errors?.phonenumber : ""
                            }
                            onChange={handleChange}
                            value={values?.phonenumber}
                          />
                        </Box>
                        <Box className="checkBox-wrap">
                          <FormControlLabel
                            className="documentsRead-check"
                            control={
                              <Checkbox
                                className="documentsRead-checkbox"
                                checked={values?.documentsRead}
                                onChange={() =>
                                  setFieldValue(
                                    "documentsRead",
                                    !values?.documentsRead
                                  )
                                }
                                color="primary"
                              />
                            }
                            error={
                              touched?.documentsRead && errors?.documentsRead
                            }
                          />
                          <Typography className="policy-text">
                            I have read and agree to the{" "}
                            <span
                            // onClick={() => navigate("")}
                            >
                              <NavLink to="/privacy-policy" target="_blank">
                                Privacy Policy
                              </NavLink>
                            </span>{" "}
                            &{" "}
                            <span
                            // onClick={() => navigate("/terms-and-conditions")}
                            >
                              <NavLink
                                to="/subscription-terms-and-conditions"
                                target="_blank"
                              >
                                Subscription Terms and Conditions
                              </NavLink>
                            </span>
                          </Typography>
                        </Box>
                        {touched?.documentsRead && errors?.documentsRead && (
                          <Typography variant="body2" color="error">
                            {errors?.documentsRead}
                          </Typography>
                        )}
                        <Box className="payment-btn-wrap">
                          <Button
                            variant="contained"
                            type="submit"
                            className="payment-btn"
                            disabled={
                              !planId ||
                              getUserData?.subscription?.plateform == "app"
                            }
                          >
                            Continue for payment
                          </Button>
                        </Box>
                      </Box>
                      {setformvalues(values)}
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
        <OurPartner />
      </Box>
      {/* <DialogBox
        open={paymentModalOpen}
        handleClose={handleClose}
        title={false}
        className="payment-modal"
        content={<>payment</>}
        closeIcon={true}
      /> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={paymentModalOpen}
        className="payment-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Payment
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            className="close-icon"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="payment-details">
          <PaymentModal
            paymentModalOpen={paymentModalOpen}
            setPaymentModalOpen={setPaymentModalOpen}
            handleClose={handleClose}
            fetchGetPlanDetails={fetchGetPlanDetails}
            planPrice={planPrice}
            planId={planId}
            planDetails={planDetails}
            couponApply={couponApply}
            couponCode={couponCode}
            couponCodeDetails={couponCodeDetails}
          />
        </DialogContent>
      </Dialog>
      <DialogBox
        open={otpVerifyModal}
        onClose={handleOtpVerifyModalClose}
        handleClose={handleOtpVerifyModalClose}
        title={false}
        className="my-subscription-otp-modal"
        content={
          <>
            <Box className="otp-msg-wrap">
              <Formik
                initialValues={{
                  otp: ""
                }}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required(localesData?.validation?.required_message)
                    .matches(/^\d{6}$/, "OTP must be a 6-digit number")
                })}
                onSubmit={values => handleVerifyOtp(values)}
              >
                {({
                  errors,
                  resetForm,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  ...formik
                }) => (
                  <form onSubmit={handleSubmit}>
                    {isLoading ? (
                      <div className="auth-loader">
                        <Loader />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Typography className="login-title login-heading">
                      {localesData?.register?.enter_otp}
                    </Typography>
                    <Typography className="otp-content">
                      We have sent an OTP to{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formvalues?.email}
                      </span>{" "}
                      Please check it and enter the OTP here. If you don't
                      receive an email in your inbox, please check your spam or
                      junk folder
                    </Typography>
                    <Box className="textfield-sec">
                      <Typography className="textfield-text">
                        {localesData?.register?.otp}
                      </Typography>
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type="text"
                        name="otp"
                        className="text-field"
                        error={Boolean(touched?.otp && errors?.otp)}
                        helperText={touched?.otp ? errors?.otp : ""}
                        onChange={handleChange}
                        value={values?.otp}
                        onKeyPress={e => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 6
                          }
                        }}
                        onFocus={() => setOtpError("")}
                      />
                      <span className="text-danger">{isOtpError}</span>
                    </Box>
                    <Typography
                      className="forgot-password-link"
                      onClick={() => {
                        resetForm();
                        handleResendOtp();
                      }}
                    >
                      {localesData?.register?.RESEND_OTP}
                    </Typography>
                    <Button
                      type="submit"
                      className="submit-btn"
                      disabled={customerUserLoader}
                    >
                      {localesData?.register?.VERIFY_OTP}
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
          </>
        }
        closeIcon={true}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default SubscriptionAndPaymentPage;
