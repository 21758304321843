import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { Box, Button, TextField, Typography } from "@material-ui/core";

import Loader from "src/components/Loader";
import { useNavigate } from "react-router";

const VerifyOtp = ({
  handleVerifyOtp,
  isLoading,
  formvalues,
  isOtpError,
  setOtpError,
  handleResendOtp,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;


  return (
    <Box className="otp-msg-wrap-v2">
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={Yup.object().shape({
          otp: Yup.string()
            .required(localesData?.validation?.required_message)
            .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
        })}
        onSubmit={(values) => handleVerifyOtp(values)}
      >
        {({
          errors,
          resetForm,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          ...formik
        }) => (
          <form onSubmit={handleSubmit}>
            {isLoading ? (
              <div className="auth-loader">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            <Typography className="login-title login-heading">
              {localesData?.register?.enter_otp}
            </Typography>
            <Typography className="otp-content">
              We have sent an OTP to{" "}
              <span style={{ fontWeight: "bold" }}>
                {formvalues?.email}
              </span>{" "}
              Please enter the OTP to confirm your email address. If you don't receive an email in your inbox, please check your spam or junk folder
            </Typography>
            <Box className="textfield-sec">
              {/* <Typography className="textfield-text">
                        {localesData?.register?.otp}
                    </Typography> */}
              <TextField
                placeholder={""}
                variant="outlined"
                type="text"
                name="otp"
                label="Enter OTP"
                className="text-field"
                error={Boolean(touched?.otp && errors?.otp)}
                helperText={touched?.otp ? errors?.otp : ""}
                onChange={handleChange}
                value={values?.otp}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 6,
                  },
                }}
                onFocus={() => setOtpError("")}
              />
              <span className="text-danger">{isOtpError}</span>
            </Box>
            <Button type="submit" className="submit-btn">
              {localesData?.register?.CONFIRM_OTP}
            </Button>
            <Box className="sign-in-account-sec forgot-password-link">
              <Typography className="account-text">
                {localesData?.register?.DONT_RECEIEVE_OTP}
              </Typography>
              <Typography
                className="redirect-link"
                onClick={() => {
                  resetForm();
                  handleResendOtp();
                }}
              >
                {localesData?.register?.RESEND_OTP}
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default VerifyOtp;
