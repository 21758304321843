import React, { useState, useEffect } from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useParams, useNavigate } from "react-router-dom";
// import Placeholder from "../../../../assets/images/placeOrder.png";
// import Video1 from "../../../../assets/images/video1.png";
// import Video2 from "../../../../assets/images/video2.png";
import adNews from "../../../../assets/images/ad-placeholder/adnews.webp";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import { ReactComponent as BookmarkIcon } from "src/assets/images/icons/bookmark-icon.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment-timezone";
import axiosInstance from "src/helpers/Axios/axiosInstance";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import RightSidebar from "../../righsidebar/rightsidebar";
import { setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import RightSideBarNews from "../../UI/rightSideBarNews";

// const slider1Data = [
//   {
//     id: 1,
//     video: Video1
//   },
//   {
//     id: 2,
//     video: Video1
//   },
//   {
//     id: 3,
//     video: Video1
//   },
//   {
//     id: 4,
//     video: Video1
//   },
//   {
//     id: 5,
//     video: Video1
//   },
//   {
//     id: 6,
//     video: Video1
//   }
// ];

// const slider2Data = [
//   {
//     id: 1,
//     video: Video2
//   },
//   {
//     id: 2,
//     video: Video2
//   },
//   {
//     id: 3,
//     video: Video2
//   },
//   {
//     id: 4,
//     video: Video2
//   },
//   {
//     id: 5,
//     video: Video2
//   },
//   {
//     id: 6,
//     video: Video2
//   }
// ];

const RightNewsSideBar = ({ fetchSideNewsAds }) => {
  const ReduxNewsData = useSelector(state => state?.reduxData?.RelatedNewsData);
  const ReduxBookkeeperData = useSelector(
    state => state?.reduxData?.BookkeeperData
  );
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [NewsRelatedArticles, setNewsRelatedArticles] = useState([]);
  const [NewsCategoryId, setNewsCatergoryId] = useState("");
  const [featureNewsData, setFeatureNewsData] = useState([]);
  const [featureNewsLoader, setFeatureNewsLoader] = useState(false);
  const [topBookMakersData, setTopBookMakersData] = useState([]);
  // const [categoryList, setCategoryList] = useState([]);
  // const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (ReduxNewsData) {
      setNewsRelatedArticles(ReduxNewsData?.NewsRelatedArticles);
      setNewsCatergoryId(ReduxNewsData?.NewsCategoryId);
    }
  }, [ReduxNewsData]);

  useEffect(() => {
    if (ReduxBookkeeperData) {
      const isFeaturedData = ReduxBookkeeperData?.filter(
        item => item?.isFeatured === true
      );
      const sortedData = isFeaturedData?.sort(
        (a, b) => a.featured_order - b.featured_order
      );
      setTopBookMakersData(sortedData);
    }
  }, [ReduxBookkeeperData]);

  useEffect(() => {
    fetchFeaturedNews();
    // fetchNewsCategoryList();
  }, []);

  // useEffect(() => {
  //   if (categoryList?.length > 0) {
  //     fetchCategoryName(categoryList);
  //   }
  // }, [categoryList, params?.categoryId]);

  // const fetchNewsCategoryList = async () => {
  //   setLoading(true);
  //   try {
  //     const { status, data } = await axiosInstance.get(
  //       "v2/news/category?All=0"
  //     );
  //     if (status === 200) {
  //       setLoading(false);
  //       let response = data?.result;
  //       let newdata = [];
  //       let FinalData = response?.map(item => {
  //         newdata.push({
  //           label: item?.title,
  //           value: item?.id
  //         });
  //       });
  //       const allCategory = { label: "all", value: 0 };
  //       let allCategoryList = [...newdata, allCategory];
  //       let sortData = allCategoryList?.sort((a, b) => {
  //         return a.value > b.value ? 1 : -1;
  //       });
  //       setCategoryList(sortData);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // const fetchCategoryName = id => {
  //   let categoryName = categoryList?.filter(item => item?.value == id);
  //   return categoryName?.[0]?.label;
  // };

  const fetchFeaturedNews = async () => {
    setFeatureNewsLoader(true);
    const allSportId = [
      ...release[Config.release]?.sportId,
      ...release[Config.release]?.raceSportId
    ];
    try {
      const passApi = `/v2/news/feature/articles?SportId=${allSportId?.toString()}`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFeatureNewsLoader(false);
        const articalData = data?.result;
        setFeatureNewsData(articalData);
      }
    } catch (error) {
      setFeatureNewsLoader(false);
    }
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleNewsSave = async (e, item, type) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted"
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.NewsArticle?.id}`,
          payload
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.NewsArticle?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!"
          );
          if (type === "featuredNews") {
            const updateNews = featureNewsData?.map(article =>
              article.NewsArticleId === item?.NewsArticleId
                ? {
                    ...article,
                    NewsArticle: {
                      ...article.NewsArticle,
                      isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0
                    }
                  }
                : article
            );
            setFeatureNewsData(updateNews);
          } else {
            const updateNews = NewsRelatedArticles?.map(article =>
              article.relatedArticleId === item?.relatedArticleId
                ? {
                    ...article,
                    NewsArticle: {
                      ...article.NewsArticle,
                      isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0
                    }
                  }
                : article
            );
            setNewsRelatedArticles(updateNews);
          }
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
    }
  };

  const handleFeaturedBookmakersClick = async data => {
    const payload = {
      BookKeeperId: data?.id,
      type: "featured"
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
    window.open(data.affiliate_link, "_blank");
  };
  return (
    <>
      {/* <RightSideBarNews mainData={featureNewsData} /> */}
      {featureNewsData?.length > 0 ? (
        <Box className="right-sider-bar-section">
          <Typography variant="h4" className="news-heading">
            Featured news
          </Typography>
          {featureNewsData?.length > 0 ? (
            featureNewsData?.slice(0, 6)?.map((item, index) => (
              <>
                <Box className="related-articles" key={index}>
                  <Box
                    className="single-related-stories"
                    onClick={() =>
                      navigate(
                        `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
                      )
                    }
                  >
                    <Box className="related-stories-img">
                      <img
                        src={
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.gallery?.url
                              : DefaultImg
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                              : DefaultImg
                            : DefaultImg
                        }
                        alt={
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.alt
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
                            : ""
                        }
                      />
                    </Box>
                    <Box className="stories-details">
                      <Typography
                        className="details"
                        dangerouslySetInnerHTML={{
                          __html: item?.NewsArticle?.title
                        }}
                      ></Typography>
                      <Box className="text-details">
                        <Typography className="stories-time">
                          {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                        </Typography>
                        <Box className="bookmark-chat-wrapper">
                          <Box
                            onClick={e =>
                              handleNewsSave(e, item, "featuredNews")
                            }
                          >
                            <Tooltip
                              placement="bottom"
                              arrow
                              title='Save to "Saved articles"'
                              classes={{
                                tooltip: "news-tooltip"
                              }}
                            >
                              <BookmarkIcon
                                className={
                                  item?.NewsArticle?.isSaved === 0
                                    ? "unsaved-bookmark-icon"
                                    : "saved-bookmark-icon"
                                }
                              />
                            </Tooltip>
                          </Box>
                          <Tooltip
                            placement="bottom"
                            arrow
                            title="Comments"
                            classes={{
                              tooltip: "news-tooltip"
                            }}
                          >
                            <Box className="chat-details">
                              <ChatIcon />
                              <Typography className="stories-time">
                                {item?.NewsArticle?.totalComments}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ))
          ) : (
            <Box className="no-data">
              <Typography>No Data Available</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <></>
      )}

      {NewsRelatedArticles?.length > 0 ? (
        <Box className="right-sider-bar-section mt-30">
          <Typography variant="h4" className="news-heading">
            Related Articles
          </Typography>
          {NewsRelatedArticles?.length > 0 ? (
            NewsRelatedArticles?.slice(0, 6)?.map((item, index) => (
              <>
                <Box className="related-articles">
                  <Box
                    key={item?.id}
                    className="single-related-stories"
                    onClick={() =>
                      navigate(
                        `/news/${NewsCategoryId}/${item?.relatedArticleId}`
                      )
                    }
                  >
                    <Box className="related-stories-img">
                      <img
                        src={
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.gallery?.url
                              : DefaultImg
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                              : DefaultImg
                            : DefaultImg
                        }
                        alt={
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.alt
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
                            : ""
                        }
                      />
                    </Box>
                    <Box className="stories-details">
                      <Typography
                        className="details"
                        dangerouslySetInnerHTML={{
                          __html: item?.NewsArticle?.subTitle
                        }}
                      ></Typography>
                      <Box className="text-details">
                        <Typography className="stories-time">
                          {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                        </Typography>
                        <Box className="bookmark-chat-wrapper">
                          <Box
                            onClick={e =>
                              handleNewsSave(e, item, "relatedArticles")
                            }
                          >
                            <Tooltip
                              placement="bottom"
                              arrow
                              title='Save to "Saved articles"'
                              classes={{
                                tooltip: "news-tooltip"
                              }}
                            >
                              <BookmarkIcon
                                className={
                                  item?.NewsArticle?.isSaved === 0
                                    ? "unsaved-bookmark-icon"
                                    : "saved-bookmark-icon"
                                }
                              />
                            </Tooltip>
                          </Box>
                          <Tooltip
                            placement="bottom"
                            arrow
                            title="Comments"
                            classes={{
                              tooltip: "news-tooltip"
                            }}
                          >
                            <Box className="chat-details">
                              <ChatIcon />
                              <Typography className="stories-time">
                                {item?.NewsArticle?.totalComments}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ))
          ) : (
            <Box className="no-data">
              <Typography>No Data Available</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <></>
      )}

      {topBookMakersData?.length > 0 ? (
        <Box className="right-sider-bar-section mt-30">
          <Typography variant="h4" className="news-heading">
            Our top bookmakers
          </Typography>
          {topBookMakersData?.length > 0 ? (
            topBookMakersData?.slice(0, 6)?.map((item, index) => {
              return (
                <Box
                  className="top-bookmaker-detail-wrap  cursor-pointer"
                  onClick={() => handleFeaturedBookmakersClick(item)}
                  key={index}
                >
                  <Box
                    className="top-bookmaker-left"
                    style={{ backgroundColor: `${item?.logo_bg}` }}
                  >
                    <img
                      src={
                        item?.featured_logo
                          ? Config.mediaURL + item?.featured_logo
                          : DefaultImg
                      }
                      alt="bookmaker"
                    />
                  </Box>
                  <Box className="top-bookmaker-right">
                    <Box className="top-bookmaker-right-details-wrap">
                      <Typography className="top-bookmaker-description">
                        {" "}
                        {item?.featured_content}{" "}
                      </Typography>

                      <a
                        href={item?.affiliate_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="claim-btn"
                      >
                        claim now
                      </a>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box className="no-data">
              <Typography>No Data Available</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <></>
      )}

      {/* <RightSidebar /> */}
    </>
  );
};

export default RightNewsSideBar;
