import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import DefaultImg from "src/assets/images/smartb_default_icon.png";
import flag from "src/helpers/constants/flag";

const TippingSupportedTeamModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  tournamentId,
  compId,
  sportId,
  compCode,
  callBackFunc
}) => {
  const localAuth = fetchFromStorage("auth_token");
  let isLogin = localAuth ? true : false;
  const dispatch = useDispatch();
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamOption, setTeamOption] = useState([]);

  useEffect(() => {
    if (open) {
      fetchTeams();
    }
  }, [open]);

  const fetchTeams = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/teamList?tournamentId=${tournamentId}&SportId=${sportId}&limit=500&offset=`
      );
      if (status === 200) {
        let newData = [];
        const teamData = data?.result?.map(item => {
          newData.push({
            label: item?.name,
            value: item?.id,
            flag: item?.flag
          });
        });
        setTeamOption(newData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateTeam = async () => {
    const payload = {
      selectedTeam: selectedTeam
    };
    try {
      const { status, data } = await axiosInstance.put(
        `/tipping/addLadder/${compCode}`,
        payload
      );
      if (status === 200) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  const formatOptionLabel = ({ label, flag }) => {
    // if (isCountryPhoneSearch) {
    //   return label;
    // } else {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={flag ? Config?.mediaURL + flag : DefaultImg}
          onError={e => (e.target.src = DefaultImg)}
          style={{ width: "32px", height: "32px", borderRadius: "50%" }}
        />
        <span
          style={{
            margin: "0px 5px",
            display: "inline-block"
          }}
        >
          {label}
        </span>
      </div>
    );
    // }
  };

  return (
    <Dialog
      className="blackBook-dialog supported-team-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {/* {closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )} */}
        <Box className="blackBook-content-wrap social-padding">
          <Box className="close-btn">
            {closeIcon !== false && (
              <IconButton
                className="dialog-close"
                style={{ textAlig: "end" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>

          <Typography className="header-label">
            Please select a team you support for the league.
          </Typography>
          <Typography className="textfield-text">
            Team Supported<span className="danger">*</span>:
          </Typography>
          <Select
            className="React sort-select"
            // onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
            // onInputChange={e => {
            //   handleOrgInputChange(0, e, selectedSports);
            // }}
            onChange={e => {
              setSelectedTeam(e?.value ? e?.value : "");
            }}
            // value={
            //   selectedOrg &&
            //   (isOrgSearch
            //     ? searchOrg?.find(item => {
            //         return item?.value == selectedOrg;
            //       })
            //     : OrgAll?.find(item => {
            //         return item?.value == selectedOrg;
            //       }))
            // }
            // options={isOrgSearch ? searchOrg : OrgAll}
            value={
              selectedTeam &&
              teamOption?.find(item => {
                return item?.value == selectedTeam;
              })
            }
            menuPosition="fixed"
            options={teamOption}
            getOptionLabel={formatOptionLabel}
            classNamePrefix="select"
            placeholder="- Select Team -"
            components={{ DropdownIndicator }}
          />

          <Box className="join-now-btn-sec">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              fullWidth
              className="join-now-btn"
              onClick={e => {
                handleClose();
                handleUpdateTeam();
                callBackFunc();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>
  );
};

export default TippingSupportedTeamModal;
