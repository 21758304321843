import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IntlContext } from "src/App";
import PropTypes from "prop-types";
import {
  AppBar,
  Grid,
  // TextField,
  Button,
  SwipeableDrawer,
  Box,
  IconButton,
  Typography,
  Collapse,
  ClickAwayListener
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import InputAdornment from "@material-ui/core/InputAdornment";
import { Link, useNavigate } from "react-router-dom";
import LeftSideBar from "../../views/component/leftsidebar/leftsidebar";
// import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as FaBars } from "../../assets/images/icons/menu.svg";
import logo from "../../assets/images/logo.svg";
import logoWhite from "../../assets/images/logo-white.png";
import DialogBox from "src/views/component/UI/dialogBox";
import HeaderTopBar from "./headertopbar/headertopbar";
import CloseIcon from "@material-ui/icons/Close";
import SignUp from "src/views/component/auth/SignUp/signup";
import SignIn from "src/views/component/auth/SignIn/signin";
import ForgotPassword from "src/views/component/auth/ForgotPassword/forgotpassword";
import EnterOtp from "src/views/component/auth/EnterOtp/enterOtp";
// import { loginUser } from "src/helpers/store/Actions/auth";
import { useDispatch, useSelector } from "react-redux";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import HeaderTopBarV2 from "./headertopbar/headertopbarV2";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import HeaderMenuList from "./HeaderMenuList";
import { fetchALLSport } from "src/helpers/store/Actions/AllSport";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import BetSlipCollapse from "./BetslipCollapse";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { loginUser } from "src/helpers/store/Actions/auth";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchUpcomingRaces } from "src/helpers/store/Actions/sport";
import { fetchLayoutData } from "src/helpers/store/Actions/LayoutData";
import { CustomTooltip } from "./CustomMenuTooltip";

const TopBar = ({ user_statics }) => {
  let dispatch = useDispatch();
  const location = useLocation();
  const BetSlipToggleData = useSelector(
    state => state?.reduxData?.BetSlipToggle
  );
  const BetSlipCount = useSelector(state => state?.reduxData?.BetSlipCount);
  const reduxSubscriptionData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const [showMenu, setshowMenu] = useState(false);
  const [modal, setModal] = useState();
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [betCount, setBetCount] = useState(0);
  const [profileCollapse, setProfileCollapse] = useState(false);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  let token = localStorage.getItem("auth_token");

  const toggleMenu = () => {
    setshowMenu(!showMenu);
  };
  const handleModal = mName => {
    setModal(mName);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAllSportData();
  }, []);
  useEffect(() => {
    setshowMenu(false);
  }, [location?.pathname, location?.search]);

  useEffect(() => {
    if (BetSlipCount) {
      setBetCount(BetSlipCount);
    } else if (BetSlipCount === 0) {
      setBetCount(BetSlipCount);
    }
  }, [BetSlipCount]);

  const handleCloseMenu = () => {
    setshowMenu(false);
  };

  const handleBetSlipToggle = () => {
    if (!token || token == "null") {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    } else {
      dispatch(
        fetchBetSlipToggle(BetSlipToggleData ? !BetSlipToggleData : true)
      );
      if (!BetSlipToggleData) {
        document.body.classList.add("no-scroll");
        if (screenWidth < 799) {
          window.scrollTo(0, 0);
        }
      } else {
        document.body.classList.remove("no-scroll");
      }
    }
  };

  // All sport api set in redux
  const fetchAllSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/sports/sport`);
      if (status === 200) {
        dispatch(fetchALLSport(data?.result));
      } else {
        dispatch(fetchALLSport([]));
      }
    } catch (err) {
      dispatch(fetchALLSport([]));
    }
  };

  const collapseStyles = {
    transformOrigin: "top right", // Set the transformation origin to top right
    transform: `scaleX(${BetSlipToggleData ? 1 : 0})`, // Scale the element in X-axis to 1 (normal size) when open, 0 when closed
    transition: "transform 0.3s ease-in-out" // Add a transition for a smooth animation
  };

  const handleProfileMenu = () => {
    setProfileCollapse(!profileCollapse);
  };

  const handleLogout = () => {
    let Type = localStorage.getItem("type");
    let Url = localStorage.getItem("url");
    localStorage.removeItem("auth_token");
    dispatch(loginUser(""));
    dispatch(fetchBetSlipToggle(false));
    dispatch(fetchBetSlipData([]));
    dispatch(fetchBetSlipCount(0));
    dispatch(fetchSubscriptionData(undefined));
    dispatch(fetchUpcomingRaces(undefined));
    dispatch(fetchLayoutData(null));
    localStorage.removeItem("loginPopupShown");
    const intervalId = localStorage.getItem("popup_intervalId");
    clearTimeout(intervalId);
    localStorage.removeItem("popup_intervalId");
    if (Type && Url) {
      window.location.href = Url;
      localStorage.removeItem("type");
      localStorage.removeItem("url");
      localStorage.setItem("user_status", "logout");
    } else {
      navigate("/");
    }
  };
  return (
    <>
      {/* <HeaderTopBar /> */}
      <HeaderTopBarV2 />
      <AppBar
        position="static"
        elevation={0}
        className={`main-menu-wrap ${
          screenWidth < 800 && BetSlipToggleData ? "betslip-open" : ""
        }`}
        style={{ backgroundColor: "#FFF" }}
      >
        {/* Mobile memnu Before Login */}
        <div className="mobileMenu">
          <SwipeableDrawer
            anchor={"left"}
            open={showMenu}
            onClose={toggleMenu}
            onOpen={toggleMenu}
            className="mobileMenuDrawer"
            swipeAreaWidth={0}
          >
            <div className="mobileMenuDrawerClose">
              <Box className="logo-container">
                <CloseIcon onClick={toggleMenu} />
                <img
                  src={logoWhite}
                  // style={{ maxWidth: " 240.56px" }}
                  className="App-logo-main header-logo"
                  alt="logo"
                  // loading="lazy"
                />
              </Box>

              {(!token || token == "null") && (
                <Box className="signup-login-wrap">
                  <Link
                    to="/sign-up"
                    // onClick={() => handleModal("signup")}
                  >
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="auth-btn signup"
                    >
                      {localesData?.auth_btn?.signup}
                    </Button>
                  </Link>
                  <Link
                    to="/sign-in"
                    // onClick={() => handleModal("signin")}
                  >
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="auth-btn signin"
                    >
                      {localesData?.auth_btn?.signin}
                    </Button>
                  </Link>
                </Box>
              )}
            </div>
            <LeftSideBar handleCloseMenu={handleCloseMenu} />
          </SwipeableDrawer>
        </div>
        <Grid container spacing={0} alignItems="center" className="header-wrap">
          {/* <Grid item md={1} sm={1} xs={1} className="mobileMenu">
            <FaBars onClick={toggleMenu} className="MenuTopBar-button" />
          </Grid> */}
          <Grid
            item
            lg={1}
            md={7}
            sm={7}
            xs={7}
            className="text-left logo-container"
          >
            <Box className="mobileMenu">
              <FaBars onClick={toggleMenu} className="MenuTopBar-button" />
            </Box>
            <NavLink to="/">
              <img
                src={logo}
                // style={{ maxWidth: " 240.56px" }}
                className="App-logo-main header-logo"
                alt="logo"
                // loading="lazy"
              />
            </NavLink>
          </Grid>
          <Grid
            item
            lg={9}
            md={12}
            sm={12}
            xs={12}
            className="text-left header-menu-container"
          >
            <HeaderMenuList />
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={4}
            xs={4}
            className="MuiGrid-spacing-xs-0 login-buttons"
          >
            {!token || token == "null" ? (
              <Box className="signup-login-wrap">
                <Link to="/sign-up" onClick={() => handleModal("signup")}>
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signup"
                  >
                    {localesData?.auth_btn?.signup}
                  </Button>
                </Link>
                <Link
                  to="/sign-in"
                  // onClick={() => handleModal("signin")}
                >
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signin"
                  >
                    {localesData?.auth_btn?.signin}
                  </Button>
                </Link>
              </Box>
            ) : (
              <>
                <Box className="profile-wrap">
                  {/* <ClickAwayListener
                    onClickAway={() => setProfileCollapse(false)}
                  > */}
                  <CustomTooltip
                    placement="bottom-end"
                    interactive
                    title={
                      <>
                        <Box className="profile-section-list-wrap">
                          <Box
                            className="profile-list-menu-list mb-9"
                            onClick={() => {
                              navigate("/profile");
                              setProfileCollapse(false);
                            }}
                          >
                            <Typography className="profile-list-menu-text">
                              My Profile
                            </Typography>
                          </Box>
                          <Box
                            className="profile-list-menu-list mb-9"
                            onClick={() => {
                              navigate("/blackbook");
                              setProfileCollapse(false);
                            }}
                          >
                            <Typography className="profile-list-menu-text">
                              My BlackBook
                            </Typography>
                          </Box>
                          <Box
                            className="profile-list-menu-list"
                            onClick={() => {
                              navigate("/smartbook");
                              setProfileCollapse(false);
                            }}
                          >
                            <Typography className="profile-list-menu-text">
                              My SmartBook
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="profile-logout-wrap">
                          <Box
                            className="profile-list-menu-list p-w-100"
                            onClick={() => {
                              handleLogout();
                              setProfileCollapse(false);
                            }}
                          >
                            <Typography className="profile-list-menu-text">
                              Log out
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    }
                    // open={sub?.secondSubMenu?.length === 9 ? true : false}

                    classes={{
                      tooltip: "profile-collapse-wrap"
                    }}
                    PopperProps={{
                      style: {
                        zIndex: 1200
                      }
                    }}
                  >
                    <Box
                      className="profile-logout-wrap"
                      onClick={() => handleProfileMenu()}
                    >
                      {user_statics?.Media?.filePath ? (
                        <Box className="profile-img-wrap">
                          <img
                            src={
                              Config?.countryMediaURL +
                              user_statics?.Media?.filePath
                            }
                            alt="profile"
                          />
                        </Box>
                      ) : (
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="primary-search-account-menu"
                          aria-haspopup="true"
                          color="inherit"
                          className="profile-icon"
                        >
                          <PersonIcon />
                        </IconButton>
                      )}
                      <Typography className="user-name">
                        {reduxSubscriptionData?.firstName
                          ? reduxSubscriptionData?.firstName
                          : ""}
                      </Typography>
                      {/* bet slip button */}

                      {/* <Box
                  className="bet-slip-button"
                  onClick={() => handleBetSlipToggle()}
                >
                  <Typography className="bet-slip">Bet Slip</Typography>
                  <Box className="bet-slip-count">{betCount}</Box>
                </Box> */}

                      {/* {screenWidth > 799 ? (
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signup logout"
                    onClick={() => {
                      localStorage.removeItem("auth_token");
                      navigate("/");
                      dispatch(loginUser(""));
                    }}
                  >
                    {localesData?.auth_btn?.logout}
                  </Button>
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    className="profile-icon"
                    onClick={() => {
                      localStorage.removeItem("auth_token");
                      navigate("/");
                      dispatch(loginUser(""));
                    }}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                )} */}
                    </Box>
                  </CustomTooltip>
                  {/* </ClickAwayListener> */}
                </Box>
              </>
            )}
            {/* V3 header */}
            {token && release[Config.release]?.betSlip && (
              <Box
                className="bet-slip-button"
                onClick={() => handleBetSlipToggle()}
              >
                <Typography className="bet-slip">Bet Slip</Typography>
                <Box className="bet-slip-count">{betCount}</Box>
              </Box>
            )}
            <Collapse
              in={BetSlipToggleData}
              className="betslip-collapse"
              style={screenWidth < 799 ? collapseStyles : ""}
            >
              <BetSlipCollapse />
            </Collapse>
          </Grid>
        </Grid>
      </AppBar>
      <DialogBox
        open={open}
        handleClose={handleClose}
        title={false}
        content={
          modal === "signup" ? (
            <SignUp handleModal={handleModal} />
          ) : modal === "signin" ? (
            <SignIn handleModal={handleModal} />
          ) : modal === "forgotpassword" ? (
            <ForgotPassword handleModal={handleModal} />
          ) : modal === "enterotp" ? (
            <EnterOtp />
          ) : (
            <></>
          )
        }
        closeIcon={false}
      />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
