import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "165px",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      width: "220px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      width: "220px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const LeagueCoachDetails = ({ headerData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [loader, setLoader] = useState(false);
  const [coachDeatils, setCoachDeatils] = useState([]);
  const [pastCoach, setPastCoach] = useState([]);

  //   const [offset, setOffset] = useState(0);
  //   const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    fetchCoachDetails();
  }, [params, SeasonId]);

  const fetchCoachDetails = async (offset, teamData) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/coaches/${params?.tournamentId}?SportId=${
          params?.sportId
        }&limit=10&offset=0&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setLoader(false);
        // setDataCount(data?.result?.team?.length);
        // const teamDeatilsData = [...teamData, ...data?.result?.team];
        setCoachDeatils(data?.result?.coach);
        setPastCoach(data?.result?.pastCoach);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  //   const handleMoreData = () => {
  //     setOffset(offset + 10);
  //     fetchTeamDetails(offset + 10, coachDeatils);
  //   };
  //   const handleLessData = () => {
  //     setOffset(offset - 10);
  //     if (coachDeatils?.length % 10 === 0) {
  //       setCoachDeatils(prevArray => prevArray.slice(0, -10));
  //     } else {
  //       setCoachDeatils(prevArray => prevArray.slice(0, -dataCount));
  //     }
  //   };
  return (
    <>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {SeasonId
                  ? headerData?.season?.name
                  : headerData?.tournament?.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="league-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  P - <span>Played</span> , W - <span>Win</span>, L -{" "}
                  <span>Loss</span>, D - <span>Draw</span>
                  {/* , F - , A -{" "}
                  <span>Away</span>, PD - <span>Point Differential</span>, FPG -{" "}
                  <span>Fantasy Points per Game</span>, APG -{" "}
                  <span>Assists Points per Game</span>, Att% -{" "}
                  <span>Attempts</span> , Def% - <span>Defeated</span>{" "} */}
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  {SeasonId
                    ? headerData?.season?.year + " ladder"
                    : "Current ladder"}
                </Typography>
              </Box>
              <Box className="table-overlay">
                {loader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!loader && (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table summary-table"
                    >
                      <Box className="overlay"></Box>
                      <Table className="data-table" style={{ minWidth: 940 }}>
                        <TableHead className="table-sub-header">
                          <TableRow className="table-sub-head-row">
                            <StickyTableCell
                              className={`${classes.head} sticky-head`}
                            ></StickyTableCell>
                            <TableCell className={classes.head} colSpan={4}>
                              Home
                            </TableCell>
                            <TableCell className={classes.head} colSpan={4}>
                              Away
                            </TableCell>
                            <TableCell className={classes.head} colSpan={4}>
                              Overall
                            </TableCell>
                            {/* <TableCell className={classes.head} colSpan={3}>
                              stats
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <StickyTableCell
                              className={`${classes.head} table-head-first`}
                            >
                              Coaches
                            </StickyTableCell>
                            <TableCell className={classes.head}>P</TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              D
                            </TableCell>
                            {/* <TableCell className={classes.head}>F</TableCell>
                            <TableCell className={classes.head}>A</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              PD
                            </TableCell> */}
                            <TableCell className={classes.head}>P</TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              D
                            </TableCell>
                            {/* <TableCell className={classes.head}>F</TableCell>
                            <TableCell className={classes.head}>A</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              PD
                            </TableCell> */}
                            <TableCell className={classes.head}>P</TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell
                              className={`${classes.head} border-right`}
                            >
                              D
                            </TableCell>
                            {/* <TableCell className={classes.head}>Bye</TableCell>
                            <TableCell className={classes.head}>F</TableCell>
                            <TableCell className={classes.head}>A</TableCell>
                            <TableCell className={classes.head}>Pts</TableCell>
                            <TableCell className={classes.head}>PD</TableCell>
                            <TableCell className={classes.head}>FPG</TableCell>
                            <TableCell className={classes.head}>APG</TableCell>
                            <TableCell className={classes.head}>Att%</TableCell>
                            <TableCell className={classes.head}>Def%</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {coachDeatils?.length > 0 ? (
                            coachDeatils?.map((item, i) => {
                              return (
                                <>
                                  <TableRow key={i} className="table-body-row ">
                                    <StickyTableCell
                                      className={`${classes.cell} table-body-first cursor-pointer`}
                                      onClick={() =>
                                        navigate(
                                          `/sport/statistics/${
                                            params?.sportId
                                          }/${
                                            params?.tournamentId
                                          }/league-data/${
                                            item?.id
                                          }/coach-data?seasonId=${
                                            SeasonId ? SeasonId : ""
                                          }`
                                        )
                                      }
                                    >
                                      {item?.name}
                                    </StickyTableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.home?.played ||
                                      item?.home?.played === 0
                                        ? item?.home?.played
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.home?.win || item?.home?.win === 0
                                        ? item?.home?.win
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.home?.loss ||
                                      item?.home?.loss === 0
                                        ? item?.home?.loss
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.home?.draw ||
                                      item?.home?.draw === 0
                                        ? item?.home?.draw
                                        : "-"}
                                    </TableCell>
                                    {/* <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      -
                                    </TableCell> */}
                                    <TableCell className={classes.cell}>
                                      {item?.away?.played ||
                                      item?.away?.played === 0
                                        ? item?.away?.played
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.away?.win || item?.away?.win === 0
                                        ? item?.away?.win
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.away?.loss ||
                                      item?.away?.loss === 0
                                        ? item?.away?.loss
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.away?.draw ||
                                      item?.away?.draw === 0
                                        ? item?.away?.draw
                                        : "-"}
                                    </TableCell>
                                    {/* <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      -
                                    </TableCell> */}
                                    <TableCell className={classes.cell}>
                                      {item?.overAll?.played ||
                                      item?.overAll?.played === 0
                                        ? item?.overAll?.played
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.overAll?.win ||
                                      item?.overAll?.win === 0
                                        ? item?.overAll?.win
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.overAll?.loss ||
                                      item?.overAll?.loss === 0
                                        ? item?.overAll?.loss
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.overAll?.draw ||
                                      item?.overAll?.draw === 0
                                        ? item?.overAll?.draw
                                        : "-"}
                                    </TableCell>
                                    {/* <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      -
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      - */}
                                    {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="att-data"></Box>
                                    </Box> */}
                                    {/* </TableCell>
                                    <TableCell className={classes.cell}>
                                      - */}
                                    {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="def-data"></Box>
                                    </Box> */}
                                    {/* </TableCell> */}
                                    <TableCell
                                      className={classes.cell}
                                    ></TableCell>
                                  </TableRow>
                                </>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={100} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* {coachDeatils?.length > 0 ? (
                      <Box className="view-more">
                        {setOffset + 10 === coachDeatils?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {setOffset >= 10 && coachDeatils?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )} */}
                  </>
                )}
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Past Coaches</Typography>
                <Typography className="past-team-name">
                  {pastCoach?.length > 0
                    ? pastCoach?.map((item, index) => {
                        return (
                          <>
                            <span
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/sport/statistics/${params?.sportId}/${params?.tournamentId}/league-data/${item?.id}/coach-data`
                                )
                              }
                            >
                              {" "}
                              {item?.name}
                              {index !== pastCoach.length - 1 ? "," : ""}
                            </span>
                          </>
                        );
                      })
                    : "-"}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default LeagueCoachDetails;
