import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import "./ui.scss";
import { Config } from "src/helpers/context";

const SocialShareModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  shareCode
}) => {
  var originalUrl = Config.baseURL;
  var modifiedUrl = originalUrl.replace("/api", "");
  const link = modifiedUrl + `tipscompetition/private-comps?code=${shareCode}`;

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  // const shareContent = {
  //   dataShareUrl: "https://twang.dev/react-snapshare/",
  //   stickerAssetURL: "https://kit.snapchat.com/ckweb/test/image.png"
  // };

  // const url =
  //   Config.baseURL.replace("/api", "") +
  //   location.pathname.replace(/^\/+/, "") +
  //   "?code=" +
  //   encodeURIComponent(
  //     location.search?.replace("?code=", "").split("&fbclid")[0]
  //   );

  const shareOnMessenger = () => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "708883073941381",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0"
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <Dialog
      className="blackBook-dialog social-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {/* {closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )} */}
        <Box className="blackBook-content-wrap social-padding">
          <Box className="close-btn">
            {closeIcon !== false && (
              <IconButton
                className="dialog-close"
                style={{ textAlign: "end" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>

          <Typography className="send-invite-txt">Send invite!</Typography>
          <Typography className="share-txt">
            Share the comps with your friends and family.
          </Typography>
          <Box className="share-icon-option">
            <Box className="social-option-box">
              <Box className="option">
                <CopyToClipboard text={link} onCopy={handleCopy}>
                  <Box className="cursor-pointer">
                    <img src={Copy} alt="Copy" />
                    <Typography className="option-name">Copy Link</Typography>
                  </Box>
                </CopyToClipboard>
              </Box>
              <Box className="option">
                <WhatsappShareButton url={link}>
                  <img src={Wp} alt="Whatsapp" />
                </WhatsappShareButton>
                <Typography className="option-name">Whatsapp</Typography>
              </Box>
              <Box className="option">
                <FacebookShareButton url={link}>
                  <img src={Facebook} alt="Facebook" />
                </FacebookShareButton>
                <Typography className="option-name">Facebook</Typography>
              </Box>
              <Box className="option cursor-pointer">
                <img
                  src={Messenger}
                  alt="Messenger"
                  className="social-icon"
                  onClick={() => shareOnMessenger()}
                />
                <Typography className="option-name">Messenger</Typography>
              </Box>
              <Box className="option">
                <TwitterShareButton url={link}>
                  <img src={Twitter_X} alt="Twitter_X" />
                </TwitterShareButton>
                <Typography className="option-name">Twitter</Typography>
              </Box>
              <Box className="option">
                <LinkedinShareButton url={link}>
                  <img src={Linkedin} alt="Linkedin" />
                </LinkedinShareButton>
                <Typography className="option-name">Linkedin</Typography>
              </Box>
              {/* <Box className="option">
                <img src={Snapchat} alt="Snapchat" />
                <Typography className="option-name">Snapchat</Typography>
              </Box>
              <Box className="option">
                {" "}
                <img src={Message} alt="Message" />
                <Typography className="option-name">Message</Typography>
              </Box> */}
            </Box>
          </Box>
          <Box className="skip-now-btn-sec">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              fullWidth
              className="skip-now-btn"
              onClick={() => {
                handleClose();
              }}
            >
              Skip for now
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>
  );
};

export default SocialShareModal;
