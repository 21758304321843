import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography
} from "@material-ui/core";
import Cropper from "react-cropper";
import { Add, Remove } from "@material-ui/icons";
import DialogBox from "src/views/component/UI/dialogBox";
import "cropperjs/dist/cropper.css";
import "./cropimage.scss";

const CropImage = ({
  file,
  localesData,
  getCropData,
  onhandleclose,
  isProfile,
  setImage
}) => {
  const [cropper, setCropper] = useState();
  const [crop, setCrop] = useState(false);
  const [zoom, setZoom] = useState(0);
  const cropperRef = useRef(null);
  const [disModal, setDisModal] = useState(false);
  const [cropData, setCropData] = useState(null);
  const zoomIn = () => {
    setZoom((parseFloat(zoom) + 0.1).toFixed(1));
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(0.1);
    }
  };

  const zoomOut = () => {
    setZoom((parseFloat(zoom) - 0.1).toFixed(1));
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(-0.1);
    }
  };

  const customZoom = zoomValue => {
    setZoom(zoomValue);
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom((zoomValue - zoom).toFixed(1));
    }
  };
  const discardModalDetails = () => {
    return (
      <Box className="block-top-wrap">
        <Typography variant="h5" className="mb-17">
          {localesData?.discard_changes_text}
        </Typography>
        <Divider className="divider" />
        <Box className="all-block-wrap" textAlign="end">
          <Button className="btn-gray" onClick={() => handleDiscardModal()}>
            {localesData?.cancel}
          </Button>
          <Button
            className="btn-blue ml-18"
            onClick={() => {
              handleDiscardModal();
              onhandleclose();
            }}
          >
            {localesData?.okay}
          </Button>
        </Box>
      </Box>
    );
  };
  const handleDiscardModal = () => {
    // setDisModal(!disModal);
  };
  const cropImage = () => {
    // setZoom(0)
    if (cropperRef.current) {
      const croppedData = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(croppedData);
    }
  };

  const undoCrop = () => {
    setZoom(0);
    setCropData(null);
    if (cropperRef.current) {
      cropperRef.current.cropper.reset();
    }
  };
  return (
    <>
      <Box className="crop-image-wrap">
        <Box className="crop-image">
          <Box className={crop ? "cropped-image" : "without-crop"}>
            <Cropper
              ref={cropperRef}
              style={{ height: 329, width: "100%" }}
              // zoomable={false}
              aspectRatio={1}
              initialAspectRatio={1}
              // src={crop ? cropData : file?.preview}
              src={file?.preview}
              viewMode={1}
              // minCropBoxHeight={10}
              // minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              className={isProfile && "profile-cropper"}
              checkOrientation={false}
              onInitialized={instance => {
                setCropper(instance);
              }}
              guides={true}
              zoomOnWheel={false}
              dragMode="move"
              cropBoxMovable={true}
              cropBoxResizable={true}
            />
          </Box>
        </Box>
        <Divider className="divider" />
        <Box className="crop-image-top">
          <Box className="zoom-plus-minus">
            <Box className="icon-btn">
              <IconButton
                disabled={zoom > 0 ? false : true}
                className={zoom > 0 ? "" : "disabled-btn"}
                onClick={() => zoom > 0 && zoomOut()}
              >
                <Remove />
              </IconButton>
            </Box>
            <Box className="zoom-bar">
              <input
                type="range"
                min={0}
                max={1}
                step={0.1}
                value={zoom}
                // min="0"
                // max="1"
                // step="0.1"
                defaultValue={0}
                onChange={e => {
                  customZoom(e.target.value);
                }}
              />
            </Box>
            <Box className="icon-btn">
              <IconButton
                disabled={zoom < 1 ? false : true}
                className={zoom < 1 ? "" : "disabled-btn"}
                onClick={() => zoom < 1 && zoomIn()}
              >
                <Add />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className="crop-save-btn">
          <Button
            className="upload-button-white upload-button"
            onClick={() => {
              if (!crop) {
                cropImage();
                setCrop(!crop);
              } else {
                undoCrop();
                setCrop(!crop);
              }
            }}
          >
            {!crop ? "crop" : "undo crop"}
          </Button>
          <Box className="all-block-wrap" textAlign="end">
            <Button
              className="upload-button-white upload-button w-50"
              onClick={() =>
                // handleDiscardModal()
                {
                  onhandleclose();
                  setCropper(null);
                  setImage(null);
                }
              }
            >
              cancel
            </Button>
            <Button
              className="upload-button-blue upload-button w-50"
              onClick={() => getCropData(cropper)}
            >
              save
            </Button>
          </Box>
        </Box>
        <DialogBox
          handleClose={() => handleDiscardModal()}
          open={disModal}
          title={localesData?.discard_changes}
          content={discardModalDetails()}
        />
      </Box>
    </>
  );
};

export default CropImage;
