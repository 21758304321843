import React, { useEffect, useState, Suspense } from "react";
import { lazy } from "react";
import { Box, Collapse, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { ReactComponent as WhiteRightArrow } from "../../../assets/images/oddsComparison/whiteRightArrow.svg";
import { ReactComponent as WhiteLeftArrow } from "../../../assets/images/oddsComparison/whiteLeftArrow.svg";
import OddsComparisonHeader from "./header";
// import LeftRaceBar from "./leftRaceBar";
// import LeftRaceBarV2 from "./leftRaceBar/leftSideBarV2";
// import RightRaceBar from "./rightRaceBar";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import { fetchFromStorage } from "src/helpers/context";
import "./oddsComparison.scss";
import { Helmet } from "react-helmet";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";

const LeftRaceBarV2 = lazy(() => import("./leftRaceBar/leftSideBarV2"));
const RightRaceBar = lazy(() => import("./rightRaceBar"));

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const OddsCompLayout = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [LeftMenuOpen, setLeftMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [leftSideBarMenuHide, setLeftSideBarMenuHide] = useState(false);
  const [nextToJumpRace, setNextToJumpRace] = useState({});
  const userData = useSelector(state => state?.reduxData?.SubscripitionData);

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    if (!handleRestrictedUser()) {
      navigate("/sign-in");
    } else if (
      userData &&
      (userData?.isPremiumUser === false ||
        userData?.subscription?.SubscriptionPlan?.key !== "odds")
    ) {
      navigate("/soc-signup");
    }
  }, [userData]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (location?.state && screenWidth < 799) {
      fetchLeftRacingList(
        moment(new Date()).format("YYYY-MM-DD"),
        "1,2,3",
        "",
        "",
        0
      );
    }
  }, [location?.state]);
  const fetchLeftRacingList = async (date, SportId, search, signal, offset) => {
    try {
      const {
        status,
        data
      } = await axiosInstance.get(
        `/odds/raceList/?todate=${date}&SportsId=${SportId}&timezone=${timezone}&raceName=${search ??
          ""}`,
        { signal }
      );
      if (status === 200) {
        // if (data?.count > 0) {
        const filterRaceData = data?.raceData?.filter(obj => {
          return obj?.raceNumber !== 0;
        });

        let upnextRaces = filterRaceData?.filter(
          item =>
            item?.startTimeDate !== null &&
            moment(new Date()).isBefore(new Date(item?.startTimeDate))
        );
        const firstMatch =
          upnextRaces?.length > 0 ? upnextRaces : filterRaceData;

        const raceName =
          firstMatch?.[0]?.sportId == 1
            ? "horse"
            : firstMatch?.[0]?.sportId == 2
            ? "harness"
            : firstMatch?.[0]?.sportId == 3
            ? "greyhounds"
            : "";
        navigate(
          `/odds-comparison/${raceName}/${firstMatch?.[0]?.sportId}/${firstMatch?.[0]?.track?.id}/${firstMatch?.[0]?.id}`
        );
        // } else {
        //   handleOnScrollBottomRaceList(
        //     moment(new Date()).format("YYYY-MM-DD"),
        //     "1,2,3",
        //     "",
        //     "",
        //     0
        //   );
        // }
      } else {
      }
    } catch (err) {}
  };
  // const handleOnScrollBottomRaceList = async (
  //   date,
  //   SportId,
  //   search,
  //   signal,
  //   offset
  // ) => {
  //   try {
  //     const {
  //       status,
  //       data
  //     } = await axiosInstance.get(
  //       `/odds/raceList/?todate=${date}&SportsId=${SportId}&timezone=${timezone}&raceName=${search ??
  //         ""}&limit=50&offset=${offset}&cursor=2`,
  //       { signal }
  //     );
  //     if (status === 200) {
  //       if (data?.count > 0) {
  //         const filterRaceData = data?.raceData?.filter(obj => {
  //           return obj?.raceNumber !== 0;
  //         });
  //         const raceName =
  //           filterRaceData?.[0]?.sportId == 1
  //             ? "horse"
  //             : filterRaceData?.[0]?.sportId == 2
  //             ? "harness"
  //             : filterRaceData?.[0]?.sportId == 3
  //             ? "greyhounds"
  //             : "";
  //         navigate(
  //           `/odds-comparison/${raceName}/${filterRaceData?.[0]?.sportId}/${filterRaceData?.[0]?.track?.id}/${filterRaceData?.[0]?.id}`
  //         );
  //       } else {
  //         handleOnScrollBottomRaceList(
  //           moment(new Date()).format("YYYY-MM-DD"),
  //           "1,2,3",
  //           "",
  //           "",
  //           0
  //         );
  //       }
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  const handleLeftMenuOpen = () => {
    setLeftMenuOpen(!LeftMenuOpen);
    // if (!LeftMenuOpen) {
    //   document.body.classList.add("no-scroll");
    //   if (screenWidth < 799) {
    //     window.scrollTo(0, 0);
    //   }
    // } else {
    //   document.body.classList.remove("no-scroll");
    // }
  };

  const collapseStyles = {
    transformOrigin: "top left", // Set the transformation origin to top right
    transform: `scaleX(${LeftMenuOpen ? 1 : 0})`, // Scale the element in X-axis to 1 (normal size) when open, 0 when closed
    transition: "transform 0.3s ease-in-out" // Add a transition for a smooth animation
  };

  const handleLeftMenuHide = () => {
    setLeftSideBarMenuHide(!leftSideBarMenuHide);
  };
  return (
    <>
      <Helmet>
        <title>Racing Odds Comparison</title>
        {/* <meta
          name="description"
          content="Cricket and other sports. Find out everything you need to know with smartb. Best Odds comparison platform on the web for cricket in all formats."
        /> */}
      </Helmet>
      <Box className="odds-main-wrap">
        <Box className="header-contain-wrap">
          <OddsComparisonHeader />
        </Box>
        <Box className="main-contain-wrap">
          {screenWidth > 799 ? (
            <Box className="left-race-bar-hideon">
              <Box
                className="left-side-arrow-wrap"
                onClick={() => handleLeftMenuHide()}
              >
                <WhiteRightArrow
                  className={leftSideBarMenuHide && "right-arrow"}
                />
              </Box>
              {!leftSideBarMenuHide && (
                <Typography className="hide-text">Hide left column</Typography>
              )}
            </Box>
          ) : (
            <></>
          )}
          {screenWidth > 799 && (
            <Box
              className={
                leftSideBarMenuHide
                  ? "left-hide left-contain-wrap"
                  : "left-contain-wrap"
              }
            >
              {/* <LeftRaceBar
                setNextToJumpRace={setNextToJumpRace}
                setLeftMenuOpen={setLeftMenuOpen}
              /> */}
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <LeftRaceBarV2
                  setNextToJumpRace={setNextToJumpRace}
                  setLeftMenuOpen={setLeftMenuOpen}
                />
              </Suspense>
            </Box>
          )}
          <Box
            className={
              leftSideBarMenuHide
                ? "right-hide right-contain-wrap"
                : "right-contain-wrap"
            }
          >
            <Suspense
              fallback={
                <div className="allsport-loader-center homepage-loader">
                  <Loader />
                </div>
              }
            >
              <RightRaceBar
                leftSideBarMenuHide={leftSideBarMenuHide}
                nextToJumpRace={nextToJumpRace}
              />
            </Suspense>
          </Box>
        </Box>
        {LeftMenuOpen ? (
          <></>
        ) : (
          <Box
            className="left-side-mobile"
            onClick={() => handleLeftMenuOpen()}
          >
            <WhiteRightArrow />
          </Box>
        )}
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
      {LeftMenuOpen && (
        <Collapse
          in={LeftMenuOpen}
          className="left-side-collapse"
          style={collapseStyles}
        >
          <Box className="menu-close-icon">
            <Close onClick={() => setLeftMenuOpen(false)} />
          </Box>
          {screenWidth > 799 ? (
            <></>
          ) : (
            <>
              {/* <LeftRaceBar setLeftMenuOpen={setLeftMenuOpen} /> */}
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <LeftRaceBarV2 setLeftMenuOpen={setLeftMenuOpen} />
              </Suspense>
            </>
          )}
          <Box
            className="left-side-mobile"
            onClick={() => handleLeftMenuOpen()}
          >
            <WhiteLeftArrow />
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default OddsCompLayout;
