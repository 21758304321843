import React, { useContext, useState, useEffect } from "react";
import { IntlContext } from "src/App";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";

import Loader from "src/components/Loader";
import { useNavigate } from "react-router";
const ProfileSports = ({
  handleSelectedSports,
  selectedSports,
  formvalues,
  nextStep,
  fetchEditProfile,
  setformvalues,
  notificationFetchSportData,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [sportsList, setSportsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorSportOther, seterrorSportOther] = useState("");
  useEffect(() => {
    notificationFetchSportData();
    fetchSportData();
  }, []);

  const fetchSportData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(`/sports/sport`);
      if (status === 200) {
        const releaseFilterSportData = data?.result?.filter(
          (item) =>
            release[Config.release]?.sportId?.includes(item?.id) ||
            release[Config.release]?.raceSportId?.includes(item?.id)
        );
        const otherPushData = releaseFilterSportData?.push({
          sportName: "Other",
        });
        setSportsList(releaseFilterSportData);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const handleSportsOrEventsOther = (e) => {
    setformvalues({ ...formvalues, sportOrEventOther: [e?.target?.value] });
  };
  const ValidateSportOther = () => {
    let flag = true;
    if (
      (selectedSports?.includes("Other") ||
        selectedSports?.includes("Other - Please specify")) &&
      (formvalues?.sportOrEventOther
        ? formvalues?.sportOrEventOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorSportOther("Please Add Sport or Event");
    } else {
      seterrorSportOther("");
    }
    return flag;
  };
  return (
    <Box className="step4-container">
      {isLoading ? (
        <div className="auth-loader">
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <Typography Typography className="textfield-text">
        {localesData?.register?.HABITS_AND_INTERESTS}
      </Typography>
      <Box className="sportslist-wrap">
        <FormLabel component="legend" className="sportlist-label signup-que">
          {localesData?.register?.INTRESTED_SPORTS}
        </FormLabel>
        <Box className="sports-wrap">
          <Box className="checkbox-list-1">
            {sportsList?.map((element) => (
              <span
                className={`bookmakers-check ${
                  selectedSports?.includes(element?.sportName)
                    ? "active-label"
                    : ""
                }`}
                onClick={() => handleSelectedSports(element)}
              >
                {element?.sportName}
              </span>
            ))}
            {selectedSports?.includes("Other") ||
            selectedSports?.includes("Other - Please specify") ? (
              <span>
                <TextField
                  placeholder={"Please Specify"}
                  variant="outlined"
                  type="text"
                  name="sportoreventother"
                  className="text-field"
                  value={formvalues?.sportOrEventOther}
                  onChange={(e) => handleSportsOrEventsOther(e)}
                />
                {errorSportOther ? (
                  <p className="text-danger">{errorSportOther}</p>
                ) : (
                  ""
                )}
              </span>
            ) : (
              ""
            )}
          </Box>
        </Box>
        {/* <Box className="other-filed">
                    <Box className="sport-field"></Box>
                    <Box>
                        {selectedSports?.includes("Other - Please specify") ? (
                            <Box>
                                <TextField
                                    placeholder={""}
                                    variant="outlined"
                                    type="text"
                                    name="sportoreventother"
                                    className="text-field"
                                    value={formvalues?.sportOrEventOther}
                                    onChange={(e) => handleSportsOrEventsOther(e)}
                                />
                                {errorSportOther ? (
                                    <p className="text-danger">{errorSportOther}</p>
                                ) : (
                                    ""
                                )}
                            </Box>
                        ) : (
                            ""
                        )}
                    </Box>
                </Box> */}
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          type="submit"
          className="submit-btn step1-btn stepper-next"
          onClick={() => {
            if (ValidateSportOther()) {
              nextStep();
            }
          }}
        >
          {localesData?.register?.NEXT}
        </Button>

        <Button className="finish-btn" onClick={() => fetchEditProfile("skip")}>
          {" "}
          <a>{localesData?.register?.FINISH_MSG_STEPPER}</a>
        </Button>
      </Box>
    </Box>
  );
};

export default ProfileSports;
