import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";

const SmartBookModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  type,
  dataId,
  sportId,
  editComment,
  isEdit,
  tabType,
  listingFunction,
  tableId
}) => {
  const location = useLocation();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popUpData, setPopUpData] = useState({});
  const GetBlackBook = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `blackbook/sport/popUp/${dataId}?type=${type}&SportId=${sportId}`
      );
      if (status === 200) {
        setPopUpData(data?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const handleAddBlackBook = async () => {
    try {
      const payload = {
        type: type,
        id: dataId,
        SportId: sportId,
        comment: comment
      };
      const { status, data } = await axiosInstance.post(
        `blackbook/favoriteData`,
        payload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        listingFunction();
        setTimeout(() => {
          handleClose();
          setPopUpData({});
          setComment("");
        }, 3000);
      } else {
      }
    } catch (error) {
      toast.error("An Error Occured".data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
      handleClose();
    }
  };
  const handleUpdateBlackBook = async () => {
    try {
      const payload = {
        type: type,
        id: dataId,
        SportId: sportId,
        comment: comment
      };
      const { status, data } = await axiosInstance.put(
        `blackbook/favoriteData/${tableId}`,
        payload
      );
      if (status === 200) {
        toast.success("Smartbook Updated", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        setTimeout(() => {
          handleClose();
          setPopUpData({});
          setComment("");
        }, 3000);

        listingFunction();
      } else {
      }
    } catch (error) {
      toast.error("An Error Occured".data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  useEffect(() => {
    if (open) {
      GetBlackBook();
      const comment = isEdit ? editComment : "";
      setComment(comment);
    }
  }, [open]);
  const fetchModalDetail = popUpData => {
    const IsDetail =
      popUpData?.SportId === 4 &&
      popUpData?.CricketTeamManagers?.[0]?.CricketManager?.name
        ? popUpData?.CricketTeamManagers?.[0]?.CricketManager?.name
        : popUpData?.SportId === 10 &&
          popUpData?.NBATeamManagers?.[0]?.NBAManager?.name
        ? popUpData?.NBATeamManagers?.[0]?.NBAManager?.name
        : popUpData?.SportId === 15 &&
          popUpData?.AFLTeamManagers?.[0]?.AFLManager?.name
        ? popUpData?.AFLTeamManagers?.[0]?.AFLManager?.name
        : popUpData?.SportId === 9 &&
          popUpData?.ARTeamManagers?.[0]?.ARTeamManager?.name
        ? popUpData?.ARTeamManagers?.[0]?.ARTeamManager?.name
        : popUpData?.SportId === 16 &&
          popUpData?.GolfTeamManagers?.[0]?.GolfManager?.name
        ? popUpData?.GolfTeamManagers?.[0]?.GolfManager?.name
        : popUpData?.SportId === 7 &&
          popUpData?.TennisTeamManagers?.[0]?.TennisManager?.name
        ? popUpData?.TennisTeamManagers?.[0]?.TennisManager?.name
        : popUpData?.SportId === 11 &&
          popUpData?.BaseballTeamManagers?.[0]?.BaseballManager?.name
        ? popUpData?.BaseballTeamManagers?.[0]?.BaseballManager?.name
        : popUpData?.SportId === 17 &&
          popUpData?.IceHockeyTeamManagers?.[0]?.IceHockeyManager
        ? popUpData?.IceHockeyTeamManagers?.[0]?.IceHockeyManager?.name
        : popUpData?.SportId === 6 &&
          popUpData?.BoxingManagers?.[0]?.BoxingManager?.name
        ? popUpData?.BoxingManagers?.[0]?.BoxingManager?.name
        : popUpData?.SportId === 5 &&
          popUpData?.MMAManagers?.[0]?.MMAManager?.name
        ? popUpData?.MMAManagers?.[0]?.MMAManager?.name
        : popUpData?.SportId === 8 &&
          popUpData?.SoccerManagers?.[0]?.SoccerManager?.name
        ? popUpData?.SoccerManagers?.[0]?.SoccerManager?.name
        : popUpData?.SportId === 12 &&
          popUpData?.RLManagers?.[0]?.RLManager?.name
        ? popUpData?.RLManagers?.[0]?.RLManager?.name
        : null;
    return (
      <>
        {IsDetail && IsDetail !== "" && (
          <Box className="blackbook-content">
            <span className="smart-content-label">Coach:</span>{" "}
            <span className="content-des">
              {" "}
              {/* {moment
                    .utc(popUpData?.startTimeDate)
                    .local()
                    .format("DD/MM/YYYY")}{" "} */}
              {/* {popUpData?.coach ? popUpData?.coach : "N/A"}{" "} */}
              {type === "Team"
                ? popUpData?.SportId === 4 &&
                  popUpData?.CricketTeamManagers?.[0]?.CricketManager?.name
                  ? popUpData?.CricketTeamManagers?.[0]?.CricketManager?.name
                  : popUpData?.SportId === 10 &&
                    popUpData?.NBATeamManagers?.[0]?.NBAManager?.name
                  ? popUpData?.NBATeamManagers?.[0]?.NBAManager?.name
                  : popUpData?.SportId === 15 &&
                    popUpData?.AFLTeamManagers?.[0]?.AFLManager?.name
                  ? popUpData?.AFLTeamManagers?.[0]?.AFLManager?.name
                  : popUpData?.SportId === 9 &&
                    popUpData?.ARTeamManagers?.[0]?.ARTeamManager?.name
                  ? popUpData?.ARTeamManagers?.[0]?.ARTeamManager?.name
                  : popUpData?.SportId === 16 &&
                    popUpData?.GolfTeamManagers?.[0]?.GolfManager?.name
                  ? popUpData?.GolfTeamManagers?.[0]?.GolfManager?.name
                  : popUpData?.SportId === 7 &&
                    popUpData?.TennisTeamManagers?.[0]?.TennisManager?.name
                  ? popUpData?.TennisTeamManagers?.[0]?.TennisManager?.name
                  : popUpData?.SportId === 11 &&
                    popUpData?.BaseballTeamManagers?.[0]?.BaseballManager?.name
                  ? popUpData?.BaseballTeamManagers?.[0]?.BaseballManager?.name
                  : popUpData?.SportId === 17 &&
                    popUpData?.IceHockeyTeamManagers?.[0]?.IceHockeyManager
                  ? popUpData?.IceHockeyTeamManagers?.[0]?.IceHockeyManager
                      ?.name
                  : popUpData?.SportId === 6 &&
                    popUpData?.BoxingManagers?.[0]?.BoxingManager?.name
                  ? popUpData?.BoxingManagers?.[0]?.BoxingManager?.name
                  : popUpData?.SportId === 5 &&
                    popUpData?.MMAManagers?.[0]?.MMAManager?.name
                  ? popUpData?.MMAManagers?.[0]?.MMAManager?.name
                  : popUpData?.SportId === 8 &&
                    popUpData?.SoccerManagers?.[0]?.SoccerManager?.name
                  ? popUpData?.SoccerManagers?.[0]?.SoccerManager?.name
                  : popUpData?.SportId === 12 &&
                    popUpData?.RLManagers?.[0]?.RLManager?.name
                  ? popUpData?.RLManagers?.[0]?.RLManager?.name
                  : "N/A"
                : "N/A"}
            </span>
          </Box>
        )}
        {/* <Box className="blackbook-content details">
                <span className="des-wrap">
                  <span className="smart-content-label">Captains: </span>{" "}
                  <span className="content-des">
                    {popUpData?.captains?.length > 0
                      ? popUpData?.captains
                          ?.map(obj => {
                            return obj?.name;
                          })
                          .join(", ")
                      : "N/A"}
                  </span>
                </span>
              </Box> */}
      </>
    );
  };
  return (
    <Dialog
      className="blackBook-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {title && closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                      setPopUpData({});
                      setComment("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )}
        <Box className="blackBook-content-wrap">
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : type ? (
            <Box className="blackbook-type-wrap">
              {fetchModalDetail(popUpData)}
            </Box>
          ) : (
            <></>
          )}
          <TextareaAutosize
            id="outlined-basic"
            variant="outlined"
            className="details-textarea-field"
            placeholder="Comment(optional)..."
            multiline
            maxRows={6}
            name="message"
            style={{ width: "97%", height: "241px" }}
            onChange={e => setComment(e.target.value)}
            value={comment}
          />
          <Box className="blackbook-btn-wrap">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              className="blackbook-button"
              onClick={() => {
                isEdit ? handleUpdateBlackBook() : handleAddBlackBook();
              }}
            >
              {isEdit ? "Update Smartbook" : "Add to my Smartbook"}
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>
  );
};

export default SmartBookModal;
