import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";

const CompleteProfile = ({
  fetchSportData,
  nextStep,
  fetchEditProfile,
  setIsCompleteProfile,
  setIsOTPVerify,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  useEffect(() => {
    fetchSportData();
  }, []);

  return (
    <Box className="complete-profile">
      {/* <Typography className="login-title login-heading">
                {localesData?.register?.COMPLETE_PROFILE}
            </Typography> */}
      <Typography className="otp-content">
        {localesData?.register?.COMPLETE_PROFILE_TEXT_1}{" "}
        <span className="text-semibold">
          {localesData?.register?.COMPLETE_PROFILE}
        </span>{" "}
        {localesData?.register?.COMPLETE_PROFILE_TEXT_2}
      </Typography>
      <Button
        onClick={() => {
          setIsCompleteProfile(true);
          setIsOTPVerify(false);
        }}
        className="submit-btn"
      >
        {localesData?.register?.COMPLETE_PROFILE}
      </Button>
      <Button
        onClick={() => {
          // skipToUpdateNotification();
          navigate("/");
          fetchEditProfile("skip");
        }}
        className="submit-btn white-submit-btn"
      >
        {localesData?.register?.SKIP_NOW}
      </Button>
    </Box>
  );
};

export default CompleteProfile;
