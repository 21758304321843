import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@material-ui/core";
import { IntlContext } from "src/App";
import AboveTable from "../../../../../assets/images/ad-placeholder/sports1.webp";
const jockeyMonths = [
  {
    id: 1,
    distance: "1000",
    recordTime: "0:57.200",
    avgSpeed: "63.2km/h",
    date: "29 Feb 1992",
    horse: "Ace Bell"
  },
  {
    id: 2,
    distance: "1100",
    recordTime: "1:02.850",
    avgSpeed: "63.2km/h",
    date: "29 Feb 1992",
    horse: "Music Star"
  },
  {
    id: 3,
    distance: "1600",
    recordTime: "1:34.800",
    avgSpeed: "63.2km/h",
    date: "29 Feb 1992",
    horse: "Legendary"
  },
  {
    id: 4,
    distance: "2000",
    recordTime: "2:02.500",
    avgSpeed: "63.2km/h",
    date: "29 Feb 1992",
    horse: "Hyraami"
  },
  {
    id: 5,
    distance: "2020",
    recordTime: "2:08.200",
    avgSpeed: "63.2km/h",
    date: "29 Feb 1992",
    horse: "Stationary"
  }
];

function RecordsDetails({ fetchAds }) {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <>
      {fetchAds(1, "102px", AboveTable)}
      <Box className="record-details-wrap">
        <TableContainer id="details-table" className="details-table">
          <Table className="table-wrap" style={{ minWidth: 940 }}>
            <TableHead>
              <TableRow className="table-head-row ">
                <TableCell align="center">
                  {localesData?.sport?.DISTANCE}
                </TableCell>
                <TableCell>{localesData?.sport?.UPCOMING}</TableCell>
                <TableCell>{localesData?.sport?.AVG_SPEED}</TableCell>
                <TableCell>{localesData?.sport?.DATE}</TableCell>
                <TableCell>{localesData?.sport?.HORSE}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jockeyMonths.map((item, i) => (
                <TableRow key={i} className="table-body-row ">
                  <TableCell align="center" className="body-1">
                    {item?.distance}
                  </TableCell>
                  <TableCell>{item?.recordTime}</TableCell>
                  <TableCell>{item?.avgSpeed}</TableCell>
                  <TableCell>{item?.date}</TableCell>
                  <TableCell>{item?.horse}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default RecordsDetails;
