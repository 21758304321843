import React from "react";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import moment from "moment";
import TrackRaceResult from "../allsports/racing/racingList/trackRaceResult";
import TrackListCountdown from "../allsports/racing/racingList/trackListCountdown";
import OurPartner from "../ourPartners";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import BlackBookModal from "../UI/blackBookModal";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NoDataComp from "../UI/NoData";
import { Routes } from "../../../helpers/constants/routeConstants";

const UpcomingRunner = ({
  upcomingData,
  isUpcomingLoading,
  fetchUpcomingBlackBook,
  countryId,
  stateId,
  handlePaginationClick,
  upcomingCount,
  upcomingPage,
  pageNumbers,
  isToggle
}) => {
  const navigate = useNavigate();
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [raceMenuCollapse, setRaceMenuCollapse] = useState(-1);
  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const handleTracklist = ele => {
    let data = ele?.race;
    let a = data.map(a => a.raceNumber) || [];

    for (var i = 1; i <= 12; i++) {
      if (a.indexOf(i) == -1) {
        let dummy_ele = { startTimeDate: null, raceNumber: i };
        data.push(dummy_ele);
      }
    }

    ele["race"] = data
      .sort((a, b) => {
        return a?.raceNumber - b?.raceNumber;
      })
      ?.filter(race => race?.raceNumber !== 0);
    const track = ele?.race?.slice(0, 12)?.map((obj, i) => {
      if (
        obj?.startTimeDate !== null &&
        moment(new Date(obj?.startTimeDate)).isBefore(new Date())
      ) {
        return (
          <TrackRaceResult // if race is close
            race={ele}
            race_obj={obj}
            key={i}
            intl={
              ele?.track?.countryId === 157 || ele?.track?.countryId === 13
                ? false
                : true
            }
            selectedDate={moment(obj?.startTimeDate).format("YYYY-MM-DD")}
            // raceData={
            //   item?.RaceData
            //     ?.intlData
            // }
            isMobile={false}
            isBlackBook={true}
          />
        );
      } else {
        return (
          <TrackListCountdown // if race is upcoming
            race={ele}
            race_obj={obj}
            key={i}
            intl={
              ele?.track?.countryId === 157 || ele?.track?.countryId === 13
                ? false
                : true
            }
            selectedDate={moment(obj?.startTimeDate).format("YYYY-MM-DD")}
            // raceData={
            //   item?.RaceData
            //     ?.intlData
            // }
            expiryTimestamp={
              obj?.startTimeDate !== null
                ? new Date(new Date(obj?.startTimeDate).toUTCString()).getTime()
                : new Date().getTime()
            }
            checkRaceMinCell={checkRaceMinCell}
            isMobile={false}
            isBlackBook={true}
          />
        );
      }
    });
    return track;
  };
  const fetchNewMobileViewRacingData = (ele, intl, index) => {
    let haveRace = ele?.race
      ?.filter(data => data?.startTimeDate !== null)
      ?.filter(race => race?.raceNumber !== 0);
    let upnextRaces = ele?.race
      ?.filter(
        item =>
          item?.startTimeDate !== null &&
          moment(new Date()).isBefore(new Date(item?.startTimeDate))
      )
      ?.filter(race => race?.raceNumber !== 0);
    let completedRaces = ele?.race
      ?.filter(
        item =>
          item?.startTimeDate !== null &&
          moment(new Date(item?.startTimeDate)).isBefore(new Date())
      )
      ?.filter(race => race?.raceNumber !== 0);

    return (
      <Box className="Mobile-racing-data">
        <React.Fragment>
          <Box
            className={`${
              raceMenuCollapse === index ? "racing-menu-collapse" : ""
            } racing-data-row`}
          >
            <Box
            // onClick={
            //   upnextRaces?.length > 0
            //     ? () => {
            //       navigate(
            //         // Redirect to Runner Details
            //         Routes.RunnerDetails(
            //           race?.sportId === 1
            //             ? "horse"
            //             : race?.sportId === 2
            //               ? "harness"
            //               : "greyhounds",
            //           race?.sportId,
            //           upnextRaces[0].id,
            //           race?.track?.id,
            //           race?.id,
            //           selectedDate,
            //           intl
            //         ),
            //         {
            //           state: {
            //             raceData: data,
            //             CurrentData: race,
            //             SelectedRaceid: haveRace[0].id,
            //           },
            //         }
            //       );
            //     }
            //     : haveRace[0]?.raceNumber !== undefined
            //       ? () => {
            //         navigate(
            //           Routes.RunnerDetails(
            //             race?.sportId === 1
            //               ? "horse"
            //               : race?.sportId === 2
            //                 ? "harness"
            //                 : "greyhounds",
            //             race?.sportId,
            //             haveRace[0].id,
            //             race?.track?.id,
            //             race?.id,
            //             selectedDate,
            //             intl
            //           ),
            //           {
            //             state: {
            //               raceData: data,
            //               CurrentData: race,
            //               SelectedRaceid: haveRace[0].id,
            //             },
            //           }
            //         );
            //       }
            //       : () => { }
            // }
            >
              <Typography className="track-title" variant="subtitle1">
                <Box>
                  <span
                    className="track-name"
                    onClick={() => handleNavigate(ele)}
                  >
                    {ele?.eventName}
                  </span>
                  <span className="track-code">
                    {" "}
                    ({ele?.track?.Country?.countryCode})
                  </span>
                </Box>
                <Box className="track-country"></Box>
              </Typography>
            </Box>
            <Box className="upcoming-list">
              {upnextRaces?.length > 0 ? (
                <Box className="collapse-menu">
                  {fetchMobileMenuTrackList(
                    [],
                    ele,
                    upnextRaces?.[0],
                    upnextRaces?.[0]?.raceNumber,
                    intl
                  )}
                  <span className={`race-tag race-tag-upcoming`}>
                    Race {upnextRaces?.[0]?.raceNumber}
                  </span>
                </Box>
              ) : (
                <></>
              )}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  return setRaceMenuCollapse(
                    raceMenuCollapse === index ? -1 : index
                  );
                  // setDropDownSportId(race?.sportId),
                  // setIsIntl(intl)
                }}
              >
                {raceMenuCollapse === index ? (
                  // &&
                  //   dropDownSportId === race?.sportId &&
                  //   isintl === intl
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Box>
          </Box>
          <Collapse
            in={raceMenuCollapse === index}
            timeout="auto"
            unmountOnExit
            className={`${
              raceMenuCollapse !== index ? "" : "collapse-section"
            }`}
          >
            {upnextRaces?.length > 0 ? (
              upnextRaces?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Box
                      className={`collapse-menu collapse-td ${fetchFixedOdds(
                        item
                      )}`}
                    >
                      {fetchMobileMenuTrackList(
                        [],
                        ele,
                        item,
                        item?.raceNumber,
                        intl
                      )}
                      <span
                        // className={
                        //   index === 0
                        //     ? `race-tag race-tag-close`
                        //     : `race-tag race-tag-secondary-close`
                        // }
                        className={`race-tag ${checkRaceMinCell(
                          item?.startTimeDate,
                          "mobile"
                        )}`}
                      >
                        Race {item?.raceNumber}
                      </span>
                    </Box>
                  </React.Fragment>
                );
              })
            ) : (
              <></>
            )}
            {completedRaces?.length > 0 ? (
              completedRaces?.map((item, index) => {
                return (
                  <Box
                    className={`collapse-menu collapse-td ${fetchFixedOdds(
                      item
                    )}`}
                    key={index}
                  >
                    {fetchMobileMenuTrackList(
                      [],
                      ele,
                      item,
                      item?.raceNumber,
                      intl
                    )}
                    <span
                      className={`race-tag ${
                        item?.resultDisplay == null
                          ? "race-tag-interim"
                          : "race-tag-result"
                      }`}
                    >
                      Race {item?.raceNumber}
                    </span>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Collapse>
        </React.Fragment>
      </Box>
    );
  };
  const fetchFixedOdds = race_obj => {
    if (race_obj?.id) {
      if (race_obj?.isOdd) {
        return "fixed";
      } else {
        return "";
      }
    }
  };
  const fetchMobileMenuTrackList = (data, race, obj, racetrack, intl) => {
    if (
      obj?.startTimeDate !== null &&
      moment(new Date(obj?.startTimeDate)).isBefore(new Date())
    ) {
      return (
        <TrackRaceResult // if race is close
          race={race}
          race_obj={obj}
          selectedDate={moment(obj?.startTimeDate).format("YYYY-MM-DD")}
          key={racetrack}
          raceData={data}
          intl={intl}
          isMobile={false}
        />
      );
    } else {
      return (
        <TrackListCountdown // if race is upcoming
          race={race}
          race_obj={obj}
          selectedDate={moment(obj?.startTimeDate).format("YYYY-MM-DD")}
          key={racetrack}
          intl={intl}
          raceData={data}
          expiryTimestamp={
            obj?.startTimeDate !== null
              ? new Date(new Date(obj?.startTimeDate).toUTCString()).getTime()
              : new Date().getTime()
          }
          checkRaceMinCell={checkRaceMinCell}
          isMobile={false}
        />
      );
    }
  };
  const checkRaceMinCell = (time, type) => {
    let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
    let end_time_string = moment(time).format("YYYY/MM/DD HH:mm:ss");
    let diff_sec = moment(end_time_string).diff(current_time_string, "second");
    let class_to_pass = "";
    if (type === "desktop") {
      class_to_pass = !isNaN(diff_sec)
        ? // ? diff_sec > 1800
          //   ? "interim"
          diff_sec <= 1800 && diff_sec > 300
          ? "close-secondary"
          : diff_sec <= 300 && diff_sec > 0
          ? "close"
          : diff_sec < 0
          ? "upcoming_race_cell_close"
          : "white"
        : "";
    } else {
      class_to_pass = !isNaN(diff_sec)
        ? diff_sec <= 1800 && diff_sec > 300
          ? "race-tag-close"
          : diff_sec <= 300 && diff_sec > 0
          ? "race-tag-green"
          : diff_sec < 0
          ? "race-tag-interim"
          : "race-tag-secondary-close"
        : "";
    }
    return class_to_pass;
  };
  const fetchBestOpenValue = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })

    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map(obj => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter(x => x !== undefined);
          return fetchClickableOdds(maxno, providerid?.[0], "header", data);
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map(obj => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
          style={{ margin: "0px auto" }}
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map(obj => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter(x => x !== undefined);
          return oddsicon(providerid?.[0], "header", data);
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  const fetchCurrentBestValue = data => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = data => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const fetchClickableOdds = (odds, BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {" "}
          {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <span onClick={() => handleBookkeeperCounter(BookKeeperId, type)}>
            {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
          </span>
        </a>
      );
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let sportId = 1;
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="Odds Icon"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="Odds Icon"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      );
    }
  };
  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        handleDeleteClose();

        fetchUpcomingBlackBook(countryId, stateId, upcomingPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    const typeId =
      type === "Runner"
        ? item?.runner?.[0]?.animal?.id
        : type === "Jockey"
        ? item?.runner?.[0]?.Jockey?.id
        : type === "Trainer"
        ? item?.runner?.[0]?.Trainer?.id
        : type === "Track"
        ? item?.trackId
        : "";
    setTypeId(typeId);
    setSelectedId(id);
    const title =
      type === "Runner"
        ? item?.runner?.[0]?.animal?.name
        : type === "Jockey"
        ? item?.runner?.[0]?.Jockey?.name
        : type === "Trainer"
        ? item?.runner?.[0]?.Trainer?.name
        : type === "Track"
        ? item?.eventName
        : "";

    const comment = item?.favoriteData?.comment;
    setEditComment(comment);
    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  useEffect(() => {
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const handleNavigate = race => {
    let haveRace = race?.race?.filter(data => data?.startTimeDate !== null);
    let upnextRaces = race?.race?.filter(
      item =>
        item?.startTimeDate !== null &&
        moment(new Date()).isBefore(new Date(item?.startTimeDate))
    );
    let completedRaces = race?.race?.filter(
      item =>
        item?.startTimeDate !== null &&
        moment(new Date(item?.startTimeDate)).isBefore(new Date())
    );
    const selectedDate = race?.eventDate
      ? moment(race?.eventDate).format("YYYY-MM-DD")
      : null;
    const intl =
      race?.track?.countryId === 157 || race?.track?.countryId === 13
        ? false
        : true;
    if (upnextRaces?.length > 0) {
      navigate(
        // Redirect to Runner Details
        Routes.RunnerDetails(
          race?.sportId === 1
            ? "horse"
            : race?.sportId === 2
            ? "harness"
            : "greyhounds",
          race?.sportId,
          upnextRaces[0].id,
          race?.track?.id,
          race?.id,
          selectedDate,
          intl
        )
      );
    } else if (haveRace[0]?.raceNumber !== undefined) {
      navigate(
        Routes.RunnerDetails(
          race?.sportId === 1
            ? "horse"
            : race?.sportId === 2
            ? "harness"
            : "greyhounds",
          race?.sportId,
          haveRace[0].id,
          race?.track?.id,
          race?.id,
          selectedDate,
          intl
        )
      );
    } else {
    }
  };
  const handleRunnerNavigate = item => {
    const selectedDate = item?.startTimeDate
      ? moment(item?.startTimeDate).format("YYYY-MM-DD")
      : null;
    const intl =
      item?.track?.countryId === 157 || item?.track?.countryId === 13
        ? false
        : true;
    navigate(
      // Redirect to Runner Details
      Routes.RunnerDetails(
        item?.sportId === 1
          ? "horse"
          : item?.sportId === 2
          ? "harness"
          : "greyhounds",
        item?.sportId,
        item?.id,
        item?.trackId,
        item?.eventId,
        selectedDate,
        intl
      )
    );
  };
  return (
    <>
      <Box className="page-content-wrap">
        {isUpcomingLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : upcomingData && upcomingData?.Favorites?.length > 0 ? (
          upcomingData?.Favorites?.map((item, index) => {
            return (
              <>
                <Box className="blackbook-runner-details" key={index}>
                  <Box className="top-header-details">
                    <Box className="header-details">
                      <Typography variant="h6" className="day-time-details">
                        <span className="dark-color">
                          {item?.startTimeDate
                            ? fetchDayName(item?.startTimeDate)
                            : ""}{" "}
                          {item?.startTimeDate
                            ? moment
                                .utc(item?.startTimeDate)
                                .local()
                                .format("DD/MM/YYYY")
                            : item?.eventDate
                            ? moment
                                .utc(item?.eventDate)
                                .local()
                                .format("DD/MM/YYYY")
                            : ""}{" "}
                        </span>
                        |{" "}
                        <span className="dark-color">
                          {item?.startTimeDate
                            ? moment
                                .utc(item?.startTimeDate)
                                .local()
                                .format("hh:mm A")
                            : item?.eventDate
                            ? moment
                                .utc(item?.eventDate)
                                .local()
                                .format("hh:mm A")
                            : ""}{" "}
                        </span>
                        {item?.Distance ? "|" : ""}
                        <span className="gray-color">
                          {" "}
                          {item?.Distance ? item?.Distance?.name + "m" : ""}
                        </span>
                      </Typography>
                    </Box>
                    <Box className="Updated-date">
                      <Typography className="date">
                        Updated:{" "}
                        {moment
                          .utc(item?.favoriteData?.updatedAt)
                          .local()
                          .format("DD/MM/YYYY")}
                      </Typography>
                      <Box
                        className="delete-box cursor-pointer"
                        onClick={() =>
                          handleDelete(
                            item?.favoriteData?.type,
                            item?.favoriteData?.id
                          )
                        }
                      >
                        <Delete />
                      </Box>
                    </Box>
                  </Box>

                  {item?.favoriteData?.type !== "Track" ? (
                    <Box className="runner-deatils">
                      <Box className="runner-info">
                        <Typography
                          className="runner-name"
                          onClick={() => handleRunnerNavigate(item)}
                        >
                          {item?.runner?.[0]?.runnerNumber}
                          {"."} {item?.runner?.[0]?.animal?.name} (
                          {item?.runner?.[0]?.barrierNumber})
                        </Typography>
                        <Box className="info-details">
                          <Box>
                            <Typography className="runner-info-details">
                              {item?.runner?.[0]?.JockeyWeight
                                ? `Weight:${Number(
                                    item?.runner?.[0]?.JockeyWeight
                                  ).toFixed(2) + "Kg"}`
                                : ""}
                            </Typography>
                            {/* <Typography className="runner-info-details">
                            Form: [Form]
                          </Typography> */}
                          </Box>
                          <Box>
                            <Typography className="runner-info-details color-purple">
                              {item?.runner?.[0]?.Jockey?.name
                                ? `Jockey: ${item?.runner?.[0]?.Jockey?.name}`
                                : ""}
                            </Typography>
                            <Typography className="runner-info-details color-purple">
                              {item?.runner?.[0]?.Trainer?.name
                                ? `Trainer: ${item?.runner?.[0]?.Trainer?.name} `
                                : ""}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="race-deatils">
                        <Box className="race-wrap">
                          <Typography className="race-name">
                            {item?.raceNumber ? "R" + item?.raceNumber : ""}{" "}
                            {item?.raceName}
                          </Typography>
                          <Typography className="race-time">
                            {item?.startTimeDate
                              ? moment
                                  .utc(item?.startTimeDate)
                                  .local()
                                  .format("hh:mm A")
                              : ""}{" "}
                            <RightArrow />
                          </Typography>
                        </Box>
                        <Box className="odds-deatils">
                          <Table className="odds-table">
                            <TableHead>
                              <TableRow>
                                <TableCell className="best-at-open border-right">
                                  Best at Open
                                </TableCell>
                                <TableCell className="current-best">
                                  Current Best
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell className=" best-at-open-cell border-right">
                                  <Box className="odds-wrap">
                                    <span>
                                      {" "}
                                      {fetchBestOpenValue(
                                        item?.runner?.[0]?.MarketRelations,
                                        0
                                      )}
                                    </span>
                                    {fetchBestOpenIcon(
                                      item?.runner?.[0]?.MarketRelations,
                                      0
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell className="current-best-cell">
                                  {" "}
                                  <Box className="odds-wrap current-best-cell">
                                    <span>
                                      {fetchCurrentBestValue(
                                        item?.runner?.[0]?.MarketRelations
                                      )}
                                    </span>
                                    {fetchCurrentBestIcon(
                                      item?.runner?.[0]?.MarketRelations
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box className="runner-deatils track-details">
                      {" "}
                      {screenWidth > 899 ? (
                        <>
                          <Box className="runner-info">
                            <Typography
                              className="runner-name track-flag"
                              onClick={() => handleNavigate(item)}
                            >
                              {item?.track?.Country?.country_flag?.includes(
                                "uploads"
                              ) ? (
                                <img
                                  src={
                                    Config.mediaURL +
                                    item?.track?.Country?.country_flag
                                  }
                                  alt="Race Contry"
                                  className="flag"
                                />
                              ) : (
                                <img
                                  src={item?.track?.Country?.country_flag}
                                  alt="Race Contry"
                                  className="flag"
                                />
                              )}
                              {item?.eventName}
                            </Typography>
                            <Box>
                              <Box>
                                <Typography className="runner-info-details">
                                  Distance: N/A
                                </Typography>
                                <Typography className="runner-info-details">
                                  Track: N/A
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Table className="track-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Box className="track-runner">R1</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R2</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R3</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R4</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R5</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R6</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R7</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R8</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R9</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R10</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R11</Box>
                                </TableCell>
                                <TableCell>
                                  <Box className="track-runner">R12</Box>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>{handleTracklist(item)}</TableRow>
                            </TableBody>
                          </Table>
                        </>
                      ) : (
                        <>
                          {fetchNewMobileViewRacingData(
                            item,
                            item?.track?.countryId === 157 ||
                              item?.track?.countryId === 13
                              ? false
                              : true,
                            index
                          )}
                          <Box className="runner-info">
                            <Box className="distance-track-wrap">
                              <Typography className="runner-info-details">
                                Distance: N/A
                              </Typography>
                              <Typography className="runner-info-details">
                                Track: N/A
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  )}

                  {isToggle ? (
                    <Box className="comment-section">
                      <Box className="runner-comment">
                        <Typography className="runner">
                          {item?.favoriteData?.type}:
                        </Typography>
                        <Typography className="runner comment">
                          {item?.favoriteData?.comment}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className="edit-comment cursor-pointer"
                          onClick={() =>
                            handleEdit(
                              item?.favoriteData?.type,
                              item?.favoriteData?.id,
                              item
                            )
                          }
                        >
                          <EditIcon />
                          Edit comment
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif"
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isUpcomingLoading &&
        upcomingData &&
        upcomingData?.Favorites?.length > 0 ? (
          <Box className="pagination-section">
            {/* <Pagination count={1} /> */}
            <Pagination
              // hideNextButton
              // hidePrevButton
              disabled={upcomingCount / 10 > 1 ? false : true}
              page={upcomingPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              // count={10}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <OurPartner />
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        id={selectedId}
        typeId={typeId}
        editComment={editComment}
        isEdit={true}
        tabType="Upcoming"
        listingFunction={() =>
          fetchUpcomingBlackBook(countryId, stateId, upcomingPage)
        }
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default UpcomingRunner;
