import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import racingBanner from "../../../assets/images/horse-racing-banner.png";
import "./internationalRacing.scss";

const raceRecord = [
  {
    id: 1,
    field: "Win",
    field1: "Select a winner"
  },
  {
    id: 2,
    field: "Place",
    field1: "Select any horse of the first three horses"
  },
  {
    id: 3,
    field: "Quinella (QIN)",
    field1: "Select the 1st and 2nd horses in any order"
  },
  {
    id: 4,
    field: "Quinella Place (QPL)",
    field1: "Select any two of the first three horses in any order"
  }
];

const HongKongHorseRacing = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <Box className="img-sec hw-100">
        <img src={racingBanner} alt="website-img" className="inner-img" />
      </Box>
      <Box className="view-tips-btn-sec">
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          fullWidth
          className="skip-now-btn"
          // onClick={() => {
          //   handleNavigation();
          // }}
          // endIcon={<RightIcon />}
        >
          View Hong Kong racing tips
        </Button>
      </Box>
      <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">Hong Kong Horse Racing</Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            Recognised as Hong Kong’s national sport, Hong Kong horse racing is
            as popular now as it has ever been, and the history of horse racing
            showcased throughout the country goes back hundreds of years.
          </Typography>
          <Typography className="description mt-27">
            Having over 120 years of horse racing history, HK horse racing began
            in 1884 with the start of the Hong Kong Jockey Club, a non-profit
            organisation that gives its betting revenues to charity. The Hong
            Kong Jockey Club provides anything a punter would need to back a
            winner, from race updates to full-video horse race replays and
            analysis.
          </Typography>
          <Typography className="description mt-27">
            The HKJC conducts nearly 700 horse races a year at their two
            racetracks; Sha Tin and Happy Valley, from September to July with a
            total of 88 Hong Kong horse race meetings. The Hong Kong horse race
            schedule is roughly split between day and night races. Day races
            mostly takes place on Sunday and night races on Wednesday.
          </Typography>
        </Box>
      </Box>
      <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">Hong Kong Horse Racecourse</Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            Sha Tin Racecourse
          </Typography>
          <Typography className="description">
            Mainly hosts Sunday day races with ten horse races per meeting
          </Typography>
          <Typography className="description">
            Track: Turf or All-Weather
          </Typography>
          <Typography className="description">
            Number of Starters: Maximum 14
          </Typography>
          <Typography className="description mt-27">
            Happy Valley Racecourse
          </Typography>
          <Typography className="description mt-27">
            Mainly hosts Wednesday night races with eight horse races per race
            meeting.
          </Typography>
          <Typography className="description">Track: Turf</Typography>
          <Typography className="description">
            Number of Starters: Maximum 12
          </Typography>
        </Box>
      </Box>
      <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">Hong Kong Horse Racecourse</Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            If you’re looking to see the best horse racing Hong Kong has to
            offer, it is the mid-December International Group 1s which include
            the Hong Kong Cup, Hong Kong Mile, Hong Kong Vase and Hong Kong
            Sprint. Over 40 international horses may seek to win the lucrative
            and attractive races.
          </Typography>
        </Box>
      </Box>
      <Box className="txt-sec mt-27 pb-bottom w-100">
        <Typography className="title">HK Horse Race Betting</Typography>
        <Box className="des-box">
          <Typography className="description mt-27">
            How to place bets on HK horse racing?
          </Typography>

          <Box className="mt-27">
            <TableContainer className="international-table-container border-gray">
              <Table
                style={{ minWidth: "max-content", height: "100%" }}
                className="international-table"
              >
                <TableBody>
                  {raceRecord?.map((item, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell style={{ width: "20%" }}>
                            {item?.field}
                          </TableCell>
                          <TableCell>{item?.field1}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HongKongHorseRacing;
