import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./geofencingpage.scss"

const GeofencingPage = () => {
    return (
        <>
            <Box className="restricted-main-wrap">
                <Box className="detail-header-wrap">
                    <Typography variant="h1"> Access denied </Typography>
                    <Typography> This content is restricted to Australia. You must be located in Australia to view it. </Typography>
                </Box>
            </Box>
        </>
    );
};

export default GeofencingPage;