import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Popover,
  ListItemIcon,
  ListItemText,
  ListItem
} from "@material-ui/core";
import { google, outlook, office365, ics } from "calendar-link";
import { ReactComponent as Arrowdown } from "src/assets/images/eventcalendar/arrowupcal.svg";
import { ReactComponent as Arrowup } from "src/assets/images/eventcalendar/arrowdowncal.svg";
import { ReactComponent as Addtocal } from "src/assets/images/eventcalendar/addtocalendar.svg";
// import { ReactComponent as Day } from "src/assets/images/eventcalendar/day.svg";
// import { ReactComponent as ListIcon } from "src/assets/images/eventcalendar/list.svg";
import { ReactComponent as SelectedMonth } from "src/assets/images/eventcalendar/selectedmonth.svg";
import { ReactComponent as SelectedDay } from "src/assets/images/eventcalendar/selectedday.svg";
import { event } from "jquery";
import moment from "moment";

const AddToCalendar = ({ event }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [select, setSelect] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const calendarList = [
    { name: "Google Calendar", id: 1 },
    { name: "iCalender", id: 2 },
    { name: "OUTLOOK 365", id: 3 },
    { name: "OUTLOOK LIVE", id: 4 }
  ];

  const handleClose = () => {
    setAnchorEl(null);
    // setDDArray(dropdownArray)
  };

  useEffect(() => {
    const eventData = {
      title: event?.eventName,
      start: moment.utc(event?.eventDate),
      end: moment.utc(event?.eventDate)
    };
    if (select === 1) {
      let URL = google(eventData);
      window.open(URL, "_blank");
    } else if (select === 2) {
      let URL = ics(eventData);
      window.open(URL, "_blank");
    } else if (select === 3) {
      let URL = office365(eventData);
      window.open(URL, "_blank");
    } else if (select === 4) {
      let URL = outlook(eventData);
      window.open(URL, "_blank");
    }
  }, [select, event]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button
        endIcon={anchorEl ? <Arrowdown /> : <Arrowup />}
        startIcon={
          select === "month" ? (
            <SelectedMonth />
          ) : select === "day" ? (
            <SelectedDay />
          ) : (
            <Addtocal />
          )
        }
        className={
          select === "month" || select === "day"
            ? "calendar-list-button selected-calendar-list"
            : "calendar-list-button"
        }
        onClick={handleClick}
      >
        {"Add to Calender"}
      </Button>
      <Popover
        className="add-calendar-list"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {calendarList &&
          calendarList.length > 0 &&
          calendarList.map(item => {
            return (
              <ListItem
                className={
                  select === item?.id ? "active list-item" : "list-item"
                }
                onClick={() => {
                  setSelect(item?.id);
                  // onSelect("month");
                  handleClose();
                }}
              >
                <ListItemText primary={item?.name} className="list-text" />
              </ListItem>
            );
          })}

        {/* <ListItem
          className="list-item"
          onClick={() => {
            setSelect("day");
            onSelect("day");
            handleClose();
          }}
        >
          <ListItemIcon className="list-icon">
            <Day />
          </ListItemIcon>
          <ListItemText primary={"iCalender"} className="list-text" />
        </ListItem> */}
      </Popover>
    </>
  );
};

export default AddToCalendar;
