import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles
} from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";

const StickyTableCell = withStyles(theme => ({
  head: {
    right: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "45px",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "45px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    right: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const PlayerGamesDetails = ({ setGamesPlayerRelation }) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [gamesLoader, setGamesLoader] = useState(false);
  const [playerGamesData, setPlayerGamesData] = useState([]);

  useEffect(() => {
    fetchPlayerGamesDetails();
  }, [params, SeasonId]);

  const fetchPlayerGamesDetails = async () => {
    setGamesLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/player/games/${params?.playerId}?SportId=${
          params?.sportId
        }&tournamentId=${params?.tournamentId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`
      );
      if (status === 200) {
        setGamesLoader(false);
        setPlayerGamesData(data?.result?.event);
        setGamesPlayerRelation(data?.result?.playerRelation);
      } else {
        setGamesLoader(false);
      }
    } catch (err) {
      setGamesLoader(false);
    }
  };

  const getTeamScores = data => {
    const teamScores =
      Number(params?.sportId) === 4
        ? data?.CricketScores
        : Number(params?.sportId) === 10
        ? data?.NBAScores
        : Number(params?.sportId) === 12 ||
          Number(params?.sportId) === 13 ||
          Number(params?.sportId) === 14
        ? data?.RLScores
        : Number(params?.sportId) === 15
        ? data?.AFLScores
        : Number(params?.sportId) === 9
        ? data?.ARScores
        : Number(params?.sportId) === 16
        ? data?.GolfScores
        : Number(params?.sportId) === 7
        ? data?.TennisScores
        : Number(params?.sportId) === 11
        ? data?.BaseballScores
        : Number(params?.sportId) === 17
        ? data?.IceHockeyScores
        : Number(params?.sportId) === 6
        ? data?.BoxingScores
        : Number(params?.sportId) === 5
        ? data?.MMAScores
        : Number(params?.sportId) === 8
        ? data?.SoccerScores
        : "-";
    const hometeamScore = teamScores?.filter(
      item => item?.teamId === Number(params?.teamId)
    );
    const awayTeamScore = teamScores?.filter(
      item => item?.teamId !== Number(params?.teamId)
    );

    const homeScore =
      Number(params?.sportId) === 4
        ? hometeamScore?.[0]?.score?.innings?.inning1
          ? hometeamScore?.[0]?.score?.innings?.inning1?.score +
            "/" +
            hometeamScore?.[0]?.score?.innings?.inning1?.wickets +
            " " +
            "(" +
            hometeamScore?.[0]?.score?.innings?.inning1?.overs +
            ")" +
            (hometeamScore?.[0]?.score?.innings?.inning2
              ? "&" +
                (hometeamScore?.[0]?.score?.innings?.inning2?.score +
                  "/" +
                  hometeamScore?.[0]?.score?.innings?.inning2?.wickets +
                  " " +
                  "(" +
                  hometeamScore?.[0]?.score?.innings?.inning2?.overs +
                  ")")
              : "")
          : "-"
        : hometeamScore?.[0]?.score?.current;

    const awayScore =
      Number(params?.sportId) === 4
        ? awayTeamScore?.[0]?.score?.innings?.inning1
          ? awayTeamScore?.[0]?.score?.innings?.inning1?.score +
            "/" +
            awayTeamScore?.[0]?.score?.innings?.inning1?.wickets +
            " " +
            "(" +
            awayTeamScore?.[0]?.score?.innings?.inning1?.overs +
            ")" +
            (awayTeamScore?.[0]?.score?.innings?.inning2
              ? "&" +
                (awayTeamScore?.[0]?.score?.innings?.inning2?.score +
                  "/" +
                  awayTeamScore?.[0]?.score?.innings?.inning2?.wickets +
                  " " +
                  "(" +
                  awayTeamScore?.[0]?.score?.innings?.inning2?.overs +
                  ")")
              : "")
          : "-"
        : awayTeamScore?.[0]?.score?.current;
    return homeScore + "-" + awayScore;
  };

  const handleNavigate = data => {
    const stateToPass = {
      viewListPage: data?.id,
      viewPage: true
      // Add your state data here
    };
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${params?.teamId}/team-data?seasonId=${
        SeasonId ? SeasonId : ""
      }`,
      {
        state: stateToPass
      }
    );
  };

  const getTournamentName = data => {
    const sportName =
      Number(params?.sportId) === 4
        ? data?.CricketTournament
        : Number(params?.sportId) === 10
        ? data?.NBATournament
        : Number(params?.sportId) === 12 ||
          Number(params?.sportId) === 13 ||
          Number(params?.sportId) === 14
        ? data?.RLTournament
        : Number(params?.sportId) === 15
        ? data?.AFLTournament
        : Number(params?.sportId) === 9
        ? data?.ARTournament
        : Number(params?.sportId) === 16
        ? data?.GolfTournament
        : Number(params?.sportId) === 7
        ? data?.TennisTournament
        : Number(params?.sportId) === 11
        ? data?.BaseballTournament
        : Number(params?.sportId) === 17
        ? data?.IceHockeyTournament
        : Number(params?.sportId) === 6
        ? data?.BoxingTournament
        : Number(params?.sportId) === 5
        ? data?.MMATournament
        : Number(params?.sportId) === 8
        ? data?.SoccerTournament
        : "-";

    return sportName ? sportName?.name : "-";
  };

  const getCoachName = data => {
    const TeamManagers =
      Number(params?.sportId) === 4
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.CricketTeamManagers?.[0]?.CricketManager?.name
          : data?.awayTeam?.CricketTeamManagers?.[0]?.CricketManager?.name
        : Number(params?.sportId) === 10
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.NBATeamManagers?.[0]?.NBAManager?.name
          : data?.awayTeam?.NBATeamManagers?.[0]?.NBAManager?.name
        : Number(params?.sportId) === 12 ||
          Number(params?.sportId) === 13 ||
          Number(params?.sportId) === 14
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.RLTeamManagers?.[0]?.RLManager?.name
          : data?.awayTeam?.RLTeamManagers?.[0]?.RLManager?.name
        : Number(params?.sportId) === 15
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.AFLTeamManagers?.[0]?.AFLManager?.name
          : data?.awayTeam?.AFLTeamManagers?.[0]?.AFLManager?.name
        : Number(params?.sportId) === 9
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.ARTeamManagers?.[0]?.ARManager?.name
          : data?.awayTeam?.ARTeamManagers?.[0]?.ARManager?.name
        : Number(params?.sportId) === 16
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.GolfTeamManagers?.[0]?.GolfManager?.name
          : data?.awayTeam?.GolfTeamManagers?.[0]?.GolfManager?.name
        : Number(params?.sportId) === 7
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.TennisTeamManagers?.[0]?.TennisManager?.name
          : data?.awayTeam?.TennisTeamManagers?.[0]?.TennisManager?.name
        : Number(params?.sportId) === 11
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.BaseballTeamManagers?.[0]?.BaseballManager?.name
          : data?.awayTeam?.BaseballTeamManagers?.[0]?.BaseballManager?.name
        : Number(params?.sportId) === 17
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.IceHockeyTeamManagers?.[0]?.IceHockeyManager?.name
          : data?.awayTeam?.IceHockeyTeamManagers?.[0]?.IceHockeyManager?.name
        : Number(params?.sportId) === 6
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.BoxingTeamManagers?.[0]?.BoxingManager?.name
          : data?.awayTeam?.BoxingTeamManagers?.[0]?.BoxingManager?.name
        : Number(params?.sportId) === 5
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.MMATeamManagers?.[0]?.MMAManager?.name
          : data?.awayTeam?.MMATeamManagers?.[0]?.MMAManager?.name
        : Number(params?.sportId) === 8
        ? Number(params?.teamId) === data?.homeTeamId
          ? data?.homeTeam?.SoccerTeamManagers?.[0]?.SoccerManager?.name
          : data?.awayTeam?.SoccerTeamManagers?.[0]?.SoccerManager?.name
        : "-";

    return TeamManagers;
  };

  const handleCoachNavigate = data => {
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${params?.teamId}/team-data/${
        params?.playerId
      }/player-data/${
        Number(params?.sportId) === 4
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.CricketTeamManagers?.[0]?.CricketManagerId
            : data?.awayTeam?.CricketTeamManagers?.[0]?.CricketManagerId
          : Number(params?.sportId) === 10
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.NBATeamManagers?.[0]?.NBAManagerId
            : data?.awayTeam?.NBATeamManagers?.[0]?.NBAManagerId
          : Number(params?.sportId) === 12 ||
            Number(params?.sportId) === 13 ||
            Number(params?.sportId) === 14
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.RLTeamManagers?.[0]?.RLManagerId
            : data?.awayTeam?.RLTeamManagers?.[0]?.RLManagerId
          : Number(params?.sportId) === 15
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.AFLTeamManagers?.[0]?.AFLManagerId
            : data?.awayTeam?.AFLTeamManagers?.[0]?.AFLManagerId
          : Number(params?.sportId) === 9
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.ARTeamManagers?.[0]?.ARManagerId
            : data?.awayTeam?.ARTeamManagers?.[0]?.ARManagerId
          : Number(params?.sportId) === 16
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.GolfTeamManagers?.[0]?.GolfManagerId
            : data?.awayTeam?.GolfTeamManagers?.[0]?.GolfManagerId
          : Number(params?.sportId) === 7
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.TennisTeamManagers?.[0]?.TennisManagerId
            : data?.awayTeam?.TennisTeamManagers?.[0]?.TennisManagerId
          : Number(params?.sportId) === 11
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.BaseballTeamManagers?.[0]?.BaseballManagerId
            : data?.awayTeam?.BaseballTeamManagers?.[0]?.BaseballManagerId
          : Number(params?.sportId) === 17
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.IceHockeyTeamManagers?.[0]?.IceHockeyManagerId
            : data?.awayTeam?.IceHockeyTeamManagers?.[0]?.IceHockeyManagerId
          : Number(params?.sportId) === 6
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.BoxingTeamManagers?.[0]?.BoxingManagerId
            : data?.awayTeam?.BoxingTeamManagers?.[0]?.BoxingManagerId
          : Number(params?.sportId) === 5
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.MMATeamManagers?.[0]?.MMAManagerId
            : data?.awayTeam?.MMATeamManagers?.[0]?.MMAManagerId
          : Number(params?.sportId) === 8
          ? Number(params?.teamId) === data?.homeTeamId
            ? data?.homeTeam?.SoccerTeamManagers?.[0]?.SoccerManagerId
            : data?.awayTeam?.SoccerTeamManagers?.[0]?.SoccerManagerId
          : "-"
      }/coach-data?seasonId=${SeasonId ? SeasonId : ""}`
    );
  };
  return (
    <>
      <Box className="player-games-wrap">
        <Box className="short-name-details">
          <Typography className="short-name">
            H - <span>Home</span>, A - <span>Away</span>, N -{" "}
            <span>Neutral/Alternative Venue</span>, L - <span>Loss</span>, W -{" "}
            <span>Win</span>, D - <span>Draw</span>
          </Typography>
        </Box>
        <Box className="teamsport-header-details">
          <Typography className="heading-title">List of games</Typography>
        </Box>
        {gamesLoader && (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        )}
        {!gamesLoader && (
          <>
            <Typography className="note" variant="p">
              To view more info on a match, click the <span>View</span> button.
            </Typography>
            <Box className="table-overlay">
              <TableContainer
                id="details-table"
                className="teamsport-data-table team-sport-tables player-data-table"
              >
                {/* <Box className="overlay"></Box> */}
                <Table
                  className="data-table"
                  style={{ minWidth: "max-content" }}
                >
                  <TableHead className="table-header">
                    <TableRow className="table-head-row ">
                      <TableCell className={`${classes.head} first-cell`}>
                        Date
                      </TableCell>
                      <TableCell className={classes.head}>
                        Competition
                      </TableCell>
                      <TableCell className={classes.head}>Round</TableCell>
                      <TableCell className={classes.head}></TableCell>
                      <TableCell className={classes.head}>Team</TableCell>
                      <TableCell className={classes.head}></TableCell>
                      <TableCell className={classes.head}>Score</TableCell>
                      <TableCell className={classes.head}>Opposition</TableCell>
                      <TableCell className={classes.head}>Coach(es)</TableCell>
                      <TableCell className={classes.head}>Captain(s)</TableCell>
                      <TableCell className={classes.head}>Venue</TableCell>
                      <TableCell className={classes.head}>Crowd</TableCell>
                      <TableCell className={classes.head}>Number</TableCell>
                      <TableCell className={classes.head}>Pos</TableCell>
                      <TableCell className={classes.head}>Scooring</TableCell>
                      <StickyTableCell
                        className={classes.head}
                      ></StickyTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {playerGamesData?.length > 0 ? (
                      <>
                        {playerGamesData?.map((item, i) => {
                          return (
                            <>
                              <TableRow key={i} className="table-body-row ">
                                <TableCell
                                  className={`${classes.cell} first-cell`}
                                >
                                  {moment(item?.startTime).format("MMM Do")}
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} light-blue cursor-pointer`}
                                  onClick={() =>
                                    navigate(
                                      `/sport/statistics/${params?.sportId}/${
                                        Number(params?.sportId) === 4
                                          ? item?.CricketTournament?.id
                                          : Number(params?.sportId) === 10
                                          ? item?.NBATournament?.id
                                          : Number(params?.sportId) === 12 ||
                                            Number(params?.sportId) === 13 ||
                                            Number(params?.sportId) === 14
                                          ? item?.RLTournament?.id
                                          : Number(params?.sportId) === 15
                                          ? item?.AFLTournament?.id
                                          : Number(params?.sportId) === 9
                                          ? item?.ARTournament?.id
                                          : Number(params?.sportId) === 16
                                          ? item?.GolfTournament?.id
                                          : Number(params?.sportId) === 7
                                          ? item?.TennisTournament?.id
                                          : Number(params?.sportId) === 11
                                          ? item?.BaseballTournament?.id
                                          : Number(params?.sportId) === 17
                                          ? item?.IceHockeyTournament?.id
                                          : Number(params?.sportId) === 6
                                          ? item?.BoxingTournament?.id
                                          : Number(params?.sportId) === 5
                                          ? item?.MMATournament?.id
                                          : Number(params?.sportId) === 8
                                          ? item?.SoccerTournament?.id
                                          : "-"
                                      }/league-data?seasonId=${
                                        SeasonId ? SeasonId : ""
                                      }`
                                    )
                                  }
                                >
                                  {getTournamentName(item)}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.round ? "Round " + item?.round : "-"}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {Number(params?.teamId) === item?.homeTeamId
                                    ? "H"
                                    : "A"}
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} light-blue cursor-pointer`}
                                  onClick={() =>
                                    navigate(
                                      `/sport/statistics/${params?.sportId}/${
                                        Number(params?.sportId) === 4
                                          ? item?.CricketTournament?.id
                                          : Number(params?.sportId) === 10
                                          ? item?.NBATournament?.id
                                          : Number(params?.sportId) === 12 ||
                                            Number(params?.sportId) === 13 ||
                                            Number(params?.sportId) === 14
                                          ? item?.RLTournament?.id
                                          : Number(params?.sportId) === 15
                                          ? item?.AFLTournament?.id
                                          : Number(params?.sportId) === 9
                                          ? item?.ARTournament?.id
                                          : Number(params?.sportId) === 16
                                          ? item?.GolfTournament?.id
                                          : Number(params?.sportId) === 7
                                          ? item?.TennisTournament?.id
                                          : Number(params?.sportId) === 11
                                          ? item?.BaseballTournament?.id
                                          : Number(params?.sportId) === 17
                                          ? item?.IceHockeyTournament?.id
                                          : Number(params?.sportId) === 6
                                          ? item?.BoxingTournament?.id
                                          : Number(params?.sportId) === 5
                                          ? item?.MMATournament?.id
                                          : Number(params?.sportId) === 8
                                          ? item?.SoccerTournament?.id
                                          : "-"
                                      }/league-data/${
                                        Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? item?.homeTeamId
                                          : item?.awayTeamId
                                      }/team-data?seasonId=${
                                        SeasonId ? SeasonId : ""
                                      }`
                                    )
                                  }
                                >
                                  {" "}
                                  {Number(params?.teamId) === item?.homeTeamId
                                    ? item?.homeTeam?.name
                                    : item?.awayTeam?.name}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {" "}
                                  {item?.winnerCode === 1 ? "W" : "L"}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {getTeamScores(item)}
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} light-blue cursor-pointer`}
                                  onClick={() =>
                                    navigate(
                                      `/sport/statistics/${params?.sportId}/${
                                        Number(params?.sportId) === 4
                                          ? item?.CricketTournament?.id
                                          : Number(params?.sportId) === 10
                                          ? item?.NBATournament?.id
                                          : Number(params?.sportId) === 12 ||
                                            Number(params?.sportId) === 13 ||
                                            Number(params?.sportId) === 14
                                          ? item?.RLTournament?.id
                                          : Number(params?.sportId) === 15
                                          ? item?.AFLTournament?.id
                                          : Number(params?.sportId) === 9
                                          ? item?.ARTournament?.id
                                          : Number(params?.sportId) === 16
                                          ? item?.GolfTournament?.id
                                          : Number(params?.sportId) === 7
                                          ? item?.TennisTournament?.id
                                          : Number(params?.sportId) === 11
                                          ? item?.BaseballTournament?.id
                                          : Number(params?.sportId) === 17
                                          ? item?.IceHockeyTournament?.id
                                          : Number(params?.sportId) === 6
                                          ? item?.BoxingTournament?.id
                                          : Number(params?.sportId) === 5
                                          ? item?.MMATournament?.id
                                          : Number(params?.sportId) === 8
                                          ? item?.SoccerTournament?.id
                                          : "-"
                                      }/league-data/${
                                        Number(params?.teamId) ===
                                        item?.homeTeamId
                                          ? item?.awayTeamId
                                          : item?.homeTeamId
                                      }/team-data?seasonId=${
                                        SeasonId ? SeasonId : ""
                                      }`
                                    )
                                  }
                                >
                                  {" "}
                                  {Number(params?.teamId) === item?.homeTeamId
                                    ? item?.awayTeam?.name
                                    : item?.homeTeam?.name}
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} light-blue cursor-pointer`}
                                  onClick={() => handleCoachNavigate(item)}
                                >
                                  {getCoachName(item)}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  -
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} light-blue cursor-pointer`}
                                  onClick={() =>
                                    navigate(
                                      `/sport/statistics/${params?.sportId}/${
                                        params?.tournamentId
                                      }/league-data/${
                                        params?.teamId
                                      }/team-data/${
                                        params?.playerId
                                      }/player-data/${
                                        Number(params?.sportId) === 4
                                          ? item?.CricketStadiumId
                                          : Number(params?.sportId) === 10
                                          ? item?.NBAStadiumId
                                          : Number(params?.sportId) === 12 ||
                                            Number(params?.sportId) === 13 ||
                                            Number(params?.sportId) === 14
                                          ? item?.RLStadiumId
                                          : Number(params?.sportId) === 15
                                          ? item?.AFLStadiumId
                                          : Number(params?.sportId) === 9
                                          ? item?.ARStadiumId
                                          : Number(params?.sportId) === 16
                                          ? item?.GolfStadiumId
                                          : Number(params?.sportId) === 7
                                          ? item?.TennisStadiumId
                                          : Number(params?.sportId) === 11
                                          ? item?.BaseballStadiumId
                                          : Number(params?.sportId) === 17
                                          ? item?.IceHockeyStadiumId
                                          : Number(params?.sportId) === 6
                                          ? item?.BoxingStadiumId
                                          : Number(params?.sportId) === 5
                                          ? item?.MMAStadiumId
                                          : Number(params?.sportId) === 8
                                          ? item?.SoccerStadiumId
                                          : "-"
                                      }/stadium-data?seasonId=${
                                        SeasonId ? SeasonId : ""
                                      }`
                                    )
                                  }
                                >
                                  {Number(params?.sportId) === 4
                                    ? item?.CricketStadium?.name
                                    : Number(params?.sportId) === 10
                                    ? item?.NBAStadium?.name
                                    : Number(params?.sportId) === 12 ||
                                      Number(params?.sportId) === 13 ||
                                      Number(params?.sportId) === 14
                                    ? item?.RLStadium?.name
                                    : Number(params?.sportId) === 15
                                    ? item?.AFLStadium?.name
                                    : Number(params?.sportId) === 9
                                    ? item?.ARStadium?.name
                                    : Number(params?.sportId) === 16
                                    ? item?.GolfStadium?.name
                                    : Number(params?.sportId) === 7
                                    ? item?.TennisStadium?.name
                                    : Number(params?.sportId) === 11
                                    ? item?.BaseballStadium?.name
                                    : Number(params?.sportId) === 17
                                    ? item?.IceHockeyStadium?.name
                                    : Number(params?.sportId) === 6
                                    ? item?.BoxingStadium?.name
                                    : Number(params?.sportId) === 5
                                    ? item?.MMAStadium?.name
                                    : Number(params?.sportId) === 8
                                    ? item?.SoccerStadium?.name
                                    : "-"}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  -
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  -
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  -
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  -
                                </TableCell>
                                <StickyTableCell className={classes.cell}>
                                  <span
                                    className="view-btn cursor-pointer"
                                    onClick={() => handleNavigate(item)}
                                  >
                                    View
                                  </span>
                                </StickyTableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={100} className="no-data-td">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default PlayerGamesDetails;
