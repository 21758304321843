import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  withStyles,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableSortLabel
} from "@material-ui/core";
import playerAvtar from "../../../assets/images/player-avatar.png";
import { ReactComponent as AccordionExpand } from "../../../assets/images/icons/accordion-arrow-down.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/print.svg";
import { ReactComponent as FirstRank } from "../../../assets/images/icons/first_rank.svg";
import { ReactComponent as SecondRank } from "../../../assets/images/icons/second_rank.svg";
import { ReactComponent as ThirdRank } from "../../../assets/images/icons/third_rank.svg";
import { ReactComponent as SortingArrow } from "../../../assets/images/icons/sorting-arrow.svg";
import NoDataComp from "../UI/NoData";
import Loader from "src/components/Loader";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "../AdBanner/AdBanner";
import tippingAbove from "../../../assets/images/ad-placeholder/tipping1.png";
import tippingBelow from "../../../assets/images/ad-placeholder/tipping2.png";
import BannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import { Config } from "src/helpers/context";
const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const LadderTab = ({
  stepperCount,
  tabvalue,
  redux_userData,
  eventByIdData,
  compsId
}) => {
  const params = useParams();
  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [ladderList, setLadderList] = useState([]);
  const [ladderCount, setLadderCount] = useState(0);
  const [ladderOffset, setLadderOffset] = useState(0);
  const [currentUser, setCurrentUser] = useState({});
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [sortType, setSortType] = useState("totalPoints");
  const [sortRound, setSortRound] = useState(true);
  const [sortOverallRound, setSortOverallRound] = useState(false);
  const [sortWinPoint, setSortWinPoint] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);

  useEffect(() => {
    if (tabvalue === 1) {
      setSortType("totalPoints");
      setSortRound(true);
      setSortOverallRound(false);
      setSortWinPoint(true);
      getLadderList(0, [], stepperCount, "totalPoints", false);
    }
  }, [params, stepperCount]);

  const fetchAdsData = async () => {
    try {
      const passPageId =
        params?.SportId == 4 ? 41 : params?.SportId == 12 ? 42 : 43;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const getLadderList = async (
    offset,
    ladderLists,
    stepperCount,
    type,
    order
  ) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/ladderBoard?competitionId=${Number(
          params?.compsId
        )}&limit=10&offset=${offset}&round=${stepperCount}&sort=${
          type ? type : ""
        }&orderBy=${order ? "asc" : "desc"}`
      );
      if (status === 200) {
        setLoading(false);
        setLadderCount(data?.result?.length);
        setCurrentUser(data?.currentUser);
        const LadderDatas = [...ladderLists, ...data?.result];
        setLadderList(LadderDatas);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleMoreData = () => {
    let sortData =
      sortType === "roundPoints"
        ? sortRound
        : sortType === "totalPoints"
        ? sortOverallRound
        : sortWinPoint;
    setLadderOffset(ladderOffset + 10);
    getLadderList(ladderOffset + 10, ladderList, stepperCount, sortType, false);
  };

  const handleLessData = () => {
    // scrollToTopSection();
    setLadderOffset(ladderOffset - 10);
    if (ladderList?.length % 10 === 0) {
      setLadderList(prevArray => prevArray.slice(0, -10));
    } else {
      setLadderList(prevArray => prevArray.slice(0, -ladderCount));
    }
  };

  const sortLabelHandler = type => {
    setSortType(type);
    if (type === "roundPoints") {
      getLadderList(ladderOffset, [], stepperCount, type, !sortRound);
      setSortRound(!sortRound);
      setSortOverallRound(true);
      setSortWinPoint(true);
    } else if (type === "totalPoints") {
      getLadderList(ladderOffset, [], stepperCount, type, !sortOverallRound);
      setSortRound(true);
      setSortOverallRound(!sortOverallRound);
      setSortWinPoint(true);
    } else {
      getLadderList(ladderOffset, [], stepperCount, type, !sortWinPoint);
      setSortRound(true);
      setSortOverallRound(true);
      setSortWinPoint(!sortWinPoint);
    }
  };

  const printLadderPDF = async () => {
    // setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/generatePdf?competitionId=${compsId}&round=${stepperCount}`
      );
      if (status === 200) {
        // setLoading(false);

        // Example download
        download(data?.pdfFilePath, data?.fileName);
      } else {
        // setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
    }
  };

  const download = (path, filename) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    anchor.target = "_blank";

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };
  return (
    <>
      <Box className="ladder-wrapper">
        {fetchAds(
          1,
          "100%",
          Config?.release == "IN" ? BannerIndia : tippingAbove
        )}
      </Box>

      {/* new Table Design  */}

      {loading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <>
          {/* {redux_userData?.id === eventByIdData?.User?.id &&
            currentUser &&
            ladderCount > 0 && (
              <Box className="print-btn-box">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="print-btn"
                  onClick={() => {
                    printLadderPDF();
                  }}
                  endIcon={<Print />}
                >
                  <span className="btn-txt">Print Ladder</span>
                </Button>
              </Box>
            )} */}

          {ladderList && ladderList?.length > 0 ? (
            <>
              <Box className="new-ladder-sec">
                <Table
                  style={{ minWidth: screenWidth > 799 ? 904 : 370 }}
                  className="new-ladder-table"
                >
                  <TableHead>
                    <TableRow>
                      <StickyTableCell className={`${classes.head}`}>
                        <Table className="sticky-cell-table">
                          <TableRow>
                            <TableCell className="sticky-cell-table-pos primary-font-color">
                              #Pos
                            </TableCell>
                            <TableCell className="primary-font-color">
                              Tipster name
                            </TableCell>
                          </TableRow>
                        </Table>
                      </StickyTableCell>
                      {/* <TableCell
                              onClick={() => sortLabelHandler("winPoint")}
                              className="primary-font-color table-cell-center"
                            >
                              <Box className="sorting-cell">
                                Total Weekly Wins
                                <TableSortLabel
                                  active={true}
                                  direction={
                                    sortType === "winPoint"
                                      ? sortWinPoint
                                        ? "asc"
                                        : "desc"
                                      : "desc"
                                  }
                                />
                                <span className="sorting-arrow-wrap">
                                  <SortingArrow
                                    className={`${
                                      sortType == "winPoint" &&
                                      sortWinPoint == true
                                        ? "active"
                                        : ""
                                    }`}
                                  />
                                  <SortingArrow
                                    className={`${
                                      sortType == "winPoint" &&
                                      sortWinPoint == false
                                        ? "active"
                                        : ""
                                    } down-arrow`}
                                  />
                                </span>
                              </Box>
                            </TableCell> */}
                      <TableCell
                        onClick={() => sortLabelHandler("roundPoints")}
                        className="primary-font-color table-cell-center cursor-pointer"
                      >
                        <Box className="sorting-cell">
                          {eventByIdData?.SportId == 4 ? "Day" : "Round"} Points{" "}
                          {/* <TableSortLabel
                                active={true}
                                direction={
                                  sortType === "roundPoints"
                                    ? sortRound
                                      ? "asc"
                                      : "desc"
                                    : "desc"
                                }
                              /> */}
                          <span className="sorting-arrow-wrap">
                            <SortingArrow
                              className={`${
                                sortType == "roundPoints" && sortRound == true
                                  ? "active"
                                  : ""
                              }`}
                            />
                            <SortingArrow
                              className={`${
                                sortType == "roundPoints" && sortRound == false
                                  ? "active"
                                  : ""
                              } down-arrow`}
                            />
                          </span>
                        </Box>
                      </TableCell>
                      {eventByIdData?.tippingType === "winning" && (
                        <TableCell className="primary-font-color table-cell-center">
                          {eventByIdData?.SportId == 4 ? "Day" : "Round"} Margin
                        </TableCell>
                      )}
                      <TableCell
                        className="primary-font-color table-cell-center cursor-pointer"
                        onClick={() => sortLabelHandler("totalPoints")}
                      >
                        <Box className="sorting-cell">
                          Overall Points{" "}
                          {/* <TableSortLabel
                                active={true}
                                direction={
                                  sortType === "totalPoints"
                                    ? sortOverallRound
                                      ? "asc"
                                      : "desc"
                                    : "desc"
                                }
                              /> */}
                          <span className="sorting-arrow-wrap">
                            <SortingArrow
                              className={`${
                                sortType == "totalPoints" &&
                                sortOverallRound == true
                                  ? "active"
                                  : ""
                              }`}
                            />
                            <SortingArrow
                              className={`${
                                sortType == "totalPoints" &&
                                sortOverallRound == false
                                  ? "active"
                                  : ""
                              } down-arrow`}
                            />
                          </span>
                        </Box>
                      </TableCell>
                      {eventByIdData?.tippingType === "winning" && (
                        <TableCell className="primary-font-color table-cell-center">
                          Overall Margin
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUser && (
                      <TableRow className="current-user-row">
                        <StickyTableCell
                          className={`${classes.cell} current-user-stickycell`}
                        >
                          <Table className="sticky-cell-table">
                            <TableRow>
                              <TableCell className="highlight-border sticky-cell-table-pos">
                                {currentUser?.rank == 1 ? (
                                  <FirstRank />
                                ) : currentUser?.rank == 2 ? (
                                  <SecondRank />
                                ) : currentUser?.rank == 3 ? (
                                  <ThirdRank />
                                ) : (
                                  <span className="rank-name">
                                    {currentUser?.rank}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell className="highlight-border">
                                <Box className="tipper-img-wrap">
                                  <Box
                                    className={
                                      currentUser?.filePath
                                        ? "profile-icon-wrap icon-border"
                                        : "profile-icon-wrap"
                                    }
                                  >
                                    <img
                                      src={
                                        currentUser?.filePath
                                          ? Config?.countryMediaURL +
                                            currentUser?.filePath
                                          : playerAvtar
                                      }
                                      alt="avtar"
                                      className="profile-icon"
                                    />
                                  </Box>
                                  <span>
                                    {currentUser?.firstName}{" "}
                                    {currentUser?.lastName}
                                  </span>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </StickyTableCell>
                        {/* <TableCell className="table-cell-center highlight-border">
                                {currentUser?.roundData?.winPoint ?? "-"}
                              </TableCell> */}
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.roundPoints ?? "-"}
                        </TableCell>
                        {eventByIdData?.tippingType === "winning" && (
                          <TableCell className="table-cell-center highlight-border">
                            {currentUser?.roundData?.margin ?? "-"}
                          </TableCell>
                        )}
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.totalPoints ?? "-"}
                        </TableCell>
                        {eventByIdData?.tippingType === "winning" && (
                          <TableCell className="table-cell-center highlight-border">
                            {currentUser?.roundData?.totalMargin ?? "-"}
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                    {ladderList?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <StickyTableCell className={`${classes.cell}`}>
                            <Table className="sticky-cell-table">
                              <TableRow>
                                <TableCell className="sticky-cell-table-pos">
                                  {item?.rank ? (
                                    item?.rank == 1 ? (
                                      <FirstRank />
                                    ) : item?.rank == 2 ? (
                                      <SecondRank />
                                    ) : item?.rank == 3 ? (
                                      <ThirdRank />
                                    ) : (
                                      <span className="rank-name">
                                        {item?.rank}
                                      </span>
                                    )
                                  ) : (
                                    <span>{index + 1}</span>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Box className="tipper-img-wrap">
                                    <Box
                                      className={
                                        item?.filePath
                                          ? "profile-icon-wrap icon-border"
                                          : "profile-icon-wrap"
                                      }
                                    >
                                      <img
                                        src={
                                          item?.filePath
                                            ? Config?.countryMediaURL +
                                              item?.filePath
                                            : playerAvtar
                                        }
                                        alt="avtar"
                                        className="profile-icon"
                                      />
                                    </Box>
                                    <span>
                                      {item?.firstName} {item?.lastName}
                                    </span>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </Table>
                          </StickyTableCell>
                          {/* <TableCell className="table-cell-center">
                                  {item?.roundData?.winPoint ?? "-"}
                                </TableCell> */}
                          <TableCell className="table-cell-center">
                            {item?.roundData?.roundPoints ?? "-"}
                          </TableCell>
                          {eventByIdData?.tippingType === "winning" && (
                            <TableCell className="table-cell-center">
                              {item?.roundData?.margin ?? "-"}
                            </TableCell>
                          )}
                          <TableCell className="table-cell-center">
                            {item?.roundData?.totalPoints ?? "-"}
                          </TableCell>
                          {eventByIdData?.tippingType === "winning" && (
                            <TableCell className="table-cell-center">
                              {item?.roundData?.totalMargin ?? "-"}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {ladderList?.length > 0 ? (
                  <Box className="view-more">
                    {ladderOffset + 10 === ladderList?.length ? (
                      <span
                        className="view-more-text"
                        onClick={() => handleMoreData()}
                      >
                        View More
                      </span>
                    ) : (
                      <></>
                    )}
                    {ladderOffset >= 10 && ladderList?.length > 10 ? (
                      <span
                        className="view-more-text view-lese-text"
                        onClick={() => handleLessData()}
                      >
                        View Less
                      </span>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              {/* <Box className="new-ladder-sec">
                <Table style={{ minWidth: 902 }} className="new-ladder-table">
                  <TableHead>
                    <TableRow>
                      <StickyTableCell className={`${classes.head}`}>
                        <Table className="sticky-cell-table">
                          <TableRow>
                            <TableCell className="sticky-cell-table-pos">
                              #Pos
                            </TableCell>
                            <TableCell>Tipper</TableCell>
                          </TableRow>
                        </Table>
                      </StickyTableCell>
              
                      <TableCell
                        onClick={() => sortLabelHandler("roundPoints")}
                        className="primary-font-color table-cell-center"
                      >
                        <Box className="sorting-cell">
                          {eventByIdData?.SportId == 4 ? "Day" : "Round"} Points{" "}
                          
                          <span className="sorting-arrow-wrap">
                            <SortingArrow
                              className={`${
                                sortType == "roundPoints" && sortRound == true
                                  ? "active"
                                  : ""
                              }`}
                            />
                            <SortingArrow
                              className={`${
                                sortType == "roundPoints" && sortRound == false
                                  ? "active"
                                  : ""
                              } down-arrow`}
                            />
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell className="primary-font-color table-cell-center">
                        {eventByIdData?.SportId == 4 ? "Day" : "Round"} Margin
                      </TableCell>
                      <TableCell
                        className="primary-font-color table-cell-center"
                        onClick={() => sortLabelHandler("totalPoints")}
                      >
                        <Box className="sorting-cell">
                          Overall Points{" "}
                          
                          <span className="sorting-arrow-wrap">
                            <SortingArrow
                              className={`${
                                sortType == "totalPoints" &&
                                sortOverallRound == true
                                  ? "active"
                                  : ""
                              }`}
                            />
                            <SortingArrow
                              className={`${
                                sortType == "totalPoints" &&
                                sortOverallRound == false
                                  ? "active"
                                  : ""
                              } down-arrow`}
                            />
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell className="primary-font-color table-cell-center">
                        Overall Margin
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUser && (
                      <TableRow className="current-user-row">
                        <StickyTableCell
                          className={`${classes.cell} current-user-stickycell`}
                        >
                          <Table className="sticky-cell-table">
                            <TableRow>
                              <TableCell className="highlight-border sticky-cell-table-pos">
                                {currentUser?.rank == 1 ? (
                                  <FirstRank />
                                ) : currentUser?.rank == 2 ? (
                                  <SecondRank />
                                ) : currentUser?.rank == 3 ? (
                                  <ThirdRank />
                                ) : (
                                  <span className="rank-name">
                                    {currentUser?.rank}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell className="highlight-border">
                                <Box className="tipper-img-wrap">
                                  <Box
                                    className={
                                      currentUser?.filePath
                                        ? "profile-icon-wrap icon-border"
                                        : "profile-icon-wrap"
                                    }
                                  >
                                    <img
                                      src={
                                        currentUser?.filePath
                                          ? Config?.countryMediaURL +
                                            currentUser?.filePath
                                          : playerAvtar
                                      }
                                      onError={e => {
                                        e.target.src = playerAvtar;
                                      }}
                                      alt="avtar"
                                      className="profile-icon"
                                    />
                                  </Box>
                                  <span>
                                    {currentUser?.firstName}{" "}
                                    {currentUser?.lastName}
                                  </span>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </StickyTableCell>
              
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.roundPoints ?? "-"}
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.margin ?? "-"}
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.totalPoints ?? "-"}
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.totalMargin ?? "-"}
                        </TableCell>
                      </TableRow>
                    )}
                    {ladderList?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <StickyTableCell className={`${classes.cell}`}>
                            <Table className="sticky-cell-table">
                              <TableRow>
                                <TableCell className="sticky-cell-table-pos">
                                  {item?.rank ? (
                                    item?.rank == 1 ? (
                                      <FirstRank />
                                    ) : item?.rank == 2 ? (
                                      <SecondRank />
                                    ) : item?.rank == 3 ? (
                                      <ThirdRank />
                                    ) : (
                                      <span className="rank-name">
                                        {item?.rank}
                                      </span>
                                    )
                                  ) : (
                                    <span>{index + 1}</span>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Box className="tipper-img-wrap">
                                    <Box
                                      className={
                                        item?.filePath
                                          ? "profile-icon-wrap icon-border"
                                          : "profile-icon-wrap"
                                      }
                                    >
                                      <img
                                        src={
                                          item?.filePath
                                            ? Config?.countryMediaURL +
                                              item?.filePath
                                            : playerAvtar
                                        }
                                        onError={e => {
                                          e.target.src = playerAvtar;
                                        }}
                                        alt="avtar"
                                        className="profile-icon"
                                      />
                                    </Box>
                                    <span>
                                      {item?.firstName} {item?.lastName}
                                    </span>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </Table>
                          </StickyTableCell>
                  
                          <TableCell className="table-cell-center">
                            {item?.roundData?.roundPoints ?? "-"}
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.margin ?? "-"}
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.totalPoints ?? "-"}
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.totalMargin ?? "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

              
              </Box> */}
            </>
          ) : (
            <Box
              style={{
                textAlign: "center"
              }}
            >
              <NoDataComp />
            </Box>
          )}
        </>
      )}
      <Box className="ladder-wrapper">
        {fetchAds(
          2,
          "100%",
          Config?.release == "IN" ? BannerIndia : tippingBelow
        )}
      </Box>
    </>
  );
};

export default LadderTab;
