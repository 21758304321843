import React, { useState, useEffect, useRef, useContext } from "react";
import { IntlContext } from "src/App";
import { animateScroll as scroll } from "react-scroll";
import moment from "moment-timezone";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import {
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
} from "@material-ui/core";
import $ from "jquery";

const AdvertisingScreenTable = ({
  runnerData,
  fetchBestOpenValue,
  fetchBestOpenIcon,
  fetchCurrentBestValue,
  fetchCurrentBestIcon,
  fetchTableCell,
  oddsicon,
  sponsoredId,
  pageHeadingData,
}) => {
  const tableRef = useRef(null);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  // useEffect(() => {
  //     const delay = 10000; // 10 seconds delay before scrolling to top
  //     const duration = 7000; // 7 seconds to scroll up and down
  //     // Scroll to the bottom of the table
  //     const scrollToptoBottam = setInterval(() => {
  //         scroll.scrollToBottom({ smooth: true, duration });
  //     }, delay);
  //     // Scroll to the top of the table with a delay
  //     const timer = setInterval(() => {
  //         scroll.scrollToTop({ smooth: true, duration });
  //     }, delay);
  //     // Clear the timeout on component unmount
  //     // return () => clearInterval(timer), clearInterval(scrollToptoBottam);
  // }, []);

  const fetchSponsoredOdds = (data) => {
    let newOdds = data
      ?.filter((odds) => {
        return sponsoredId?.includes(odds.ApiProviderId);
      })
      ?.slice(0, 2);
    let firstSponsored = newOdds?.filter(
      (item) => item?.ApiProviderId === sponsoredId?.[0]
    );
    let secondSponsored = newOdds?.filter(
      (item) => item?.ApiProviderId === sponsoredId?.[1]
    );

    let SponsoredOdds =
      sponsoredId?.length > 0 ? (
        <>
          <Box className="sponsored-odds-wrap">
            {firstSponsored?.length > 0 ? (
              <>
                <Box>
                  {firstSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                    ? firstSponsored?.[0]?.RaceOdds?.[0]?.intValue
                    : "SP"}
                </Box>
              </>
            ) : (
              <>
                <Box style={{ height: "25.2px" }}></Box>
              </>
            )}
            {oddsicon(sponsoredId?.[0])}
          </Box>
          {sponsoredId?.[1] ? (
            <Box className="sponsored-odds-wrap">
              {secondSponsored?.length > 0 ? (
                <>
                  <Box>
                    {secondSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                      ? secondSponsored?.[0]?.RaceOdds?.[0]?.intValue
                      : "SP"}
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ height: "25.2px" }}></Box>
                </>
              )}
              {oddsicon(sponsoredId?.[1])}
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <TableCell colSpan={2}>
          {" "}
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="no-odds">NOA</span>
          </Tooltip>
        </TableCell>
      );
    return SponsoredOdds;
  };

  useEffect(() => {
    setTimeout(() => {
      var $el = $(".table-responsive");
      function anim() {
        var st = $el.scrollTop();
        var sb = $el.prop("scrollHeight") - $el.innerHeight();
        $el.animate({ scrollTop: st < sb / 2 ? sb : 0 }, 5000, anim);
      }
      function stop() {
        $el.stop();
      }
      anim();
      $el.hover(stop, anim);
    }, 5000);
  });

  // useEffect(() => {
  //   const tableElement = tableRef.current;

  //   const anim = () => {
  //     const st = tableElement.scrollTop;
  //     const sb = tableElement.scrollHeight - tableElement.clientHeight;
  //     tableElement.scrollTo({
  //       top: st < sb / 2 ? sb : 0,
  //       behavior: "smooth",
  //       duration: 10000
  //     });
  //     setTimeout(anim, 10000);
  //   }

  //   const stop = () => {
  //     clearTimeout(timerRef.current);
  //   }

  //   let timerRef = { current: setTimeout(anim, 5000) };
  //   anim();
  //   tableElement.addEventListener('mouseenter', stop);
  //   tableElement.addEventListener('mouseleave', () => {
  //     timerRef.current = setTimeout(anim, 5000);
  //   });

  //   // Cleanup
  //   return () => {
  //     tableElement.removeEventListener('mouseenter', stop);
  //     tableElement.removeEventListener('mouseleave', () => {
  //       timerRef.current = setTimeout(anim, 5000);
  //     });
  //     clearTimeout(timerRef.current);
  //   };
  // }, []);
  return (
    <>
      <Box>
        <TableContainer
          style={{ overflowX: "hidden", overflowY: "auto" }}
          className="table-responsive tableContainer custome-Scrollbar"
          id="adv-table-container"
          ref={tableRef}
        >
          <Table
            stickyHeader
            className="racing-data-details"
            aria-label="customized table"
            style={{ minWidth: 700 }}
            id="adv-table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "220px" }}>
                  {" "}
                  {localesData?.RUNNER_TABLE?.RUNNER}
                </TableCell>
                <TableCell style={{ width: "160px", textAlign: "center" }}>
                  {localesData?.RUNNER_TABLE?.BEST_AT_OPEN}
                </TableCell>

                <TableCell
                  style={{ width: "160px", textAlign: "center" }}
                  className="current-best"
                >
                  {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                </TableCell>
                {sponsoredId?.length > 0 ? (
                  <TableCell style={{ width: "160px", textAlign: "center" }}>
                    <Box className="sponsored-head">
                      {localesData?.RUNNER_TABLE?.SPONSORED_ODDS}
                    </Box>
                  </TableCell>
                ) : (
                  <></>
                )}
                {pageHeadingData?.length > 0 && (
                  <TableCell colSpan={17} style={{ paddingLeft: "15px" }}>
                    {" "}
                    {localesData?.RUNNER_TABLE?.OTHER_ODDS}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowX: "hidden", overflowY: "auto" }}>
              {runnerData?.map((item) => {
                return (
                  <>
                    {item?.isScratched === "false" ||
                    item?.isScratched === null ||
                    item?.isScratched === "0" ? (
                      <TableRow>
                        <TableCell>
                          <Box className="runner-details">
                            <Typography variant="h6">
                              {item?.runnerNumber}.{" " + item?.animal?.name} (
                              {item?.barrierNumber})
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className="odds-details">
                            {item?.oddsData?.[0]?.data ? (
                              <>
                                <span style={{ paddingBottom: "5px" }}>
                                  {fetchBestOpenValue(
                                    item?.oddsData?.[0]?.data
                                  )}
                                </span>
                                {fetchBestOpenIcon(item?.oddsData?.[0]?.data)}
                              </>
                            ) : (
                              <Tooltip
                                title="No odds available"
                                className="odds-tooltip"
                                placement="top"
                              >
                                <span className="no-odds">NOA</span>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell className="current-best">
                          <Box className="odds-details">
                            {item?.oddsData?.[0]?.data ? (
                              <>
                                <span style={{ paddingBottom: "5px" }}>
                                  {fetchCurrentBestValue(
                                    item?.oddsData?.[0]?.data
                                  )}
                                </span>
                                {fetchCurrentBestIcon(
                                  item?.oddsData?.[0]?.data
                                )}
                              </>
                            ) : (
                              <Tooltip
                                title="No odds available"
                                className="odds-tooltip"
                                placement="top"
                              >
                                <span className="no-odds">NOA</span>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                        {sponsoredId?.length > 0 ? (
                          <TableCell colSpan={2}>
                            <Box className="sponsored-odds">
                              {fetchSponsoredOdds(item?.oddsData?.[0]?.data)}
                            </Box>
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {pageHeadingData?.map((id) =>
                          fetchTableCell(item, id?.ApiProviderId)
                        )}

                        {/* {fetchTableCell(item, 3)}
                        {fetchTableCell(item, 4)}
                        {fetchTableCell(item, 5)}
                        {fetchTableCell(item, 8)}
                        {fetchTableCell(item, 10)}
                        {fetchTableCell(item, 11)}
                        {fetchTableCell(item, 12)}
                        {fetchTableCell(item, 13)}
                        {fetchTableCell(item, 14)}
                        {fetchTableCell(item, 15)} */}
                      </TableRow>
                    ) : (
                      <TableRow className="racing-data">
                        <TableCell colSpan={100}>
                          <Box className="racer-detail-wrap">
                            <Typography variant="h6">
                              <Box className="scratched">
                                <span className="scratched-runner-name">
                                  {item?.runnerNumber}
                                  {"."} {item?.animal?.name} (
                                  {item?.barrierNumber})
                                </span>
                              </Box>
                              <Box className="scratched-data">
                                <span className="scratched-runner-text">
                                  {" "}
                                  {localesData?.RUNNER_TABLE?.SCRATCHED}{" "}
                                </span>{" "}
                                <span className="scratched-runner-time">
                                  {item?.scratchTime
                                    ? "@" +
                                      moment(item?.scratchTime).format(
                                        "hh:mm A"
                                      )
                                    : ""}
                                </span>
                                <span className="scratched-runner-date">
                                  {item?.scratchTime
                                    ? moment(item?.scratchTime).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""}
                                </span>
                              </Box>
                            </Typography>
                          </Box>
                        </TableCell>
                        {/* <TableCell colSpan={100}></TableCell> */}
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AdvertisingScreenTable;
