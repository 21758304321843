import Axios from "axios/index";
import { errorHandler, fetchFromStorage } from "../context";
import { identifiers } from "../constants/identifier";
import { Config } from "../context/config";
import { EventEmitter } from "../EventEmitter";

const isCheckLoginExpired = data => {
  EventEmitter.dispatch("onapifailure", data);
};
const isIssuePage = data => {
  EventEmitter.dispatch("onissue", data);
};
const isMaintenancePage = data => {
  EventEmitter.dispatch("onmaintenance", data);
};
const axiosInstance = Axios.create({
  baseURL: Config.baseURL,
  headers: { "Content-Type": "application/json" }
});
axiosInstance.interceptors.request.use(config => {
  const token = fetchFromStorage("auth_token");
  const clonedConfig = config;
  if (token) {
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };
  }

  return clonedConfig;
});

axiosInstance.interceptors.response.use(
  config => {
    // isIssuePage(false);
    updateHeaderResponse(config);
    return config;
  },
  error => {
    const errorCode = error?.response?.status;
    updateHeaderResponse(error?.response);
    if (
      error?.response?.status &&
      error?.response?.data?.message == "jwt expired"
    ) {
      isCheckLoginExpired(true);
    }
    if (errorCode === 404 || errorCode === 500 || errorCode === 503) {
      // isIssuePage(true);
    }

    errorHandler(error);
    return Promise.reject(error);
  }
);
let maintenanceModeEmitted = false;
const updateHeaderResponse = response => {
  const maintenance = response?.headers?.["x-maintenance"];
  if (!maintenanceModeEmitted) {
    isMaintenancePage(maintenance);
    maintenanceModeEmitted = true;
  }
};
export default axiosInstance;
