import React, { useState, useEffect } from "react";
import { TableCell, withStyles, Chip } from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
// import { EventEmitter } from "../../../../services/event";
import moment from "moment";
import { Routes } from "../../../../../helpers/constants/routeConstants";
import { useNavigate } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "transparent",
    fontWeight: 400,
  },
  body: {
    borderBottom: "none",
  },
}))(TableCell);

const TrackRaceResult = ({
  race,
  race_obj,
  isMobile,
  intl,
  selectedDate,
  isBlackBook,
}) => {
  const navigate = useNavigate();
  const [winValue, setwinValue] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [IsOdds, setIsOdds] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // console.log(
  //   "summary",
  //   race_obj?.RaceResultSummary?.summary
  //     ? JSON.parse(race_obj?.RaceResultSummary?.summary)
  //     : null
  // );
  const fetchFixedOdds = async () => {
    if (race_obj?.id) {
      // try {
      //   const { status, data } = await axiosInstance.get(
      //     `/public/checkOdd?raceId=${race_obj?.id}`
      //   );
      //   if (status === 200) {
      //     let odds = data?.events?.[0]?.race?.[0]?.bookKeepers.filter(
      //       (obj) => {
      //         if (obj.isOdd === true) {
      //           return true;
      //         } else {
      //           return false;
      //         }
      //       }
      //     );
      //     if (odds.length > 0) {
      //       setIsOdds(true);
      //     } else {
      //       setIsOdds(false);
      //     }
      //   }
      // } catch (error) { }
      // let oddsData = race_obj?.runner?.map((item) => {
      //   return {
      //     RaceOdds:
      //       item?.MarketRelations?.length > 0
      //         ? item?.MarketRelations?.map((obj) => {
      //           if (obj?.ApiProviderId > 0) {
      //             return {
      //               RaceOddslength:
      //                 obj?.RaceOdds?.length > 0 ? true : false,
      //               OddsValues: obj?.RaceOdds?.[0]?.intValue,
      //             };
      //           } else {
      //             return {
      //               RaceOddslength: false,
      //               OddsValues: 0,
      //             };
      //           }
      //         })
      //         : [{ RaceOddslength: false }],
      //   };
      // });
      // let isOdds = oddsData
      //   ?.map((item) => {
      //     return item?.RaceOdds?.some((obj) => {
      //       return obj?.RaceOddslength === true && obj?.OddsValues > 0;
      //     });
      //   })
      //   ?.filter((x) => x === true);
      if (race_obj?.isOdd) {
        setIsOdds(true);
      } else {
        setIsOdds(false);
      }
    }
  };
  // let raceResultSummaryData = race_obj?.RaceResultSummary?.summary
  //   ? JSON.parse(race_obj?.RaceResultSummary?.summary)
  //   : [];
  // let resultData = raceResultSummaryData?.filter(
  //   (obj) => obj?.position ? (obj?.position == 1 || obj?.position == 2 || obj?.position == 3) : (obj?.Position == 1 || obj?.Position == 2 || obj?.Position == 3)
  // );
  useEffect(() => {
    fetchFixedOdds();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
    let end_time_string = moment(
      isBlackBook ? race_obj?.startDate : race_obj?.startTimeDate
    ).format("YYYY/MM/DD HH:mm:ss");
    let diff_sec = moment(end_time_string).diff(current_time_string, "second");

    if (diff_sec < -840) {
      // fetchFinishedRaceWin(race_obj?.id);
    } else {
      let winRes = setInterval(() => {
        let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
        let diff_sec = moment(end_time_string).diff(
          current_time_string,
          "second"
        );
        if (diff_sec < -840) {
          // fetchFinishedRaceWin(race_obj?.id);
          clearInterval(winRes);
        }
      }, 1000);
    }
  }, []);

  // GET RACE RESULT
  const fetchFinishedRaceWin = async (id) => {
    setisLoading(true);
    const { status, data } = await axiosInstance.get(
      `events/getRaceWinner/${id}`
    );
    if (status === 200) {
      let filtered_result = data?.result.filter((obj) => obj !== null);

      let result_value = "";
      let dumy = filtered_result?.map((obj) => {
        result_value = result_value === "" ? obj : result_value + "," + obj;
      });

      setwinValue(result_value);
      setisLoading(false);
    }
  };
  return isMobile === false ? (
    <>
      <StyledTableCell
        align="center"
        className={
          // (resultData?.length > 0 ? "paying" : "upcoming_race_cell_close interim") && (IsOdds === true ? "fixed" : "")
          // IsOdds === true ? resultData?.length > 0 ? "paying fixed" : "upcoming_race_cell_close interim fixed" : resultData?.length > 0 ? "paying" : "upcoming_race_cell_close interim"
          // IsOdds === true ? "fixed" : ""
          race_obj?.resultDisplay
            ? IsOdds === true
              ? "paying fixed"
              : "paying"
            : IsOdds === true
            ? "upcoming_race_cell_close interim fixed"
            : "upcoming_race_cell_close interim"
        }
        // style={{ backgroundColor: "#bfccd8" }}
        onClick={
          // race_obj?.startTimeDate === null || isBlackBook
          //   ? () => {}
          //   :
          () =>
            navigate(
              Routes.RunnerDetails(
                race?.sportId === 1
                  ? "horse"
                  : race?.sportId === 2
                  ? "harness"
                  : "greyhounds",
                race?.sportId,
                race_obj?.id,
                isBlackBook ? race?.trackId : race?.track?.id,
                isBlackBook ? race?.eventId : race?.id,
                selectedDate,
                // race_obj?.startTimeDate,
                intl
              )
            )
        }
      >
        {race_obj?.resultDisplay ? (
          <Chip
            className={"singlerace-count-chip"}
            style={{
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            size="small"
            label={<span>{race_obj?.resultDisplay}</span>}
          />
        ) : (
          <Chip
            className={"singlerace-count-chip"}
            style={{
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            size="small"
            label={<span style={{ color: "red" }}>{"Closed"}</span>}
          />
        )}
      </StyledTableCell>
    </>
  ) : (
    <td className={winValue === "" ? "upcoming_race_cell_close" : ""}>
      {winValue !== "" ? (
        <Chip
          className={"singlerace-count-chip"}
          style={{
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          size="small"
          label={<span>{winValue}</span>}
        />
      ) : (
        <Chip
          className={"singlerace-count-chip"}
          style={{
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          size="small"
          label={<span>{"-"}</span>}
        />
      )}
    </td>
  );
};

export default TrackRaceResult;
