import React, { useEffect } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import players from "../../../assets/images/banner/players.svg";
import players1 from "../../../assets/images/banner/players-small.svg";
import { useNavigate } from "react-router-dom";
import "./maintenance.scss";

const Maintenance = () => {
  const navigate = useNavigate();
  const handleRefresh = () => {
    const prevPage = localStorage.getItem("previous_page");
    if (prevPage) {
      setTimeout(() => {
        navigate(prevPage);
        localStorage.removeItem("previous_page");
      }, 1000);
    } else {
      navigate("/");
    }
  };

  // useEffect(() => {
  //   if (performance.navigation.type === 1) {
  //     const firstLoad = localStorage.getItem("firstLoadDone");
  //     if (!firstLoad) {
  //       alert("ok");
  //       handleRefresh();

  //       // If it's the first load, set the flag in local storage to true and reload the page
  //       localStorage.setItem("firstLoadDone", 1);
  //     } else {
  //     }
  //   } else {
  //   }
  // }, []);
  useEffect(() => {
    const handlePageReload = () => {
      if (performance.navigation.type === 1) {
        handleRefresh();
      }
    };

    window.addEventListener("load", handlePageReload);

    return () => {
      window.removeEventListener("load", handlePageReload);
    };
  }, []);

  return (
    <>
      <Box className="maintenance-banner-box">
        <Box className="image-container">
          <Box className="content-container">
            <Box className="players-box">
              <Box className="txt-box">
                <Typography className="title">
                  Something's wrong here
                </Typography>
                <Typography className="des">
                  We will be back online soon.{" "}
                </Typography>
                <Button
                  variant="outlined"
                  className="go-to-home-btn"
                  onClick={() => handleRefresh()}
                >
                  Refresh
                </Button>
              </Box>
              {/* <Box className="image-container-box"> */}
              <img className="players-img" src={players} />
              {/* </Box> */}
            </Box>
          </Box>
          <Box className="responsive-content-container">
            <Box className="flex-box">
              <Box className="txt-box">
                <Typography className="title">
                  Something's wrong here
                </Typography>
                <Typography className="des">
                  We will be back online soon.{" "}
                </Typography>
                <Button
                  variant="outlined"
                  className="go-to-home-btn"
                  onClick={() => handleRefresh()}
                >
                  Refresh
                </Button>
              </Box>
              <Box className="players-img-box">
                <img className="players-img" src={players1} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Maintenance;
