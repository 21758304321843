import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ClickAwayListener,
  Button,
  Tabs,
  Tab,
  Collapse
} from "@material-ui/core";
import { IntlContext } from "src/App";
import Select, { components } from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as Order } from "../../../../../assets/images/icons/order.svg";
import { ReactComponent as BookIcon } from "../../../../../assets/images/icons/bookicon.svg";
import { ReactComponent as AddBookIcon } from "../../../../../assets/images/add-blackbook.svg";
import { ReactComponent as BlackbookRight } from "../../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../../assets/images/icons/blackbookPlus.svg";
import { ReactComponent as RightBlack } from "../../../../../assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "../../../../../assets/images/icons/plusblack.svg";
import { ReactComponent as CollepseClose } from "../../../../../assets/images/icons/collepse-close.svg";
import { ReactComponent as CollepseOpen } from "../../../../../assets/images/icons/collepse-open.svg";
import { ReactComponent as TooltipArrow } from "../../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Polyhon } from "../../../../../assets/images/icons/polygon.svg";
import { fetchInterval } from "../../../../../helpers/store/Actions/interval";
import { Config } from "../../../../../helpers/context/config";
import "react-lazy-load-image-component/src/effects/blur.css";
import _ from "lodash";
import BlackBookModal from "src/views/component/UI/blackBookModal";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchFromStorage } from "src/helpers/context";
import HighChart from "src/views/component/UI/graph";
import "./fluctuationGraph.scss";
import NoDataComp from "src/views/component/UI/NoData";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { toast } from "react-toastify";
import DefaultImg from "src/assets/images/smartb_default.png";
import RunnerModal from "./runnerModal";

const options = [
  { value: "", label: "ODDS TYPE", disabled: true },
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
  { value: "3", label: "Win Tote" },
  { value: "4", label: "Place Tote" },
  // { value: "4", label: "Starting Price" },
];

const FluctuationGraphPage = ({
  runnerInfo,
  isrunnnerLoading,
  raceId,
  isResult,
  raceTrackdata,
  fetchRaceRunner
}) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  const BetslipData = useSelector(
    (state) => state?.reduxData?.BetSlipData
  )
  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData
  );
  const [selectedOption, setSelectedOption] = useState("1");
  const [runnerInfoData, setrunnerInfoData] = useState([]);
  const [timeinterval, setTimeInterval] = useState();
  const [apiTimeout, setAPiTimeout] = useState();
  const [isOrder, setIsOrder] = useState(false);
  const [open, setOpen] = useState(-1);
  const [isInputModalOpen, setisInputModalOpen] = useState(false)
  const [isFullFormExpand, setisFullFormExpand] = useState(false)
  const [ModalDataId, setModalDataId] = useState([])
  const [modalData, setmodalData] = useState("")
  const [value, setValue] = React.useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [oddsType, setOddsType] = useState({ marketId: 1, oddKey: 1 });
  const [oddsLoader, setOddsLoader] = useState(false);
  const [oddsApiCount, setOddsAPiCount] = useState(0);
  const [ProviderData, setProviderData] = useState([]);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [BBopen, setBBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedGraphId, setSelectedGraphId] = useState(-1);
  const [providerOption, setProviderOption] = useState([]);
  const [isPageHeaderLoading, setIsPageHeaderLoading] = useState(false);

  const localAuth = fetchFromStorage("auth_token");
  const handleModal = () => {
    setBBOpen(true);
  };

  const handleClose = () => {
    setBBOpen(false);
  };

  const handleBlackBookModal = (title, id, type) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      setSelectedTitle(title);
      setSelectedType(type);
      setSelectedId(id);
      handleModal();
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  let sportId = params.sportId;
  const dispatch = useDispatch();

  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
  }, [raceId]);

  useEffect(() => {
    // fetchTableHeading()
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    return () => clearInterval(timeinterval);
  }, []);

  const fetchTableHeading = async (Provider) => {
    setIsPageHeaderLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=1,2,3`
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter((item) =>
          Provider?.includes(item?.BookKeeperId)
        );
        setPageHeadingData(filteredHeading);
        let providerList = [];
        const list = filteredHeading?.map((item) => {
          return providerList?.push({
            label: item?.BookKeeper?.name,
            value: item?.BookKeeperId,
          });
        });
        let alldatas = providerList?.unshift(
          {
            label: "Current Best",
            value: -1,
          },
          {
            label: "Best At Open",
            value: -2,
          }
        );
        setProviderOption(providerList);
        setIsPageHeaderLoading(false);
      } else {
        setIsPageHeaderLoading(false);
      }
    } catch (err) {
      setIsPageHeaderLoading(false);
    }
  };
  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId, true);
      fetchLatestOdds(raceId);
    }
    let timeout = setTimeout(() => {
      if (raceId) {
        fetchOddsData(raceId, false);
      }
      let Interval = setInterval(() => {
        if (raceId) {
          fetchOddsData(raceId, false);
        }
      }, 30000);
      setTimeInterval(Interval);
      dispatch(fetchInterval(Interval));
    }, 15000);
    setAPiTimeout(timeout);
    dispatch(fetchInterval(timeout));
    setOpen(-1);
    setisFullFormExpand(false);
    return () => clearInterval(timeinterval);
  }, [runnerInfo]);

  const oddsTypeChange = (e) => {
    setSelectedOption(e?.value);
    switch (e?.value) {
      case "1":
        setOddsType({ marketId: 1, oddKey: 1 });
        break;
      case "2":
        setOddsType({ marketId: 1, oddKey: 2 });
        break;
      case "3":
        setOddsType({ marketId: 2, oddKey: 1 });
        break;
      case "4":
        setOddsType({ marketId: 2, oddKey: 2 });
        break;
      default:
        setOddsType({ marketId: 1, oddKey: 1 });
    }
  };
  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
    if (raceId) {
      fetchOddsData(raceId, true);
      fetchLatestOdds(raceId);
    }
    let timeout = setTimeout(() => {
      if (raceId) {
        fetchOddsData(raceId, false);
      }
      let Interval = setInterval(() => {
        if (raceId) {
          fetchOddsData(raceId, false);
        }
      }, 30000);
      setTimeInterval(Interval);
      dispatch(fetchInterval(Interval));
    }, 15000);
    setAPiTimeout(timeout);
    dispatch(fetchInterval(timeout));
  }, [selectedOption]);

  const fetchOddsData = async (raceId, isFirstTime) => {
    if (isFirstTime) {
      setOddsLoader(true);
    }
    let runnerInfoData = runnerInfo?.map((obj) => {
      return {
        ...obj,
        RunnerInfos: obj?.RunnerInfos?.map((item) => {
          return {
            ...item,
            info: item?.info ? JSON.parse(item?.info) : "",
          };
        }),
      };
    });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getOddsByrace/${raceId}?marketId=${oddsType?.marketId}&oddKey=${oddsType?.oddKey}`
      );
      if (status === 200) {
        setOddsLoader(false);
        setOddsAPiCount(oddsApiCount + 1);

        let Provider = [];
        let ProviderListfirstRunner = data?.marketRelation?.[0]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        let ProviderListsecondRunner = data?.marketRelation?.[1]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        let ProviderListthirdRunner = data?.marketRelation?.[2]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        const uniqueArray = _.uniq(Provider);
        setProviderData(uniqueArray);
        // fetchTableHeading(uniqueArray);
        // hideSeeAllOddsColumn(data?.marketRelation)
        let newData = runnerInfoData?.map((obj) => {
          return {
            ...obj,
            oddsData: data?.marketRelation
              ?.map((item) => {
                return item?.RacingParticipantId == obj?.id ? item : [];
              })
              ?.filter((x) => {
                return x?.data?.length > 0;
              }),
          };
        });
        setrunnerInfoData(newData);
      }
    } catch (err) { }
  };

  const fetchLatestOdds = async (raceId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `sync/oddOnDemand/${raceId}`
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleRunnerOrder = () => {
    setIsOrder(!isOrder);
    if (isOrder) {
      const runnerDescending = [...runnerInfoData].sort((a, b) => a.id - b.id);
      setrunnerInfoData(runnerDescending);
    } else {
      const runnerDescending = [...runnerInfoData].sort((a, b) => b.id - a.id);
      setrunnerInfoData(runnerDescending);
    }
  };

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item, oddsType) => {

    const newData = raceTrackdata?.ApiRaceIdentifiers?.map((obj) => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter((item) => {
        return item?.BookKeeperId === BookKeeperId
      })
      return {
        ...obj,
        BookKeeperId: BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      }

    })

    const filteredData = newData?.filter?.((obj) => obj?.BookKeeperId === BookKeeperId)
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId)
    let iconData = icon?.[0]
    let imageUrl = oddsType == "currentbest" ? iconData?.currentBest_logo : iconData?.long_logo
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
      return (
        Bet365Data && Bet365Data?.length > 0 ?
          (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
            }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >
            {oddsType == "currentbest" ?
              <img className="currentbest-bookmaker-thumb" src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)} /> :
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>}
          </a>
          ) : (<></>)
      )
    } else if (BookKeeperId == 13) {

      const fetchUrlArray = filteredData?.[0]?.url?.split("/")
      const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1]
      const isMobile = () => {
        const userAgent = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      }
      const betFairUrl = isMobile() ? filteredData?.[0]?.url ? filteredData?.[0]?.url + `?Referrer=SmartB` : iconData?.affiliate_link : filteredData?.[0]?.url ? `https://www.betfair.com.au/exchange/plus/${params?.sportId == 3 ? "greyhound-racing" : "horse-racing"}/market/${fetchMarketId}` : iconData?.affiliate_link
      return (
        <Box className="current-best-odds-icon">
          <a
            href={betFairUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {oddsType == "currentbest" ?
              <img className="currentbest-bookmaker-thumb" src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)} /> :
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>}
          </a>
        </Box>
      );
    } else {
      return (
        <Box className="current-best-odds-icon">
          <a
            href={filteredData?.length > 0 && filteredData?.[0]?.url ? filteredData?.[0]?.url + `?Referrer=SmartB` : iconData?.affiliate_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {oddsType == "currentbest" ?
              <img className="currentbest-bookmaker-thumb" src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)} /> :
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg} alt="Odds Icon" onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>}
          </a>
        </Box>
      );
    }
  }
  const fetchClickableOdds = (odds, BookKeeperId, type, item, fulldata, IsBetslip, oddsType) => {
    const BookKeeperData = item?.filter((obj) => obj?.BookKeeperId === BookKeeperId)
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId)
    let iconData = icon?.[0]

    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(params?.raceId)
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      oddsType == "currentbest" ?
        <Box
          className={`current-best-odds-value ${isAdded ? "active-odd-style" : ""
            }`}
        >

          <span className={isAdded && IsBetslip === "betslip" ? "betslip-added odds-link" : "odds-link"} onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : isAdded ? handleDeleteBetslip(BookKeeperId, item) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}>

            {IsBetslip === "betslip" && !isResult ? <Tooltip title="Bet" className="bet-tooltip" placement="top">
              <span className="bet-now">  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </span>
            </Tooltip>
              :
              <>
                {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
              </>
            }
          </span>
        </Box>
        :
        <Box className={`odds-style  ${isAdded ? "active-odd-style" : ""
          }`} onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : isAdded ? handleDeleteBetslip(BookKeeperId, item) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }} >
          {IsBetslip === "betslip" && !isResult ? <Tooltip title="Bet" className="bet-tooltip" placement="top">
            <Typography className="odds bet-now"> {odds === "SP" ? "SP" : Number(odds).toFixed(2)}</Typography>
          </Tooltip>
            :
            <>
              {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
            </>
          }
        </Box>
      // </a>
    );
    // }

  }
  const handleAddToBetSlip = async (BookKeeperId, type, item, fulldata) => {
    handleBookkeeperCounter(BookKeeperId, type)
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (!isResult) {
        const BookKeeperData = item?.filter((obj) => obj?.BookKeeperId === BookKeeperId)

        let payload = {
          sportId: Number(params?.sportId),
          eventId: Number(params?.raceId),
          teamId: BookKeeperData?.[0]?.RacingParticipantId,
          bookKeeperId: BookKeeperId,
          betOfferId: BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId
        }
        try {
          const { status, data } = await axiosInstance.post(`betSlipCard/BetSlipCard`, payload);
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });
            const addedData = [...BetslipData, data?.result]
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {

          }
        } catch (err) {
          console.log("error", err)
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }

  }
  const handleDeleteBetslip = async (BookKeeperId, item) => {
    const BookKeeperData = item?.filter((obj) => obj?.BookKeeperId === BookKeeperId)
    let delslip = BetslipData?.filter((el) => {
      return el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(params?.raceId)
    })?.[0];


    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) { }
  };

  const fetchFluctuationGraph = (data) => {
    let selectedId = null;
    if (selectedGraphId === -2) {
      let maxno = data?.reduce((max, obj) => {
        obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
          ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
          : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno == 0) {
          let newmaxno = data?.reduce((max, obj) => {
            obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
              ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
              : (max = max);
            return max;
          }, -1);
          if (newmaxno !== -1) {
            let providerid = data
              ?.map((obj) => {
                if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                  return obj?.BookKeeperId;
                }
              })
              ?.filter((x) => x !== undefined);
            selectedId = providerid?.[0];
          } else {
            selectedId = null;
          }
        } else {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          selectedId = providerid?.[0];
        }
        // return maxno;
      } else {
        selectedId = null;
      }
    } else if (selectedGraphId === -1) {
      let maxno = data?.reduce((max, obj) => {
        obj?.RaceOdds?.[0]?.intValue > max
          ? (max = obj?.RaceOdds?.[0]?.intValue)
          : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno !== 0) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          selectedId = providerid?.[0];
        } else {
          selectedId = null;
        }
      } else {
        selectedId = null;
      }
    } else {
      selectedId = selectedGraphId;
    }
    if (selectedId) {
      const selectedFilterData = data?.filter((item) => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.RaceOdds?.[0]?.RaceOddFlucs?.map(
        (odds) => {
          return FlucData?.push({
            point: odds?.intValue,
          });
        }
      );
      return <HighChart FlucData={FlucData} />;
    } else {
      return <Box>No Data Available</Box>;
    }
  };

  const fetchCurrentBestValue = (data, fulldata) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max ? max = obj?.RaceOdds?.[0]?.intValue : max = max
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data?.map((obj) => {
          if (obj?.RaceOdds?.[0]?.intValue === maxno) {
            return obj?.BookKeeperId;
          }
        }).filter((x) => x !== undefined)
        return fetchClickableOdds(maxno, providerid?.[0], "header", data, fulldata, "betslip", "currentbest");
      } else {
        return "SP"
      }
    } else { return "SP"; }
  }
  const fetchCurrentBestIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max ? max = obj?.RaceOdds?.[0]?.intValue : max = max
      return max;
    }, -1)
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data?.map((obj) => {
          if (obj?.RaceOdds?.[0]?.intValue === maxno) {
            return obj?.BookKeeperId;
          }
        }).filter((x) => x !== undefined)
        return oddsicon(providerid?.[0], "header", data, "currentbest");
      } else { return "" }
    } else { return ""; }
  }

  // const handleMouseEnter = (index) => {
  //   setTooltipOpen(true);
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setTooltipOpen(false);
  // };

  const handleTooltipContentClick = (event, index) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(index);
  };

  const handleOutsideClick = (item, index) => {
    if (hoveredIndex === index) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, index) => {
    return (
      <Box
        className="custom-tooltip-runnertable"
        onClick={(e) => handleTooltipContentClick(e, index)}
      >
        {item?.animal?.isBlackBook ||
          item?.Jockey?.isBlackBook ||
          item?.Trainer?.isBlackBook > 0 ? (
          <BlackbookRight className="book-icon" />
        ) : (
          <BlackbookPlus className="book-icon" />
        )}
        {hoveredIndex === index ?
          tooltipOpen && (
            <Box
              // className={sportId === "3" ? `tooltip greyhound-tooltip` : `tooltip`}
              className="tooltip runner-table-tooltip"
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-blackbook">
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography>
              <Box className="black-details">
                {item?.animal ? <Box className="tooltip-details" onClick={() => handleBlackBookModal(item?.animal?.name, item?.animal?.id, "Runner")}> <Typography className="details">Runner: {item?.animal?.name}</Typography>
                  {item?.animal?.isBlackBook >
                    0 ? (
                    <RightBlack className="icon-blackbook" />
                  ) : (
                    <PlusBlack className="icon-blacbook" />
                  )}
                </Box> : ""}
                {item?.Jockey ? <Box className="tooltip-details" onClick={() => handleBlackBookModal(item?.Jockey?.name, item?.Jockey?.id, "Jockey")}> <Typography className="details" >{sportId === "2"
                  ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                  : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}{item?.Jockey?.name}</Typography>
                  {item?.Jockey?.isBlackBook >
                    0 ? (
                    <RightBlack className="icon-blackbook" />
                  ) : (
                    <PlusBlack className="icon-blacbook" />
                  )}
                </Box> : ""}
                {item?.Trainer ? <Box className="tooltip-details" onClick={() => handleBlackBookModal(item?.Trainer?.name, item?.Trainer?.id, "Trainer")}> <Typography className="details" >Trainer: {item?.Trainer?.name}</Typography>
                  {item?.Trainer?.isBlackBook >
                    0 ? (
                    <RightBlack className="icon-blackbook" />
                  ) : (
                    <PlusBlack className="icon-blacbook" />
                  )}  </Box> : ""}
              </Box>
            </Box>
          ) : <></>}
      </Box>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  // const handleTableOpen = (isColleps) => {
  //   if (overFlow == true && isColleps == "isColleps") {
  //     setscrollX("left")
  //     var container = document.getElementById("sticky-race-bookmakersodd");
  //     var scrollCompleted = 0;
  //     var slideVar = setInterval(function () {
  //       container.scrollLeft -= 10;
  //       scrollCompleted += 10;
  //       if (scrollCompleted >= 600) {
  //         window.clearInterval(slideVar);
  //       }
  //     }, 10);
  //   }
  // };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  const handleExpandfullForm = () => {
    setisFullFormExpand(!isFullFormExpand);
    setOpen(-1);
  }

  const SlideTable = (direction) => {
    var container = document.getElementById("runner-tab-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 10);
  }
  const toggleInputModal = () => {
    setisInputModalOpen(false)
  };
  const handleOpen = (data, id) => {
    setisInputModalOpen(true);
    setmodalData(data)
    setModalDataId(id)
  };

  const fetchMobileViewRunnerModal = (item) => {
    return (
      <>
        <Box className="runner-info-td">
          <Box className="runner-info-wrap">
            <Polyhon className="collapse-icon" />
            <Box className="runner-comment">
              <Box className="comment-content">
                <Typography variant="h6">
                  {localesData?.RUNNER_TABLE?.COLLAPSE?.RUNNER_COMMENTS}
                </Typography>
                <Typography variant="body2" className="des">
                  {item?.RunnerInfos?.[0]?.info?.entrant_comment
                    ? item?.RunnerInfos?.[0]?.info?.entrant_comment
                    : "No Comment Available"}
                </Typography>
              </Box>
              <Box className="full-from-btn">
                <Button onClick={() => handleOpen(runnerInfoData, item?.id)}>{localesData?.RUNNER_TABLE?.COLLAPSE?.FULL_FORM}</Button>
              </Box>
              {/* <Box className="win-graph">
          <span className="graph-label lb first">
            Win
          </span>
          <span className="graph-label value second">
            12%
          </span>
          <span className="graph-label lb third">
            Place
          </span>
          <span className="graph-label value fourth">
            36%
          </span>
          <PieChart
            width={174}
            height={178}
            // onMouseEnter={this.onPieEnter}
          >
            <Pie
              data={data}
              innerRadius={70}
              outerRadius={85}
              paddingAngle={-10}
              cornerRadius={20}
              startAngle={440}
              endAngle={80}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    COLORS[
                      index % COLORS.length
                    ]
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </Box> */}
            </Box>
            <Box className="advance-race-detail-wrap">
              <ul>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.SIRE}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info
                      ?.sire?.name
                      ? item?.RunnerInfos?.[0]?.info
                        ?.sire?.name : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.DAM}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info
                      ?.dam?.name ? item?.RunnerInfos?.[0]?.info?.dam?.name
                      : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.TYPE}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.colour
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.colour : "-"} / {item?.RunnerInfos?.[0]?.info?.runner_info?.sex ? item?.RunnerInfos?.[0]?.info?.runner_info?.sex
                        : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.AGE}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.age
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.age + "yo"
                      : "-"}
                  </span>
                </li>

                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.prize_money
                      ? formatter.format(item?.RunnerInfos?.[0]?.info?.runner_info?.prize_money)
                      : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.OVERALL}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                      ?.starts
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                        ?.starts
                      : "0"}{" "}
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                      ?.wins
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                        ?.wins
                      : "0"}
                    -
                    {/* {runnerPastPerfomance?.overall
                ?.placings
                ? runnerPastPerfomance?.overall
                    ?.placings
                : "0"}
              - */}
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                      ?.seconds
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                        ?.seconds
                      : "0"}
                    -
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                      ?.thirds
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                        ?.thirds
                      : "0"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.LAST_SIX}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.slice(
                        item?.RunnerInfos?.[0]?.info?.runner_info
                          ?.last_starts?.length - 6
                      )
                      : "-"}
                  </span>
                </li>
                <li></li>
              </ul>
            </Box>
            <Box className="runner-detail-tab-wrap">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Short Form" />
                <Tab label="Last Starts" />
              </Tabs>
              <TabPanel
                value={value}
                index={0}
                role="tabpanel"
              >
                <Box className="short-form">
                  <ul>
                    <li>
                      <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                          ?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                          ?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track?.wins
                          : "0"}
                        -
                        {/* {runnerPastPerfomance?.track
                    ?.placings
                    ? runnerPastPerfomance
                        ?.track?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                          ?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                          ?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>{localesData?.RUNNER_TABLE?.DISTANCE}:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.distance?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.distance?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                    ?.distance?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.distance?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.distance?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.distance?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Trk/Dst:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track_distance?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track_distance?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                    ?.track_distance?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.track_distance
                        ?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track_distance?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track_distance?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>1st Up:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.first_up?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.first_up?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                    ?.first_up?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.first_up?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.first_up?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.first_up?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>2nd Up:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.second_up?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.second_up?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                    ?.second_up?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.second_up?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.second_up?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.second_up?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Firm:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                          ?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                            ?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                          ?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                            ?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                    ?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                        ?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                          ?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                            ?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                          ?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                            ?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Good:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                          ?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                            ?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                          ?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                            ?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                    ?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                        ?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                          ?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                            ?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                          ?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                            ?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Soft:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                          ?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                            ?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                          ?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                            ?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                    ?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                        ?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                          ?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                            ?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                          ?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                            ?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Heavy:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                          ?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.heavy?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                          ?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.heavy?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                    ?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.heavy?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                          ?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.heavy?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                          ?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.heavy?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Synthetic:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.starts
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.synthetic?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.wins
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.synthetic?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                    ?.synthetic?.placings
                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.synthetic?.placings
                    : "0"}
                  - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.seconds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.synthetic?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.thirds
                          ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.synthetic?.thirds
                          : "0"}
                      </span>
                    </li>
                  </ul>
                </Box>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                role="tabpanel"
              >
                <Box className="runner-tab-table-head">
                  <Typography variant="h6">

                  </Typography>
                  <Box className="table-nav">
                    <ChevronLeftIcon
                      onClick={() =>
                        SlideTable("left")
                      }
                    />

                    <ChevronRightIcon
                      onClick={() =>
                        SlideTable("right")
                      }
                    />
                  </Box>
                </Box>

                <TableContainer
                  id="runner-tab-table"
                  className="runner-tab-table"
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.PLACE}</TableCell>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.DATE}</TableCell>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.TRACK}</TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.DISTANCE}
                        </TableCell>
                        <TableCell className="table-header">Jockey</TableCell>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.WEIGHT}</TableCell>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.MARGIN}</TableCell>
                        <TableCell className="table-header">SP</TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.CONDITION}
                        </TableCell>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.RESULT}</TableCell>
                        <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.CLASS}</TableCell>
                        {/* <TableCell>Gate</TableCell> */}
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.RACE_TIME}
                        </TableCell>
                        {/* <TableCell>
                    Last 600m
                  </TableCell> */}
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}
                        </TableCell>
                        {/* <TableCell>
                    Run Comment
                  </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {item?.RunnerInfos?.[0]?.info?.previous_runs?.length > 0 ? item?.RunnerInfos?.[0]?.info?.previous_runs?.map(
                        (item) => {
                          return (
                            <TableRow>
                              <TableCell className="table-cell" align="left">
                                <span>
                                  {item?.finish && item?.number_of_runners
                                    ? item?.finish + "/" + item?.number_of_runners
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span>
                                  {item?.date
                                    ? item?.date
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span >
                                  {item?.track
                                    ? item?.track
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span >
                                  {item?.distance
                                    ? item?.distance +
                                    "m"
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span>
                                  {item?.jockey
                                    ? item?.jockey
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span>
                                  {item?.weight_carried
                                    ? item?.weight_carried
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span>
                                  {item?.margin
                                    ? item?.margin +
                                    "L"
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span>
                                  {item?.starting_price
                                    ? "$" +
                                    item?.starting_price
                                    : "-"}
                                </span>
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span >
                                  {item?.track_condition
                                    ? item?.track_condition
                                    : "-"}

                                </span>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="result table-cell"

                              >
                                {item?.winner ? (
                                  <span>
                                    {"1."}  {item?.winner}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {item?.second ? (
                                  <span className="disable">
                                    {"2."}  {item?.second}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {item?.third ? (
                                  <span className="disable">
                                    {"3."}  {item?.third}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell className="table-cell" align="left">
                                <span>{item?.class ? item?.class : "-"}</span>
                              </TableCell>
                              {/* <TableCell align="left">
                          <span>{item?.gate ? item?.gate : "-"}</span>
                        </TableCell> */}
                              <TableCell className="table-cell" align="left">
                                {item?.time ? item?.time : "-"}
                              </TableCell>
                              {/* <TableCell align="left">
                        {item?.last ? item?.last : "-"}
                        </TableCell> */}
                              <TableCell className="table-cell" align="left">
                                {item?.prize_money ? formatter.format(item?.prize_money) : "-"}
                              </TableCell>
                              {/* <TableCell align="left">
                          <span>
                          {item?.comment ? item?.comment : "-"}
                          </span>
                        </TableCell> */}
                            </TableRow>
                          );
                        }
                      ) : <TableCell className="table-cell" align="center" style={{
                        textAlign: "center"
                      }}
                        colSpan={100}>{localesData?.sport?.NO_DATA}</TableCell>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </>
    )
  }
  return (
    <>
      <Box className="race-player-detail-wrap side-space flutuation-graph-details-wrap">
        <Box className="runner-detail-wrap">
          <Box className="odds-table-head">
            <Box className="select-wrap">
              <Select
                className="React series-select"
                value={options?.find((item) => {
                  return item?.value == selectedOption;
                })}
                onChange={(e) => oddsTypeChange(e)}
                options={options}
                isOptionDisabled={(option) => option.disabled}
                classNamePrefix="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
              />
            </Box>
            {/* {reduxSubscriptionData?.isPremiumUser && ( */}
            {/* <Box className="select-odds-wrap">
              <Select
                className="React odds-select"
                value={providerOption?.find((item) => {
                  return item?.value === selectedGraphId;
                })}
                onChange={(e) => {
                  setSelectedGraphId(e?.value);
                }}
                isLoading={isPageHeaderLoading}
                options={providerOption}
                classNamePrefix="select"
                placeholder="odds"
                // isSearchable={false}
                components={{ DropdownIndicator }}
              />
            </Box> */}
            {/* )} */}
          </Box>
          {screenWidth > 799 ? (
            <TableContainer className="race-bookmakersodd">
              {isrunnnerLoading || oddsLoader ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                <Table
                  className="racing-data flutuation-graph-table featured-table"
                  aria-label="customized table"
                  style={{ minWidth: 700, borderRadius: 5 }}
                >
                  <TableHead className="racing-track-table-head">
                    <TableRow style={{ fontSize: 12 }} className="head-row">
                      <TableCell
                        className="track-table-th table-left-header"
                        style={{ textAlign: "left", width: "35%" }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            columnGap: "18px",
                          }}
                        >
                          <span>
                            {localesData?.RUNNER_TABLE?.RUNNER}
                            <Order
                              className="player-order"
                              onClick={handleRunnerOrder}
                            />
                          </span>
                          <span className="ex-full" onClick={() => handleExpandfullForm()}>{localesData?.RUNNER_TABLE?.EXPAND_FULL_FORM}</span>
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: "52%" }} className="middle-header"> Fluctuation Graph</TableCell>
                      <TableCell
                        className="track-tabel-odds-th table-head current-best"
                        style={{ width: "134px", textAlign: "center" }}
                      >
                        {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {runnerInfoData?.length > 0 ? (
                      <>
                        {runnerInfoData?.map((item, index) => {
                          return (
                            <>
                              {item?.isScratched === "false" ||
                                item?.isScratched === null ||
                                item?.isScratched === "0" ? (
                                <TableRow className="racing-track-data-row">
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    // className='border-right'
                                    style={{ cursor: "pointer" }}
                                    className="border-right"
                                  >
                                    <Box className="table-runner-info">
                                      <Box className="collapse-icon" onClick={() => {
                                        setOpen(open === index ? -1 : index);

                                      }}>
                                        {open === index || isFullFormExpand ?
                                          <CollepseOpen /> : <CollepseClose />}
                                      </Box>
                                      <Box
                                        className="racer-detail-wrap"

                                      >
                                        <ClickAwayListener
                                          onClickAway={() =>
                                            handleOutsideClick(item, index)
                                          }
                                        >
                                          <Box className="blackbook-icon">
                                            <Typography variant="h6" onClick={() => {
                                              setOpen(open === index ? -1 : index);
                                            }} className="cursor-pointer">
                                              {item?.runnerNumber}
                                              {"."} {item?.animal?.name} (
                                              {item?.barrierNumber})
                                            </Typography>
                                            {handleOpenTooltip(item, index)}{" "}
                                          </Box>
                                        </ClickAwayListener>
                                        <Box className="player-wrap">
                                          <Box className="player-detail">
                                            {item?.Jockey ? (
                                              <>
                                                <span className="text-semibold">
                                                  {sportId === "2"
                                                    ? `D: `
                                                    : `J: `}</span>
                                                <span>
                                                  {item?.Jockey?.name}
                                                </span>
                                              </>
                                            ) : (
                                              item?.Trainer ?
                                                (
                                                  <>
                                                    <span className="text-semibold">T: </span>
                                                    <span>
                                                      {item?.Trainer?.name}
                                                    </span>
                                                  </>) : ("")
                                            )}
                                          </Box>
                                          {sportId === "1" ?
                                            <Box className="player-detail">
                                              <span className="text-semibold">W: </span>
                                              <span>
                                                {Number(item?.JockeyWeight).toFixed(2) +
                                                  "Kg"}
                                              </span>
                                            </Box> : ""}
                                        </Box>
                                        <Box className="player-wrap">
                                          {/* <Box className="player-detail">
                                    <span>Form:</span>
                                    <span> {}</span>
                                  </Box> */}
                                          {(sportId === "1" || sportId === "2") ?
                                            <Box className="player-detail">
                                              {item?.Trainer ? (
                                                <>
                                                  <span className="text-semibold">T: </span>
                                                  <span>
                                                    {item?.Trainer?.name}
                                                  </span>
                                                </>
                                              ) : ("")}
                                            </Box>
                                            : ""}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="chart-cell">
                                    {fetchFluctuationGraph(
                                      item?.oddsData?.[0]?.data
                                    )}
                                  </TableCell>
                                  <TableCell className="border-right current-best-odds-column">
                                    {item?.oddsData?.[0]?.data ? (
                                      <Box className={`current-best-odds-wrap ${localAuth ? "" : "blur-comp"}`}>

                                        {fetchCurrentBestValue(item?.oddsData?.[0]?.data, item)}
                                        {/* </Box> */}
                                        {/* <Box className="current-best-odds-icon"> */}
                                        {fetchCurrentBestIcon(item?.oddsData?.[0]?.data)}
                                        {/* </Box> */}

                                      </Box>
                                    ) : (<Tooltip title="No odds available" className="odds-tooltip" placement="top">
                                      <span className="no-odds">NOA</span>
                                    </Tooltip>)}
                                  </TableCell>
                                  <TableCell className="border-left border-bottom"></TableCell>
                                </TableRow>
                              ) : (
                                <TableRow className="racing-track-data-row">
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    className="racing-data"
                                    colSpan={3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Box
                                      className="racer-detail-wrap"
                                      onClick={() => {
                                        setOpen(open === index ? -1 : index);
                                      }}
                                    >
                                      <Typography variant="h6">
                                        <Box className="scratched">
                                          <span className="scratched-runner-name cursor-pointer" onClick={() => {
                                            setOpen(open === index ? -1 : index);
                                          }} >
                                            {item?.runnerNumber}
                                            {"."} {item?.animal?.name} (
                                            {item?.barrierNumber})
                                          </span>
                                        </Box>
                                        <Box className="scratched-data">
                                          <span className="scratched-runner-text">
                                            {
                                              localesData?.RUNNER_TABLE
                                                ?.SCRATCHED
                                            }{" "}
                                          </span>{" "}
                                          @{" "}
                                          <span className="scratched-runner-time">
                                            {" "}
                                            {moment(item?.updatedAt).format(
                                              "hh:mm A"
                                            )}
                                          </span>
                                          <span className="scratched-runner-date">
                                            {" "}
                                            {moment(item?.updatedAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </Box>
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="border-left border-bottom"></TableCell>
                                </TableRow>
                              )}
                              <TableRow
                                style={{
                                  borderBottom: "none",
                                }}
                                className="runner-info-tr"
                              >
                                <TableCell
                                  colSpan={100}
                                  className="runner-info-td"
                                  style={{
                                    padding: "0 !important",
                                    borderBottom: "none",
                                  }}
                                >

                                  <Collapse
                                    in={isFullFormExpand ? isFullFormExpand : open === index}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <>
                                      <Box className="runner-info-wrap">
                                        <Polyhon className="collapse-icon" />
                                        <Box className="runner-comment">
                                          <Box className="comment-content">
                                            <Typography variant="h6">
                                              {localesData?.RUNNER_TABLE?.COLLAPSE?.RUNNER_COMMENTS}
                                            </Typography>
                                            <Typography variant="body2">
                                              {item?.RunnerInfos?.[0]?.info?.entrant_comment
                                                ? item?.RunnerInfos?.[0]?.info?.entrant_comment
                                                : localesData?.RUNNER_TABLE?.COLLAPSE?.NO_COMMENTS}
                                            </Typography>
                                          </Box>
                                          <Box className="full-from-btn">
                                            <Button onClick={() => handleOpen(runnerInfoData, item?.id)}> {localesData?.RUNNER_TABLE?.COLLAPSE?.FULL_FORM}</Button>
                                          </Box>
                                          {/* <Box className="win-graph">
                                          <span className="graph-label lb first">
                                            Win
                                          </span>
                                          <span className="graph-label value second">
                                            12%
                                          </span>
                                          <span className="graph-label lb third">
                                            Place
                                          </span>
                                          <span className="graph-label value fourth">
                                            36%
                                          </span>
                                          <PieChart
                                            width={174}
                                            height={178}
                                            // onMouseEnter={this.onPieEnter}
                                          >
                                            <Pie
                                              data={data}
                                              innerRadius={70}
                                              outerRadius={85}
                                              paddingAngle={-10}
                                              cornerRadius={20}
                                              startAngle={440}
                                              endAngle={80}
                                              dataKey="value"
                                            >
                                              {data.map((entry, index) => (
                                                <Cell
                                                  key={`cell-${index}`}
                                                  fill={
                                                    COLORS[
                                                      index % COLORS.length
                                                    ]
                                                  }
                                                />
                                              ))}
                                            </Pie>
                                          </PieChart>
                                        </Box> */}
                                        </Box>
                                        <Box className="advance-race-detail-wrap">
                                          <ul>
                                            <li>
                                              <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.SIRE}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info
                                                  ?.sire?.name
                                                  ? item?.RunnerInfos?.[0]?.info
                                                    ?.sire?.name : "-"}
                                              </span>
                                            </li>
                                            <li>
                                              <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.DAM}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info
                                                  ?.dam?.name ? item?.RunnerInfos?.[0]?.info?.dam?.name
                                                  : "-"}
                                              </span>
                                            </li>
                                            <li>
                                              <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.TYPE}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info?.runner_info?.colour
                                                  ? item?.RunnerInfos?.[0]?.info?.runner_info?.colour : "-"} / {item?.RunnerInfos?.[0]?.info?.runner_info?.sex ? item?.RunnerInfos?.[0]?.info?.runner_info?.sex
                                                    : "-"}
                                              </span>
                                            </li>
                                            <li>
                                              <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.AGE}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info?.runner_info?.age
                                                  ? item?.RunnerInfos?.[0]?.info?.runner_info?.age + "yo"
                                                  : "-"}
                                              </span>
                                            </li>
                                            {/* </ul>
                                                                                            <ul> */}
                                            <li>
                                              <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info?.runner_info?.prize_money
                                                  ? formatter.format(item?.RunnerInfos?.[0]?.info?.runner_info?.prize_money)
                                                  : "-"}
                                              </span>
                                            </li>
                                            <li>
                                              <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.OVERALL}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                  ?.starts
                                                  ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                    ?.starts
                                                  : "0"}{" "}
                                                {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                  ?.wins
                                                  ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                    ?.wins
                                                  : "0"}
                                                -
                                                {/* {runnerPastPerfomance?.overall
                                                ?.placings
                                                ? runnerPastPerfomance?.overall
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                  ?.seconds
                                                  ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                    ?.seconds
                                                  : "0"}
                                                -
                                                {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                  ?.thirds
                                                  ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                    ?.thirds
                                                  : "0"}
                                              </span>
                                            </li>
                                            <li>
                                              <span>Last {item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts ? item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.length > 5 ? 6 : item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.length : 6}:</span>
                                              <span className="title">
                                                {item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts
                                                  ? item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.length > 5 ?
                                                    item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.slice(
                                                      item?.RunnerInfos?.[0]?.info?.runner_info
                                                        ?.last_starts?.length - 6
                                                    ) : item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts
                                                  : "-"}
                                              </span>
                                            </li>
                                            {/* <li></li> */}
                                          </ul>
                                        </Box>
                                        <Box className="runner-detail-tab-wrap">
                                          <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="simple tabs example"
                                          >
                                            <Tab label="Short Form" />
                                            <Tab label="Last Starts" />
                                          </Tabs>
                                          <TabPanel
                                            value={value}
                                            index={0}
                                            role="tabpanel"
                                          >
                                            <Box className="short-form">
                                              <ul>
                                                <li>
                                                  <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                                                      ?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                                                      ?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track?.wins
                                                      : "0"}
                                                    -
                                                    {/* {runnerPastPerfomance?.track
                                                    ?.placings
                                                    ? runnerPastPerfomance
                                                        ?.track?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                                                      ?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                                                      ?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>{localesData?.RUNNER_TABLE?.DISTANCE}:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.distance?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.distance?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.distance?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.distance?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.distance?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.distance?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.distance?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.distance?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.distance?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.distance?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>Trk/Dst:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.track_distance?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track_distance?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.track_distance?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track_distance?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.track_distance?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track_distance
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.track_distance?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track_distance?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.track_distance?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.track_distance?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>1st Up:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.first_up?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.first_up?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.first_up?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.first_up?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.first_up?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.first_up?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.first_up?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.first_up?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.first_up?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.first_up?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>2nd Up:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.second_up?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.second_up?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.second_up?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.second_up?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.second_up?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.second_up?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.second_up?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.second_up?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.second_up?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.second_up?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>Firm:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                      ?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                        ?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                      ?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                        ?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                      ?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                        ?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                      ?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                        ?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>Good:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                      ?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                        ?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                      ?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                        ?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                      ?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                        ?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                      ?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                        ?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>Soft:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                      ?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                        ?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                      ?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                        ?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                        ?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                      ?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                        ?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                      ?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                        ?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>Heavy:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                      ?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.heavy?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                      ?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.heavy?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                    ?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.heavy?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                      ?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.heavy?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                      ?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.heavy?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>Synthetic:</span>
                                                  <span className="value">
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.synthetic?.starts
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.synthetic?.starts
                                                      : "0"}{" "}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.synthetic?.wins
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.synthetic?.wins
                                                      : "0"}
                                                    -
                                                    {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.synthetic?.placings
                                                    ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.synthetic?.placings
                                                    : "0"}
                                                  - */}
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.synthetic?.seconds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.synthetic?.seconds
                                                      : "0"}
                                                    -
                                                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                      ?.synthetic?.thirds
                                                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                        ?.synthetic?.thirds
                                                      : "0"}
                                                  </span>
                                                </li>
                                              </ul>
                                            </Box>
                                          </TabPanel>
                                          <TabPanel
                                            value={value}
                                            index={1}
                                            role="tabpanel"
                                          >
                                            <Box className="runner-tab-table-head">
                                              <Typography variant="h6">

                                              </Typography>
                                              <Box className="table-nav">
                                                <ChevronLeftIcon
                                                  onClick={() =>
                                                    SlideTable("left")
                                                  }
                                                />

                                                <ChevronRightIcon
                                                  onClick={() =>
                                                    SlideTable("right")
                                                  }
                                                />
                                              </Box>
                                            </Box>

                                            <TableContainer
                                              id="runner-tab-table"
                                              className="runner-tab-table"
                                            >
                                              <Table>
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.PLACE}</TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.DATE}</TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.TRACK}</TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.DISTANCE}</TableCell>
                                                    <TableCell className="table-header">Jockey</TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.WEIGHT}</TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.MARGIN}</TableCell>
                                                    <TableCell className="table-header">SP</TableCell>
                                                    <TableCell className="table-header">
                                                      {localesData?.RUNNER_TABLE?.COLLAPSE?.CONDITION}
                                                    </TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.RESULT}</TableCell>
                                                    <TableCell className="table-header">{localesData?.RUNNER_TABLE?.COLLAPSE?.CLASS}</TableCell>
                                                    {/* <TableCell>Gate</TableCell> */}
                                                    <TableCell className="table-header">
                                                      {localesData?.RUNNER_TABLE?.COLLAPSE?.RACE_TIME}                                           </TableCell>
                                                    {/* <TableCell>
                                                    Last 600m
                                                  </TableCell> */}
                                                    <TableCell className="table-header">
                                                      {localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                    Run Comment
                                                  </TableCell> */}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                  {item?.RunnerInfos?.[0]?.info?.previous_runs?.length > 0 ? item?.RunnerInfos?.[0]?.info?.previous_runs?.map(
                                                    (item) => {
                                                      return (
                                                        <TableRow>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>
                                                              {item?.finish && item?.number_of_runners
                                                                ? item?.finish + "/" + item?.number_of_runners
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>
                                                              {item?.date
                                                                ? item?.date
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span >
                                                              {item?.track
                                                                ? item?.track
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span >
                                                              {item?.distance
                                                                ? item?.distance +
                                                                "m"
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>
                                                              {item?.jockey
                                                                ? item?.jockey
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>
                                                              {item?.weight_carried
                                                                ? item?.weight_carried
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>
                                                              {item?.margin
                                                                ? item?.margin +
                                                                "L"
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>
                                                              {item?.starting_price
                                                                ? "$" +
                                                                item?.starting_price
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span >
                                                              {item?.track_condition
                                                                ? item?.track_condition
                                                                : "-"}
                                                            </span>
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className="result table-cell"
                                                          >
                                                            {item?.winner ? (
                                                              <span>
                                                                {"1."}  {item?.winner}
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {item?.second ? (
                                                              <span className="disable">
                                                                {"2."}  {item?.second}
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {item?.third ? (
                                                              <span className="disable">
                                                                {"3."}  {item?.third}
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </TableCell>
                                                          <TableCell className="table-cell" align="left">
                                                            <span>{item?.class ? item?.class : "-"}</span>
                                                          </TableCell>
                                                          {/* <TableCell align="left">
                                                          <span>{item?.gate ? item?.gate : "-"}</span>
                                                        </TableCell> */}
                                                          <TableCell className="table-cell" align="left">
                                                            {item?.time ? item?.time : "-"}
                                                          </TableCell>
                                                          {/* <TableCell align="left">
                                                        {item?.last ? item?.last : "-"}
                                                        </TableCell> */}
                                                          <TableCell className="table-cell" align="left">
                                                            {item?.prize_money ? formatter.format(item?.prize_money) : "-"}
                                                          </TableCell>
                                                          {/* <TableCell align="left">
                                                          <span>
                                                          {item?.comment ? item?.comment : "-"}
                                                          </span>
                                                        </TableCell> */}
                                                        </TableRow>
                                                      );
                                                    }
                                                  ) : <TableCell className="table-cell" align="center" style={{
                                                    textAlign: "center"
                                                  }}
                                                    colSpan={100}>{localesData?.sport?.NO_DATA}</TableCell>}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </TabPanel>
                                        </Box>
                                      </Box>
                                    </>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow style={{ border: "transparent" }}>
                        <TableCell
                          style={{
                            textAlign: "center",
                            border: "transparent",
                          }}
                          colSpan={21}
                        >
                          {/* {localesData?.sport?.NO_DATA} */}
                          <NoDataComp />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          ) : (
            <Box className="race-bookmakersodd featured-table">
              {isrunnnerLoading || oddsLoader ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <Box className="racing-data">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        columnGap: "18px",
                        alignItems: "center",
                      }}
                      className="runnertable-header"
                    >
                      <span>
                        {localesData?.RUNNER_TABLE?.RUNNER}
                        <Order
                          className="player-order"
                          onClick={handleRunnerOrder}
                        />
                      </span>
                      <span className="ex-full" onClick={() => handleExpandfullForm()}>{localesData?.RUNNER_TABLE?.EXPAND_FULL_FORM}</span>
                    </Box>
                    {runnerInfoData?.length > 0 ? (
                      <>
                        {runnerInfoData?.map((item, index) => {
                          return (
                            <>
                              <Box
                                // className="left-runner-details"
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Box className="responsive-top-section">
                                  <Box className="table-runner-info">
                                    <Box className="collapse-icon" onClick={() => {
                                      setOpen(open === index ? -1 : index);

                                    }}>
                                      {open === index || isFullFormExpand ?
                                        <CollepseOpen /> : <CollepseClose />}
                                    </Box>
                                    <Box
                                      className="racer-detail-wrap"
                                      onClick={() => {
                                        setOpen(open === index ? -1 : index);
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          handleOutsideClick(item, index)
                                        }
                                      >
                                        <Box className="mobile-blackbook">
                                          <Typography
                                            variant="h6"
                                            className="mobile-racer-info cursor-pointer"
                                            onClick={() => {
                                              setOpen(open === index ? -1 : index);
                                            }}
                                          >
                                            {item?.runnerNumber}
                                            {"."} {item?.animal?.name} (
                                            {item?.barrierNumber})
                                          </Typography>
                                          {handleOpenTooltip(item, index)}
                                        </Box>
                                      </ClickAwayListener>
                                      <Box className="player-detail-container">
                                        <Box className="player-wrap">
                                          <Box className="player-detail">
                                            {item?.Jockey ? (
                                              <>
                                                <span className="text-bold">
                                                  {sportId === "2"
                                                    ? `D: `
                                                    : `J: `}</span>
                                                <span>
                                                  {item?.Jockey?.name}
                                                </span>
                                              </>
                                            ) : (
                                              item?.Trainer ?
                                                (
                                                  <>
                                                    <span className="text-bold">T: </span>
                                                    <span>
                                                      {item?.Trainer?.name}
                                                    </span>
                                                  </>) : ("")
                                            )}
                                          </Box>
                                          {sportId === "1" ?
                                            <Box className="player-detail">
                                              <span className="text-bold">W: </span>
                                              <span>
                                                {Number(item?.JockeyWeight).toFixed(2) +
                                                  "Kg"}{" "}
                                              </span>
                                            </Box> : ""}

                                        </Box>
                                        <Box className="player-wrap">
                                          {(sportId === "1" || sportId === "2") ?
                                            <Box className="player-detail">
                                              {item?.Trainer ? (
                                                <>
                                                  <span className="text-bold">T: </span>
                                                  <span>
                                                    {item?.Trainer?.name}
                                                  </span>
                                                </>
                                              ) : ("")}
                                            </Box>
                                            : ""}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {screenWidth < 799 && (
                                    <Box
                                      className={`odds-value-wrap align-right  current-best-odds-wrap ${localAuth ? "" : "blur-comp"}`}
                                    >
                                      {/* <Box className="current-best-odds-value"> */}{" "}
                                      {fetchCurrentBestValue(item?.oddsData?.[0]?.data, item)}
                                      {/* </Box> */}
                                      {/* <Box className="current-best-odds-icon"> */}
                                      {fetchCurrentBestIcon(item?.oddsData?.[0]?.data)}
                                      {/* </Box> */}
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box style={{ marginBottom: "10px" }}>
                                <Collapse
                                  in={isFullFormExpand ? isFullFormExpand : open === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {fetchMobileViewRunnerModal(item)}
                                </Collapse>

                              </Box>
                              <Box className="mobile-table-container">
                                <TableContainer>
                                  <Table className="mobile-graph-table">
                                    <TableHead className="mobile-data-head">
                                      <TableCell
                                        className="mobile-table-data fluc-header"

                                      >
                                        Fluctuation Graph
                                      </TableCell>

                                    </TableHead>
                                    <TableBody className="mobile-table-body">
                                      <TableCell className="mobile-table-data ">
                                        {fetchFluctuationGraph(
                                          item?.oddsData?.[0]?.data
                                        )}
                                      </TableCell>

                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Box
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {/* {localesData?.sport?.NO_DATA} */}
                        <NoDataComp />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {isInputModalOpen &&
        <RunnerModal
          runnerDatas={modalData}
          open={isInputModalOpen}
          onClose={() => toggleInputModal()}
          ModalDataId={ModalDataId}
        />}
      <BlackBookModal
        open={BBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        listingFunction={() => {
          fetchRaceRunner(raceId); clearInterval(timeinterval);
          clearTimeout(apiTimeout);
        }}
      />
    </>
  );
};

export default FluctuationGraphPage;
