import React, { useState, useContext, useEffect } from "react";
import AdvertisingBannner from "../../../../../advertisingBanner";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Button
} from "@material-ui/core";
import "./horses.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Australia from "../../../../../../../assets/images/flag/au_flag.svg";
import HorsesProfile from "./horsesProfile";
import HorsesGallery from "../galleryInfoPage";
import HorsesStats from "./horsesStats";
import OurPartner from "../../../../../ourPartners";
import { IntlContext } from "src/App";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import AboveTable from "../../../../../../../assets/images/ad-placeholder/sports1.webp";
import banner from "../../../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import AboveOurPartner from "../../../../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../../../../assets/images/ad-placeholder/sports5.webp";
import { Config } from "../../../../../../../helpers/context/config";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import { ReactComponent as BlackBookIcon } from "../../../../../../../assets/images/icons/blackbookicon.svg";
import BlackBookModal from "src/views/component/UI/blackBookModal";

const HorsesInfo = () => {
  const { state } = useLocation();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [value, setValue] = useState(0);
  const [headerData, setHeaderData] = useState({});
  const [isHeaderLoading, setisHeaderLoading] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  // const [trackProfileDetail, settrackProfileDetail] = useState(state);
  const trackProfileDetail = state;
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBlackBookModal = () => {
    setSelectedTitle(headerData?.horse?.name);
    setSelectedType("Runner");
    setSelectedId(headerData?.horse?.id);
    handleModal();
  };
  const HorsesTab = [
    {
      id: 0,
      name: localesData?.EDITPROFILE?.PROFILE
    },
    // {
    //   id: 1,
    //   name: localesData?.STATISTICS?.GALLERY
    // },
    {
      id: 1,
      name: localesData?.STATISTICS?.STATS
    }
  ];
  const handleChange = (event, value) => {
    setValue(value);
    fetchAdsData(value);
  };
  useEffect(() => {
    fetchHeadingData();
    fetchAdsData(0);
  }, []);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = async tabValue => {
    try {
      let pageId = tabValue === 0 ? 38 : 39;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchHeadingData = async () => {
    setisHeaderLoading(true);
    let passApi = `statistics/headerData?trackId=${params?.trackId}&trainerId=${params?.personId}&horseId=${params?.horseId}`;
    try {
      const { status, data } = await axiosInstance.get(`${passApi}`);
      if (status === 200) {
        setHeaderData(data?.result);
        setisHeaderLoading(false);
      } else {
        setisHeaderLoading(false);
      }
    } catch (err) {
      setisHeaderLoading(false);
    }
  };
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="player-info-wrap">
          <Box className="bredcrumn-details">
            {!isHeaderLoading ? (
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    {localesData?.MENU?.HOME}
                  </Link>
                  <Link underline="hover" color="inherit" to="">
                    {localesData?.MENU?.STATISTICS}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={
                      params?.trackId !== "0"
                        ? "/statistics/trackprofiles"
                        : "/statistics/runnerprofiles"
                    }
                  >
                    {params?.trackId !== "0"
                      ? "Track profiles"
                      : "Runner Profiles"}
                  </Link>
                  {params?.trackId !== "0" ? (
                    <Typography>
                      {headerData?.track?.name},
                      {headerData?.track?.Country?.country}
                    </Typography>
                  ) : (
                    <Typography>{headerData?.horse?.name}</Typography>
                  )}
                </Breadcrumbs>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box className="player-details">
            {!isHeaderLoading ? (
              <Box className="player-ul">
                <span className="player-name">
                  <span className="player-label"> Runner:</span>{" "}
                  <span className="player-value">
                    {headerData?.horse?.name}
                  </span>
                </span>
                {/* {params?.trackId !== "0" ? (
                  <Box className="player-scroll-horse">
                    <ul>
                      <li>
                        <img
                          src={
                            headerData?.track?.Country?.country_flag.includes(
                              "uploads"
                            )
                              ? Config.baseURL +
                                headerData?.track?.Country?.country_flag
                              : headerData?.track?.Country?.country_flag
                          }
                          alt="flag"
                        />
                        <span>
                          {" "}
                          {headerData?.track?.name},
                          {headerData?.track?.Country?.country}
                        </span>
                      </li>
                      <li>
                        {" "}
                        {params?.personType === "jockey"
                          ? "Jockey Stats"
                          : "Trainer Stats"}
                      </li>
                      <li>
                        {" "}
                        {params?.personType === "jockey"
                          ? headerData?.jockey?.name
                          : headerData?.trainer?.name}
                      </li>
                      <li>Horses</li>
                      <li>{headerData?.horse?.name}</li>
                    </ul>
                  </Box>
                ) : (
                  <></>
                )} */}
              </Box>
            ) : (
              <></>
            )}
            <Box className="player-tab blackbook-icon-wrap">
              <Box>
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  textColor="primary"
                  className="player-tab-detail"
                  disableRipple
                  disableFocusRipple
                >
                  {HorsesTab?.map((item, index) => {
                    return (
                      <Box>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={item?.name}
                          value={item?.id}
                          className={item?.id === value ? "active" : ""}
                          onChange={(event, newValue) =>
                            handleChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
              <Box className="blackbook-button-wrap black-book-desktop">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="blackbook-button"
                  onClick={() => handleBlackBookModal()}
                >
                  <span style={{ display: "flex" }}>
                    <BlackBookIcon />{" "}
                  </span>{" "}
                  <span style={{ paddingLeft: "8px" }}>
                    {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                  </span>
                </Button>
              </Box>
            </Box>
            {value === 0 ? (
              <Box className="tabs-header">
                <Typography className="tabs-para">
                  {localesData?.STATISTICS?.HORSE}{" "}
                  {localesData?.EDITPROFILE?.PROFILE}
                </Typography>
              </Box>
            ) : value === 1 ? (
              <Box className="tabs-header">
                <Typography className="tabs-para">
                  {localesData?.STATISTICS?.HORSE}{" "}
                  {localesData?.MENU?.STATISTICS}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box className="blackbook-button-wrap black-book-mobile">
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                className="blackbook-button"
                onClick={() => handleBlackBookModal()}
              >
                <span style={{ display: "flex" }}>
                  <BlackBookIcon />{" "}
                </span>{" "}
                <span style={{ paddingLeft: "8px" }}>
                  {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                </span>
              </Button>
            </Box>
          </Box>

          <Box className="player-tab-details">
            {value === 0 ? (
              <>
                <HorsesProfile fetchAds={fetchAds} headerData={headerData} />
              </>
            ) : value === 1 ? (
              <>
                <HorsesStats fetchAds={fetchAds} />
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
        {fetchAds(2, "102px", AboveOurPartner)}
        <OurPartner />
        {fetchAds(3, "102px", BelowOurPartner)}
      </Box>
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
      />
    </>
  );
};

export default HorsesInfo;
