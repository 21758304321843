import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as RightIcon } from "src/assets/images/icons/right-btn.svg";
import PaymentRight from "src/assets/images/icons/payment-right.png";
import Loader from "src/components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";

const PaymentSuccessfulModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  page
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/purchased-tips/${page}`);
  };

  return (
    <Dialog
      className="blackBook-dialog social-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        <Box className="blackBook-content-wrap social-padding">
          <Box className="close-btn">
            {closeIcon !== false && (
              <IconButton
                className="dialog-close"
                style={{ textAlig: "end" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box className="done-img-sec">
            <img src={PaymentRight} alt="Payment Done" className="done-img" />
          </Box>

          <Box className="payment-txt-sec">
            <Typography className="title">Payment Successful!</Typography>
            <Typography className="des">
              Your payment has been successful.
            </Typography>
          </Box>

          <Box className="skip-now-btn-sec">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              fullWidth
              className="view-tips-btn"
              onClick={() => {
                handleNavigation();
              }}
              endIcon={<RightIcon />}
            >
              View purchased tips
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
    </Dialog>
  );
};

export default PaymentSuccessfulModal;
