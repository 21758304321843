import { Tooltip, withStyles } from "@material-ui/core";

export const CustomTooltip = withStyles({
  tooltip: {
    color: "#003764",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "9px",
    margin: "27px 0px 0px",
    boxShadow: "0px 3px 9px rgba(0, 0, 0, 0.16)",
    maxWidth: "100%",
  },
  arrow: {
    color: "#1E2125",
    fontSize: "21px",
    "&:before": {
      border: "1px solid #40BFC5",
      borderRadius: "3px",
    },
  },
})(Tooltip);
