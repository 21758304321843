import React, { useEffect, useMemo, useState, Suspense } from "react";
import { Box } from "@material-ui/core";
import "./HomeComponents/homeComponents.scss";
import banner from "../../../assets/images/ad-placeholder/main-header-v2.png";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import HomePageBanner from "../../../assets/images/ad-placeholder/home-page-placeholder-v2.png";
import { useSelector } from "react-redux";
import ScriptLoader from "../scriptLoader/ScriptLoader";
import AdBannner from "../AdBanner/AdBanner";
import { Config, fetchFromStorage } from "src/helpers/context";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { lazy } from "react";

const UpcomingRaces = lazy(() => import("./HomeComponents/UpcomingRaces"));
const UpcomingFixture = lazy(() => import("./HomeComponents/UpcomingFixture"));
const FeaturedNews = lazy(() => import("./HomeComponents/FeaturedNews"));
const FeaturedSportV2 = lazy(() => import("./featuredSportsV2"));
const FeaturedRace = lazy(() => import("./featuredRace"));
const PublicTippingComp = lazy(() =>
  import("./HomeComponents/PublicTippingComp")
);
const HomeRanking = lazy(() => import("./HomeComponents/HomeRanking"));
const TipsOfTheDay = lazy(() => import("./HomeComponents/TipsOfTheDay"));
const Podcast = lazy(() => import("./HomeComponents/Podcast"));
const OurPartner = lazy(() => import("../ourPartners"));
const ExpertTips = lazy(() => import("./HomeComponents/ExpertsTips"));
const RacingNews = lazy(() => import("./HomeComponents/RacingNews"));
const UpcomingFixtureSports = lazy(() =>
  import("./HomeComponents/UpcomingFixtureSports")
);

const HomeV3 = () => {
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [isHomePageLoading, setIsHomePageLoading] = useState(true);
  const [availableSport, setAvailableSport] = useState([]);
  const [isAvailableSportLoading, setIsAvailableSportLoading] = useState(false);

  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const redux_userData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const layoutInfo = useMemo(() => {
    if (redux_userData) {
      return redux_userData?.isDefaultHomePage === true
        ? "default"
        : redux_userData?.isDefaultRacingPage === true
        ? "racing"
        : redux_userData?.isDefaultSportPage === true
        ? "sport"
        : "default";
    }
  }, [redux_userData]);
  const [layoutType, setLayoutType] = useState(layoutInfo);
  const layoutData = useSelector(state => state?.reduxData?.LayoutData);
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    fetchAdsData();
    fetchAvailableSports();
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    if (
      redux_userData &&
      ((layoutData && Object.entries(layoutData)?.length > 0) ||
        layoutData === null)
    ) {
      setLayoutType(
        redux_userData?.isDefaultHomePage === true
          ? "default"
          : redux_userData?.isDefaultRacingPage === true
          ? "racing"
          : redux_userData?.isDefaultSportPage === true
          ? "sport"
          : "default"
      );
      setIsHomePageLoading(false);
    } else if (!handleRestrictedUser()) {
      setIsHomePageLoading(false);
      setLayoutType("default");
    }
  }, [redux_userData, layoutData]);

  var defaultArray = [
    {
      key: "featured-news",
      component: (
        <FeaturedNews layoutType={layoutType} availableSport={availableSport} />
      )
    },
    {
      key: "smartb-tipoftheday-racing",
      component: <TipsOfTheDay />
    },
    {
      key: "upcoming-races",
      component: <UpcomingRaces availableSport={availableSport} />
    },
    {
      key: "upcoming-fixtures-sports",
      component: (
        <UpcomingFixture type={"fixture"} availableSport={availableSport} />
      )
    },
    {
      key: "latest-results-sports",
      component: (
        <UpcomingFixture type={"result"} availableSport={availableSport} />
      )
    },
    {
      key: "featured-fixtures-sports",
      component: <FeaturedSportV2 availableSport={availableSport} />
    },
    {
      key: "featured-races-racing",
      component: <FeaturedRace availableSport={availableSport} />
    },
    {
      key: "free-expert-tips",
      component: (
        <ExpertTips layoutType={layoutType} availableSport={availableSport} />
      )
    },

    {
      key: "tipping-comps-sports",
      // component: <PublicTippingComp />
      component: (
        <>
          {handleRestrictedUser() &&
          Object?.keys(layoutData ?? {})?.length > 0 &&
          layoutData?.tippingcompetitions ? (
            layoutData?.tippingcompetitions?.includes("public") ? (
              <>
                <PublicTippingComp />
              </>
            ) : null
          ) : (
            <PublicTippingComp />
          )}
        </>
      )
    },
    {
      key: "tipping-comps-rankings-sports",
      // component: (<HomeRanking />
      component: (
        <>
          {handleRestrictedUser() &&
          Object?.keys(layoutData ?? {})?.length > 0 &&
          layoutData?.tippingcompetitions?.length > 0 ? (
            <>
              {layoutData?.tippingcompetitions?.includes("ranking") ? (
                <HomeRanking />
              ) : null}
            </>
          ) : (
            <HomeRanking />
          )}
        </>
      )
    },
    {
      key: "featured-podcasts",
      component: <Podcast layoutType={layoutType} />
    }
  ];

  var sportArray = [
    {
      key: "sports-news",
      component: (
        <FeaturedNews layoutType={layoutType} availableSport={availableSport} />
      )
    },
    {
      key: "upcoming-fixtures-american-football",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={15}
        />
      )
    },
    {
      key: "upcoming-fixtures-australian-rules",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={9}
        />
      )
    },
    {
      key: "upcoming-fixtures-basketball",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={10}
        />
      )
    },
    {
      key: "upcoming-fixtures-Baseball",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={11}
        />
      )
    },
    {
      key: "upcoming-fixtures-boxing",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={6}
        />
      )
    },

    {
      key: "upcoming-fixtures-cricket",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={4}
        />
      )
    },
    {
      key: "upcoming-fixtures-golf",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={16}
        />
      )
    },
    {
      key: "upcoming-fixtures-ice-hockey",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={17}
        />
      )
    },
    {
      key: "upcoming-fixtures-mixed-martial-arts",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={5}
        />
      )
    },
    {
      key: "upcoming-fixtures-rugby-league",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={12}
        />
      )
    },
    {
      key: "upcoming-fixtures-rugby-union",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={13}
        />
      )
    },
    {
      key: "upcoming-fixtures-soccer",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={8}
        />
      )
    },
    {
      key: "upcoming-fixtures-tennis",
      component: (
        <UpcomingFixtureSports
          type={"fixture"}
          availableSport={availableSport}
          SportId={7}
        />
      )
    },
    {
      key: "latest-results-sports",
      component: (
        <UpcomingFixture
          type={"result"}
          layoutType={layoutType}
          availableSport={availableSport}
        />
      )
    },
    {
      key: "featured-fixtures-sports",
      component: <FeaturedSportV2 availableSport={availableSport} />
    },
    {
      key: "free-expert-tips-sport",
      component: (
        <ExpertTips layoutType={layoutType} availableSport={availableSport} />
      )
    },

    {
      key: "tipping-comps-sports",
      component: (
        <>
          {handleRestrictedUser() &&
          Object?.keys(layoutData ?? {})?.length > 0 &&
          layoutData?.tippingcompetitions ? (
            layoutData?.tippingcompetitions?.includes("public") ? (
              <>
                <PublicTippingComp />
              </>
            ) : null
          ) : (
            <PublicTippingComp />
          )}
        </>
      )
    },
    {
      key: "tipping-comps-rankings-sports",
      component: (
        <>
          {handleRestrictedUser() &&
          Object?.keys(layoutData ?? {})?.length > 0 &&
          layoutData?.tippingcompetitions?.length > 0 ? (
            <>
              {layoutData?.tippingcompetitions?.includes("ranking") ? (
                <HomeRanking />
              ) : null}
            </>
          ) : (
            <HomeRanking />
          )}
        </>
      )
    },

    {
      key: "featured-podcasts-sports",
      component: <Podcast layoutType={layoutType} />
    }
  ];

  var raceArray = [
    {
      key: "featured-news-racing",
      component: (
        <FeaturedNews layoutType={layoutType} availableSport={availableSport} />
      )
    },
    {
      key: "smartb-tipoftheday-racing",
      component: <TipsOfTheDay />
    },
    {
      key: "upcoming-races",
      component: <UpcomingRaces availableSport={availableSport} />
    },
    {
      key: "featured-races-racing",
      component: <FeaturedRace availableSport={availableSport} />
    },
    {
      key: "free-expert-tips-racing",
      component: (
        <ExpertTips layoutType={layoutType} availableSport={availableSport} />
      )
    },
    {
      key: "horse-racing-news",
      component: <RacingNews raceType={"Horse Racing"} SportId={1} />
    },
    {
      key: "greyhound-racing-news",
      component: <RacingNews raceType={"Greyhound Racing"} SportId={3} />
    },
    {
      key: "harness-racing-news",
      component: <RacingNews raceType={"Harness Racing"} SportId={2} />
    },
    {
      key: "featured-podcasts-racing",
      component: <Podcast layoutType={layoutType} />
    }
  ];

  const defaultHomeArray = useMemo(() => {
    const listingArr =
      layoutType == "default"
        ? defaultArray
        : layoutType == "sport"
        ? sportArray
        : raceArray;
    const sortedByPageOrder = layoutData?.pageOrder
      ?.map(orderItem => {
        const sortedItem = listingArr?.find(item => item.key === orderItem.key);
        if (sortedItem) {
          return sortedItem;
        }
        return null;
      })
      .filter(item => item !== null);

    if (handleRestrictedUser()) {
      return layoutData?.pageOrder?.length > 0 ? sortedByPageOrder : listingArr;
    } else {
      return listingArr;
    }
  }, [
    layoutData,
    availableSport,
    layoutType,
    redux_userData?.isDefaultHomePage,
    redux_userData?.isDefaultRacingPage,
    redux_userData?.isDefaultSportPage
  ]);

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter(item => {
    //   return item?.position_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const fetchAvailableSports = async () => {
    let passApi = `/homepage/sport`;
    try {
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setIsAvailableSportLoading(false);
        setAvailableSport(data?.result);
      }
    } catch (error) {
      setIsAvailableSportLoading(false);
    }
  };

  const scriptCode = `
    rdt('track', 'ViewContent', {
      "products": [
        {
          "id": "product id 1",
          "name": "product name 1",
          "category": "product category 1"
        },
        // additional products can be added here
      ]
    });
  `;

  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      {isHomePageLoading ? (
        <div className="allsport-loader-center homepage-loader">
          <Loader />
        </div>
      ) : (
        <Box className="homepage-wrap">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner
          )}
          {/* <FeaturedNews layoutType={layoutType} /> */}
          {layoutType == "default" ? (
            // <>
            //   <TipsOfTheDay />
            //   <UpcomingRaces />
            //   <UpcomingFixture
            //     type={"fixture"}
            //     availableSport={availableSport}
            //   />
            //   {fetchAds(1, "218px", HomePageBanner)}
            //   <UpcomingFixture
            //     type={"result"}
            //     availableSport={availableSport}
            //   />
            //   <FeaturedSportV2 availableSport={availableSport} />
            //   <FeaturedRace availableSport={availableSport} />
            //   <ExpertTips
            //     layoutType={layoutType}
            //     availableSport={availableSport}
            //   />
            //   <PublicTippingComp />
            //   <HomeRanking />
            // </>
            <>
              {defaultHomeArray?.map((item, index) => {
                return (
                  <>
                    <Suspense
                      fallback={
                        <div className="allsport-loader-center homepage-loader">
                          <Loader />
                        </div>
                      }
                    >
                      {item?.component}
                    </Suspense>
                    {defaultHomeArray?.length > 3 &&
                      index === 2 &&
                      fetchAds(1, "218px", HomePageBanner)}
                  </>
                );
              })}
            </>
          ) : layoutType == "racing" ? (
            <>
              {/* <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <TipsOfTheDay />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <UpcomingRaces />
              </Suspense>
              {fetchAds(1, "218px", HomePageBanner)}
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <FeaturedRace availableSport={availableSport} />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <ExpertTips
                  layoutType={layoutType}
                  availableSport={availableSport}
                />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <RacingNews raceType={"Horse Racing"} SportId={1} />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <RacingNews raceType={"Greyhound Racing"} SportId={3} />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <RacingNews raceType={"Harness Racing"} SportId={2} />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <Podcast layoutType={layoutType} />
              </Suspense> */}
              {defaultHomeArray?.map((item, index) => {
                return (
                  <>
                    <Suspense
                      fallback={
                        <div className="allsport-loader-center homepage-loader">
                          <Loader />
                        </div>
                      }
                    >
                      {item?.component}
                    </Suspense>
                    {defaultHomeArray?.length > 3 &&
                      index === 2 &&
                      fetchAds(1, "218px", HomePageBanner)}
                  </>
                );
              })}
            </>
          ) : (
            <>
              {/* <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <FeaturedNews
                  layoutType={layoutType}
                  availableSport={availableSport}
                />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <UpcomingFixtureSports
                  type={"fixture"}
                  availableSport={availableSport}
                />
              </Suspense>
              {fetchAds(1, "218px", HomePageBanner)}
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <UpcomingFixture
                  type={"result"}
                  layoutType={layoutType}
                  availableSport={availableSport}
                />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <FeaturedSportV2 availableSport={availableSport} />
              </Suspense>
            
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <ExpertTips
                  layoutType={layoutType}
                  availableSport={availableSport}
                />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <PublicTippingComp />
              </Suspense>
              <Suspense
                fallback={
                  <div className="allsport-loader-center homepage-loader">
                    <Loader />
                  </div>
                }
              >
                <HomeRanking />
              </Suspense>{" "}
              */}
              {defaultHomeArray?.map((item, index) => {
                return (
                  <>
                    <Suspense
                      fallback={
                        <div className="allsport-loader-center homepage-loader">
                          <Loader />
                        </div>
                      }
                    >
                      {item?.component}
                    </Suspense>
                    {defaultHomeArray?.length > 3 &&
                      index === 2 &&
                      fetchAds(1, "218px", HomePageBanner)}
                  </>
                );
              })}
            </>
          )}
          {fetchAds(2, "218px", HomePageBanner)}
          {/* <Suspense
            fallback={
              <div className="allsport-loader-center homepage-loader">
                <Loader />
              </div>
            }
          >
            <Podcast layoutType={layoutType} />
          </Suspense> */}
          <Suspense
            fallback={
              <div className="allsport-loader-center homepage-loader">
                <Loader />
              </div>
            }
          >
            <OurPartner />
          </Suspense>
        </Box>
      )}
    </>
  );
};

export default HomeV3;
