import React, { useRef } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { ReactComponent as CheckBoxChecked } from "../../../../../assets/images/oddsComparison/filterCheckboxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "../../../../../assets/images/oddsComparison/filterCheckBoxUnChecked.svg";
import { ReactComponent as Indeterminent } from "../../../../../assets/images/oddsComparison/filterIndeterminent.svg";
import { ReactComponent as FilterPlus } from "../../../../../assets/images/oddsComparison/filterPlus.svg";
import { ReactComponent as FilterRight } from "../../../../../assets/images/oddsComparison/filterRight.svg";
import { ReactComponent as DownArrow } from "../../../../../assets/images/oddsComparison/downArrow.svg";
import { ReactComponent as UpArrow } from "../../../../../assets/images/oddsComparison/upArrow.svg";

const CustomDropdown = ({
  mainOption,
  options,
  indexOf,
  isOpen,
  selectBoxIndex,
  toggleDropdown,
  selectMainId,
  selectSubId,
  toggleSelectAll,
  handleOptionClick,
  type,
  dropdownCountryRef
}) => {
  const selectAllRef = useRef();

  const allSubOptionChecked = mainOption?.states?.every(state =>
    selectSubId?.includes(state?.id)
  );
  const someSubOptionChecked = mainOption?.states?.some(state =>
    selectSubId?.includes(state?.id)
  );

  return (
    <Box className="home-custom-dropdown" ref={dropdownCountryRef}>
      <Box
        className="dropdown-header"
        onClick={() => toggleDropdown(indexOf, type)}
      >
        <Box className="header-details">
          <Box>
            {isOpen && indexOf == selectBoxIndex ? <UpArrow /> : <DownArrow />}
          </Box>
          <Box>
            <Typography className="country-name">{mainOption?.name}</Typography>
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                selectMainId?.includes(mainOption?.id) || allSubOptionChecked
              }
              icon={<CheckboxUnChecked />}
              checkedIcon={<CheckBoxChecked />}
              indeterminateIcon={<Indeterminent />}
              indeterminate={!allSubOptionChecked && someSubOptionChecked}
              onChange={e =>
                toggleSelectAll(
                  e.target.checked,
                  mainOption?.id,
                  mainOption?.states
                )
              }
              onClick={e => e.stopPropagation()}
              ref={selectAllRef}
              color="primary"
            />
          }
        />
      </Box>
      {isOpen && indexOf == selectBoxIndex && (
        <Box className="dropdown-options">
          {options?.map(option => (
            <Box
              key={option?.id}
              onClick={e =>
                handleOptionClick(
                  e?.target?.checked,
                  mainOption,
                  option,
                  mainOption?.id
                )
              }
              className="options-list"
            >
              <Typography className="state-name">{option?.state}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<FilterPlus />}
                    checkedIcon={<FilterRight />}
                    checked={selectSubId?.some(
                      selectedOption => selectedOption === option?.id
                    )}
                    readOnly
                    color="primary"
                  />
                }
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CustomDropdown;
