import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Tab,
  Tabs,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  withStyles,
  makeStyles,
  TableContainer,
  Button,
  Tooltip,
  Collapse,
  Switch,
  FormControlLabel,
  ClickAwayListener
} from "@material-ui/core";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
// import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { fetchFromStorage } from "src/helpers/context";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as LockIcon } from "src/assets/images/icons/lock.svg";
import { ReactComponent as TickIcon } from "src/assets/images/icons/tick.svg";
// import { ReactComponent as Reset } from "../../../assets/images/reset.svg";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import { parseISO } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { ToastContainer, toast } from "react-toastify";
import Loader from "src/components/Loader";
import teamLogo from "src/assets/images/bookmakers/BlueBet-thumb.png";

import "./enterSportsTips.scss";
import { IntlContext } from "src/App";
import { useDispatch, useSelector } from "react-redux";
// import { fetchInterval } from "../../../../../helpers/store/Actions/interval";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Config } from "../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataComp from "src/views/component/UI/NoData";
import HighChart from "../../UI/graph";
import SportsTipModal from "../../UI/sportsTipModal";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import OurPartner from "../../ourPartners";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const sportsTipData = [
  {
    id: 1,
    sportName: "Australian Rules",
    updateDate: "2023-11-07T01:48:03.000Z",
    image1: "https://i.postimg.cc/5NzhQP37/flag1.png",
    image2: "https://i.postimg.cc/bNQzfYKw/flag2.png",
    team1: "Brisbane Lions",
    team2: "Melbourne",
    bestAtOpen1: 1.0,
    currentBest1: 1.0,
    bestAtOpen2: 1.0,
    currentBest2: 1.0,
    comment: "lorem ipsum is so good",
    date: "2023-11-08T13:10:00.000Z",
    time: "07:50PM",
    markets: "159 Markets",
    chip: "NFL Divisions"
  },
  {
    id: 2,
    sportName: "Australian Rules",
    updateDate: "2023-11-07T01:48:03.000Z",
    image1: "https://i.postimg.cc/5NzhQP37/flag1.png",
    image2: "https://i.postimg.cc/bNQzfYKw/flag2.png",
    team1: "Brisbane Lions",
    team2: "Melbourne",
    bestAtOpen1: 1.0,
    currentBest1: 1.0,
    bestAtOpen2: 1.0,
    currentBest2: 1.0,
    coach: "Dan",
    captains: [
      { id: 1, name: "Ross" },
      { id: 1, name: "James" },
      { id: 1, name: "Charles" },
      { id: 1, name: "Don" }
    ],
    comment: "lorem ipsum is so good",
    date: "2023-11-08T13:10:00.000Z",
    time: "07:50PM",
    markets: "159 Markets",
    chip: "NFL Divisions"
  },
  {
    id: 3,
    sportName: "Australian Rules",
    updateDate: "2023-11-07T01:48:03.000Z",
    image1: "https://i.postimg.cc/5NzhQP37/flag1.png",
    image2: "https://i.postimg.cc/bNQzfYKw/flag2.png",
    team1: "Brisbane Lions",
    team2: "Melbourne",
    bestAtOpen1: 1.0,
    currentBest1: 1.0,
    bestAtOpen2: 1.0,
    currentBest2: 1.0,
    coach: "Dan",
    captains: [
      { id: 1, name: "Ross" },
      { id: 1, name: "James" },
      { id: 1, name: "Charles" },
      { id: 1, name: "Don" }
    ],
    comment: "lorem ipsum is so good",
    date: "2023-11-08T13:10:00.000Z",
    time: "07:50PM",
    markets: "159 Markets",
    chip: "NFL Divisions"
  }
];

const EnterSportsTips = () => {
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [count, setcount] = useState(0);
  const limit = 20;
  const [searchValue, setSearchValue] = useState("");
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState("");
  const [OrgAll, setOrgAll] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [isTeamSelectOpen, setisTeamSelectOpen] = useState(false);
  const [searchTeam, setsearchTeam] = useState([]);
  const [searchTeamCount, setsearchTeamCount] = useState(0);
  const [SearchTeampage, setSearchTeampage] = useState(0);
  const [pageTeam, setpageTeam] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);

  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [countOrg, setcountOrg] = useState(0);
  const [AdData, setAdData] = useState([]);
  const [passModalData, setPassModalData] = useState([]);
  const [tippedTeamId, setTippedTeamId] = useState(null);
  const [tippedTitle, setTippedTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const endDate = moment(Date())
    .add(6, "days")
    .format("YYYY-MM-DD");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(
    params?.oddstype === "outrights" ? 2 : 0
  );
  const [selectedDate, setselectedDate] = useState(null);
  const [selectedteam, setselectedteam] = useState(0);
  let [stepperCount, setStepperCount] = useState(0);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [EventCount, setEventCount] = useState(0);
  const [EventPage, setEventPage] = useState(0);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTipsModal, setOpenTipsModal] = useState(false);
  const [showAllFixture, setShowAllFixtures] = useState(false);
  const [sportsMarketId, setSportsMarketId] = useState(null);
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const reduxSubscriptionData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchSportData();
  }, []);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchSportData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        var sportsdata = data?.result.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });

        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        setSelectedSports(sdata?.[0]?.value);
        setSports(sdata);
        fetchOrgData(0, sdata?.[0]?.value, []);
        fetchTeamData(0, sdata?.[0]?.value, []);
        fetchmarketList(sdata?.[0]?.value);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(OrgAll, newdata)?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0
        });
        let finalData = _.uniqBy(filterData, function(e) {
          return e.value;
        });
        setOrgAll(finalData);
      }
    } catch (err) {}
  };
  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };
  const handleOnScrollBottomTeams = () => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(SearchTeampage / 20)
    ) {
      handleTeamInputChange(SearchTeampage + 20, isTeamSearch, selectedSports);

      setSearchTeampage(SearchTeampage + 20);
    } else {
      if (count !== Math.ceil(pageTeam / 20)) {
        fetchTeamData(pageTeam + 20, selectedSports, teamsAll);
        setpageTeam(pageTeam + 20);
      }
    }
  };
  const handleTeamInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/team?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchTeam, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeam(filterData);
          // setsearchTeam(
          //   _.uniqBy(filterData, function(e) {
          //     return e.value;
          //   })
          // );
          setIsTeamSearch(value);
        }
      });
  };
  const fetchTeamData = async (page, sID, teamsAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(teamsAll, newdata)?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
        let allteamdatas = filterData?.unshift({
          label: "All Teams",
          value: 0
        });
        let finalData = _.uniqBy(filterData, function(e) {
          return e.value;
        });
        setTeamsAll(finalData);
      }
    } catch (err) {}
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleBookkeeperCounter = async (BookKeeperId, type, e) => {
    e.stopPropagation();
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(selectedSports)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
    // href={
    //   Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //     ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //     : `${iconData?.affiliate_link}`
    // }
  };
  const oddsicon = (BookKeeperId, type, item, fullData) => {
    const Identifiers =
      selectedSports == 4
        ? fullData?.CricketIdentifiers
        : selectedSports == 10
        ? fullData?.NBAIdentifiers
        : selectedSports == 15
        ? fullData?.AFLIdentifiers
        : selectedSports == 9
        ? fullData?.ARIdentifiers
        : selectedSports == 16
        ? fullData?.GolfIdentifiers
        : selectedSports == 7
        ? fullData?.TennisIdentifiers
        : selectedSports == 11
        ? fullData?.BaseballIdentifiers
        : selectedSports == 17
        ? fullData?.IceHockeyIdentifiers
        : selectedSports == 6
        ? fullData?.BoxingIdentifiers
        : selectedSports == 5
        ? fullData?.MMAIdentifiers
        : selectedSports == 8
        ? fullData?.SoccerIdentifiers
        : fullData?.RLIdentifiers;
    const newData = Identifiers?.map(obj => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        item => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData
      ?.filter?.(obj => obj?.BookKeeperId === BookKeeperId)
      ?.filter(obj => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        ele => ele?.providerMarketId && ele?.providerParticipantId
      );
      const urlLink =
        Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box
          // href={
          //
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="odds-link bookmaker-wrap"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            onClick={e => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    } else {
      const urlLink =
        filteredData?.length > 0 && filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="bookmaker-wrap"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            onClick={e => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.filter(
    //     ele => ele?.providerMarketId && ele?.providerParticipantId
    //   );
    //   return (
    //     <a
    //       href={
    //         Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //           : `${iconData?.affiliate_link}`
    //       }
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="odds-link"
    //     >
    //       {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
    //     </a>
    //   );
    // } else {
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <>
        {odds ? (
          <span
            className="odds-btn cursor-pointer"
            onClick={e => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type, e)
                : isAdded
                ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                : handleAddToBetSlip(
                    BookKeeperId,
                    type,
                    item,
                    fulldata,
                    Isscore,
                    e
                  );
            }}
          >
            <span
              className={
                isAdded && IsBetslip === "betslip"
                  ? "betslip-added odds-link"
                  : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">NOA</span>
        )}
      </>
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}

      // </a>
    );
    // }
  };
  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    Isscore,
    e
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (!Isscore) {
        const BookKeeperData = item?.filter(
          obj => obj?.BookKeeperId === BookKeeperId
        );
        const oddsInfo = BookKeeperData?.[0];

        let passTeamId =
          selectedSports == 4
            ? oddsInfo?.CricketTeamId
              ? oddsInfo?.CricketTeamId
              : oddsInfo?.CricketOddLabelId
              ? oddsInfo?.CricketOddLabelId
              : oddsInfo?.CricketPlayerId
            : selectedSports == 10
            ? oddsInfo?.NBATeamId
              ? oddsInfo?.NBATeamId
              : oddsInfo?.NBAOddLabelId
              ? oddsInfo?.NBAOddLabelId
              : oddsInfo?.NBAPlayerId
            : selectedSports == 15
            ? oddsInfo?.AFLTeamId
              ? oddsInfo?.AFLTeamId
              : oddsInfo?.AFLOddLabelId
              ? oddsInfo?.AFLOddLabelId
              : oddsInfo?.AFLPlayerId
            : selectedSports == 9
            ? oddsInfo?.ARTeamId
              ? oddsInfo?.ARTeamId
              : oddsInfo?.AROddLabelId
              ? oddsInfo?.AROddLabelId
              : oddsInfo?.ARPlayerId
            : selectedSports == 16
            ? oddsInfo?.GolfTeamId
              ? oddsInfo?.GolfTeamId
              : oddsInfo?.GolfOddLabelId
              ? oddsInfo?.GolfOddLabelId
              : oddsInfo?.GolfPlayerId
            : selectedSports == 7
            ? oddsInfo?.TennisTeamId
              ? oddsInfo?.TennisTeamId
              : oddsInfo?.TennisOddLabelId
              ? oddsInfo?.TennisOddLabelId
              : oddsInfo?.TennisPlayerId
            : selectedSports == 11
            ? oddsInfo?.BaseballTeamId
              ? oddsInfo?.BaseballTeamId
              : oddsInfo?.BaseballOddLabelId
              ? oddsInfo?.BaseballOddLabelId
              : oddsInfo?.BaseballPlayerId
            : selectedSports == 17
            ? oddsInfo?.IceHockeyTeamId
              ? oddsInfo?.IceHockeyTeamId
              : oddsInfo?.IceHockeyOddLabelId
              ? oddsInfo?.IceHockeyOddLabelId
              : oddsInfo?.IceHockeyPlayerId
            : selectedSports == 6
            ? oddsInfo?.BoxingTeamId
              ? oddsInfo?.BoxingTeamId
              : oddsInfo?.BoxingOddLabelId
              ? oddsInfo?.BoxingOddLabelId
              : oddsInfo?.BoxingPlayerId
            : selectedSports == 5
            ? oddsInfo?.MMATeamId
              ? oddsInfo?.MMATeamId
              : oddsInfo?.MMAOddLabelId
              ? oddsInfo?.MMAOddLabelId
              : oddsInfo?.MMAPlayerId
            : selectedSports == 8
            ? oddsInfo?.SoccerTeamId
              ? oddsInfo?.SoccerTeamId
              : oddsInfo?.SoccerOddLabelId
              ? oddsInfo?.SoccerOddLabelId
              : oddsInfo?.SoccerPlayerId
            : oddsInfo?.RLTeamId
            ? oddsInfo?.RLTeamId
            : oddsInfo?.RLOddLabelId
            ? oddsInfo?.RLOddLabelId
            : oddsInfo?.RLPlayerId;

        let payload = {
          sportId: fulldata?.SportId,
          eventId: fulldata?.id,
          teamId: passTeamId,
          bookKeeperId: BookKeeperId,
          betOfferId: oddsInfo?.id
        };
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, fulldata, e) => {
    e.stopPropagation();
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const clearDate = () => {
    setselectedDate(null);
    setOpen(false);
    fetchAllEvents(
      0,
      selectedSports,
      selectedOrg,
      selectedTeams,
      null,
      sportsMarketId
    );
  };

  const handleDateChange = date => {
    setselectedDate(date ? moment(date).format("YYYY-MM-DD") : null);
    fetchAllEvents(
      0,
      selectedSports,
      selectedOrg,
      selectedTeams,
      date ? moment(date).format("YYYY-MM-DD") : null,
      sportsMarketId
    );
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const scrollToTop = () => {
    document.getElementById("inner-content").scrollIntoView();
  };

  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      selectedSports == 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : selectedSports == 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : selectedSports == 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : selectedSports == 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : selectedSports == 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : selectedSports == 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : selectedSports == 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : selectedSports == 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : selectedSports == 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : selectedSports == 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : selectedSports == 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return selectedSports == 4
        ? item?.CricketTeamId == data?.homeTeamId
        : selectedSports == 10
        ? item?.NBATeamId == data?.homeTeamId
        : selectedSports == 15
        ? item?.AFLTeamId == data?.homeTeamId
        : selectedSports == 9
        ? item?.ARTeamId == data?.homeTeamId
        : selectedSports == 16
        ? item?.GolfTeamId == data?.homeTeamId
        : selectedSports == 7
        ? item?.TennisTeamId == data?.homeTeamId
        : selectedSports == 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : selectedSports == 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : selectedSports == 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : selectedSports == 5
        ? item?.MMATeamId == data?.homeTeamId
        : selectedSports == 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return selectedSports == 4
        ? item?.CricketTeamId == data?.awayTeamId
        : selectedSports == 10
        ? item?.NBATeamId == data?.awayTeamId
        : selectedSports == 15
        ? item?.AFLTeamId == data?.awayTeamId
        : selectedSports == 9
        ? item?.ARTeamId == data?.awayTeamId
        : selectedSports == 16
        ? item?.GolfTeamId == data?.awayTeamId
        : selectedSports == 7
        ? item?.TennisTeamId == data?.awayTeamId
        : selectedSports == 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : selectedSports == 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : selectedSports == 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : selectedSports == 5
        ? item?.MMATeamId == data?.awayTeamId
        : selectedSports == 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map(obj => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        return fetchClickableOdds(
          maxno,
          bookkeeperid?.[0],
          "header",
          teamData,
          data,
          "betslip",
          Isscore
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      selectedSports == 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : selectedSports == 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : selectedSports == 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : selectedSports == 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : selectedSports == 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : selectedSports == 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : selectedSports == 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : selectedSports == 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : selectedSports == 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : selectedSports == 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : selectedSports == 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return selectedSports == 4
        ? item?.CricketTeamId == data?.homeTeamId
        : selectedSports == 10
        ? item?.NBATeamId == data?.homeTeamId
        : selectedSports == 15
        ? item?.AFLTeamId == data?.homeTeamId
        : selectedSports == 9
        ? item?.ARTeamId == data?.homeTeamId
        : selectedSports == 16
        ? item?.GolfTeamId == data?.homeTeamId
        : selectedSports == 7
        ? item?.TennisTeamId == data?.homeTeamId
        : selectedSports == 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : selectedSports == 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : selectedSports == 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : selectedSports == 5
        ? item?.MMATeamId == data?.homeTeamId
        : selectedSports == 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return selectedSports == 4
        ? item?.CricketTeamId == data?.awayTeamId
        : selectedSports == 10
        ? item?.NBATeamId == data?.awayTeamId
        : selectedSports == 15
        ? item?.AFLTeamId == data?.awayTeamId
        : selectedSports == 9
        ? item?.ARTeamId == data?.awayTeamId
        : selectedSports == 16
        ? item?.GolfTeamId == data?.awayTeamId
        : selectedSports == 7
        ? item?.TennisTeamId == data?.awayTeamId
        : selectedSports == 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : selectedSports == 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : selectedSports == 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : selectedSports == 5
        ? item?.MMATeamId == data?.awayTeamId
        : selectedSports == 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = teamInfo;
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map(obj => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);

        return oddsicon(providerid?.[0], "header", teamData, data);
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };

  const fetchFluctuationGraph = (data, team) => {
    let selectedId = null;
    let allTeamOdds =
      selectedSports == 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : selectedSports == 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : selectedSports == 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : selectedSports == 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : selectedSports == 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : selectedSports == 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : selectedSports == 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : selectedSports == 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : selectedSports == 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : selectedSports == 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : selectedSports == 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return selectedSports == 4
        ? item?.CricketTeamId == data?.homeTeamId
        : selectedSports == 10
        ? item?.NBATeamId == data?.homeTeamId
        : selectedSports == 15
        ? item?.AFLTeamId == data?.homeTeamId
        : selectedSports == 9
        ? item?.ARTeamId == data?.homeTeamId
        : selectedSports == 16
        ? item?.GolfTeamId == data?.homeTeamId
        : selectedSports == 7
        ? item?.TennisTeamId == data?.homeTeamId
        : selectedSports == 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : selectedSports == 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : selectedSports == 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : selectedSports == 5
        ? item?.MMATeamId == data?.homeTeamId
        : selectedSports == 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return selectedSports == 4
        ? item?.CricketTeamId == data?.awayTeamId
        : selectedSports == 10
        ? item?.NBATeamId == data?.awayTeamId
        : selectedSports == 15
        ? item?.AFLTeamId == data?.awayTeamId
        : selectedSports == 9
        ? item?.ARTeamId == data?.awayTeamId
        : selectedSports == 16
        ? item?.GolfTeamId == data?.awayTeamId
        : selectedSports == 7
        ? item?.TennisTeamId == data?.awayTeamId
        : selectedSports == 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : selectedSports == 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : selectedSports == 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : selectedSports == 5
        ? item?.MMATeamId == data?.awayTeamId
        : selectedSports == 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    const teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map(obj => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        selectedId = bookkeeperid?.[0];
      } else {
        selectedId = null;
      }
    } else {
      selectedId = null;
    }

    if (selectedId) {
      const selectedFilterData = teamData?.filter(item => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.oddFlucs?.map(
        odds => {
          return FlucData?.push({
            point: odds?.odd
          });
        }
      );

      return FlucData?.length > 0 ? (
        <HighChart FlucData={FlucData} />
      ) : (
        <Box className="nodata"> No Data Available</Box>
      );
    } else {
      return <Box className="nodata"> No Data Available</Box>;
    }
  };
  const fetchmarketList = async sportId => {
    try {
      let SportsType =
        sportId == 4
          ? `crickets`
          : sportId == 12
          ? `rls`
          : sportId == 13
          ? `rls`
          : sportId == 10
          ? `nba`
          : sportId == 15
          ? `afl`
          : sportId == 9
          ? `ar`
          : sportId == 16
          ? `golf`
          : sportId == 7
          ? `tennis`
          : sportId == 11
          ? `baseball`
          : sportId == 17
          ? `icehockey`
          : sportId == 6
          ? `boxing`
          : sportId == 5
          ? `mma`
          : sportId == 8
          ? `soccer`
          : `rls`;
      const { status, data } = await axiosInstance.get(
        `public/${SportsType}/market?isPrimary=true&SportId=${
          sportId == 12 ? 12 : sportId == 13 ? 13 : ""
        }`
      );
      if (status === 200) {
        const h2hmarket = data?.result?.rows?.filter(
          item => item?.name == "h2h"
        );
        setSportsMarketId(h2hmarket?.[0]?.id);
        fetchAllEvents(0, sportId, 0, 0, null, h2hmarket?.[0]?.id);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setIsLoading(false);
    }
  };
  const fetchAllEvents = async (
    EventPage,
    sportId,
    selectedseries,
    selectedteam,
    selectedDate,
    marketId
  ) => {
    setEventPage(EventPage);
    setIsLoading(true);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;

    let isPublic = !isLogin ? "public/" : "";
    let isSmartBook = !isLogin ? "" : "smartBook=true";
    try {
      let passApi =
        sportId == 4
          ? `${isPublic}crickets/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&CricketTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 12
          ? `${isPublic}rls/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&RLTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
              stepperCount === 0 ? "" : stepperCount
            }&timezone=${timezone}&SportId=12&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 13
          ? `${isPublic}rls/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&RLTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
              stepperCount === 0 ? "" : stepperCount
            }&timezone=${timezone}&SportId=13&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 10
          ? `${isPublic}nba/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&NBATournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 15
          ? `${isPublic}afl/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&AFLTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 9
          ? `${isPublic}ar/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&ARTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 16
          ? `${isPublic}golf/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&GolfTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=${!showAllFixture}&isOuright=true
            }&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true
            &${isSmartBook}`
          : sportId == 7
          ? `${isPublic}tennis/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&TennisTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 11
          ? `${isPublic}baseball/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&BaseballTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 17
          ? `${isPublic}icehockey/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&IceHockeyTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 6
          ? `${isPublic}boxing/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&BoxingTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 5
          ? `${isPublic}mma/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&MMATournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : sportId == 8
          ? `${isPublic}soccer/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&SoccerTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
          : `${isPublic}rls/event?startDate=${
              selectedDate === null
                ? moment()
                    .tz(timezone)
                    .format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&RLTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
              stepperCount === 0 ? "" : stepperCount
            }&timezone=${timezone}&SportId=14&oddCheck=true&marketId=${marketId ??
              1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`;
      // /public/crickets/event?startDate=2023-01-17&endDate=2023-01-18&CricketTournamentId=6&teamId=1&awayTeamId=1&homeTeamId=86
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        let oddsApiCount = 0;
        let count = data?.result?.count / 20;
        setEventCount(Math.ceil(count));
        let fullData = [];
        let teamdata = [data?.result?.rows?.[0]];
        let newData =
          teamdata?.length > 0
            ? sportId == 4
              ? teamdata?.[0]?.CricketBetOffers
              : sportId == 10
              ? teamdata?.[0]?.NBABetOffers
              : sportId == 15
              ? teamdata?.[0]?.AFLBetOffers
              : sportId == 9
              ? teamdata?.[0]?.ARBetOffers
              : sportId == 16
              ? teamdata?.[0]?.GolfBetOffers
              : sportId == 7
              ? teamdata?.[0]?.TennisBetOffers
              : sportId == 11
              ? teamdata?.[0]?.BaseballBetOffers
              : sportId == 17
              ? teamdata?.[0]?.IceHockeyBetOffers
              : sportId == 6
              ? teamdata?.[0]?.BoxingBetOffers
              : sportId == 5
              ? teamdata?.[0]?.MMABetOffers
              : sportId == 8
              ? teamdata?.[0]?.SoccerBetOffers
              : teamdata?.[0]?.RLBetOffers
            : [];
        setEventList(data?.result?.rows);
        setIsLoading(false);
        // let SportsOdds =
        //   selectedSports == 4
        //     ? newData?.[0]?.CricketOdds
        //     : selectedSports == 10
        //     ? newData?.[0]?.NBAOdds
        //     : selectedSports == 15
        //     ? newData?.[0]?.AFLOdds
        //     : selectedSports == 9
        //     ? newData?.[0]?.AROdds
        //     : selectedSports == 16
        //     ? newData?.[0]?.GolfOdds
        //     : selectedSports == 7
        //     ? newData?.[0]?.TennisOdds
        //     : selectedSports == 11
        //     ? newData?.[0]?.BaseballOdds
        //     : selectedSports == 17
        //     ? newData?.[0]?.IceHockeyOdds
        //     : selectedSports == 6
        //     ? newData?.[0]?.BoxingOdds
        //     : selectedSports == 5
        //     ? newData?.[0]?.MMAOdds
        //     : selectedSports == 8
        //     ? newData?.[0]?.SoccerOdds
        //     : newData?.[0]?.RLOdds;

        // let SportMarket =
        //   selectedSports == 4
        //     ? newData?.[0]?.CricketMarket
        //     : selectedSports == 10
        //     ? newData?.[0]?.NBAMarket
        //     : selectedSports == 15
        //     ? newData?.[0]?.AFLMarket
        //     : selectedSports == 9
        //     ? newData?.[0]?.ARMarket
        //     : selectedSports == 16
        //     ? newData?.[0]?.GolfMarket
        //     : selectedSports == 7
        //     ? newData?.[0]?.TennisMarket
        //     : selectedSports == 11
        //     ? newData?.[0]?.BaseballMarket
        //     : selectedSports == 17
        //     ? newData?.[0]?.IceHockeyMarket
        //     : selectedSports == 6
        //     ? newData?.[0]?.BoxingMarket
        //     : selectedSports == 5
        //     ? newData?.[0]?.MMAMarket
        //     : selectedSports == 8
        //     ? newData?.[0]?.SoccerMarket
        //     : newData?.[0]?.RLMarket;
        // setMarketName(
        //   SportMarket?.displayName
        //     ? SportMarket?.displayName
        //     : SportMarket?.name
        // );
        // let datas = data?.result?.rows?.filter(item => {
        //   return item?.outrights === false;
        // });
      }
    } catch (err) {
      setIsEventLoading(false);
    }
  };
  const handleOnScrollBottomEvents = async (
    EventPage,
    sportId,
    selectedseries,
    selectedteam,
    selectedDate,
    marketId
  ) => {
    if (EventCount !== (EventCount == 1 ? 1 : Math.ceil(EventPage / 20))) {
      const localAuth = fetchFromStorage("auth_token");
      let isLogin = localAuth ? true : false;

      let isPublic = !isLogin ? "public/" : "";
      let isSmartBook = !isLogin ? "" : "smartBook=true";
      try {
        let passApi =
          sportId == 4
            ? `${isPublic}crickets/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&CricketTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 12
            ? `${isPublic}rls/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&RLTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                stepperCount === 0 ? "" : stepperCount
              }&timezone=${timezone}&SportId=12&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 13
            ? `${isPublic}rls/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&RLTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                stepperCount === 0 ? "" : stepperCount
              }&timezone=${timezone}&SportId=13&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 10
            ? `${isPublic}nba/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&NBATournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 15
            ? `${isPublic}afl/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&AFLTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 9
            ? `${isPublic}ar/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&ARTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 16
            ? `${isPublic}golf/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&GolfTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=${!showAllFixture}&isOuright=true
            }&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true
            &${isSmartBook}`
            : sportId == 7
            ? `${isPublic}tennis/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&TennisTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 11
            ? `${isPublic}baseball/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&BaseballTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 17
            ? `${isPublic}icehockey/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&IceHockeyTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 6
            ? `${isPublic}boxing/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&BoxingTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 5
            ? `${isPublic}mma/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&MMATournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : sportId == 8
            ? `${isPublic}soccer/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&SoccerTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`
            : `${isPublic}rls/event?startDate=${
                selectedDate === null
                  ? moment()
                      .tz(timezone)
                      .format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&RLTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                stepperCount === 0 ? "" : stepperCount
              }&timezone=${timezone}&SportId=14&oddCheck=true&marketId=${marketId ??
                1}&isOuright=&limit=20&offset=${EventPage}&isAdmin=false&isHome=false&isGraph=true&${isSmartBook}`;
        // /public/crickets/event?startDate=2023-01-17&endDate=2023-01-18&CricketTournamentId=6&teamId=1&awayTeamId=1&homeTeamId=86
        const { status, data } = await axiosInstance.get(passApi);
        if (status === 200) {
          let fullData = [];
          let uniqbydata = _.uniqBy(
            [...eventList, ...data?.result?.rows],
            function(e) {
              return e?.id;
            }
          );
          setEventList(uniqbydata);
          setEventPage(EventPage);
        }
      } catch (err) {}
    }
  };
  const handleClose = () => {
    setOpenTipsModal(false);
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="tips-marketplace-tips-wrap">
          <Box className="marketplace-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {/* {localesData?.MENU?.HOME} */}
                  HOME
                </Link>
                <Link underline="hover" color="inherit" to="#">
                  {/* {localesData?.MENU?.HOME} */}
                  Tips Marketplace
                </Link>
                <Typography>
                  {/* {localesData?.BLACKBOOK?.BLACKBOOK} */}
                  Enter Sports Tips
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">
              {/* {localesData?.BLACKBOOK?.BLACKBOOK} */}
              Enter Sports Tips
            </Typography>
          </Box>
          <Box className="Marketplace-select-wrap">
            <Box className="filter-type-select">
              <Box className="enter-Filteritemlist-datepicker">
                <Select
                  className="React horse-select"
                  value={sports?.find(item => {
                    return item?.value === selectedSports;
                  })}
                  onChange={e => {
                    setSelectedSports(e?.value == 0 ? "" : e?.value);
                    setSelectedTeams("");
                    setSelectedOrg("");
                    setTeamsAll([]);
                    setOrgAll([]);
                    fetchOrgData(0, e?.value, []);
                    fetchTeamData(0, e?.value, []);
                    setIsLoading(true);
                    // fetchAllEvents(
                    //   0,
                    //   e?.value,
                    //   selectedOrg,
                    //   selectedTeams,
                    //   selectedDate,
                    //   sportsMarketId
                    // );
                    fetchmarketList(e?.value);
                  }}
                  // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                  options={sports}
                  classNamePrefix="select"
                  placeholder="Sports"
                  // isSearchable={false}
                  // onFocus={() => setTournamentSelect(true)}
                  // onBlur={() => setTournamentSelect(false)}
                  components={{ DropdownIndicator }}
                />
                <Select
                  className={
                    selectedSports && selectedSports.length > 0
                      ? `React horse-select disable-state`
                      : `React horse-select`
                  }
                  // className={
                  //   selectedSports && selectedSports.length > 0
                  //     ? `React period-select disable-state`
                  //     : `React period-select`
                  // }
                  onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
                  onInputChange={e =>
                    handleOrgInputChange(0, e, selectedSports)
                  }
                  onChange={e => {
                    setSelectedOrg(e?.value);
                    fetchOrgData(e?.value, selectedSports, OrgAll);
                    setOrgApiCount(0);
                    setpageOrg(0);
                    setisOrgSelectOpen(false);
                    fetchAllEvents(
                      0,
                      selectedSports,
                      e?.value,
                      selectedTeams,
                      selectedDate,
                      sportsMarketId
                    );
                  }}
                  onFocus={() => setisOrgSelectOpen(true)}
                  onBlur={() => setisOrgSelectOpen(false)}
                  value={
                    selectedOrg !== "" &&
                    (isOrgSearch
                      ? searchOrg?.find(item => {
                          return item?.value == selectedOrg;
                        })
                      : OrgAll?.find(item => {
                          return item?.value == selectedOrg;
                        }))
                  }
                  options={isOrgSearch ? searchOrg : OrgAll}
                  classNamePrefix="select"
                  placeholder="Tournament: All"
                  isDisabled={
                    selectedSports && selectedSports.length > 0 ? true : false
                  }
                  components={{ DropdownIndicator }}
                />
                <Select
                  className={
                    selectedSports && selectedSports.length > 0
                      ? `React bet-type-select disable-state`
                      : `React bet-type-select`
                  }
                  // options={teamsAll}
                  onMenuScrollToBottom={e => handleOnScrollBottomTeams(e)}
                  onInputChange={e =>
                    handleTeamInputChange(0, e, selectedSports)
                  }
                  onChange={e => {
                    setSelectedTeams(e?.value);
                    fetchTeamData(e?.value, selectedSports, teamsAll);
                    setTeamApiCount(0);
                    setpageTeam(0);
                    setisTeamSelectOpen(false);
                    fetchAllEvents(
                      0,
                      selectedSports,
                      selectedOrg,
                      e?.value,
                      selectedDate,
                      sportsMarketId
                    );
                  }}
                  onFocus={() => setisTeamSelectOpen(true)}
                  onBlur={() => setisTeamSelectOpen(false)}
                  value={
                    selectedTeams !== "" &&
                    (isTeamSearch
                      ? searchTeam?.find(item => {
                          return item?.value == selectedTeams;
                        })
                      : teamsAll?.find(item => {
                          return item?.value == selectedTeams;
                        }))
                  }
                  options={isTeamSearch ? searchTeam : teamsAll}
                  classNamePrefix="select"
                  placeholder="Teams: All"
                  isDisabled={
                    selectedSports && selectedSports.length > 0 ? true : false
                  }
                  components={{ DropdownIndicator }}
                />

                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  className="date-picker"
                >
                  <KeyboardDatePicker
                    className="details-search-picker details-day-date-picker"
                    autoOk
                    clearable
                    clearLabel="Reset"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    margin="normal"
                    id="date-picker-inline"
                    inputVariant="outlined"
                    value={selectedDate ? parseISO(selectedDate) : null}
                    onClear={clearDate}
                    keyboardIcon={<Datepicker className="keyboard-icon" />}
                    onChange={e => handleDateChange(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    views={["year", "month", "date"]}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </Box>
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : eventList?.length > 0 ? (
            <>
              <Box
                id="scrollableDiv"
                // style={{ maxHeight: 1200, overflow: "auto" }}
              >
                <InfiniteScroll
                  dataLength={eventList?.length}
                  next={() =>
                    handleOnScrollBottomEvents(
                      EventPage + 20,
                      selectedSports,
                      selectedOrg,
                      selectedTeams,
                      selectedDate,
                      sportsMarketId
                    )
                  }
                  hasMore={EventCount !== Math.ceil(EventPage / 20 + 1)}
                  loader={
                    <div className="allsport-loader-center ">
                      <Loader />
                    </div>
                  }
                  // scrollableTarget="scrollableDiv"
                >
                  {eventList?.map((item, index) => {
                    return (
                      <>
                        <Box className="team-sport-table-wrap" key={index}>
                          <Box className="table-header-wrap">
                            <Box>
                              <Typography variant="h1">
                                {fetchDayName(item?.startTime)}{" "}
                                {item?.startTime
                                  ? moment
                                      .utc(item?.startTime)
                                      .local()
                                      .format("DD/MM/YYYY")
                                  : ""}
                              </Typography>
                            </Box>
                            <Box className="team-sport-table-indicator">
                              <Box className="team-time-market">
                                {item?.startTime
                                  ? moment
                                      .utc(item?.startTime)
                                      .local()
                                      .format("hh:mm A")
                                  : ""}{" "}
                                |{" "}
                                {`${
                                  item?.marketLength ? item?.marketLength : 0
                                } Markets`}
                              </Box>
                              <span className="sport-indicator-btn">
                                {selectedSports == 4
                                  ? item?.CricketTournament?.name
                                  : selectedSports == 12 ||
                                    selectedSports == 13 ||
                                    selectedSports == 14
                                  ? item?.RLTournament?.name
                                  : selectedSports == 15
                                  ? item?.AFLTournament?.name
                                  : selectedSports == 9
                                  ? item?.ARTournament?.name
                                  : selectedSports == 16
                                  ? item?.GolfTournament?.name
                                  : selectedSports == 7
                                  ? item?.TennisTournament?.name
                                  : selectedSports == 11
                                  ? item?.BaseballTournament?.name
                                  : selectedSports == 17
                                  ? item?.IceHockeyTournament?.name
                                  : selectedSports == 6
                                  ? item?.BoxingTournament?.name
                                  : selectedSports == 5
                                  ? item?.MMATournament?.name
                                  : selectedSports == 8
                                  ? item?.SoccerTournament?.name
                                  : selectedSports == 10
                                  ? item?.NBATournament?.NBACategory === null
                                    ? item?.NBATournament?.name
                                    : item?.NBATournament?.name +
                                      " " +
                                      item?.NBATournament?.NBACategory?.name
                                  : ""}
                              </span>
                            </Box>
                            {/* {tabvalue === 0 && (
                            <Box className="currentbest-seeall">
                              <span>
                                <Typography variant="h1">
                                  {" "}
                                  {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                                </Typography>
                              </span>

                              <span
                                className="teamsports-seeall"
                                // onClick={() => handleSeeAll(item)}
                              >
                                <Typography variant="h1">
                                  {localesData?.RUNNER_TABLE?.SEE_ALL}
                                </Typography>
                              </span>
                            </Box>
                          )} */}
                          </Box>
                          {screenWidth > 1025 ? (
                            <Table className="team-sport-table">
                              <TableBody>
                                <>
                                  <TableRow className="teamsports-tablerow hometeam-row">
                                    <TableCell className="team-wrap">
                                      <span className="team-img-wrap">
                                        <Box className="team-img-wrap">
                                          {/* <img
                                          className="team-img"
                                          src={item?.image1}
                                        /> */}
                                          {fetchTeamlogo(item, "hometeam")}
                                        </Box>
                                      </span>
                                      <span className="team-name">
                                        {item?.homeTeam?.name ?? ""}
                                      </span>
                                    </TableCell>
                                    <TableCell className="hight-chart-cell">
                                      {fetchFluctuationGraph(item, "hometeam")}
                                    </TableCell>
                                    <TableCell className="td-cell bg-white">
                                      <Box className="odds-wrap">
                                        <span className="odds odds-left">
                                          {" "}
                                          {fetchCurrentBestOdds(
                                            item,
                                            "odds",
                                            "hometeam"
                                          )}
                                        </span>
                                        {fetchCurrentBestsOddsIcon(
                                          item,
                                          "odds",
                                          "hometeam"
                                        )}
                                      </Box>
                                    </TableCell>

                                    <TableCell className="current-best w160 padding28">
                                      {" "}
                                      <Box className="odds-wrap">
                                        {item?.homeTeam?.isMarketTip == 0 &&
                                        item?.awayTeam?.isMarketTip == 0 ? (
                                          <Button
                                            className="add-tip-btn"
                                            onClick={() => {
                                              setOpenTipsModal(true);
                                              setPassModalData(item);
                                              setTippedTeamId(item?.homeTeamId);
                                              setTippedTitle(
                                                item?.homeTeam?.name
                                              );
                                            }}
                                          >
                                            Add Tip
                                          </Button>
                                        ) : (
                                          <Button
                                            className={
                                              item?.homeTeam?.isMarketTip == 1
                                                ? `tipped-btn`
                                                : `tipped-disable`
                                            }
                                            startIcon={
                                              item?.homeTeam?.isMarketTip ==
                                              1 ? (
                                                <TickIcon />
                                              ) : (
                                                <LockIcon />
                                              )
                                            }
                                            disabled
                                          >
                                            Tipped
                                          </Button>
                                        )}
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow className="awayteam-row">
                                    <TableCell className="team-wrap">
                                      <span className="team-img-wrap">
                                        {fetchTeamlogo(item, "awayteam")}
                                      </span>
                                      <span className="team-name">
                                        {" "}
                                        {item?.awayTeam?.name ?? ""}
                                      </span>
                                    </TableCell>

                                    <TableCell className="hight-chart-cell">
                                      {fetchFluctuationGraph(item, "awayteam")}
                                    </TableCell>
                                    <TableCell className="td-cell bg-white">
                                      <Box className="odds-wrap">
                                        <span className="odds odds-left">
                                          {" "}
                                          {fetchCurrentBestOdds(
                                            item,
                                            "odds",
                                            "awayteam"
                                          )}
                                        </span>
                                        {fetchCurrentBestsOddsIcon(
                                          item,
                                          "odds",
                                          "awayteam"
                                        )}
                                      </Box>
                                    </TableCell>

                                    <TableCell className="current-best w160 padding28">
                                      <Box className="odds-wrap">
                                        {item?.homeTeam?.isMarketTip == 0 &&
                                        item?.awayTeam?.isMarketTip == 0 ? (
                                          <Button
                                            className="add-tip-btn"
                                            onClick={() => {
                                              setOpenTipsModal(true);
                                              setPassModalData(item);
                                              setTippedTeamId(item?.awayTeamId);
                                              setTippedTitle(
                                                item?.awayTeam?.name
                                              );
                                            }}
                                          >
                                            Add Tip
                                          </Button>
                                        ) : (
                                          <Button
                                            className={
                                              item?.awayTeam?.isMarketTip == 1
                                                ? `tipped-btn`
                                                : `tipped-disable`
                                            }
                                            startIcon={
                                              item?.awayTeam?.isMarketTip ==
                                              1 ? (
                                                <TickIcon />
                                              ) : (
                                                <LockIcon />
                                              )
                                            }
                                            disabled
                                          >
                                            Tipped
                                          </Button>
                                        )}
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </>
                              </TableBody>
                            </Table>
                          ) : (
                            <Box className="mobileview-teamsport-table-wrap">
                              <Box className="team-match-wrap">
                                <Box className="time-market-team-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      <span className="team-img-wrap">
                                        {fetchTeamlogo(item, "hometeam")}
                                      </span>
                                      <Box className="smartbook-title">
                                        <span className="team-name">
                                          {item?.homeTeam?.name ?? ""}
                                        </span>
                                      </Box>
                                    </Box>
                                    <Box className="vs"> V/S </Box>
                                    <Box className="team-wrap">
                                      <span className="team-img-wrap">
                                        {fetchTeamlogo(item, "awayteam")}
                                      </span>
                                      <Box className="smartbook-title">
                                        <span className="team-name">
                                          {" "}
                                          {item?.awayTeam?.name ?? ""}
                                        </span>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box className="win-odds-live">
                                  <Box
                                  // className="win-odds-padding"
                                  >
                                    <Box className="odds-wrap flex-row-rev">
                                      <span className="odds odds-left">
                                        {" "}
                                        {fetchCurrentBestOdds(
                                          item,
                                          "odds",
                                          "hometeam"
                                        )}
                                      </span>
                                      {fetchCurrentBestsOddsIcon(
                                        item,
                                        "odds",
                                        "hometeam"
                                      )}
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Box className="odds-wrap flex-row">
                                      <span className="odds odds-left">
                                        {fetchCurrentBestOdds(
                                          item,
                                          "odds",
                                          "awayteam"
                                        )}
                                      </span>
                                      {fetchCurrentBestsOddsIcon(
                                        item,
                                        "odds",
                                        "awayteam"
                                      )}
                                    </Box>
                                  </Box>
                                </Box>

                                {/* <Typography className="des-label des-head des-current-best">
                                CURRENT BEST
                              </Typography> */}
                                <Box className="mobile-chart-deatils">
                                  <Box className="mobile-hight-chart">
                                    <Box className="ptb-10 mobile-chart">
                                      {fetchFluctuationGraph(item, "hometeam")}
                                    </Box>
                                    <Box className="ptb-10 mobile-chart">
                                      {fetchFluctuationGraph(item, "awayteam")}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="odds-detail odds-details-best blue-btn-flex">
                                  <Box className="odds-body">
                                    <Box>
                                      <Box className="odds-wrap">
                                        {item?.homeTeam?.isMarketTip == 0 &&
                                        item?.awayTeam?.isMarketTip == 0 ? (
                                          <Button
                                            className="add-tip-btn"
                                            onClick={() => {
                                              setOpenTipsModal(true);
                                              setPassModalData(item);
                                              setTippedTeamId(item?.homeTeamId);
                                              setTippedTitle(
                                                item?.homeTeam?.name
                                              );
                                            }}
                                          >
                                            Add Tip
                                          </Button>
                                        ) : (
                                          <Button
                                            className={
                                              item?.homeTeam?.isMarketTip == 1
                                                ? `tipped-btn`
                                                : `tipped-disable`
                                            }
                                            startIcon={
                                              item?.homeTeam?.isMarketTip ==
                                              1 ? (
                                                <TickIcon />
                                              ) : (
                                                <LockIcon />
                                              )
                                            }
                                            disabled
                                          >
                                            Tipped
                                          </Button>
                                        )}
                                      </Box>
                                    </Box>
                                    <Box>
                                      {/* <Box className="odds-label">
                                      {" "}
                                      {localesData?.sport?.WIN}{" "}
                                    </Box> */}
                                      <Box className="odds-wrap">
                                        {item?.homeTeam?.isMarketTip == 0 &&
                                        item?.awayTeam?.isMarketTip == 0 ? (
                                          <Button
                                            className="add-tip-btn"
                                            onClick={() => {
                                              setOpenTipsModal(true);
                                              setPassModalData(item);
                                              setTippedTeamId(item?.awayTeamId);
                                              setTippedTitle(
                                                item?.awayTeam?.name
                                              );
                                            }}
                                          >
                                            Add Tip
                                          </Button>
                                        ) : (
                                          <Button
                                            className={
                                              item?.awayTeam?.isMarketTip == 1
                                                ? `tipped-btn`
                                                : `tipped-disable`
                                            }
                                            startIcon={
                                              item?.awayTeam?.isMarketTip ==
                                              1 ? (
                                                <TickIcon />
                                              ) : (
                                                <LockIcon />
                                              )
                                            }
                                            disabled
                                          >
                                            Tipped
                                          </Button>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>

                                {/* {tabvalue === 3 && ( */}

                                {/* )} */}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </>
                    );
                  })}
                </InfiniteScroll>
              </Box>
            </>
          ) : (
            <Box style={{ textAlign: "center" }}>
              {/* {/ No Data Available  /} */}
              <NoDataComp />
            </Box>
          )}
        </Box>
      </Box>
      <OurPartner />
      <SportsTipModal
        open={openTipsModal}
        handleClose={handleClose}
        title={tippedTitle}
        raceId={tippedTeamId}
        type={"sport"}
        SportId={selectedSports}
        data={passModalData}
        closeIcon={true}
        isEdit={false}
        listingFunction={() =>
          fetchAllEvents(
            EventPage,
            selectedSports,
            selectedOrg,
            selectedTeams,
            selectedDate,
            sportsMarketId
          )
        }
      />
    </>
  );
};

export default EnterSportsTips;
