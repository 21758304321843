import React, { useContext, useEffect, useState } from "react";
import { Box, Breadcrumbs, Typography, Tabs, Tab } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import OurPartner from "../ourPartners";
import HongKongHorseRacing from "./hongKongHorseRacing";
import SingaporeHorseRacing from "./singaporeHorseRacing";
import UKHorseRacing from "./ukHorseRacing";
import InternationalRacingDetails from "./internationalRacing";
import "./internationalRacing.scss";

const InternationalRacingData = [
  {
    id: 0,
    name: "International Racing"
  },
  {
    id: 1,
    name: "Hong Kong Horse Racing"
  },
  {
    id: 2,
    name: "Singapore Horse Racing"
  },
  {
    id: 3,
    name: "UK Horse Racing"
  }
];

const InternationalRacingPage = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(0);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="international-racing-wrap">
          <Box className="racing-header">
            <Box className="page-bredcrumn-wrap">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    {localesData?.MENU?.HOME}
                  </Link>
                  <Link underline="hover" color="inherit" to="#">
                    {localesData?.INTERNATIONAL?.INFORMATION}
                  </Link>

                  <Typography>
                    {localesData?.INTERNATIONAL?.INTERNATIONAL_RACING}
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Typography variant="h1">
                {localesData?.INTERNATIONAL?.INTERNATIONAL_RACING}
              </Typography>
            </Box>
          </Box>
          <Box className="international-racing-tab">
            <Tabs
              value={tabvalue}
              variant="scrollable"
              scrollButtons="off"
              indicatorColor="primary"
              textColor="primary"
              className="international-racing-tab-detail"
            >
              {InternationalRacingData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.name}
                      value={item?.id}
                      className={item?.id === tabvalue ? "active " : ""}
                      onChange={(event, newValue) =>
                        handleTabChange(event, item?.id)
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
          </Box>
          <Box className="international-racing-details-wrap">
            {tabvalue === 0 ? (
              <InternationalRacingDetails />
            ) : tabvalue === 1 ? (
              <HongKongHorseRacing />
            ) : tabvalue === 2 ? (
              <SingaporeHorseRacing />
            ) : tabvalue === 3 ? (
              <UKHorseRacing />
            ) : (
              ""
            )}
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default InternationalRacingPage;
