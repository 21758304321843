import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IntlContext } from "src/App";
import {
  AppBar,
  Grid,
  Button,
  Box,
  IconButton,
  Typography,
  Collapse,
  SwipeableDrawer,
  ClickAwayListener
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as FaBars } from "../../../../assets/images/oddsComparison/web-menu-side.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/oddsComparison/web-menu-close.svg";
import { ReactComponent as MobileMenu } from "../../../../assets/images/icons/menu.svg";
import Close from "@material-ui/icons/Close";
import logo from "../../../../assets/images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import "./header.scss";
import LeftSideBar from "../../leftsidebar/leftsidebar";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import BetSlipCollapse from "src/layouts/MainLayout/BetslipCollapse";

const OddsComparisonHeader = () => {
  let dispatch = useDispatch();
  const location = useLocation();
  const BetSlipToggleData = useSelector(
    state => state?.reduxData?.BetSlipToggle
  );
  const BetSlipCount = useSelector(state => state?.reduxData?.BetSlipCount);
  const reduxSubscriptionData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  let token = localStorage.getItem("auth_token");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [betCount, setBetCount] = useState(0);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [showMenu, setshowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (BetSlipCount) {
      setBetCount(BetSlipCount);
    } else if (BetSlipCount === 0) {
      setBetCount(BetSlipCount);
    }
  }, [BetSlipCount]);

  const handleToggleMenu = () => {
    setMenuOpen(!MenuOpen);
  };

  const toggleMenu = () => {
    setshowMenu(!showMenu);
  };

  useEffect(() => {
    setshowMenu(false);
  }, [location?.pathname, location?.search]);

  const handleCloseMenu = () => {
    setshowMenu(false);
  };

  const handleClickAway = () => {
    setMenuOpen(false);
  };

  const handleBetSlipToggle = () => {
    if (!token || token == "null") {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    } else {
      dispatch(
        fetchBetSlipToggle(BetSlipToggleData ? !BetSlipToggleData : true)
      );
      if (!BetSlipToggleData) {
        document.body.classList.add("no-scroll");
        if (screenWidth < 799) {
          window.scrollTo(0, 0);
        }
      } else {
        document.body.classList.remove("no-scroll");
      }
    }
  };

  const collapseStyles = {
    transformOrigin: "top right", // Set the transformation origin to top right
    transform: `scaleX(${BetSlipToggleData ? 1 : 0})`, // Scale the element in X-axis to 1 (normal size) when open, 0 when closed
    transition: "transform 0.3s ease-in-out" // Add a transition for a smooth animation
  };
  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        className="main-menu-wrap"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="mobileMenu">
          <SwipeableDrawer
            anchor={"left"}
            open={showMenu}
            onClose={toggleMenu}
            onOpen={toggleMenu}
            className="mobileMenuDrawer"
            swipeAreaWidth={0}
          >
            <div className="mobileMenuDrawerClose">
              <Close onClick={toggleMenu} />
            </div>
            <LeftSideBar handleCloseMenu={handleCloseMenu} />
          </SwipeableDrawer>
        </div>
        <Grid container spacing={0} alignItems="center" className="header-wrap">
          <Grid
            item
            md={6}
            sm={6}
            xs={6}
            lg={6}
            className="text-left logo-container"
          >
            <Box className="header-left-menu-wrap">
              <Box>
                {/* <ClickAwayListener onClickAway={() => handleClickAway()}> */}
                {screenWidth >= 799 ? (
                  MenuOpen ? (
                    <CloseIcon
                      className="MenuTopBar-button"
                      onClick={() => handleToggleMenu()}
                    />
                  ) : (
                    <FaBars
                      className="MenuTopBar-button"
                      onClick={() => handleToggleMenu()}
                    />
                  )
                ) : (
                  <MobileMenu
                    className="MenuTopBar-button"
                    onClick={() => toggleMenu()}
                  />
                )}
                {/* </ClickAwayListener> */}
              </Box>
              <Box>
                <NavLink to="/">
                  <img
                    src={logo}
                    style={{ maxWidth: "240.56px" }}
                    className="App-logo-main"
                    alt="logo"
                  />
                </NavLink>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className="MuiGrid-spacing-xs-0 login-buttons"
          >
            {!token || token == "null" ? (
              <Box className="signup-login-wrap">
                <Link to="/sign-up">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signup"
                  >
                    {localesData?.auth_btn?.signup}
                  </Button>
                </Link>
                <Link to="/sign-in">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signin"
                  >
                    {localesData?.auth_btn?.signin}
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box
                className="profile-logout-wrap"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                {reduxSubscriptionData?.Media?.filePath ? (
                  <Box className="profile-img-wrap">
                    <img
                      src={
                        Config?.countryMediaURL +
                        reduxSubscriptionData?.Media?.filePath
                      }
                      alt="profile"
                    />
                  </Box>
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    className="profile-icon"
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    {/* <AccountCircle /> */}
                    <PersonIcon />
                  </IconButton>
                )}
                <Typography className="user-name">
                  {reduxSubscriptionData?.firstName
                    ? reduxSubscriptionData?.firstName
                    : ""}
                </Typography>
              </Box>
            )}
            {release[Config.release]?.betSlip && (
              <Box
                className="bet-slip-button"
                onClick={() => handleBetSlipToggle()}
              >
                <Typography className="bet-slip">Bet Slip</Typography>
                <Box className="bet-slip-count">{betCount}</Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </AppBar>
      <Collapse in={MenuOpen} className="Menu-collapse">
        <Box className="left-sidebarcontent">
          <LeftSideBar />
        </Box>
      </Collapse>
      <Collapse
        in={BetSlipToggleData}
        className="betslip-collapse"
        style={screenWidth < 799 ? collapseStyles : ""}
      >
        <BetSlipCollapse />
      </Collapse>
    </>
  );
};

export default OddsComparisonHeader;
