import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "165px",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "133px",
      minWidth: "133px",
      boxShadow: "inset -2px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "133px",
      minWidth: "133px",
      boxShadow: "inset -2px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const LeagueSummaryDetails = ({ headerData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [loader, setLoader] = useState(false);
  const [teamDeatils, setTeamDeatils] = useState([]);
  const [pastTeam, setPastTeam] = useState([]);
  //   const [offset, setOffset] = useState(0);
  //   const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    fetchTeamDetails();
  }, [params, SeasonId]);

  const fetchTeamDetails = async (offset, teamData) => {
    setLoader(true);
    const passApi =
      params?.sportId == 12
        ? `/sports/statistics/rl/all/table/tournament/${
            params?.tournamentId
          }?seasonId=${SeasonId ? SeasonId : ""}`
        : `/sports/statistics/teams/${params?.tournamentId}?SportId=${
            params?.sportId
          }&seasonId=${SeasonId ? SeasonId : ""}`;
    try {
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setLoader(false);
        // setDataCount(data?.result?.team?.length);
        // const teamDeatilsData = [...teamData, ...data?.result?.team];
        setTeamDeatils(
          params?.sportId == 12 ? data?.result : data?.result?.team
        );
        setPastTeam(data?.result?.pastTeam);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  //   const handleMoreData = () => {
  //     setOffset(offset + 10);
  //     fetchTeamDetails(offset + 10, teamDeatils);
  //   };
  //   const handleLessData = () => {
  //     setOffset(offset - 10);
  //     if (teamDeatils?.length % 10 === 0) {
  //       setTeamDeatils(prevArray => prevArray.slice(0, -10));
  //     } else {
  //       setTeamDeatils(prevArray => prevArray.slice(0, -dataCount));
  //     }
  //   };

  const handleNavigate = data => {
    const NavigateRoute =
      params?.sportId == 12
        ? `/sport/statistics/${params?.sportId}/${
            params?.tournamentId
          }/league-data/${data?.team?.id}/team-data?seasonId=${
            SeasonId ? SeasonId : ""
          }`
        : `/sport/statistics/${params?.sportId}/${
            params?.tournamentId
          }/league-data/${data?.id}/team-data?seasonId=${
            SeasonId ? SeasonId : ""
          }`;
    navigate(NavigateRoute);
  };
  return (
    <>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {SeasonId
                  ? headerData?.season?.name
                  : headerData?.tournament?.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="league-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  P - <span>Played</span> , W - <span>Win</span>, L -{" "}
                  <span>Loss</span>, D - <span>Draw</span>
                  {/* F - , A -{" "}
                  <span>Away</span>, PD - <span>Point Differential</span>, FPG -{" "}
                  <span>Fantasy Points per Game</span>, APG -{" "}
                  <span>Assists Points per Game</span>, Att% -{" "}
                  <span>Attempts</span> , Def% - <span>Defeated</span>{" "} */}
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  {SeasonId
                    ? headerData?.season?.year + " ladder"
                    : "Current ladder"}
                </Typography>
              </Box>
              <Box className="table-overlay">
                {loader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!loader && (
                  <>
                    {/* showing by default hide table data that's why camparing with 0 sports id */}
                    {Number(params?.sportId) === 4 ? (
                      <>
                        <TableContainer
                          id="details-table"
                          className="teamsport-data-table summary-table"
                        >
                          <Box className="overlay"></Box>
                          <Table
                            className="data-table"
                            style={{ minWidth: "max-content" }}
                          >
                            <TableHead className="table-sub-header">
                              <TableRow className="table-sub-head-row">
                                <StickyTableCell
                                  className={`${classes.head} sticky-head`}
                                ></StickyTableCell>

                                <TableCell className={classes.head} colSpan={4}>
                                  Overall
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableHead className="table-header">
                              <TableRow className="table-head-row ">
                                <StickyTableCell
                                  className={`${classes.head} table-head-first`}
                                >
                                  Teams
                                </StickyTableCell>

                                <TableCell className={classes.head}>
                                  M
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  D
                                </TableCell>
                                {/* <TableCell className={classes.head}>
                                  NR
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Pts
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  NRR
                                </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                              {teamDeatils?.length > 0 ? (
                                teamDeatils?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <StickyTableCell
                                          className={`${classes.cell} table-body-first cursor-pointer`}
                                          onClick={() => handleNavigate(item)}
                                        >
                                          {item?.name}
                                        </StickyTableCell>

                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.played ||
                                          item?.overAll?.played === 0
                                            ? item?.overAll?.played
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.win ||
                                          item?.overAll?.win === 0
                                            ? item?.overAll?.win
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.loss ||
                                          item?.overAll?.loss === 0
                                            ? item?.overAll?.loss
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.overAll?.draw ||
                                          item?.overAll?.draw === 0
                                            ? item?.overAll?.draw
                                            : "-"}
                                        </TableCell>
                                        {/* <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          -
                                        </TableCell> */}
                                        <TableCell
                                          className={classes.cell}
                                        ></TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={100}
                                    className="no-data-td"
                                  >
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* {teamDeatils?.length > 0 ? (
                          <Box className="view-more">
                            {setOffset + 10 === teamDeatils?.length ? (
                              <span
                                className="view-more-text"
                                onClick={() => handleMoreData()}
                              >
                                View More
                              </span>
                            ) : (
                              <></>
                            )}
                            {setOffset >= 10 && teamDeatils?.length > 10 ? (
                              <span
                                className="view-more-text view-lese-text"
                                onClick={() => handleLessData()}
                              >
                                View Less
                              </span>
                            ) : (
                              <></>
                            )}
                          </Box>
                        ) : (
                          <></>
                        )} */}
                      </>
                    ) : Number(params?.sportId) == 12 ? (
                      <>
                        <TableContainer
                          id="details-table"
                          className="teamsport-data-table summary-table"
                        >
                          <Box className="overlay"></Box>
                          <Table
                            className="data-table"
                            style={{ minWidth: 940 }}
                          >
                            <TableHead className="table-sub-header">
                              <TableRow className="table-sub-head-row">
                                <StickyTableCell
                                  className={`${classes.head} sticky-head`}
                                ></StickyTableCell>
                                <TableCell className={classes.head} colSpan={4}>
                                  Home
                                </TableCell>
                                <TableCell className={classes.head} colSpan={4}>
                                  Away
                                </TableCell>
                                <TableCell className={classes.head} colSpan={4}>
                                  Overall
                                </TableCell>
                                {/* <TableCell className={classes.head} colSpan={3}>
                                  stats
                                </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableHead className="table-header">
                              <TableRow className="table-head-row ">
                                <StickyTableCell
                                  className={`${classes.head} table-head-first`}
                                >
                                  Teams
                                </StickyTableCell>
                                <TableCell className={classes.head}>
                                  P
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  D
                                </TableCell>
                                {/* <TableCell className={classes.head}>
                                  F
                                </TableCell>
                                <TableCell className={classes.head}>
                                  A
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  PD
                                </TableCell> */}
                                <TableCell className={classes.head}>
                                  P
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  D
                                </TableCell>
                                {/* <TableCell className={classes.head}>
                                  F
                                </TableCell>
                                <TableCell className={classes.head}>
                                  A
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  PD
                                </TableCell> */}
                                <TableCell className={classes.head}>
                                  P
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  D
                                </TableCell>
                                {/* <TableCell className={classes.head}>
                                  Bye
                                </TableCell>
                                <TableCell className={classes.head}>
                                  F
                                </TableCell>
                                <TableCell className={classes.head}>
                                  A
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Pts
                                </TableCell>
                                <TableCell className={classes.head}>
                                  PD
                                </TableCell>
                                <TableCell className={classes.head}>
                                  FPG
                                </TableCell>
                                <TableCell className={classes.head}>
                                  APG
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Att%
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Def%
                                </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                              {teamDeatils?.length > 0 ? (
                                teamDeatils?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <StickyTableCell
                                          className={`${classes.cell} table-body-first cursor-pointer`}
                                          onClick={() => handleNavigate(item)}
                                        >
                                          {item?.team?.name}
                                        </StickyTableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.matches ||
                                          item?.home?.matches === 0
                                            ? item?.home?.matches
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.wins ||
                                          item?.home?.wins === 0
                                            ? item?.home?.wins
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.losses ||
                                          item?.home?.losses === 0
                                            ? item?.home?.losses
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.home?.draws ||
                                          item?.home?.draws === 0
                                            ? item?.home?.draws
                                            : "-"}
                                        </TableCell>
                                        {/* <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          -
                                        </TableCell> */}
                                        <TableCell className={classes.cell}>
                                          {item?.away?.matches ||
                                          item?.away?.matches === 0
                                            ? item?.away?.matches
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.away?.wins ||
                                          item?.away?.wins === 0
                                            ? item?.away?.wins
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.away?.losses ||
                                          item?.away?.losses === 0
                                            ? item?.away?.losses
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.away?.draws ||
                                          item?.away?.draws === 0
                                            ? item?.away?.draws
                                            : "-"}
                                        </TableCell>
                                        {/* <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          -
                                        </TableCell> */}
                                        <TableCell className={classes.cell}>
                                          {item?.overall?.matches ||
                                          item?.overall?.matches === 0
                                            ? item?.overall?.matches
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overall?.wins ||
                                          item?.overall?.wins === 0
                                            ? item?.overall?.wins
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overall?.losses ||
                                          item?.overall?.losses === 0
                                            ? item?.overall?.losses
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          {item?.overall?.draws ||
                                          item?.overall?.draws === 0
                                            ? item?.overall?.draws
                                            : "-"}
                                        </TableCell>
                                        {/* <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell> */}
                                        {/* <TableCell className={classes.cell}>
                                          - */}
                                        {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="att-data"></Box>
                                    </Box> */}
                                        {/* </TableCell>
                                        <TableCell className={classes.cell}>
                                          - */}
                                        {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="def-data"></Box>
                                    </Box> */}
                                        {/* </TableCell> */}
                                        <TableCell
                                          className={classes.cell}
                                        ></TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={100}
                                    className="no-data-td"
                                  >
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* {teamDeatils?.length > 0 ? (
                      <Box className="view-more">
                        {setOffset + 10 === teamDeatils?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {setOffset >= 10 && teamDeatils?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )} */}
                      </>
                    ) : (
                      <>
                        <TableContainer
                          id="details-table"
                          className="teamsport-data-table summary-table"
                        >
                          <Box className="overlay"></Box>
                          <Table
                            className="data-table"
                            style={{ minWidth: 940 }}
                          >
                            <TableHead className="table-sub-header">
                              <TableRow className="table-sub-head-row">
                                <StickyTableCell
                                  className={`${classes.head} sticky-head`}
                                ></StickyTableCell>
                                <TableCell className={classes.head} colSpan={7}>
                                  Home
                                </TableCell>
                                <TableCell className={classes.head} colSpan={7}>
                                  Away
                                </TableCell>
                                <TableCell
                                  className={classes.head}
                                  colSpan={11}
                                >
                                  Overall
                                </TableCell>
                                <TableCell className={classes.head} colSpan={3}>
                                  stats
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableHead className="table-header">
                              <TableRow className="table-head-row ">
                                <StickyTableCell
                                  className={`${classes.head} table-head-first`}
                                >
                                  Teams
                                </StickyTableCell>
                                <TableCell className={classes.head}>
                                  P
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell className={classes.head}>
                                  D
                                </TableCell>
                                <TableCell className={classes.head}>
                                  F
                                </TableCell>
                                <TableCell className={classes.head}>
                                  A
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  PD
                                </TableCell>
                                <TableCell className={classes.head}>
                                  P
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell className={classes.head}>
                                  D
                                </TableCell>
                                <TableCell className={classes.head}>
                                  F
                                </TableCell>
                                <TableCell className={classes.head}>
                                  A
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right`}
                                >
                                  PD
                                </TableCell>
                                <TableCell className={classes.head}>
                                  P
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell className={classes.head}>
                                  D
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Bye
                                </TableCell>
                                <TableCell className={classes.head}>
                                  F
                                </TableCell>
                                <TableCell className={classes.head}>
                                  A
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Pts
                                </TableCell>
                                <TableCell className={classes.head}>
                                  PD
                                </TableCell>
                                <TableCell className={classes.head}>
                                  FPG
                                </TableCell>
                                <TableCell className={classes.head}>
                                  APG
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Att%
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Def%
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                              {teamDeatils?.length > 0 ? (
                                teamDeatils?.map((item, i) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={i}
                                        className="table-body-row "
                                      >
                                        <StickyTableCell
                                          className={`${classes.cell} table-body-first cursor-pointer`}
                                          onClick={() => handleNavigate(item)}
                                        >
                                          {item?.name}
                                        </StickyTableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.played ||
                                          item?.home?.played === 0
                                            ? item?.home?.played
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.win ||
                                          item?.home?.win === 0
                                            ? item?.home?.win
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.loss ||
                                          item?.home?.loss === 0
                                            ? item?.home?.loss
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.home?.draw ||
                                          item?.home?.draw === 0
                                            ? item?.home?.draw
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.away?.played ||
                                          item?.away?.played === 0
                                            ? item?.away?.played
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.away?.win ||
                                          item?.away?.win === 0
                                            ? item?.away?.win
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.away?.loss ||
                                          item?.away?.loss === 0
                                            ? item?.away?.loss
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.away?.draw ||
                                          item?.away?.draw === 0
                                            ? item?.away?.draw
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell
                                          className={`${classes.cell} border-right`}
                                        >
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.played ||
                                          item?.overAll?.played === 0
                                            ? item?.overAll?.played
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.win ||
                                          item?.overAll?.win === 0
                                            ? item?.overAll?.win
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.loss ||
                                          item?.overAll?.loss === 0
                                            ? item?.overAll?.loss
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          {item?.overAll?.draw ||
                                          item?.overAll?.draw === 0
                                            ? item?.overAll?.draw
                                            : "-"}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                          {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="att-data"></Box>
                                    </Box> */}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                          -
                                          {/* <Box className="percentage-data">
                                      <span className="line"></span>
                                      <Box className="def-data"></Box>
                                    </Box> */}
                                        </TableCell>
                                        <TableCell
                                          className={classes.cell}
                                        ></TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={100}
                                    className="no-data-td"
                                  >
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* {teamDeatils?.length > 0 ? (
                      <Box className="view-more">
                        {setOffset + 10 === teamDeatils?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {setOffset >= 10 && teamDeatils?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )} */}
                      </>
                    )}
                  </>
                )}
              </Box>
              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">Past Teams</Typography>
                <Typography className="past-team-name">
                  {pastTeam?.length > 0
                    ? pastTeam?.map((item, index) => {
                        return (
                          <>
                            <span
                              key={index}
                              onClick={() => handleNavigate(item)}
                              className="cursor-pointer"
                            >
                              {" "}
                              {item?.name}
                              {index !== pastTeam.length - 1 ? "," : ""}
                            </span>
                          </>
                        );
                      })
                    : "-"}
                </Typography>
              </Box> */}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default LeagueSummaryDetails;
