import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles,
  Tooltip
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Bet365 from "../../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../../assets/images/bookmakers/betstar-thumb.svg";
// import ViceBet from "../../../../assets/images/bookmakers/vice-bet-thumb.svg";
import BlueBet from "../../../../assets/images/bookmakers/BlueBet-thumb.png";
import TopSport from "../../../../assets/images/bookmakers/top-sport-thumb.svg";
import betFair from "../../../../assets/images/bookmakers/betfair-thumb.svg";
import BoomBet from "../../../../assets/images/bookmakers/BoomBet.png";
import LadBrokes from "../../../../assets/images/bookmakers/Ladbrokes-thumb.png";
import Draftstars from "../../../../assets/images/bookmakers/DraftStars.png";
import SouthernCrossBet from "../../../../assets/images/bookmakers/SouthernCrossBet.png";
import Thunder from "../../../../assets/images/Sport/thunder.png";
import Pistons from "../../../../assets/images/Sport/pistons.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const bettingTrendsData = [
  {
    id: 1,
    eventHeader: "ATS Trend of the Day - Monday 19/08/2022",
    matchup:
      "Matchup: Detroit Pistons vs. Oklahoma City Thunder (NBA League Pass, 8:00 p.m. ET)",
    matchUpDetails:
      "The Thunder are 8-0 ATS since November 25 coming off a loss where they were favored.",
    betType: "Spread",
    logo1: Pistons,
    name1: "Pistons",
    logo2: Thunder,
    name2: "Thunder",
    number1: "+9",
    number2: "-110"
  },
  {
    id: 2,
    eventHeader: "OU Trend of the Day",
    matchup:
      "Matchup: Utah Jazz vs. San Antonio Spurs (NBA League Pass, 8:00 p.m. ET)",
    matchUpDetails:
      "The Spurs are 13-0 OU as underdogs vs a team on a 4+ game losing streak.",
    betType: "Total",
    logo1: Pistons,
    name1: "Jazz",
    logo2: Thunder,
    name2: "Spurs",
    number1: "+9",
    number2: "-110"
  },
  {
    id: 3,
    eventHeader: "ATS Trend of the Day - Monday 19/08/2022",
    matchup:
      "Matchup: Utah Jazz vs. San Antonio Spurs (NBA League Pass, 8:00 p.m. ET)",
    matchUpDetails:
      "The Spurs are 13-0 OU as underdogs vs a team on a 4+ game losing streak.",
    betType: "Spread",
    logo1: Pistons,
    name1: "Kings",
    logo2: Thunder,
    name2: "Trail blazers",
    number1: "+9",
    number2: "-110"
  }
];

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    width: "350px",
    "@media(max-width: 1160px)": {
      maxWidth: "280px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    },
    "@media(max-width: 799px)": {
      maxWidth: "180px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    "@media(max-width: 799px)": {
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const BettingTrends = () => {
  const classes = useStyles();
  const [scrollX, setscrollX] = useState("left");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const oddsicon = ApiProviderId => {
    if (ApiProviderId === 1) {
      return;
    } else if (ApiProviderId === 2) {
      return (
        <img
          className="bookmaker-thumb not-clickable"
          src={Neds}
          alt="Odds Icon"
        />
      );
    } else if (ApiProviderId === 3) {
      return (
        <img
          className="bookmaker-thumb not-clickable"
          src={LadBrokes}
          alt="Odds Icon"
        />
      );
    } else if (ApiProviderId === 4) {
      return (
        <img
          className="bookmaker-thumb not-clickable"
          src={BookMaker}
          alt="Odds Icon"
        />
      );
    } else if (ApiProviderId === 5) {
      return (
        <img
          className="bookmaker-thumb not-clickable"
          src={BetStar}
          alt="Odds Icon"
        />
      );
    } else if (ApiProviderId === 6) {
      return (
        <a
          href="https://www.bet365.com/olp/open-account?affiliate=365_02038070"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={Bet365} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 7) {
      return;
    } else if (ApiProviderId === 8) {
      return (
        <a
          href="https://www.unibet.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={UniBet} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 9) {
      return (
        <a
          href="https://record.affiliates.playup.com/_IZoOYUcz-mj6PBA04iUMN2Nd7ZgqdRLk/1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={Draftstars} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 10) {
      return (
        <a
          href="https://www.betfair.com.au/extensions/redir/NA/108/PI-660691"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={betFair} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 11) {
      return (
        <a
          href="https://www.topsport.com.au/?promocode=SmartB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={TopSport} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 12) {
      return (
        <a
          href="https://record.affiliates.playup.com/_IZoOYUcz-mhhg6WO2I1rgWNd7ZgqdRLk/1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={PlayUp} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 13) {
      return (
        <a
          href="https://record.bluebetaffiliates.com.au/_-h3CNTRAkkuXrZzxC638nWNd7ZgqdRLk/1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={BlueBet} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 14) {
      return (
        <a
          href="https://www.boombet.com.au/?Referrer=SmartB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="bookmaker-thumb" src={BoomBet} alt="Odds Icon" />
        </a>
      );
    } else if (ApiProviderId === 15) {
      return (
        <a
          href="https://www.southerncrossbet.com.au/?referrer=SmartB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={SouthernCrossBet}
            alt="Odds Icon"
          />
        </a>
      );
    } else {
      return;
    }
  };
  const SlideTable = (direction, id) => {
    setscrollX(direction);
    var container = document.getElementById(`details-table-${id}`);
    var scrollCompleted = 0;
    var slideVar = setInterval(function() {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };
  return (
    <>
      {bettingTrendsData?.map((item, index) => {
        return (
          <>
            <Box className="betting-trend-table-wrap">
              <Box className="table-header-wrap">
                <Box className="herder-border">
                  <Typography variant="h1">{item?.eventHeader}</Typography>
                </Box>
                <Typography variant="h6">{item?.matchup}</Typography>
                <Typography variant="p">{item?.matchUpDetails}</Typography>
              </Box>
              <Box className="table-wrap">
                <TableContainer
                  id={`details-table-${index}`}
                  className="details-table"
                >
                  {screenWidth > 1180 ? (
                    " "
                  ) : (
                    <Box className="arrow-wrap">
                      <span
                        className={scrollX == "right" ? "arrow" : "disable"}
                        onClick={() => SlideTable("left", index)}
                      >
                        <ChevronLeftIcon />
                      </span>

                      <span
                        className={scrollX == "left" ? "arrow" : "disable"}
                        onClick={() => SlideTable("right", index)}
                      >
                        <ChevronRightIcon />
                      </span>
                    </Box>
                  )}

                  <Table className="table-wrap" style={{ minWidth: 940 }}>
                    <TableHead>
                      <TableRow className="table-head-row ">
                        <StickyTableCell className={`${classes.head} pl-33`}>
                          <Box className="d-flex-col">
                            <span className="bet-type-name">
                              {item?.betType}
                            </span>
                            <span className="bet-type">Bet Type</span>
                          </Box>
                        </StickyTableCell>
                        <TableCell className={classes.head}>
                          {" "}
                          {oddsicon(6)}
                        </TableCell>
                        <TableCell className={classes.head}>
                          {" "}
                          {oddsicon(8)}
                        </TableCell>
                        <TableCell className={classes.head}>
                          {" "}
                          {oddsicon(4)}
                        </TableCell>
                        <TableCell className={classes.head}>
                          {" "}
                          {oddsicon(2)}
                        </TableCell>
                        <TableCell className={classes.head}>
                          {" "}
                          {oddsicon(12)}
                        </TableCell>
                        <TableCell className={classes.head}>
                          {" "}
                          {oddsicon(5)}
                        </TableCell>
                        <TableCell className={`${classes.head} pr-33`}>
                          {" "}
                          {oddsicon(6)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StickyTableCell
                          className={`${classes.cell} team-wrap-cell pl-33`}
                        >
                          <Box className="team-wrap">
                            <span className="team-img">
                              <img src={item?.logo1} alt="Logo" />
                            </span>
                            <Box className="team-name">
                              <span className="team-event">{item?.name1}</span>
                            </Box>
                          </Box>
                        </StickyTableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number  d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={`${classes.cell} pr-33`}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number bet-number-orange d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow className="opposite-team-row">
                        <StickyTableCell
                          className={`${classes.cell} team-wrap-cell opposite-team-cell pl-33`}
                        >
                          <Box className="team-wrap">
                            <span className="team-img">
                              <img src={item?.logo2} alt="Logo" />
                            </span>
                            <Box className="team-name">
                              <span className="team-event">{item?.name2}</span>
                            </Box>
                          </Box>
                        </StickyTableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number bet-number-orange d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={`${classes.cell} pr-33`}>
                          {" "}
                          <Tooltip
                            title="Bet Now"
                            placement="top"
                            className="tooltip-bet"
                          >
                            <Box className="bet-number d-flex-col">
                              <span className="bet-number-1">
                                {item?.number1}
                              </span>
                              <span className="bet-number-2">
                                {item?.number2}
                              </span>
                            </Box>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </>
        );
      })}
    </>
  );
};

export default BettingTrends;
