import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "src/components/Loader";
import {} from "react";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const TableContent = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  }
];

const StickyTableCell = withStyles(theme => ({
  head: {
    right: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    // minWidth: "20px",
    // boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 1280px)": {
      maxWidth: "20px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    right: 0,
    position: "sticky",
    background: "#FFFFFF",
    // boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 1280px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const CoachSummaryDetails = ({ headerData, loader, coachTournamentData }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const classes = useStyles();
  const [coachloader, setCoachLoader] = useState(false);
  const [coachSummaryTeam, setCoachSummaryTeam] = useState([]);

  //   const handleNavigate = data => {
  //     const stateToPass = {
  //       ListDataPage: data?.id,
  //       listPage: true
  //       // Add your state data here
  //     };
  //     navigate(
  //       `/sport/statistics/${params?.sportId}/${params?.tournamentId}/league-data/${params?.teamId}/team-data`,
  //       {
  //         state: stateToPass
  //       }
  //     );
  //   };

  useEffect(() => {
    if (coachTournamentData?.length > 0) {
      const tournamentIds = coachTournamentData?.map(item => ({
        id: item?.id,
        name: item?.name
      }));
      fetchCoachSummaryDetailsByTeam(tournamentIds);
    }
  }, [params, coachTournamentData, SeasonId]);

  const fetchCoachSummaryDetailsByTeam = async tournamentIds => {
    setCoachLoader(true);
    try {
      const requests = tournamentIds?.map(async tournamentId => {
        const { data } = await axiosInstance.get(
          `/sports/statistics/coach/summary/team/${params?.playerId}?SportId=${
            params?.sportId
          }&tournamentId=${tournamentId?.id}&seasonId=${
            SeasonId ? SeasonId : ""
          }`
        );
        return {
          id: tournamentId?.id,
          name: tournamentId?.name,
          result: data?.result
        };
      });
      const results = await Promise.all(requests);
      setCoachLoader(false);
      setCoachSummaryTeam(results);
    } catch (err) {
      setCoachLoader(false);
    }
  };

  return (
    <>
      <Box className="teamsport-data-accordian league-data-details mb-18">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Coaching Career Statistics</Typography>
            </AccordionSummary>
            <AccordionDetails className="team-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  App - <span>Starting Appearances</span>, T -{" "}
                  <span>Try/Penalty Goal</span>, GK% - , FG -{" "}
                  <span>Field Goal/Drop Goal</span>, Pts - <span>Points</span>,
                  L - <span>Loss</span>, W - <span>Win</span>, D -{" "}
                  <span>Draw</span>
                </Typography>
              </Box>
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Competitions</Typography>
              </Box>
              {loader && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!loader && (
                <>
                  <Typography className="note" variant="p">
                    To view more info on a match, click the <span>List</span>{" "}
                    button.
                  </Typography>
                  <Box className="table-overlay">
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table team-sport-tables player-data-table"
                    >
                      {/* <Box className="overlay"></Box> */}
                      <Table className="data-table" style={{ minWidth: 940 }}>
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell
                              className={`${classes.head} first-cell border-right w45 `}
                            >
                              Competition
                            </TableCell>
                            <TableCell className={classes.head}>
                              Games
                            </TableCell>
                            <TableCell className={classes.head}>W</TableCell>
                            <TableCell className={classes.head}>L</TableCell>
                            <TableCell className={classes.head}>D</TableCell>
                            <TableCell className={classes.head}>Win%</TableCell>
                            <StickyTableCell
                              className={classes.head}
                            ></StickyTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {coachTournamentData?.length > 0 ? (
                            <>
                              {coachTournamentData?.map((item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell light-blue cursor-pointer border-right`}
                                        onClick={() =>
                                          navigate(
                                            `/sport/statistics/${
                                              params?.sportId
                                            }/${
                                              item?.id
                                            }/league-data?seasonId=${
                                              SeasonId ? SeasonId : ""
                                            }`
                                          )
                                        }
                                      >
                                        {item?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.game ? item?.game : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.W ? item?.W : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.L ? item?.L : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.D ? item?.D : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.win ? item?.win : "-"}
                                      </TableCell>
                                      <StickyTableCell className={classes.cell}>
                                        <span
                                          className="view-btn cursor-pointer"
                                          //   onClick={() => handleNavigate(item)}
                                        >
                                          List
                                        </span>
                                      </StickyTableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={12} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              )}
              {/* Representative - Australia - By Team  */}

              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  Representative - Australia - By Team
                </Typography>
              </Box>

              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-data-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className="first-cell w30">
                          Competition
                        </TableCell>
                        <TableCell className="border-right w15">
                          Years
                        </TableCell>
                        <TableCell>Games</TableCell>
                        <TableCell>W</TableCell>
                        <TableCell>L</TableCell>
                        <TableCell>D</TableCell>
                        <TableCell>Win%</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell className="first-cell light-blue cursor-pointer ">
                                    NSW Reserve Grade
                                  </TableCell>
                                  <TableCell className="border-right">
                                    2016-19
                                  </TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>16.67%</TableCell>
                                  <TableCell>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>  */}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Club Career</Typography>
            </AccordionSummary>
            <AccordionDetails className="team-accordion-details">
              <Box className="short-name-details">
                <Typography className="short-name">
                  App - <span>Starting Appearances</span>, T -{" "}
                  <span>Try/Penalty Goal</span>, GK% - , FG -{" "}
                  <span>Field Goal/Drop Goal</span>, Pts - <span>Points</span>,
                  L - <span>Loss</span>, W -<span>Win</span>, D -{" "}
                  <span>Draw</span>
                </Typography>
              </Box>

              {/* NRL/NSWRL - By Year */}
              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  NRL/NSWRL - By Year
                </Typography>
              </Box>
              <Typography className="note" variant="p">
                To view more info on a match, click the <span>List</span>{" "}
                button.
              </Typography>
              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-career-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className="first-cell w30">Team</TableCell>
                        <TableCell className="border-right w15">
                          Season
                        </TableCell>
                        <TableCell>Games</TableCell>
                        <TableCell>W</TableCell>
                        <TableCell>L</TableCell>
                        <TableCell>D</TableCell>
                        <TableCell>Win%</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell className="first-cell light-blue cursor-pointer">
                                    Gold Coast
                                  </TableCell>
                                  <TableCell className="light-blue cursor-pointer border-right">
                                    NRL 2019
                                  </TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>16.67%</TableCell>
                                  <TableCell>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <TableRow className="table-body-row table-result-row">
                            <TableCell className="first-cell">
                              Overall
                            </TableCell>
                            <TableCell className="border-right">
                              2019-2023
                            </TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>
                              {" "}
                              <span className="view-btn cursor-pointer">
                                List
                              </span>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

              {/* NRL/NSWRL - By Team */}

              {coachloader && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!coachloader && coachSummaryTeam?.length > 0 ? (
                coachSummaryTeam?.map((item, index) => {
                  return (
                    <>
                      <Box className="teamsport-header-details">
                        <Typography className="heading-title">
                          {item?.name} - By Team
                        </Typography>
                      </Box>

                      <Box className="table-overlay">
                        <TableContainer
                          id="details-table"
                          className="teamsport-data-table team-sport-tables player-career-table"
                        >
                          {/* <Box className="overlay"></Box> */}
                          <Table
                            className="data-table"
                            style={{ minWidth: 940 }}
                          >
                            <TableHead className="table-header">
                              <TableRow className="table-head-row ">
                                <TableCell
                                  className={`${classes.head} first-cell w30`}
                                >
                                  Team
                                </TableCell>
                                <TableCell
                                  className={`${classes.head} border-right w15`}
                                >
                                  Season
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Games
                                </TableCell>
                                <TableCell className={classes.head}>
                                  W
                                </TableCell>
                                <TableCell className={classes.head}>
                                  L
                                </TableCell>
                                <TableCell className={classes.head}>
                                  D
                                </TableCell>
                                <TableCell className={classes.head}>
                                  Win%
                                </TableCell>
                                <TableCell className={classes.head}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                              {item?.result?.teamList?.length > 0 ? (
                                <>
                                  {item?.result?.teamList?.map((obj, i) => {
                                    return (
                                      <>
                                        <TableRow
                                          key={i}
                                          className="table-body-row "
                                        >
                                          <TableCell
                                            className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                            onClick={() =>
                                              navigate(
                                                `/sport/statistics/${
                                                  params?.sportId
                                                }/${
                                                  params?.tournamentId
                                                }/league-data/${
                                                  obj?.teamId
                                                }/team-data?seasonId=${
                                                  SeasonId ? SeasonId : ""
                                                }`
                                              )
                                            }
                                          >
                                            {obj?.name ? obj?.name : "-"}
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.cell} light-blue cursor-pointer border-right`}
                                          >
                                            {obj?.year ? obj?.year : ""}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {" "}
                                            {obj?.game || obj?.game === 0
                                              ? obj?.game
                                              : "-"}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {obj?.W || obj?.W === 0
                                              ? obj?.W
                                              : "-"}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {obj?.L || obj?.L === 0
                                              ? obj?.L
                                              : "-"}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {obj?.D || obj?.D === 0
                                              ? obj?.D
                                              : "-"}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            {obj?.win || obj?.win === 0
                                              ? obj?.win
                                              : "-"}
                                          </TableCell>
                                          <TableCell className={classes.cell}>
                                            <span className="view-btn cursor-pointer">
                                              List
                                            </span>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })}
                                  <TableRow className="table-body-row table-result-row">
                                    <TableCell
                                      className={`${classes.cell} first-cell`}
                                    >
                                      {item?.result?.overAll?.name}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} border-right`}
                                    >
                                      {item?.result?.overAll?.year}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.result?.overAll?.game ||
                                      item?.result?.overAll?.game === 0
                                        ? item?.result?.overAll?.game
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.result?.overAll?.W ||
                                      item?.result?.overAll?.W === 0
                                        ? item?.result?.overAll?.W
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.result?.overAll?.L ||
                                      item?.result?.overAll?.L === 0
                                        ? item?.result?.overAll?.L
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.result?.overAll?.D ||
                                      item?.result?.overAll?.D === 0
                                        ? item?.result?.overAll?.D
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                      {item?.result?.overAll?.win ||
                                      item?.result?.overAll?.win === 0
                                        ? item?.result?.overAll?.win
                                        : "-"}
                                    </TableCell>

                                    {/* <StickyTableCell className={classes.cell}>
                                      {" "}
                                      <span className="view-btn cursor-pointer">
                                        List
                                      </span>
                                    </StickyTableCell> */}
                                  </TableRow>
                                </>
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={12}
                                    className="no-data-td"
                                  >
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </>
                  );
                })
              ) : (
                <Box className="no-data-wrap">
                  <Typography className="text-align-center no-data">
                    No data Available
                  </Typography>
                </Box>
              )}

              {/* English Career - By Year */}

              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  English Career - By Year
                </Typography>
              </Box>

              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-career-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className="first-cell w30">Team</TableCell>
                        <TableCell className="border-right w15">
                          Season
                        </TableCell>
                        <TableCell>Games</TableCell>
                        <TableCell>W</TableCell>
                        <TableCell>L</TableCell>
                        <TableCell>D</TableCell>
                        <TableCell>Win%</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell className="first-cell light-blue cursor-pointer">
                                    Gold Coast
                                  </TableCell>
                                  <TableCell className="light-blue cursor-pointer border-right">
                                    NRL 2019
                                  </TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>16.67%</TableCell>
                                  <TableCell>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <TableRow className="table-body-row table-result-row">
                            <TableCell className="first-cell">
                              Overall
                            </TableCell>
                            <TableCell className="border-right">
                              2019-2023
                            </TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>[total]</TableCell>
                            <TableCell>
                              {" "}
                              <span className="view-btn cursor-pointer">
                                List
                              </span>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

              {/* English Career - By Team */}

              {/* <Box className="teamsport-header-details">
                <Typography className="heading-title">
                  English Career - By Team
                </Typography>
              </Box>

              <Box className="table-overlay">
                <TableContainer
                  id="details-table"
                  className="teamsport-data-table team-sport-tables player-career-table"
                >
                  {/* <Box className="overlay"></Box> /}
                  <Table className="data-table" style={{ minWidth: 940 }}>
                    <TableHead className="table-header">
                      <TableRow className="table-head-row ">
                        <TableCell className="first-cell w30">Team</TableCell>
                        <TableCell className="border-right w15">
                          Season
                        </TableCell>
                        <TableCell>Games</TableCell>
                        <TableCell>W</TableCell>
                        <TableCell>L</TableCell>
                        <TableCell>D</TableCell>
                        <TableCell>Win%</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {TableContent?.length > 0 ? (
                        <>
                          {TableContent?.map((item, i) => {
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <TableCell className="first-cell light-blue cursor-pointer">
                                    Gold Coast
                                  </TableCell>
                                  <TableCell className="light-blue cursor-pointer border-right">
                                    NRL 2019
                                  </TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>3</TableCell>
                                  <TableCell>16.67%</TableCell>
                                  <TableCell>
                                    <span className="view-btn cursor-pointer">
                                      List
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} className="no-data-td">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default CoachSummaryDetails;
