export const HomePageListing = [
  {
    id: 1,
    key: "featured-news",
    items: [
      {
        title: "Featured News RACING",
        id: 1,
        isAdded: false,
        key: "featured-news-racing"
      },
      {
        title: "Featured News SPORTS",
        id: 2,
        isAdded: false,
        key: "featured-news-sports"
      }
    ]
  },
  {
    id: 2,
    title: "SmartB's Tip of the Day RACING",
    key: "smartb-tipoftheday-racing",
    isAdded: false
  },
  {
    id: 3,
    title: "Next to Jump RACING",
    key: "upcoming-races",
    isAdded: false
  },
  {
    id: 4,
    title: "Upcoming Fixtures SPORTS",
    key: "upcoming-fixtures-sports",
    isAdded: false
  },
  {
    id: 5,
    title: "Latest Results SPORTS",
    key: "latest-results-sports",
    isAdded: false
  },
  {
    id: 6,
    title: "Featured Fixtures SPORTS",
    key: "featured-fixtures-sports",
    isAdded: false
  },
  {
    id: 7,
    title: "Featured Races RACING",
    key: "featured-races-racing",
    isAdded: false
  },
  {
    id: 8,
    key: "free-expert-tips",
    items: [
      {
        title: "Free Expert Tips RACING",
        id: 1,
        isAdded: false,
        key: "free-expert-tips-racing"
      },
      {
        title: "Free Experts Tips SPORTS",
        id: 2,
        isAdded: false,
        key: "free-expert-tips-sport"
      }
    ]
  },
  {
    id: 9,
    title: "Tipping Comps Rankings SPORTS",
    key: "tipping-comps-rankings-sports",
    isAdded: false
  },
  {
    id: 10,
    title: "Tipping Comps SPORTS",
    key: "tipping-comps-sports",
    isAdded: false
  },
  {
    id: 11,
    key: "featured-podcasts",
    items: [
      {
        title: "Featured Podcasts RACING",
        id: 1,
        isAdded: false,
        key: "featured-podcasts-racing"
      },
      {
        title: "Featured Podcasts SPORTS",
        id: 2,
        isAdded: false,
        key: "featured-podcasts-sports"
      }
    ]
  }
];

export const SportPageListing = [
  {
    id: 1,
    title: "Sports News",
    key: "sports-news",
    isAdded: false
  },
  {
    id: 2,
    title: "Upcoming Fixtures AMERICAN FOOTBALL",
    key: "upcoming-fixtures-american-football",
    isAdded: false
  },
  {
    id: 3,
    title: "Upcoming Fixtures AUSTRALIAN RULES",
    key: "upcoming-fixtures-australian-rules",
    isAdded: false
  },
  {
    id: 4,
    title: "Upcoming Fixtures BASKETBALL",
    key: "upcoming-fixtures-basketball",
    isAdded: false
  },
  {
    id: 5,
    title: "Upcoming Fixtures BASEBALL",
    key: "upcoming-fixtures-Baseball",
    isAdded: false
  },
  {
    id: 6,
    title: "Upcoming Fixtures BOXING",
    key: "upcoming-fixtures-boxing",
    isAdded: false
  },

  {
    id: 7,
    title: "Upcoming Fixtures CRICKET",
    key: "upcoming-fixtures-cricket",
    isAdded: false
  },
  {
    id: 8,
    title: "Upcoming Fixtures GOLF",
    key: "upcoming-fixtures-golf",
    isAdded: false
  },
  {
    id: 9,
    title: "Upcoming Fixtures ICE HOCKEY",
    key: "upcoming-fixtures-ice-hockey",
    isAdded: false
  },
  {
    id: 10,
    title: "Upcoming Fixtures MIXED MARTIAL ARTS",
    key: "upcoming-fixtures-mixed-martial-arts",
    isAdded: false
  },
  {
    id: 11,
    title: "Upcoming Fixtures RUGBY LEAGUE",
    key: "upcoming-fixtures-rugby-league",
    isAdded: false
  },
  {
    id: 12,
    title: "Upcoming Fixtures RUGBY UNION",
    key: "upcoming-fixtures-rugby-union",
    isAdded: false
  },
  {
    id: 13,
    title: "Upcoming Fixtures SOCCER",
    key: "upcoming-fixtures-soccer",
    isAdded: false
  },
  {
    id: 14,
    title: "Upcoming Fixtures TENNIS",
    key: "upcoming-fixtures-tennis",
    isAdded: false
  },
  {
    id: 15,
    title: "Latest Results SPORTS",
    key: "latest-results-sports",
    isAdded: false
  },
  {
    id: 16,
    title: "Featured Fixtures SPORTS",
    key: "featured-fixtures-sports",
    isAdded: false
  },
  {
    id: 17,
    title: "Free Experts Tips SPORTS",
    key: "free-expert-tips-sport",
    isAdded: false
  },
  {
    id: 18,
    title: "Tipping Comps SPORTS",
    key: "tipping-comps-sports",
    isAdded: false
  },
  {
    id: 19,
    title: "Tipping Comps Rankings SPORTS",
    key: "tipping-comps-rankings-sports",
    isAdded: false
  },
  {
    id: 20,
    title: "Featured Podcasts SPORTS",
    key: "featured-podcasts-sports",
    isAdded: false
  }
];

export const RacingPageListing = [
  {
    id: 1,
    title: "Featured News RACING",
    isAdded: false,
    key: "featured-news-racing"
  },
  {
    id: 2,
    title: "SmartB's Tip of the Day RACING",
    key: "smartb-tipoftheday-racing",
    isAdded: false
  },
  {
    id: 3,
    title: "Next to Jump RACING",
    key: "upcoming-races",
    isAdded: false
  },
  {
    id: 4,
    title: "Featured Races RACING",
    key: "featured-races-racing",
    isAdded: false
  },
  {
    id: 5,
    title: "Free Expert Tips RACING",
    key: "free-expert-tips-racing",
    isAdded: false
  },
  {
    id: 6,
    title: "Horse Racing News",
    key: "horse-racing-news",
    isAdded: false
  },
  {
    id: 7,
    title: "Greyhound Racing News",
    key: "greyhound-racing-news",
    isAdded: false
  },
  {
    id: 8,
    title: "Harness Racing News",
    key: "harness-racing-news",
    isAdded: false
  },
  {
    id: 9,
    title: "Featured Podcasts RACING",
    isAdded: false,
    key: "featured-podcasts-racing"
  }
];
