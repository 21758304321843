import { Box, Typography } from "@material-ui/core";
import React from "react";
import Imgcutout from "../../../../../../assets/images/imgcutoutsquare.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function GalleryInfoPage() {
  return (
    <Box className="galleryinfo-wrap">
      <Box className="gallery-wrap">
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
        <Box className="gallery-detail-wrap">
          <Typography variant="h4" className="gallery-header">
            Lorem ipsum dolor sit amet
          </Typography>
          <Box className="gallery-img-wrap">
            <img src={Imgcutout} alt="gallery-img" />
          </Box>
          <Box className="gallery-text-wrap">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GalleryInfoPage;
