import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Breadcrumbs,
  Button
} from "@material-ui/core";
import banner from "src/assets/images/banner/smart-b-banner-primary.png";
import AdvertisingBannner from "../advertisingBanner";
import { Link, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as UserBlock } from "src/assets/images/icons/user-block.svg";
import { ReactComponent as UserFollow } from "src/assets/images/icons/user-follow.svg";
import { ReactComponent as UserUnblock } from "src/assets/images/icons/user-block-blue.svg";
import { ReactComponent as SportsWhite } from "src/assets/images/sports-white.svg";
import { ReactComponent as SportsBlue } from "src/assets/images/sports-blue.svg";
import { ReactComponent as RaceBlue } from "src/assets/images/racing-blue.svg";
import { ReactComponent as RaceWhite } from "src/assets/images/racing-white.svg";
import Select, { components } from "react-select";
import TipperProfile from "src/assets/images/tipper-profile.png";
import AdBannner from "../AdBanner/AdBanner";
import { Check } from "@material-ui/icons";
import TippingTab from "./Tipping";
import WallTab from "./Wall";
import ActivityTab from "./Activity";
import DetailsTab from "./Details";
import TipperChatModal from "../UI/tipperChatModal";
import TipperRatingModal from "../UI/tipperRatingModal";
import { Rating } from "@material-ui/lab";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
import "./tipperProfile.scss";

const tabsData = [
  { id: 0, name: "Wall" },
  { id: 1, name: "Tipping" },
  { id: 2, name: "Activity" },
  { id: 3, name: "About" }
];
const typeData = [
  {
    id: 0,
    name: "sports",
    icon: (
      <span>
        <SportsBlue />
      </span>
    ),
    activeicon: (
      <span>
        <SportsWhite />
      </span>
    )
  },
  {
    id: 1,
    name: "racing",
    icon: (
      <span>
        <RaceBlue />
      </span>
    ),
    activeicon: (
      <span>
        <RaceWhite />
      </span>
    )
  }
];

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TipperProfilePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const tabsRef = useRef(null);
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(0);
  const [typevalue, setTypeValue] = useState(0);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [tippperRatingInfo, setTippperRatingInfo] = useState(null);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [openTipperRatingModal, setOpenTipperRatingModal] = useState(false);
  const [raceTipData, setRaceTipData] = useState({});
  const [sportTipData, setSportTipData] = useState({});

  const limit = 20;
  const [filter, setFilter] = useState("day");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState(null);
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [selectedVenues, setSelectedVenues] = useState("");
  const [orgAll, setOrgAll] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [countVen, setcountVen] = useState(0);
  const [countOrg, setcountOrg] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const [count, setcount] = useState(0);

  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [isTeamSelectOpen, setisTeamSelectOpen] = useState(false);
  const [searchTeam, setsearchTeam] = useState([]);
  const [searchTeamCount, setsearchTeamCount] = useState(0);
  const [SearchTeampage, setSearchTeampage] = useState(0);
  const [pageTeam, setpageTeam] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mentionUsersList, setMentionUsersList] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    getTipperRating();
    fetchSportData();
    getMentionUsersList();
  }, []);

  useEffect(() => {
    setTypeValue(params?.type === "sports" ? 0 : 1);
    getTipperRaceResultData(params?.type === "sports" ? "sport" : "race");
    // if (typevalue === 0) {
    getTipperSportTipdata();
    // } else {
    getTipperRaceTipdata();
    // }
  }, []);

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleTabChange = (event, value) => {
    if (value === 1) {
      if (handleRestrictedUser()) {
        setTabValue(value);
      } else {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
      }
    } else {
      setTabValue(value);
    }
  };

  const getTipperRaceResultData = async type => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/profile/activity/${Number(params?.id)}?type=${type}`
      );
      if (status === 200) {
        setLoading(false);
        setActivityData(data?.result);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const handleTypeTabChange = (event, value) => {
    // if (value === 1) {
    //   if (handleRestrictedUser()) {
    //     setTabValue(value);
    //   } else {
    //     dispatch(fetchRestrictedRoute(true));
    //     localStorage.setItem("prev_authroute", window.location.pathname);
    //   }
    // } else {
    setTypeValue(value);
    getTipperRaceResultData(value == 0 ? "sport" : "race");
    // }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const getTipperRating = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/profile/rating/${Number(params?.id)}`
      );
      if (status === 200) {
        setTippperRatingInfo(data?.result);
        setIsFollow(data?.result?.isFollowed === 0 ? false : true);
        setIsBlock(data?.result?.isBlocked === 0 ? false : true);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleOnScrollBottomTeams = () => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(SearchTeampage / 20)
    ) {
      handleTeamInputChange(SearchTeampage + 20, isTeamSearch, selectedSports);

      setSearchTeampage(SearchTeampage + 20);
    } else {
      if (count !== 0 && count !== Math.ceil(pageTeam / 20 + 1)) {
        fetchTeamData(pageTeam + 20, selectedSports, teamsAll, selectedOrg);
        setpageTeam(pageTeam + 20);
      }
    }
  };
  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, orgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };
  const handleTeamInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/team?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchTeam, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeam(filterData);
          // setsearchTeam(
          //   _.uniqBy(filterData, function(e) {
          //     return e.value;
          //   })
          // );
          setIsTeamSearch(value);
        }
      });
  };

  const fetchTeamData = async (page, sID, teamsAll, tournamentId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&offset=${page}&limit=${limit}&tournamentId=${tournamentId}`
      );
      if (status === 200) {
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(teamsAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Teams",
          value: 0
        });
        setTeamsAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const fetchOrgData = async (page, sID, orgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(orgAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0
        });
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        var sportsdata = data?.result.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "ALL", value: filterSports, isAll: true }],
          sdata
        );
        setSports(mergedArray);
      }
    } catch (err) {}
  };

  const getTipperRaceTipdata = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/race/user/tipInfo/${Number(
          params?.id
        )}?timezone=${timezone}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}`
      );
      if (status === 200) {
        setRaceTipData(data?.result);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const getTipperSportTipdata = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/sport/user/tipInfo/${Number(
          params?.id
        )}?timezone=${timezone}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}`
      );
      if (status === 200) {
        setSportTipData(data?.result);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleTipperFollow = async type => {
    // setIsFollow(type === "unfollow" ? false :  true);
    let payload = {
      type: type,
      toUserId: Number(params?.id)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `/user/follow`,
        payload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      } else {
      }
    } catch (err) {
      console.log("error", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  const handleTipperBlock = async type => {
    // setIsBlock(type === "unblock" ? false : true);
    let payload = {
      type: type,
      toUserId: Number(params?.id)
    };
    try {
      const { status, data } = await axiosInstance.post(`/user/block`, payload);
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      } else {
        console.log("else");
      }
    } catch (err) {
      console.log("error", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };

  // const handleFollowClick = () => {
  //   setIsFollow(!isFollow);
  // };
  // const handleBlockClick = () => {
  //   setIsBlock(!isBlock);
  // };

  const handleClose = () => {
    setOpenChatModal(false);
  };
  const handleTipperRatingModal = () => {
    setOpenTipperRatingModal(false);
  };

  // console.log(
  //   "Math.ceil(tippperRatingInfo?.tipperRate)",
  //   Math.ceil(tippperRatingInfo?.tipperRate)
  // );

  const getMentionUsersList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(`/post/users?search=`);
      if (status === 200) {
        setLoading(false);
        if (data?.result?.length > 0) {
          var userList = data?.result?.map(item => {
            return {
              id: item?.id,
              display: item?.firstName + " " + item?.lastName
            };
          });
          setMentionUsersList(userList);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="allracing-wrap">
          <Box className="side-space">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  Home
                </Link>
                <Link underline="hover" color="inherit" to="/">
                  Tips Marketplace
                </Link>
                {params?.type === "sports" ? (
                  <Link
                    underline="hover"
                    color="inherit"
                    to="/tips-marketplace/sports-tips"
                  >
                    Sports Tips
                  </Link>
                ) : (
                  <Link
                    underline="hover"
                    color="inherit"
                    to="/tips-marketplace/racing-tips"
                  >
                    Racing Tips
                  </Link>
                )}

                <Typography>
                  {tippperRatingInfo?.firstName} {tippperRatingInfo?.lastName}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box className="title-btn-sec">
              <Typography variant="h1">
                {tippperRatingInfo?.firstName} {tippperRatingInfo?.lastName}
              </Typography>
              {screenWidth > 1250 ? (
                <Box className="btn-sec">
                  <Button
                    className={
                      isFollow === true ? "unfollow-btn" : "follow-btn"
                    }
                    onClick={() => {
                      // handleFollowClick()
                      setIsFollow(!isFollow);
                      handleTipperFollow(isFollow ? "unfollow" : "follow");
                    }}
                    startIcon={
                      isFollow === true ? (
                        <Check sx={{ color: "#4455c7" }} />
                      ) : (
                        <UserFollow />
                      )
                    }
                  >
                    {isFollow === true ? "Following" : "Follow"}
                  </Button>
                  <Button
                    className={
                      isBlock === true
                        ? "unfollow-btn ml-20"
                        : "follow-btn ml-20"
                    }
                    onClick={() => {
                      setIsBlock(!isBlock);
                      handleTipperBlock(isBlock ? "unblock" : "block");
                    }}
                    startIcon={
                      isBlock === true ? <UserUnblock /> : <UserBlock />
                    }
                  >
                    {isBlock === true ? "Unblock" : "Block"}
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </Box>

            {screenWidth > 1250 ? (
              <Box className="follower-sec">
                <Typography className="detail">
                  {tippperRatingInfo?.following} <span>Following</span>
                </Typography>
                <Typography className="detail ml-15">
                  {tippperRatingInfo?.followers} <span>Followers</span>
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          {screenWidth < 1250 ? (
            <>
              <Box className="profile-btn-sec">
                <Box className="follower-profile-sec">
                  <Box className="profile-pic">
                    <img src={TipperProfile} alt="profile" />
                  </Box>
                  <Box className="follower-sec mb-0 mt-11">
                    <Typography className="detail">
                      {tippperRatingInfo?.following} <span>Following</span>
                    </Typography>
                    <Typography className="detail ml-15">
                      {tippperRatingInfo?.followers} <span>Followers</span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="btn-sec">
                  <Button
                    className={`w-121 ${
                      isFollow === true ? "unfollow-btn" : "follow-btn"
                    }`}
                    onClick={() => {
                      // handleFollowClick()
                      setIsFollow(!isFollow);
                      handleTipperFollow(isFollow ? "unfollow" : "follow");
                    }}
                    startIcon={
                      isFollow === true ? (
                        <Check sx={{ color: "#4455c7" }} />
                      ) : (
                        <UserFollow />
                      )
                    }
                  >
                    {isFollow === true ? "Following" : "Follow"}
                  </Button>
                  <Button
                    className={`mt-12 w-115 ${
                      isBlock === true ? "unfollow-btn" : "follow-btn"
                    }`}
                    onClick={() => {
                      setIsBlock(!isBlock);
                      handleTipperBlock(isBlock ? "unblock" : "block");
                    }}
                    startIcon={
                      isBlock === true ? <UserUnblock /> : <UserBlock />
                    }
                  >
                    {isBlock === true ? "Unblock" : "Block"}
                  </Button>
                  <Button
                    className="unfollow-btn mt-12 w-150"
                    onClick={() => setOpenChatModal(true)}
                  >
                    Send Message
                  </Button>
                </Box>
              </Box>

              <Box className="mobile-tipper-rating-sec">
                {tippperRatingInfo && (
                  <Box className="left-sec">
                    <Box className="rating-sec">
                      <Typography className="title">Tipper Ratings:</Typography>
                      <Box style={{ marginTop: "5px" }}>
                        <Rating
                          name="tipperRate"
                          defaultValue={
                            tippperRatingInfo && tippperRatingInfo?.tipperRate
                          }
                          precision={0.5}
                          className="rating"
                          readOnly
                        />
                      </Box>
                    </Box>
                    <Box className="rating-sec">
                      <Typography className="title">
                        Loyalty Ratings:
                      </Typography>
                      <Box style={{ marginTop: "5px" }}>
                        <Rating
                          name="loyaltyRate"
                          defaultValue={
                            tippperRatingInfo && tippperRatingInfo?.loyaltyRate
                          }
                          precision={0.5}
                          className="rating"
                          readOnly
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box className="rate-user-sec">
                  <Typography
                    className="learn-more"
                    onClick={() => setOpenTipperRatingModal(true)}
                  >
                    Rate user
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
          <Box className="sport-tab">
            <Tabs
              value={tabvalue}
              variant="scrollable"
              scrollButtons="off"
              indicatorColor="primary"
              textColor="primary"
              className="racing-tab-detail"
            >
              {tabsData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      disableRipple
                      disableFocusRipple
                      label={item?.name}
                      value={item?.id}
                      className={item?.id === tabvalue ? "active " : ""}
                      onChange={(event, newValue) =>
                        handleTabChange(event, item?.id)
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
            {screenWidth > 1250 ? (
              <Box className="profile-pic">
                <img src={TipperProfile} alt="profile" />
              </Box>
            ) : (
              <></>
            )}

            <Box className="Marketplace-select-wrap">
              {(tabvalue === 1 || tabvalue === 2) && (
                <Box>
                  <Tabs
                    value={typevalue}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    className="podcasts-tab-details"
                    // disableRipple
                    // disableFocusRipple
                    id="podcasts-tab"
                    ref={tabsRef}
                  >
                    {typeData?.map((item, index) => {
                      return (
                        <Box key={index}>
                          <Tab
                            disableRipple
                            disableFocusRipple
                            // label={item?.name}
                            label={
                              <Box className="tabicon-label-wrap">
                                <span className="hometab-icon">
                                  {item?.id == typevalue
                                    ? item?.activeicon
                                    : item?.icon}
                                </span>
                                <span
                                  className={` hometab-labe ${
                                    item?.id == typevalue
                                      ? "active-hometab-label"
                                      : ""
                                  }`}
                                >
                                  {item?.name}
                                </span>
                              </Box>
                            }
                            value={item?.id}
                            className={item?.id === typevalue ? "active " : ""}
                            onChange={(event, newValue) =>
                              handleTypeTabChange(event, item?.id)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Tabs>
                </Box>
              )}

              {typevalue === 0 ? (
                <Box className="filter-type-select">
                  {tabvalue === 1 && (
                    <>
                      <Select
                        className="React show-select"
                        value={sports?.find(item => {
                          return item?.value === selectedSports;
                        })}
                        onChange={e => {
                          setSelectedSports(e?.value == 0 ? "" : e?.value);
                          setCurrentPage(0);
                          setSelectedTeams(null);
                          setSelectedOrg(null);
                          setTeamsAll([]);
                          setOrgAll([]);
                          if (!e?.isAll) {
                            fetchOrgData(0, e?.value, []);
                            // fetchTeamData(0, e?.value, []);
                          }
                        }}
                        // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                        options={sports}
                        classNamePrefix="select"
                        placeholder="All Sports"
                        // isSearchable={false}
                        // onFocus={() => setTournamentSelect(true)}
                        // onBlur={() => setTournamentSelect(false)}
                        components={{ DropdownIndicator }}
                      />
                      <Select
                        className={
                          selectedSports && selectedSports.length > 0
                            ? `React country-select disable-state`
                            : `React country-select`
                        }
                        onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
                        onInputChange={e =>
                          handleOrgInputChange(0, e, selectedSports)
                        }
                        onChange={e => {
                          setSelectedOrg(e?.value);
                          // fetchOrgData(e?.value, selectedSports, orgAll);
                          e?.value !== 0 &&
                            fetchTeamData(0, selectedSports, [], e?.value);
                          setSelectedTeams(null);
                          setTeamsAll([]);
                          setOrgApiCount(0);
                          setpageOrg(0);
                          setisOrgSelectOpen(false);
                          setCurrentPage(0);
                        }}
                        onFocus={() => setisOrgSelectOpen(true)}
                        onBlur={() => setisOrgSelectOpen(false)}
                        value={
                          selectedOrg &&
                          (isOrgSearch
                            ? searchOrg?.find(item => {
                                return item?.value == selectedOrg;
                              })
                            : orgAll?.find(item => {
                                return item?.value == selectedOrg;
                              }))
                        }
                        options={isOrgSearch ? searchOrg : orgAll}
                        classNamePrefix="select"
                        placeholder="Tournament: All"
                        isDisabled={
                          selectedSports && selectedSports.length > 0
                            ? true
                            : false
                        }
                        components={{ DropdownIndicator }}
                      />
                      <Select
                        className={
                          selectedOrg
                            ? `React State-select`
                            : `React State-select disable-state`
                        }
                        // options={teamsAll}
                        onMenuScrollToBottom={e => handleOnScrollBottomTeams(e)}
                        onInputChange={e =>
                          handleTeamInputChange(0, e, selectedSports)
                        }
                        onChange={e => {
                          setSelectedTeams(e?.value);
                          // fetchTeamData(e?.value, selectedSports, teamsAll);
                          setTeamApiCount(0);
                          setpageTeam(0);
                          setHasMore(false);
                          setisTeamSelectOpen(false);
                          setCurrentPage(0);
                          // if (tabvalue === 0) {
                          //   fetchUpcomingBlackBook(
                          //     selectedOrg,
                          //     e?.value,
                          //     upcomingPage
                          //   );
                          // } else if (tabvalue === 1) {
                          //   fetchResultBlackBook(
                          //     selectedOrg,
                          //     e?.value,
                          //     resultPage
                          //   );
                          // }
                        }}
                        onFocus={() => setisTeamSelectOpen(true)}
                        onBlur={() => setisTeamSelectOpen(false)}
                        value={
                          selectedTeams &&
                          (isTeamSearch
                            ? searchTeam?.find(item => {
                                return item?.value == selectedTeams;
                              })
                            : teamsAll?.find(item => {
                                return item?.value == selectedTeams;
                              }))
                        }
                        options={isTeamSearch ? searchTeam : teamsAll}
                        classNamePrefix="select"
                        placeholder="Teams: All"
                        isDisabled={selectedOrg ? false : true}
                        components={{ DropdownIndicator }}
                      />
                    </>
                  )}
                </Box>
              ) : (
                <Box className="filter-type-select">
                  {tabvalue === 1 && (
                    <>
                      <Select
                        className="React horse-select"
                        // className="React period-select"
                        // value={SortData?.find(item => {
                        //   return item?.value === selectedSort;
                        // })}
                        // onChange={e => handleSortchange(e)}
                        // options={SortData}
                        classNamePrefix="select"
                        placeholder="All Horse racing"
                        components={{ DropdownIndicator }}
                      />
                      <Select
                        className="React horse-select"
                        // className="React period-select"
                        // value={SortData?.find(item => {
                        //   return item?.value === selectedSort;
                        // })}
                        // onChange={e => handleSortchange(e)}
                        // options={SortData}
                        classNamePrefix="select"
                        placeholder="Tipping Period:"
                        components={{ DropdownIndicator }}
                      />
                      <Select
                        className="React horse-select"
                        // className="React period-select"
                        // value={SortData?.find(item => {
                        //   return item?.value === selectedSort;
                        // })}
                        // onChange={e => handleSortchange(e)}
                        // options={SortData}
                        classNamePrefix="select"
                        placeholder="Bet type:"
                        components={{ DropdownIndicator }}
                      />
                    </>
                  )}
                </Box>
              )}

              {}
            </Box>
          </Box>
          {screenWidth > 1250 ? (
            <Box className="send-msg-btn-sec">
              <>
                <Box className="tipper-rating-sec">
                  {tippperRatingInfo && (
                    <Box className="left-sec">
                      <Box className="rating-sec">
                        <Typography className="title">
                          Tipper Ratings:
                        </Typography>
                        <Box style={{ marginTop: "5px" }}>
                          <Rating
                            name="tipperRate-web"
                            defaultValue={
                              tippperRatingInfo && tippperRatingInfo?.tipperRate
                            }
                            precision={0.5}
                            className="rating"
                            readOnly
                          />
                        </Box>
                      </Box>
                      <Box className="rating-sec border-left">
                        <Typography className="title">
                          Loyalty Ratings:
                        </Typography>
                        <Box style={{ marginTop: "5px" }}>
                          <Rating
                            name="loyaltyRate-web"
                            defaultValue={
                              tippperRatingInfo &&
                              tippperRatingInfo?.loyaltyRate
                            }
                            precision={0.5}
                            className="rating"
                            readOnly
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <Box className="rate-user-sec">
                    <Typography
                      className="learn-more"
                      onClick={() => setOpenTipperRatingModal(true)}
                    >
                      Rate user
                    </Typography>
                  </Box>
                </Box>

                <Button
                  className="unfollow-btn"
                  onClick={() => setOpenChatModal(true)}
                >
                  Send Message
                </Button>
              </>
            </Box>
          ) : (
            <></>
          )}

          <Box className="page-deatils-wrap">
            {tabvalue === 0 ? (
              <WallTab
                params={params}
                mentionUsersList={mentionUsersList}
                tippperRatingInfo={tippperRatingInfo}
              />
            ) : tabvalue === 1 ? (
              <TippingTab
                typevalue={typevalue}
                sportTipData={sportTipData}
                raceTipData={raceTipData}
                params={params}
              />
            ) : tabvalue === 2 ? (
              <ActivityTab
                params={params}
                typevalue={typevalue}
                activityData={activityData}
                loading={loading}
              />
            ) : (
              <DetailsTab typevalue={typevalue} params={params} />
            )}
          </Box>
        </Box>
        <OurPartner />
      </Box>
      {/* <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box> */}
      <TipperChatModal
        open={openChatModal}
        handleClose={handleClose}
        title={tippperRatingInfo?.firstName + " " + tippperRatingInfo?.lastName}
        closeIcon={true}
      />
      <TipperRatingModal
        open={openTipperRatingModal}
        handleClose={handleTipperRatingModal}
        title={tippperRatingInfo?.firstName + " " + tippperRatingInfo?.lastName}
        closeIcon={true}
        tippperRatingInfo={tippperRatingInfo}
      />
    </>
  );
};

export default TipperProfilePage;
