import React, { useState } from "react";
import { Box, Typography, Button, Avatar, TextField } from "@material-ui/core";
import rightArrow from "../../../../assets/images/icons/rightArrow.svg";
import { ReactComponent as BlankStar } from "../../../../assets/images/icons/blank-star.svg";
import user from "../../../../assets/images/icons/user.svg";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Pagination, Rating } from "@material-ui/lab";
import DialogBox from "../../UI/dialogBox";
import moment from "moment";
import "./bookmakersinfo.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/Loader";
import { ToastContainer, toast } from "react-toastify";

const ReviewTab = ({
  bookkeeperData,
  reviewTabData,
  reviewUserList,
  getReviewTabDataAPI,
  // handleScrollBootamReview,
  BookKeeperIdFromData,
  setEventPage,
  EventPage,
  EventCount,
  totalPage,
  totalCount,
  loader,
  showLogo
}) => {
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState("");
  const rowperPage = 10;

  const handleChange = event => {
    setReview(event.target.value);
  };
  const handleSubmit = async event => {
    event.preventDefault();
    var payload = {
      bookKeeperId: Number(bookkeeperData?.BookKeeperId),
      review: review,
      rating: rating?.toString()
    };

    if (rating === 0) {
      setRatingError("Please select a rating.");
    } else {
      setRatingError("");
      try {
        const { status, data } = await axiosInstance.post(`/reviews`, payload);
        if (status === 201) {
          setOpen(false);
          setReview("");
          setRating(0);
          toast.success("Review submitted successfully!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const oncloseDialog = () => {
    setOpen(false);
    setReview("");
    setRating(0);
  };
  // const ratingData = [
  //   {
  //     id: 0,
  //     img: "",
  //     rating: 4,
  //     name: "Chloe Royle",
  //     des:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec justo commodo.",
  //     date: "Jun 16, 2020"
  //   },
  //   {
  //     id: 1,
  //     img: "",
  //     rating: 3,
  //     name: "Wes Thea",
  //     des:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec justo commodo.",
  //     date: "Jun 16, 2020"
  //   },
  //   {
  //     id: 2,
  //     img: "",
  //     rating: 3.5,
  //     name: "Netta Kimberleigh",
  //     des:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec justo commodo.",
  //     date: "Jun 16, 2020"
  //   },
  //   {
  //     id: 3,
  //     img: "",
  //     rating: 5,
  //     name: "Jogn Doe",
  //     des:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec justo commodo.",
  //     date: "Jun 16, 2020"
  //   }
  // ];

  // const reviewData = [
  //   {
  //     id: 0,
  //     name: "5 Stars",
  //     value: 50,
  //     total: 18
  //   },
  //   {
  //     id: 1,
  //     name: "4 Stars",
  //     value: 20,
  //     total: 9
  //   },
  //   {
  //     id: 2,
  //     name: "3 Stars",
  //     value: 27,
  //     total: 15
  //   },
  //   {
  //     id: 3,
  //     name: "2 Stars",
  //     value: 9,
  //     total: 5
  //   },
  //   {
  //     id: 4,
  //     name: "1 Star",
  //     value: 2,
  //     total: 1
  //   }
  // ];

  const BorderLinearProgress = withStyles(theme => ({
    root: {
      height: 10,
      width: 116,
      borderRadius: 0
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#1a90ff"
    }
  }))(LinearProgress);

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const handlePaginationClick = (event, page) => {
    setEventPage(Number(page));
    getReviewTabDataAPI(BookKeeperIdFromData, page);
  };

  const pageNumbers = [];

  if (totalCount > 0) {
    for (let i = 1; i <= Math.ceil(totalCount / rowperPage); i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <Box className="tab-panel">
      {/* <Box className="img-btn-box">
        <Box className="img-box">
          <img
            src={showLogo(bookkeeperData)}
            alt="bookmaker-image"
            className="bookmaker-image"
          />
        </Box>
        <Box className="join-btn-box">
          <Button
            className="join-btn"
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={() =>
              // (window.location.href =
              //   bookkeeperData?.BookKeeper?.affiliate_link)
              window.open(bookkeeperData?.BookKeeper?.affiliate_link, "_blank")
            }
          >
            {"Join " +
              (bookkeeperData?.BookKeeper?.name &&
              bookkeeperData?.BookKeeper?.name
                ? bookkeeperData?.BookKeeper?.name
                : "")}
          </Button>
        </Box>
      </Box> */}
      {/* <Typography className="description mt-18">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac
        hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id
        finibus. Fusce dolor nibh, aliquet id rhoncus at, ultrices in velit.
      </Typography> */}
      <Box className="rating-review-box">
        {/* <Box className="rating-box">
          <Typography className="rr-title">Ratings & Reviews</Typography>

          <Rating
            name="customized-10"
            defaultValue={
              reviewTabData &&
              reviewTabData?.summary &&
              reviewTabData?.summary?.averageRating
            }
            precision={0.5}
            className="rating"
            readOnly
          />
          <Box className="rating-info-box">
            {reviewTabData &&
              reviewTabData?.summary &&
              reviewTabData?.summary?.ratingCounts &&
              Object.entries(reviewTabData?.summary?.ratingCounts)
                ?.reverse()
                ?.map(([key, value]) => {
                  return (
                    <Box className="rating-info" key={value}>
                      <Typography className="stars w20">
                        {key + " Stars"}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={value}
                        className="progress-bar"
                        // value={Math.ceil(
                        //   (reviewTabData?.summary?.totalRatings / value) * 100
                        // )}
                      />
                      <Typography className="stars">{value}</Typography>
                    </Box>
                  );
                })}
          </Box>

          <Button
            className="review-btn"
            disableElevation
            disableFocusRipple
            disableRipple
            endIcon={<img src={rightArrow} alt="arrow" />}
            onClick={() => setOpen(true)}
          >
            Write a review
          </Button>
        </Box> */}
        {loader ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : reviewTabData?.reviews?.length > 0 ? (
          <>
            {reviewTabData &&
              Object.entries(reviewTabData).length !== 0 &&
              reviewTabData?.reviews && (
                <Box
                  id="scrollableDiv"
                  className="review-box"
                  // style={{ height: "465px", overflow: "auto" }}
                >
                  {/* <div
                   
                    style={{
                     
                    }}
                  > */}
                  {/* <InfiniteScroll
                    dataLength={8}
                    next={() => {
                      handleScrollBootamReview(
                        BookKeeperIdFromData,
                        EventPage + 1
                      );
                      // getReviewTabDataAPI(BookKeeperIdFromData, EventPage + 1);
                    }}
                    hasMore={totalPage !== EventPage}
                    loader={
                      <div className="allsport-loader-center ">
                        <Loader />
                      </div>
                    }
                    scrollableTarget="scrollableDiv"
                  > */}
                  {reviewUserList?.length > 0 &&
                    reviewUserList?.map(item => {
                      const date = moment(item?.createdAt);
                      const formattedDate = fetchNewsTime(date);
                      return (
                        <Box key={item.id} className="reviews">
                          <Box
                            className={`avtar-box ${
                              item?.user?.filePath ? "icon-border" : ""
                            }`}
                          >
                            {/* <Avatar className="avtar" /> */}
                            <img
                              className="avtar"
                              src={
                                item?.user?.filePath
                                  ? Config?.mediaURL + item?.user?.filePath
                                  : user
                              }
                              onError={e => {
                                e.target.src = user;
                              }}
                            />
                          </Box>

                          <Box className="review-data">
                            <Box className="name-rating-box">
                              <Typography className="name">
                                {item?.user?.firstName} {item?.user?.lastName}
                              </Typography>
                              <Rating
                                value={item?.rating}
                                precision={0.5}
                                className="rating"
                                readOnly
                              />
                            </Box>

                            <Typography className="des">
                              {item?.review}
                            </Typography>

                            <Typography className="date">
                              {formattedDate}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  {/* </InfiniteScroll> */}

                  {/* </div> */}
                </Box>
              )}
          </>
        ) : (
          <Box className="no-data-wrap-bookmakers" style={{ width: "100%" }}>
            <Typography className="text-align no-data">
              No Reviews Available
            </Typography>
          </Box>
        )}
        {reviewTabData?.reviews?.length > 0 && (
          <Box className="bookmaker-Pagination mt-44">
            <Pagination
              disabled={totalCount / rowperPage > 1 ? false : true}
              page={EventPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              // count={10}
              size="small"
            />
          </Box>
        )}
      </Box>
      <DialogBox
        open={open}
        onClose={oncloseDialog}
        handleClose={oncloseDialog}
        title={false}
        className="dialog-box"
        content={
          <Box className="smartb-dialog">
            <Typography className="title">Write a review</Typography>
            <Rating
              value={rating}
              className="rating"
              name="half-rating"
              // emptyIcon={
              //   <BlankStar />
              // }
              onChange={e => setRating(e.target.value)}
            />
            {ratingError && !rating && (
              <div className="text-danger ml-4">{ratingError}</div>
            )}
            <TextField
              id="review"
              variant="outlined"
              className="txt-fld"
              placeholder="Write a review"
              multiline
              rows={8}
              fullWidth
              value={review}
              onChange={handleChange}
            />

            <Box className="submit-btn-box">
              <Button className="submit-btn" onClick={e => handleSubmit(e)}>
                Submit
              </Button>
            </Box>
          </Box>
        }
        closeIcon={true}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </Box>
  );
};

export default ReviewTab;
