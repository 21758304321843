import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Collapse,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Bet365Long from "src/assets/images/bookmakers/bet365-long.png";
import LadBrokesLong from "src/assets/images/bookmakers/ladbrokes-long.png";
import AU from "src/assets/images/flag/au.png";
import USA from "src/assets/images/flag/usa.png";
import Cup from "src/assets/images/cup.svg";
import Tennis from "src/assets/images/tennis.svg";
import { ReactComponent as Checked } from "src/assets/images/checked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/unchecked.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Link, useLocation } from "react-router-dom";
import { IntlContext } from "src/App";
import OurPartner from "src/views/component/ourPartners";

const accordionData = [
  {
    id: 1,
    title: "Top Markets",
    subaccordiondata: [
      {
        id: 1,
        title: "Match Betting"
      },
      {
        id: 2,
        title: "To Win 1st Set"
      },
      {
        id: 3,
        title: "Set 2 Winner"
      },
      {
        id: 4,
        title: "Set Betting"
      }
    ]
  },
  {
    id: 2,
    title: "Set 2 Game Markets",
    subaccordiondata: [
      {
        id: 1,
        title: "Set 2 Game 6 Correct Score"
      },
      {
        id: 2,
        title: "Set 2 Game 6 Winner"
      },
      {
        id: 3,
        title: "Set 2 Game 7 Winner"
      },
      {
        id: 4,
        title: "Set 2 Game 7 Correct Score"
      },
      {
        id: 5,
        title: "Set 2 Game 8 Correct Score"
      }
    ]
  },
  {
    id: 3,
    title: "Set Correct Score Markets"
  },
  {
    id: 4,
    title: "Set Markets"
  },
  {
    id: 5,
    title: "Total Games Markets"
  },
  {
    id: 6,
    title: "Other Markets"
  },
  {
    id: 7,
    title: "New Markets"
  }
];
const setBettingData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];
const tipsMatchData = [
  {
    id: 1,
    icon: Cup,
    tips: "Adrian Mannarino - Match",
    num: "1.55",
    content:
      "Adrian Mannarino has won five of his last six ATP main draw matches."
  },
  {
    id: 2,
    icon: Tennis,
    tips: "Over (23.5) - Total Match Games",
    num: "1.95",
    content:
      "Seven of Adrian Mannarino's last eight ATP main draw matches have gone OVER the total match games line."
  },
  {
    id: 3,
    icon: USA,
    tips: "Adrian Mannarino (-2.5) - Game Handicap",
    num: "1.95",
    content:
      "Adrian Mannarino has covered the game handicap in eight of his last nine ATP main draw matches against opponents from the USA."
  }
];

const resultData = [
  {
    id: 0,
    flag: USA,
    player: "v/s Hubert Hurkacz",
    round: "R32",
    set: "2-0"
  },
  {
    id: 1,
    flag: USA,
    player: "v/s Hubert Hurkacz",
    round: "R32",
    set: "2-0"
  },
  {
    id: 2,
    flag: USA,
    player: "v/s Juncheng Shang",
    round: "R32",
    set: "2-0",
    subMenu: [
      {
        id: 0,
        SetBetting: "Adrian Mannarino  2-1",
        ToWin1stSet: "Juncheng Shang",
        TotalMatchGames: "Over 22.0",
        GameHandicap: "Juncheng Shang +3.5",
        SetTotalGames1: "Under 9.5",
        SetTotalGames2: "Over 9.5",
        SetCorrectScore: "Juncheng Shang 6-1",
        GamesWonMargin: "1-2 Games"
      }
    ]
  }
];
const AllCombatSportMarketPage = () => {
  const { state } = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [value, setValue] = useState("yes");
  const [collapseMenu, setCollapseMenu] = useState(-1);
  const [readMore, setReadMore] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fecthVotePercentage = (vote1, vote2) => {
    const calculatePercentage = (votes, totalVotes) => {
      return ((votes / totalVotes) * 100).toFixed();
    };
    const totalVotes = vote1 + vote2;
    const firstPersonPercentage = calculatePercentage(vote1, totalVotes);
    const secondPersonPercentage = calculatePercentage(vote2, totalVotes);

    return (
      <Box className="Percentage-section">
        <span
          style={{
            width: `${firstPersonPercentage}%`,
            background: "#1A73E8"
          }}
          className="vote-Percentage Percentage-left"
        >
          {firstPersonPercentage}%
        </span>
        <span
          style={{
            width: `${secondPersonPercentage}%`,
            background: "#E22C32"
          }}
          className="vote-Percentage Percentage-right"
        >
          {secondPersonPercentage}%
        </span>
      </Box>
    );
  };

  const handleRadioChange = e => {
    setValue(e.target.value);
  };

  const rightsidesection = () => {
    return (
      <>
        <Box className="right-side-section-wrap">
          <Typography variant="h6" className="right-section-heading">
            Stats & Insights
          </Typography>

          <Box className="will-win-section">
            <Typography variant="h6" className="right-section-sub-heading">
              Who will win?
            </Typography>
            <Box className="vote-section">
              <Box className="vote vote-section-left">
                <img src={USA} alt="flag" />
                <span>99 votes</span>
              </Box>
              <Box className="vote vote-section-right">
                <span>32 votes </span>
                <img src={AU} alt="flag" />
              </Box>
            </Box>
            {fecthVotePercentage(99, 32)}
          </Box>
          <Box className="tips-match-insights">
            <Typography variant="h6">Tips & Match Insights</Typography>
            {tipsMatchData?.map(data => (
              <Box className="d-flex tips-match-section" key={data?.id}>
                <Box className="img-box">
                  <img src={data?.icon} alt="cup" />
                </Box>
                <Box className="mactch-tips">
                  <Box className="d-flex match">
                    <Typography>{data?.tips}</Typography>
                    <Typography>{data?.num}</Typography>
                  </Box>
                  <Typography className="content">{data?.content}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box className="results-section">
            <Box className="results-section-1">
              <Typography variant="h6">2023 & 2022 Results</Typography>
              <RadioGroup
                aria-label="results"
                name="results"
                className="results-radioGroup"
                value={value}
                onChange={e => handleRadioChange(e)}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      size="small"
                      icon={<UnChecked className="radio-icon" />}
                      checkedIcon={<Checked className="radio-icon" />}
                      disableRipple
                    />
                  }
                  label="Adrian Mannarino "
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      size="small"
                      icon={<UnChecked className="radio-icon" />}
                      checkedIcon={<Checked className="radio-icon" />}
                      disableRipple
                    />
                  }
                  label="Christopher Eubanks"
                />
              </RadioGroup>
            </Box>
            {/* {value === "yes" ? (
                  <> */}
            <Box className="results">
              <Typography variant="h6">Miami Open</Typography>
              <Table className="results-table">
                <TableBody>
                  {resultData?.map((result, index) => {
                    return (
                      <>
                        <TableRow
                          onClick={() => {
                            return setCollapseMenu(
                              collapseMenu === index ? -1 : index
                            );
                          }}
                        >
                          <TableCell>
                            <img src={result?.flag} alt="usa" />
                          </TableCell>
                          <TableCell>{result?.player}</TableCell>
                          <TableCell>{result?.round}</TableCell>
                          <TableCell>{result?.set}</TableCell>
                          <TableCell>
                            <IconButton aria-label="expand row" size="small">
                              {collapseMenu === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>

                        <TableRow className="collapse-tr">
                          <TableCell colSpan={100}>
                            <Collapse
                              in={collapseMenu === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              {result?.subMenu?.map(item => {
                                return (
                                  <>
                                    <Box className="result-box">
                                      <Box className="d-flex result-head">
                                        <Typography>
                                          Thu 23 March - Miami Open
                                        </Typography>
                                        <Typography>Hard Court</Typography>
                                      </Box>
                                      <Table className="score-table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell width="10%">F</TableCell>
                                            <TableCell width="10%">
                                              S1
                                            </TableCell>
                                            <TableCell width="10%">
                                              S2
                                            </TableCell>
                                            <TableCell width="10%">
                                              S3
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              Adrian Mannarino{" "}
                                            </TableCell>
                                            <TableCell>2</TableCell>
                                            <TableCell>1</TableCell>
                                            <TableCell>6</TableCell>
                                            <TableCell>6</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>
                                              Juncheng Shang
                                            </TableCell>
                                            <TableCell>1</TableCell>
                                            <TableCell>6</TableCell>
                                            <TableCell>4</TableCell>
                                            <TableCell>2</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                      <Box className="market-result">
                                        <Box className="single-market-result">
                                          <Typography>Set Betting</Typography>
                                          <Typography>
                                            {item?.SetBetting}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>
                                            To Win 1st Set
                                          </Typography>
                                          <Typography>
                                            {item?.ToWin1stSet}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>
                                            Total Match Games
                                          </Typography>
                                          <Typography>
                                            {item?.TotalMatchGames}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>Game Handicap</Typography>
                                          <Typography>
                                            {item?.GameHandicap}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>
                                            1st Set Total Games
                                          </Typography>
                                          <Typography>
                                            {item?.SetTotalGames1}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>
                                            2nd Set Total Games
                                          </Typography>
                                          <Typography>
                                            {item?.SetTotalGames2}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>
                                            !st Set Correct Score
                                          </Typography>
                                          <Typography>
                                            {item?.SetCorrectScore}
                                          </Typography>
                                        </Box>
                                        <Box className="single-market-result">
                                          <Typography>
                                            Games Won Margin
                                          </Typography>
                                          <Typography>
                                            {item?.GamesWonMargin}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              <Typography variant="h6" className="indian-wells">
                Indian Wells
              </Typography>
              {readMore ? (
                <>
                  <Box className="no-data">No Data Available</Box>
                </>
              ) : (
                ""
              )}
              <Box className="read-more">
                {readMore ? (
                  <Button onClick={() => setReadMore(false)}>
                    Less <KeyboardArrowUpIcon />{" "}
                  </Button>
                ) : (
                  <Button onClick={() => setReadMore(true)}>
                    More <KeyboardArrowDownIcon />{" "}
                  </Button>
                )}
              </Box>
            </Box>
            {/* </>
                ) : (
                  ""
                )} */}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className="sport-market-wrap">
        <Box className="market-heading-wrap">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                {localesData?.MENU?.HOME}
              </Link>
              <Link underline="hover" color="inherit" to="">
                {localesData?.MENU?.SPORTS}
              </Link>
              <Link underline="hover" color="inherit" to="">
                Tennis
              </Link>

              <Typography>Adrian Mannarino v/s Christopher Eubanks</Typography>
            </Breadcrumbs>
          </Box>
          <Box className="market-heading">
            <Typography variant="h1">
              Adrian Mannarino v/s Christopher Eubanks
            </Typography>
            <span className="live">Live</span>
          </Box>
        </Box>
        <Table className="match-point-table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>1st</TableCell>
              <TableCell>Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="player-name">Adrian Mannarino</TableCell>
              <TableCell>6</TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="player-name">Christopher Eubanks</TableCell>
              <TableCell>6</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box className="market-section">
          {screenWidth > 1023 ? (
            ""
          ) : (
            <>
              <Box className="mobile-right-section">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Stats & Insights</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="right-side-market">
                      {rightsidesection()}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </>
          )}
          <Box className="left-side-market">
            {accordionData?.map((item, index) => {
              return (
                <>
                  <Box className="left-section-details" key={index}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item?.subaccordiondata?.map((obj, id) => {
                          return (
                            <>
                              <Box className="sub-accordion">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography>{obj?.title}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {item?.id === 1 ? (
                                      obj?.id === 1 ||
                                      obj?.id === 2 ||
                                      obj?.id === 3 ? (
                                        <>
                                          <Box className="match-betting">
                                            <Box>
                                              <Box className="odds-label">
                                                Adrian Mannarino
                                              </Box>
                                              <Box className="odds-wrap">
                                                <span className="odds odds-left">
                                                  {" "}
                                                  1.88{" "}
                                                </span>
                                                <img
                                                  className="bookmaker-long"
                                                  src={Bet365Long}
                                                  alt="Odds Icon"
                                                />
                                              </Box>
                                            </Box>
                                            <Box>
                                              <Box className="odds-label">
                                                Christopher Eubanks
                                              </Box>
                                              <Box className="odds-wrap">
                                                <span className="odds odds-left">
                                                  {" "}
                                                  1.88{" "}
                                                </span>
                                                <img
                                                  className="bookmaker-long"
                                                  src={LadBrokesLong}
                                                  alt="Odds Icon"
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          {setBettingData?.map(item => (
                                            <Box className="set-betting">
                                              <Box className="odds-label">
                                                Adrian Mannarino 2-0
                                              </Box>
                                              <Box className="odds-wrap">
                                                <span className="odds odds-left">
                                                  {" "}
                                                  1.88{" "}
                                                </span>
                                                <img
                                                  className="bookmaker-long"
                                                  src={Bet365Long}
                                                  alt="Odds Icon"
                                                />
                                              </Box>
                                            </Box>
                                          ))}
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              );
            })}
          </Box>
          {screenWidth > 1023 ? (
            <Box className="right-side-market">{rightsidesection()}</Box>
          ) : (
            <></>
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default AllCombatSportMarketPage;
