import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import banner from "../../../assets/images/banner/smart-b-banner-primary.webp";
import AdvertisingBannner from "../../component/advertisingBanner";
import "./policies.scss";
import OurPartner from "../../component/ourPartners";
import { IntlContext } from "src/App";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import { Helmet } from "react-helmet";
import { Config } from "src/helpers/context";

const PrivacyPolicy = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      <Helmet>
        <title>SmartB | Check Our Detailed Privacy Policy</title>
        <meta
          name="description"
          content="This privacy policy sets out how SmartB uses and protects any information that you give SmartB when you use this website. Learn more about this here. "
        />
      </Helmet>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        {screenWidth > 479 ? "" : <AdvertisingBannner src={banner} />}
        <Box className="sport-wrap">
          <Box className="terms-wrap">
            <Typography variant="h1">
              <span className="title-orange">
                {localesData?.PRIVACY_POLICY?.TITLE}
              </span>
            </Typography>
            {/* <Typography className="created-date">
              <span className="title-skyblue">Created on: 1st August 2022</span>
            </Typography>
            <Typography className="modified-on">
              <span className="title-skyblue">Modified on:</span>
            </Typography> */}
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.PRIVACY_POLICY_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.PRIVACY_POLICY_PARA_2}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.PRIVACY_POLICY_PARA_3}{" "}
              <b>{localesData?.PRIVACY_POLICY?.DATE}</b>{" "}
              {localesData?.PRIVACY_POLICY?.EFFECTIVE_DATE}
            </Typography>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.INTRODUCTION}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.INTRODUCTION_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.INTRODUCTION_PARA_2}
            </Typography>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.PERSONAL_INFORMATION}
            </Typography>
            <Typography variant="body2" className="mb-10">
              {localesData?.PRIVACY_POLICY?.PERSONAL_INFORMATION_PARA_1}
            </Typography>
            <Box className="policy-sub-header">
              <Typography variant="h5">
                {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_PARA_1}
              </Typography>
              <ul>
                <li>
                  {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_LIST_1}
                </li>
                <li>
                  {" "}
                  {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_LIST_2}
                </li>
                <li>
                  {" "}
                  {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_LIST_3}
                </li>
                <li>
                  {" "}
                  {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_LIST_4}
                </li>
                <li>
                  {" "}
                  {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_LIST_5}
                </li>
                <li>
                  {" "}
                  {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_LIST_6}
                </li>
              </ul>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_PARA_2}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.REGISTRATION_INFORMATION_PARA_3}
              </Typography>
              <Typography variant="h5">
                {localesData?.PRIVACY_POLICY?.DEVICE_INFORMATION}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.DEVICE_INFORMATION_PARA_1}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.DEVICE_INFORMATION_PARA_2}
              </Typography>
              <Typography variant="h5">
                {localesData?.PRIVACY_POLICY?.PUBLIC_INFORMATION_AND_POSTS}
              </Typography>
              <Typography variant="body2">
                {
                  localesData?.PRIVACY_POLICY
                    ?.PUBLIC_INFORMATION_AND_POSTS_PARA_1
                }
              </Typography>
              <Typography variant="h5">
                {localesData?.PRIVACY_POLICY?.SOCIAL_MEDIA_INFORMATION}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.SOCIAL_MEDIA_INFORMATION_PARA_1}
              </Typography>
              <ul>
                <li>
                  {localesData?.PRIVACY_POLICY?.SOCIAL_MEDIA_INFORMATION_LIST_1}
                </li>
                <li>
                  {localesData?.PRIVACY_POLICY?.SOCIAL_MEDIA_INFORMATION_LIST_2}
                </li>
                <li>
                  {localesData?.PRIVACY_POLICY?.SOCIAL_MEDIA_INFORMATION_LIST_3}
                </li>
              </ul>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.SOCIAL_MEDIA_INFORMATION_PARA_2}
              </Typography>
              <Typography variant="h5">
                {localesData?.PRIVACY_POLICY?.ACTIVITY_INFORMATION}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.ACTIVITY_INFORMATION_PARA_1}
              </Typography>
              <Typography variant="body2">
                {localesData?.PRIVACY_POLICY?.ACTIVITY_INFORMATION_PARA_2}
              </Typography>
              <Typography variant="h5">
                {localesData?.PRIVACY_POLICY?.INFORMATION_FROM_OTHER_SOURCES}
              </Typography>
              <Typography variant="body2">
                {
                  localesData?.PRIVACY_POLICY
                    ?.INFORMATION_FROM_OTHER_SOURCES_PARA_1
                }
              </Typography>
              <Typography variant="body2">
                {
                  localesData?.PRIVACY_POLICY
                    ?.INFORMATION_FROM_OTHER_SOURCES_PARA_2
                }
              </Typography>
              <Typography variant="h5">
                {
                  localesData?.PRIVACY_POLICY
                    ?.INFORMATION_IN_RELATION_TO_OTHER_PERSONS
                }
              </Typography>
              <Typography variant="body2">
                {
                  localesData?.PRIVACY_POLICY
                    ?.INFORMATION_IN_RELATION_TO_OTHER_PERSONS_PARA_1
                }
              </Typography>
              <ul>
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.INFORMATION_IN_RELATION_TO_OTHER_PERSONS_LIST_1
                  }
                </li>
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.INFORMATION_IN_RELATION_TO_OTHER_PERSONS_LIST_2
                  }
                </li>
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.INFORMATION_IN_RELATION_TO_OTHER_PERSONS_LIST_3
                  }
                </li>
              </ul>
              <Typography variant="body2">
                {
                  localesData?.PRIVACY_POLICY
                    ?.INFORMATION_IN_RELATION_TO_OTHER_PERSONS_PARA_2
                }
              </Typography>
            </Box>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.SENSITIVE_INFORMATION}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.SENSITIVE_INFORMATION_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.SENSITIVE_INFORMATION_PARA_2}
            </Typography>
            <ul>
              <li>
                {localesData?.PRIVACY_POLICY?.SENSITIVE_INFORMATION_LIST_1}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.SENSITIVE_INFORMATION_LIST_2}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.SENSITIVE_INFORMATION_LIST_3}
              </li>
            </ul>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_PARA_1}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_PARA_2}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_PARA_3}
            </Typography>
            <ul>
              <li>{localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_LIST_1}</li>
              <li>{localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_LIST_2}</li>
              <li>{localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_LIST_3}</li>
              <li>{localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_LIST_4}</li>
              <li>{localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_LIST_5}</li>
              <li>{localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_LIST_6}</li>
            </ul>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.HOW_WE_USE_COOKIES_PARA_4}
            </Typography>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_PARA_1}
            </Typography>
            <ul>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_1}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_2}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_3}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_4}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_5}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_6}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_7}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_8}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_9}
              </li>
              <li>
                {" "}
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_10}
              </li>
              <li>
                {" "}
                {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_LIST_11}
              </li>
            </ul>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_PARA_2}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.USE_YOUR_INFORMATION_PARA_3}
            </Typography>
            <Box className="policy-sub-header">
              <Typography variant="h5">
                {
                  localesData?.PRIVACY_POLICY
                    ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION
                }
              </Typography>
              <ul className="terms-ul">
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_1
                  }
                </li>
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_2
                  }
                </li>
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_3
                  }
                </li>
                <li>
                  {
                    localesData?.PRIVACY_POLICY
                      ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_4
                  }
                  <ul>
                    <li>
                      {
                        localesData?.PRIVACY_POLICY
                          ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_4_SUB_1
                      }
                    </li>
                    <li>
                      {
                        localesData?.PRIVACY_POLICY
                          ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_4_SUB_2
                      }
                    </li>
                    <li>
                      {
                        localesData?.PRIVACY_POLICY
                          ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_4_SUB_3
                      }
                    </li>
                    <li>
                      {
                        localesData?.PRIVACY_POLICY
                          ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_4_SUB_4
                      }
                    </li>
                    <li>
                      {
                        localesData?.PRIVACY_POLICY
                          ?.OTHER_WAYS_WE_USE_YOUR_INFORMATION_LIST_4_SUB_5
                      }
                    </li>
                  </ul>
                </li>
              </ul>
            </Box>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.PROTECT_YOUR_INFORMATION}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.PROTECT_YOUR_INFORMATION_PARA_1}
            </Typography>
            <ul>
              <li>
                {localesData?.PRIVACY_POLICY?.PROTECT_YOUR_INFORMATION_LIST_1}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.PROTECT_YOUR_INFORMATION_LIST_2}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.PROTECT_YOUR_INFORMATION_LIST_3}
              </li>
              <li>
                {localesData?.PRIVACY_POLICY?.PROTECT_YOUR_INFORMATION_LIST_4}
              </li>
            </ul>
            <Typography variant="h5">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_1}
            </Typography>
            <ul className="terms-ul">
              <li>
                <span className="underline">
                  {localesData?.PRIVACY_POLICY?.ACCESS_UPDATE}
                </span>{" "}
                {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_LIST_1}
              </li>
              <li>
                <span className="underline">
                  {localesData?.PRIVACY_POLICY?.DEVICE_PERMISSIONS}
                </span>{" "}
                {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_LIST_2}
              </li>
              <li>
                <span className="underline">
                  {localesData?.PRIVACY_POLICY?.UNINSTALL}
                </span>
                {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_LIST_3}
              </li>
              <li>
                <span className="underline">
                  {localesData?.PRIVACY_POLICY?.ACCOUNT_CLOSURE}
                </span>{" "}
                {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_LIST_4}
              </li>
            </ul>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_2}
            </Typography>
            <ul className="terms-ul">
              <li>
                <span className="underline">
                  {localesData?.PRIVACY_POLICY?.REVIEWING_YOUR_INFORMATION}
                </span>{" "}
                {localesData?.PRIVACY_POLICY?.INFORMATION_LIST_1}
              </li>
              <li>
                <span className="underline">
                  {localesData?.PRIVACY_POLICY?.UPDATING_YOUR_INFORMATION}
                </span>{" "}
                {localesData?.PRIVACY_POLICY?.INFORMATION_LIST_2}
              </li>
            </ul>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_3}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_4}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_5}
            </Typography>
            <Typography variant="body2">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_6}
            </Typography>
            <Typography variant="body2" className="mb-0">
              {localesData?.PRIVACY_POLICY?.YOUR_RIGHTS_PARA_7}
            </Typography>
            <Typography variant="body2" className="mb-0">
              <b>SmartB</b>
            </Typography>
            <Typography variant="body2" className="mb-0">
              <a
                href={
                  Config?.release == "IN"
                    ? "mailto:info@smartb.co.in"
                    : "mailto:info@smartb.com.au"
                }
              >
                <b>
                  {Config?.release == "IN"
                    ? "info@smartb.co.in"
                    : "info@smartb.com.au"}
                </b>
              </a>
            </Typography>
            <Typography variant="body2" className="mb-0 pb-46">
              <a href="tel:1300065591">
                <b>1300 065 591 </b>
              </a>
            </Typography>
          </Box>
        </Box>
        {screenWidth > 479 ? "" : <OurPartner />}
      </Box>
    </>
  );
};

export default PrivacyPolicy;
