import React, { useState, useEffect } from "react";
import { Chip, TableCell, withStyles } from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useTimer } from "react-timer-hook";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../helpers/constants/routeConstants";
const StyledTableCell = withStyles((theme) => ({
  body: {
    // cursor: "pointer",
    borderBottom: "none",
  },
}))(TableCell);

const TrackListCountdown = ({
  expiryTimestamp,
  race,
  race_obj,
  checkRaceMinCell,
  isMobile,
  props,
  raceData,
  intl,
  selectedDate,
  isBlackBook,
}) => {
  const navigate = useNavigate();
  const [isRaceFinished, setIsRaceFinished] = useState(false);
  const [IsOdds, setIsOdds] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { days, seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsRaceFinished(true),
  });
  const fetchFixedOdds = async () => {
    if (race_obj?.id) {
      // try {
      //   const { status, data } = await axiosInstance.get(
      //     `/public/checkOdd?raceId=${race_obj?.id}`
      //   );
      //   if (status === 200) {
      //     let odds = data?.events?.[0]?.race?.[0]?.bookKeepers.filter(
      //       (obj) => {
      //         if (obj.isOdd === true) {
      //           return true;
      //         } else {
      //           return false;
      //         }
      //       }
      //     );
      //     if (odds.length > 0) {
      //       setIsOdds(true);
      //     } else {
      //       setIsOdds(false);
      //     }
      //   }
      // } catch (error) { }
      // let oddsData = race_obj?.runner?.map((item) => {
      //   return {
      //     RaceOdds: item?.MarketRelations?.length > 0 ? item?.MarketRelations?.map((obj) => {
      //       if (obj?.ApiProviderId > 0) {
      //         return {
      //           RaceOddslength: obj?.RaceOdds?.length > 0 ? true : false,
      //           OddsValues: obj?.RaceOdds?.[0]?.intValue
      //         }
      //       } else {
      //         return {
      //           RaceOddslength: false,
      //           OddsValues: 0
      //         }
      //       }
      //     }) : [{ RaceOddslength: false }]
      //   }
      // });
      // let isOdds = oddsData?.map((item) => {
      //   return item?.RaceOdds?.some((obj) => { return obj?.RaceOddslength === true && obj?.OddsValues > 0 })
      // }
      // )?.filter((x) => x === true)

      if (race_obj?.isOdd) {
        setIsOdds(true);
      } else {
        setIsOdds(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchFixedOdds();
    //eslint-disable-next-line
  }, []);

  // let raceResultSummaryData = race_obj?.RaceResultSummary?.summary
  //   ? JSON.parse(race_obj?.RaceResultSummary?.summary)
  //   : [];
  // let resultData = raceResultSummaryData?.filter(
  //   (obj) => obj?.Position == 1 || obj?.Position == 2 || obj?.Position == 3
  // );
  return isMobile === false ? (
    <StyledTableCell // Desktop View
      align="center"
      className={
        isRaceFinished &&
        (isBlackBook ? race_obj?.startDate : race_obj?.startTimeDate !== null)
          ? // ? "upcoming_race_cell_close interim"
            // : checkRaceMinCell(isBlackBook ? race_obj?.startDate :  race_obj?.startTimeDate, "desktop")
            IsOdds === true
            ? " fixed upcoming_race_cell_close interim"
            : "upcoming_race_cell_close interim"
          : IsOdds === true
          ? `fixed ${checkRaceMinCell(
              isBlackBook ? race_obj?.startDate : race_obj?.startTimeDate,
              "desktop"
            )}`
          : checkRaceMinCell(
              isBlackBook ? race_obj?.startDate : race_obj?.startTimeDate,
              "desktop"
            )
      }
      onClick={
        // isBlackBook ? race_obj?.startDate :  race_obj?.startTimeDate === null || isBlackBook
        //   ? () => {}
        //   :
        (isBlackBook
        ? race_obj?.startDate
        : race_obj?.startTimeDate !== null)
          ? () => {
              navigate(
                Routes.RunnerDetails(
                  race?.sportId === 1
                    ? "horse"
                    : race?.sportId === 2
                    ? "harness"
                    : "greyhounds",
                  race?.sportId,
                  race_obj?.id,
                  isBlackBook ? race?.trackId : race?.track?.id,
                  isBlackBook ? race?.eventId : race?.id,
                  // isBlackBook ? race_obj?.startDate :  race_obj?.startTimeDate,
                  selectedDate,
                  intl
                ),
                {
                  state: {
                    raceData: raceData,
                    CurrentData: race,
                    SelectedRaceid: race_obj?.id,
                  },
                }
              );
            }
          : () => {}
      }
    >
      {(isBlackBook ? (
        race_obj?.startDate
      ) : (
        race_obj?.startTimeDate !== null
      )) ? (
        <Chip
          className={"singlerace-count-chip"} // Race startDateTime countdown
          style={{
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          size="small"
          label={
            hours === 0 && minutes === 0 && seconds === 0 ? (
              // resultData?.length > 0 ? (
              //   resultData?.map((obj, index) => {
              //     return (
              //       <span>
              //         {obj?.RunnerNumber}
              //         {index != 2 ? "," : ""}
              //       </span>
              //     );
              //   })
              // ) : (
              <span style={{ color: "red" }}>closed</span>
            ) : (
              // )
              <span>
                {days > 0 ? days + "d" : ""}{" "}
                {days > 0 ? hours + "h" : hours > 0 ? hours + "h" : ""}{" "}
                {days === 0 ? (minutes > 0 ? minutes + "m" : "0m") : ""}{" "}
                {days === 0 && hours === 0 && minutes <= 5
                  ? seconds > 0
                    ? seconds + "s"
                    : "0s"
                  : ""}
              </span>
            )
          }
        />
      ) : (
        <span style={{ textAlign: "center" }}>-</span>
      )}
    </StyledTableCell>
  ) : (
    <td // Mobile View
      style={{ width: 80, textAlign: "right" }}
      className={
        isRaceFinished
          ? ""
          : checkRaceMinCell(
              isBlackBook
                ? race?.race[0].startDate
                : race?.race[0].startTimeDate,
              "mobile"
            )
      }
    >
      {race?.race?.length > 0 &&
      (isBlackBook
        ? race?.race[0].startDate
        : race?.race[0].startTimeDate !== null) ? (
        <Chip // Race StartDateTime countdown
          className={"singlerace-count-chip"}
          style={{
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          size="small"
          label={
            hours === 0 && minutes === 0 && seconds === 0 ? (
              <span style={{ color: "red" }}>closed</span>
            ) : (
              <span>
                {days > 0 ? days + "d" : ""}{" "}
                {days > 0 ? hours + "h" : hours > 0 ? hours + "h" : ""}{" "}
                {days === 0 ? (minutes > 0 ? minutes + "m" : "0m") : ""}{" "}
                {days === 0 && hours === 0 && minutes <= 5
                  ? seconds > 0
                    ? seconds + "s"
                    : "0s"
                  : ""}
              </span>
            )
          }
        />
      ) : (
        "-"
      )}
    </td>
  );
};
export default TrackListCountdown;
