import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button,
  TextField
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import TipperProfile from "src/assets/images/tipper-profile.png";
import Avatar from "src/assets/images/user-avatar.png";
import "./ui.scss";

const TipperChatModal = ({ handleClose, open, title, closeIcon }) => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = sender => {
    if (newMessage.trim() !== "") {
      setMessages([...messages, { text: newMessage, sender: sender }]);
      setNewMessage("");
    }
  };
  return (
    <Dialog
      className="blackBook-dialog w570 border-radius-chat"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box br-8">
        {title && closeIcon && (
          <DialogTitle className="background-img">
            <Box className="custom-dialog-title">
              <Box className="dialog-title">
                <Box className="title-chip">
                  <Box className="profile-pic">
                    <img src={TipperProfile} alt="profile" />
                  </Box>
                  <Typography variant="h3" className="title">
                    {title}
                  </Typography>
                </Box>
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                      setMessages([]);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </DialogTitle>
        )}
        <Box className="chat-content-wrap">
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <Box className="smartbook-type-wrap">
              <Box
                className={
                  messages?.length > 4 ? "messages-sec" : "d-flex messages-sec"
                }
              >
                <Box className="messages-container">
                  {messages?.length > 0 && (
                    <Box className="day-devider">
                      <Box className="devider"></Box>
                      <Typography className="day">
                        {moment(Date()).format("dddd, DD MMM")}
                      </Typography>
                      <Box className="devider"></Box>
                    </Box>
                  )}

                  {messages &&
                    messages.map((message, index) => (
                      <Box
                        key={index}
                        className={`message ${
                          message.sender === "user"
                            ? "user-message"
                            : "bot-message"
                        }`}
                      >
                        <Box
                          className={
                            message.sender === "user"
                              ? "message-sec"
                              : "message-sec bot-message-sec"
                          }
                        >
                          <Box className="profile-pic">
                            <img
                              src={
                                message.sender === "user"
                                  ? TipperProfile
                                  : Avatar
                              }
                              alt="profile"
                            />
                          </Box>
                          <Box className="msg-time">
                            <Box
                              className={
                                message.sender === "user"
                                  ? "user-message-txt chat"
                                  : "bot-message-txt chat"
                              }
                            >
                              {message.text}
                            </Box>
                            <Box
                              className={
                                message.sender === "user"
                                  ? "time"
                                  : "time align-end"
                              }
                            >
                              10:49 am
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
              <Box className="send-box">
                <TextField
                  placeholder="type..."
                  variant="outlined"
                  type="text"
                  name="sportoreventother"
                  className="text-field"
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                />
                <Button
                  className="send-btn"
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  onClick={() => newMessage && handleSendMessage("bot")}
                >
                  Send
                </Button>
              </Box>
            </Box>
          )}
          {/* <TextareaAutosize
            id="outlined-basic"
            variant="outlined"
            className="details-textarea-field"
            placeholder="Comment(optional)..."
            multiline
            maxRows={6}
            name="message"
            style={{ width: "97%", height: "241px" }}
            // onChange={e => setComment(e.target.value)}
            value={comment}
          /> */}
          {/* <Box className="blackbook-btn-wrap">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              className="blackbook-button"
              onClick={() => {
                setTipAdded(true);
                handleAddTipClick("Tipped");
              }}
            >
              Confirm Tip
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Box>
        {/* <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> */}
      </Box>
    </Dialog>
  );
};

export default TipperChatModal;
