import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button
} from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import Pagination from "@material-ui/lab/Pagination";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import Bet365 from "../../../assets/images/bookmakers/bet365-thumb.svg";
import AUS from "../../../assets/images/flag/au.png";
import playerAvtar from "../../../assets/images/player-avatar.png";
import brisbane from "../../../assets/images/brisbane.png";
import moment from "moment";
import OurPartner from "../ourPartners";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import SmartBookModal from "../UI/smartbookModal";
import NoDataComp from "../UI/NoData";

const SmartBook = ({
  allSBData,
  isAllSBLoading,
  fetchAllSmartBook,
  handlePaginationClick,
  allSBCount,
  allSBPage,
  pageNumbers,
  isToggle
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [tableId, setTableId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        handleDeleteClose();

        fetchAllSmartBook(allSBPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    const typeId = type ? item?.SportId : "";
    setTypeId(typeId);
    setTableId(item?.tableId);
    setSelectedId(id);
    const title = item?.name;
    const comment = item?.comment;
    setEditComment(comment);
    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  // const fetchRunnerData = item => {
  //   const runnerInfo = JSON.parse(item?.runnerInfo);
  //   return (
  //     <Box>
  //       <Typography className="details mr57">
  //         Career:{" "}
  //         <span>
  //           {runnerInfo.past_runner_performances?.overall?.starts
  //             ? runnerInfo.past_runner_performances?.overall?.starts
  //             : "0"}{" "}
  //           {runnerInfo.past_runner_performances?.overall?.wins
  //             ? runnerInfo.past_runner_performances?.overall?.wins
  //             : "0"}
  //           -
  //           {runnerInfo.past_runner_performances?.overall?.second
  //             ? runnerInfo.past_runner_performances?.overall?.second
  //             : "0"}
  //           -
  //           {runnerInfo.past_runner_performances?.overall?.thirds
  //             ? runnerInfo.past_runner_performances?.overall?.thirds
  //             : "0"}
  //         </span>
  //       </Typography>
  //       <Typography className="details">
  //         L{" "}
  //         {runnerInfo.runner_info?.last_starts
  //           ? runnerInfo.runner_info?.last_starts?.length
  //           : 10}
  //         :{" "}
  //         <span>
  //           {runnerInfo.runner_info?.last_starts
  //             ? runnerInfo.runner_info?.last_starts
  //             : "N/A"}{" "}
  //         </span>
  //       </Typography>
  //     </Box>
  //   );
  // };

  const raceIcon = sportId => {
    if (sportId === 15) {
      return <AmericanFootball />;
    } else if (sportId === 9) {
      return <AustralianRules />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MixedMartialArts />;
    } else if (sportId === 12) {
      return <RugbyLeague />;
    } else if (sportId === 13) {
      return <RugbyUnion />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  return (
    <>
      <Box className="page-content-wrap">
        {isAllSBLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : allSBData && allSBData?.length > 0 ? (
          allSBData?.map((item, index) => {
            return (
              <>
                <Box
                  className="team-sport-table-wrap blackbook-runner-details"
                  key={index}
                >
                  {/* <Box className="runner-deatils blackbook-details-mobile"> */}
                  {/* {item?.type !== "Track" ? ( */}

                  <Box className="smart-top-header-details black-book-header bottom-border flex-grow">
                    <Box className="header-details title-bottom-border">
                      <Typography
                        variant="h6"
                        className="day-time-details flex"
                      >
                        <span className="dark-color">
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <span className="sport-icon">
                              {raceIcon(item?.SportId)}
                            </span>

                            <p className="m-0">
                              <span className="title-txt">
                                {item?.sportName}
                              </span>
                            </p>
                          </Box>
                        </span>
                      </Typography>
                      {/* <Typography>
                        {screenWidth < 890 ? (
                          <span className="dark-color chip chip-txt">
                            {item?.sportName}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Typography> */}
                    </Box>
                    <Box className="Updated-date">
                      <Typography className="date">
                        Updated:{" "}
                        {moment
                          .utc(item?.updatedAt)
                          .local()
                          .format("DD/MM/YYYY")}
                      </Typography>
                      <Box
                        className="delete-box cursor-pointer"
                        onClick={() => handleDelete(item?.type, item?.tableId)}
                      >
                        <Delete />
                      </Box>
                    </Box>
                  </Box>
                  {/* </Box> */}
                  <Box className="smart-details black-book-header flex-grow mt-9">
                    <Box className="detail-row1">
                      <Typography className="runner-name team-details track-flag runner-tc img-style">
                        <Box className="team-img-wrap">
                          <img
                            src={
                              item?.Favorite?.flag &&
                              item?.Favorite?.flag?.includes("uploads")
                                ? Config.mediaURL + item?.Favorite?.flag
                                : item?.SportId === 5 ||
                                  item?.SportId === 6 ||
                                  item?.SportId === 7 ||
                                  item?.SportId === 16
                                ? playerAvtar
                                : brisbane
                            }
                            // src={
                            //   item?.Favorite?.flag ? item?.Favorite?.flag : AUS
                            // }
                            // src={
                            //   item?.flag?.includes("uploads")
                            //     ? Config.mediaURL + item?.flag
                            //     : item?.flag
                            // }
                            alt="Race Contry"
                            className="flag team-img"
                          />
                        </Box>
                        <Typography className="title-txt">
                          {item?.Favorite?.name}
                        </Typography>
                      </Typography>
                    </Box>

                    {item?.SportId === 5 ||
                    item?.SportId === 6 ||
                    item?.SportId === 7 ||
                    item?.SportId === 16 ? (
                      <Box className="detail-row1 mr-11">
                        <Box className="detail-row mt-4">
                          <Typography className="tdetails mr57">
                            Born:{" "}
                            <span
                              className="txt-underline"
                              // style={{ textDecoration: "underline" }}
                            >
                              {item?.coach ? item?.coach : "N/A"}
                            </span>
                          </Typography>
                        </Box>
                        <Box className="detail-row">
                          <Typography className="tdetails">
                            Place of Birth:{" "}
                            <span
                              className="txt-underline"
                              // style={{ textDecoration: "underline" }}
                            >
                              {/* {item?.captains
                                ?.map(obj => {
                                  return obj?.name;
                                })
                                .join(", ")} */}
                              N/A
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box className="detail-row1 mr-11">
                        <Box className="detail-row mt-4">
                          <Typography className="tdetails mr57">
                            Coach:{" "}
                            <span
                              className="txt-underline"
                              style={{ textDecoration: "underline" }}
                            >
                              {/* {item?.LY?.starts ? item?.LY?.starts : "0"}{" "}
                                {item?.LY?.first ? item?.LY?.first : "0"}-
                                {item?.LY?.second ? item?.LY?.second : "0"}-
                                {item?.LY?.thirds ? item?.LY?.thirds : "0"} */}
                              {/* {item?.coach ? item?.coach : "N/A"} */}
                              {item?.SportId === 4 &&
                              item?.Favorite?.CricketTeamManagers
                                ?.CricketManager?.name
                                ? item?.Favorite?.CricketTeamManagers
                                    ?.CricketManager?.name
                                : item?.SportId === 10 &&
                                  item?.Favorite?.NBATeamManagers?.NBAManager
                                ? item?.Favorite?.NBATeamManagers?.NBAManager
                                    ?.name
                                : item?.SportId === 15 &&
                                  item?.Favorite?.AFLTeamManagers?.AFLManager
                                ? item?.Favorite?.AFLTeamManagers?.AFLManager
                                    ?.name
                                : item?.SportId === 9 &&
                                  item?.Favorite?.AustralianRulesTeamManagers
                                ? item?.Favorite?.AustralianRulesTeamManagers
                                    ?.AustralianRulesManager?.name
                                : item?.SportId === 16 &&
                                  item?.Favorite?.GolfTeamManagers?.GolfManager
                                ? item?.Favorite?.GolfTeamManagers?.GolfManager
                                    ?.name
                                : item?.SportId === 7 &&
                                  item?.Favorite?.TennisTeamManagers
                                ? item?.Favorite?.TennisTeamManagers
                                    ?.TennisManager?.name
                                : item?.SportId === 11 &&
                                  item?.Favorite?.BaseballTeamManagers
                                ? item?.Favorite?.BaseballTeamManagers
                                    ?.BaseballManager?.name
                                : item?.SportId === 17 &&
                                  item?.Favorite?.IceHockeyTeamManagers
                                ? item?.Favorite?.IceHockeyTeamManagers
                                    ?.IceHockeyManager?.name
                                : item?.SportId === 6 &&
                                  item?.Favorite?.BoxingManagers?.BoxingManager
                                ? item?.Favorite?.BoxingManagers?.BoxingManager
                                    ?.names
                                : item?.SportId === 5 &&
                                  item?.Favorite?.MMAManagers?.MMAManager?.name
                                ? item?.Favorite?.MMAManagers?.MMAManager?.name
                                : item?.SportId === 8 &&
                                  item?.Favorite?.SoccerManagers?.SoccerManager
                                ? item?.Favorite?.SoccerManagers?.SoccerManager
                                    ?.name
                                : item?.SportId === 12 &&
                                  item?.Favorite?.RLManagers?.RLManager?.name
                                ? item?.Favorite?.RLManagers?.RLManager?.name
                                : "N/A"}
                            </span>
                          </Typography>
                          {/* )} */}
                          <Typography className="tdetails">
                            Top point scorer: <span>N/A</span>
                          </Typography>
                        </Box>
                        <Box className="detail-row">
                          <Typography className="tdetails">
                            Captains:{" "}
                            <span
                              className="txt-underline"
                              style={{ textDecoration: "underline" }}
                            >
                              {item?.captains?.length > 0
                                ? item?.captains
                                    ?.map(obj => {
                                      return obj?.name;
                                    })
                                    .join(", ")
                                : "N/A"}
                            </span>
                          </Typography>
                          <Typography className="tdetails mr57">
                            Top point scorer:{" "}
                            <span>
                              {/* {item?.LY?.starts ? item?.LY?.starts : "0"}{" "}
                                {item?.LY?.first ? item?.LY?.first : "0"}-
                                {item?.LY?.second ? item?.LY?.second : "0"}-
                                {item?.LY?.thirds ? item?.LY?.thirds : "0"} */}
                              N/A
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box className="smart-top-header-details black-book-header flex-grow mt-9 blackbook-details">
                    {/* {item?.type === "Runner" ? (
                            <>{fetchRunnerData(item)}</>
                          ) : ( */}
                  </Box>
                  {isToggle ? (
                    <Box className="comment-section">
                      <Box className="runner-comment width-86">
                        <Typography className="runner">
                          {item?.SportId === 5 ||
                          item?.SportId === 6 ||
                          item?.SportId === 7 ||
                          item?.SportId === 16
                            ? "Player"
                            : item?.type}{" "}
                          :
                        </Typography>
                        <Typography className="runner comment">
                          {item?.comment}
                        </Typography>
                      </Box>
                      <Box className="width-14">
                        <Typography
                          className="edit-comment cursor-pointer"
                          onClick={() =>
                            handleEdit(item?.type, item?.tableId, item)
                          }
                        >
                          <EditIcon />
                          Edit comment
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif"
                }}
              >
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isAllSBLoading && allSBData && allSBData?.length > 0 ? (
          <Box className="pagination-section">
            <Pagination
              disabled={allSBCount / 10 > 1 ? false : true}
              page={allSBPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <OurPartner />
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <SmartBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        dataId={selectedId}
        sportId={typeId}
        editComment={editComment}
        isEdit={true}
        tableId={tableId}
        tabType="Blackbook"
        listingFunction={() => fetchAllSmartBook(allSBPage)}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default SmartBook;
