import React, { useState, useContext, useEffect } from "react";
import AdvertisingBannner from "../../../advertisingBanner";
import "./trackprofiledetailpage.scss";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Button
} from "@material-ui/core";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import axiosInstance from "src/helpers/Axios/axiosInstance";
// import Loader from "../../../../../components/Loader";
import OurPartner from "../../../ourPartners";
import Select from "react-select";
import NewsDetails from "./newsDetails";
import ResultsDetails from "./resultsDetails";
import RecordsDetails from "./recordsDetails";
import JockeyStatsDetails from "./jockeyStatsDetails";
import TrainerStatsDetails from "./trainerStatsDetails";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import { ReactComponent as BlackBookIcon } from "../../../../../assets/images/icons/blackbookicon.svg";
import BlackBookModal from "src/views/component/UI/blackBookModal";

import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import _ from "lodash";

const options = [
  { value: "Adaminaby, NSW, Australia", label: "Adaminaby, NSW, Australia" },
  { value: "Albury, NSW, Australia", label: "Albury, NSW, Australia" },
  { value: "Ardlethan, NSW, Australia", label: "Ardlethan, NSW, Australia" },
  { value: "Beaumont, NSW, Australia", label: "Beaumont, NSW, Australia" },
  { value: "Carinda, NSW, Australia", label: "Carinda, NSW, Australia" }
];

function TrackprofilesDetailPage() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const params = useParams();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [selectedOption, setselectedOption] = useState(Number(params?.trackId));
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [TrackListData, setTrackListData] = useState([]);
  const [headerData, setHeaderData] = useState({});
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [trackCount, setTrackCount] = useState(0);
  const [pageTrack, setpageTrack] = useState(0);
  const [isHeaderLoading, setisHeaderLoading] = useState(false);
  // const [trackName, settrackName] = useState(state?.label);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBlackBookModal = () => {
    setSelectedTitle(headerData?.track?.name);
    setSelectedType("Track");
    setSelectedId(headerData?.track?.id);
    handleModal();
  };
  const trackName = state?.label;

  const handleChange = (event, value, name) => {
    setValue(value);
    setName(name);
    fetchAdsData(value);
  };
  const trackTab = [
    // {
    //   id: 0,
    //   name: localesData?.STATISTICS?.NEWS
    // },
    {
      id: 0,
      name: localesData?.STATISTICS?.RESULTS
    },
    // {
    //   id: 1,
    //   name: localesData?.STATISTICS?.RECORDS
    // },
    {
      id: 1,
      name: `${localesData?.RUNNER_TABLE?.JOCKEY} ${localesData?.STATISTICS?.STATS}`
    },
    {
      id: 2,
      name: `${localesData?.RUNNER_TABLE?.TRAINER} ${localesData?.STATISTICS?.STATS}`
    }
  ];
  const fetchTrackList = async (Selectedtrack, offset) => {
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrackList?countryId=&stateId=&name=&limit=20&offset=${offset}`
      );
      if (status === 200) {
        const count = data?.count / 20;
        setTrackCount(count);
        let trackdata = [];
        const Tracklist = data?.result?.map(obj => {
          trackdata.push({
            label:
              obj?.name +
              (obj?.State ? "," + obj?.State?.state : "") +
              (obj?.Country ? "," + obj?.Country?.country : ""),
            value: obj?.id
          });
        });
        let filterData = _.unionBy(Selectedtrack, trackdata);
        const sortedData = filterData?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
        let finalData = _.uniqBy(sortedData, function(e) {
          return e.value;
        });
        setTrackListData(finalData);
      } else {
      }
    } catch (err) {}
  };
  const handleOnScrollBottomTrack = () => {
    if (trackCount !== Math.ceil(pageTrack / 20)) {
      fetchTrackList(TrackListData, pageTrack + 20);
      setpageTrack(pageTrack + 20);
    }
  };
  const fetchHeadingData = async () => {
    setisHeaderLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/headerData?trackId=${params?.trackId}`
      );
      if (status === 200) {
        setHeaderData(data?.result);
        setisHeaderLoading(false);
        const Selectedtrack = [
          {
            label:
              data?.result?.track?.name +
              (data?.result?.track?.State
                ? "," + data?.result?.track?.State?.state
                : "") +
              (data?.result?.track?.Country
                ? "," + data?.result?.track?.Country?.country
                : ""),
            value: data?.result?.track?.id
          }
        ];
        setTrackListData(Selectedtrack);
        fetchTrackList(Selectedtrack, 0);
      } else {
        setisHeaderLoading(false);
      }
    } catch (err) {
      setisHeaderLoading(false);
    }
  };
  useEffect(() => {
    fetchAdsData(0);
  }, []);
  useEffect(() => {
    fetchHeadingData();
  }, [params?.trackId]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = async tabValue => {
    try {
      let pageId =
        //  tabValue === 0 ? 25 : tabValue === 1 ? 26 : tabValue === 2 ? 27 : 28;
        tabValue === 0 ? 25 : tabValue === 1 ? 27 : 28;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="trackprofiledetailpage-wrap">
          <Box className="trackprofiledetailpage-header">
            {!isHeaderLoading ? (
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    {localesData?.MENU?.HOME}
                  </Link>
                  <Link underline="hover" color="inherit" to="">
                    {localesData?.MENU?.STATISTICS}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to="/statistics/trackprofiles"
                  >
                    {localesData?.MENU?.TRACKPROFILES}
                  </Link>
                  <Typography>
                    {headerData?.track?.name},
                    {headerData?.track?.Country?.country}
                  </Typography>
                </Breadcrumbs>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box className="trackprofile-detail">
            <Box className="select-wrap">
              <Select
                className="React"
                value={TrackListData?.find(item => {
                  return item?.value === selectedOption;
                })}
                onChange={e => {
                  navigate(`/statistics/trackprofiles/${e?.value}`);
                  setselectedOption(e?.value);
                }}
                onMenuScrollToBottom={e => handleOnScrollBottomTrack(e)}
                options={TrackListData}
                isOptionDisabled={option => option.disabled}
                classNamePrefix="select"
                isSearchable={false}
              />
            </Box>
            <Box className="profile-tab blackbook-icon-wrap">
              <Box>
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  textColor="primary"
                  className="profile-tab-detail"
                  disableRipple
                  disableFocusRipple
                >
                  {trackTab?.map((item, index) => {
                    return (
                      <Box>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={item?.name}
                          value={item?.id}
                          className={item?.id === value ? "active" : ""}
                          onChange={(event, newValue, newName) =>
                            handleChange(event, item?.id, item?.name)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
              <Box
                className="blackbook-button-wrap black-book-desktop"
                onClick={() => handleBlackBookModal()}
              >
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="blackbook-button"
                  // onClick={() => navigate("/sign-in")}
                >
                  <span style={{ display: "flex" }}>
                    <BlackBookIcon />{" "}
                  </span>{" "}
                  <span style={{ paddingLeft: "8px" }}>
                    {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                  </span>
                </Button>
              </Box>
            </Box>
            {/* {value === 0 ? (
              <Box className="tabs-header">
                <Typography className="tabs-para">
                  {localesData?.STATISTICS?.NEWS}
                </Typography>
                <Typography className="para-1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  fermentum consequat est, rhoncus scelerisque urna cursus ut.
                  Morbi pharetra mi ac ex mollis tristique.
                </Typography>
              </Box>
            ) :  */}
            {value === 0 ? (
              <Box className="tabs-header">
                <Typography className="tabs-para">
                  {localesData?.STATISTICS?.RESULTS}
                </Typography>
                <Typography className="para-1">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  fermentum consequat est, rhoncus scelerisque urna cursus ut.
                  Morbi pharetra mi ac ex mollis tristique. */}
                </Typography>
              </Box>
            ) : // value === 1 ? (
            //   <Box className="tabs-header">
            //     <Typography className="tabs-para">
            //       {localesData?.STATISTICS?.TRACK_RECORDS}
            //     </Typography>
            //     <Typography className="para-1">
            //       {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            //       fermentum consequat est, rhoncus scelerisque urna cursus ut.
            //       Morbi pharetra mi ac ex mollis tristique. */}
            //     </Typography>
            //   </Box>
            // )
            value === 1 ? (
              <Box className="tabs-header">
                <Typography className="tabs-para">
                  {localesData?.RUNNER_TABLE?.JOCKEY}{" "}
                  {localesData?.MENU?.STATISTICS}
                </Typography>
                <Typography className="para-1">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  fermentum consequat est, rhoncus scelerisque urna cursus ut.
                  Morbi pharetra mi ac ex mollis tristique. */}
                </Typography>
              </Box>
            ) : value === 2 ? (
              <Box className="tabs-header">
                <Typography className="tabs-para">
                  {localesData?.RUNNER_TABLE?.TRAINER}{" "}
                  {localesData?.MENU?.STATISTICS}
                </Typography>
                <Typography className="para-1">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  fermentum consequat est, rhoncus scelerisque urna cursus ut.
                  Morbi pharetra mi ac ex mollis tristique. */}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box className="blackbook-button-wrap black-book-mobile">
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                className="blackbook-button"
                onClick={() => handleBlackBookModal()}
              >
                <span style={{ display: "flex" }}>
                  <BlackBookIcon />{" "}
                </span>{" "}
                <span style={{ paddingLeft: "8px" }}>
                  {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                </span>
              </Button>
            </Box>
          </Box>
          <Box className="profils-tab-details">
            {/* {value === 0 ? (
              <NewsDetails />
            ) : */}
            {value === 0 ? (
              <>
                <ResultsDetails fetchAds={fetchAds} headerData={headerData} />
              </>
            ) : // : value === 1 ? (
            //   <>
            //     <RecordsDetails fetchAds={fetchAds} />
            //   </>
            // )
            value === 1 ? (
              <>
                <JockeyStatsDetails
                  name={name}
                  trackName={trackName}
                  fetchAds={fetchAds}
                />
              </>
            ) : value === 2 ? (
              <>
                <TrainerStatsDetails
                  name={name}
                  trackName={trackName}
                  fetchAds={fetchAds}
                />
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
        {fetchAds(2, "102px", AboveOurPartner)}
        <OurPartner />
        {fetchAds(3, "102px", BelowOurPartner)}
      </Box>
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
      />
    </>
  );
}
export default TrackprofilesDetailPage;
