import React, { useContext, useState, useEffect } from "react";
import { IntlContext } from "src/App";
import {
    Box,
    Button,
    TextField,
    Typography,
    FormControlLabel,
    FormLabel,
    Checkbox,
} from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as RightIcon } from "src/assets/images/icons/right.svg";
import { identifiers } from "src/helpers/constants/identifier";

import Loader from "src/components/Loader";
import { useNavigate } from "react-router";
const HomePageCustomisation = ({
    handleSelectedSports,
    selectedSports,
    formvalues,
    nextStep,
    fetchEditProfile,
    setformvalues,
}) => {
    const intlContext = useContext(IntlContext);
    const localesData = intlContext?.messages;

    const [isLoading, setIsLoading] = useState(false);
    const [homeArray, setHomeArray] = useState(identifiers?.customizeHome);

    useEffect(() => {
        setformvalues({
            ...formvalues,
            isDefaultHomePage: identifiers?.customizeHome[0]?.isSelected,
            isDefaultSportPage: identifiers?.customizeHome[1]?.isSelected,
            isDefaultRacingPage: identifiers?.customizeHome[2]?.isSelected
        });

    }, []);

    const handleChangeSelect = item => {
        if (!item?.isSelected) {
            const updatedArray = homeArray?.map(obj => ({
                ...obj,
                isSelected: obj?.id === item?.id
            }));
            setHomeArray(updatedArray);
            setformvalues({
                ...formvalues,
                isDefaultHomePage: updatedArray[0]?.isSelected,
                isDefaultSportPage: updatedArray[1]?.isSelected,
                isDefaultRacingPage: updatedArray[2]?.isSelected
            });
        }
    };
    return (
        <Box className="signup-homepage-customisation customise-wrapper">
            {isLoading ? (
                <div className="auth-loader">
                    <Loader />
                </div>
            ) : (
                <></>
            )}
            <Typography Typography className="textfield-text">
                {localesData?.register?.SELECT_HOMEPAGE}
            </Typography>

            <Box className="customisation-note-container">
                <ul className="note-wrap">
                    {identifiers?.customizeHome?.map((item) => {
                        return (
                            <li className="note-item"> <span className="textfield-text note-label">{item?.title + " " + item?.type} -</span><span className="textfield-text note-des">{item?.id === 1 ? localesData?.register?.DEFAULT_HOME_MSG : item?.id === 2 ? localesData?.register?.DEFAULT_SPORTS_MSG : localesData?.register?.DEFAULT_RACING_MSG}</span></li>
                        )
                    })}
                </ul>
            </Box>
            <Typography Typography className="textfield-text">
                {localesData?.register?.CUSTOMISE_PREFERENCE}
            </Typography>

            <Box className="signup-layout-listing">
                {
                    homeArray?.map((item, index) => {
                        return (
                            <Box className="customise-container " key={index}>
                                <Box className="customise-screen-name-container">
                                    <Typography className="customise-screen-name">
                                        {item?.title}
                                        <span className="customise-screen-name-default">
                                            {item?.type}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box className="customise-screen-button-container">
                                    {item?.isSelected ? (
                                        <Button
                                            className="customise-button customise-selected-btn"
                                            disableRipple
                                        >
                                            <RightIcon className="right-icon" /> Selected
                                        </Button>
                                    ) : (
                                        <Button
                                            className="customise-button customise-btn"
                                            onClick={() => handleChangeSelect(item)}
                                            disableRipple
                                        >
                                            Select
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
            <Button
                disableElevation
                disableFocusRipple
                disableRipple
                type="submit"
                className="submit-btn step1-btn stepper-next"
                onClick={() => {
                    nextStep();
                }}
            >
                {localesData?.register?.NEXT}
            </Button>

            <Button className="finish-btn" onClick={() => fetchEditProfile("skip")}>
                {" "}
                <a>{localesData?.register?.FINISH_MSG_STEPPER}</a>
            </Button>
        </Box>

    );
};

export default HomePageCustomisation;
