import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Breadcrumbs, Tabs, Tab } from "@material-ui/core";
import _ from "lodash";
import AdvertisingBannner from "../../../../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../../../AdBanner/AdBanner";
import Loader from "src/components/Loader";
import moment from "moment";
import "./playerData.scss";
import PlayerSummaryDetails from "./playerSummaryDetails";
import PlayerGamesDetails from "./playerGamesDetails";

const newsTitle = [
  { value: 0, label: "Summary" },
  { value: 1, label: "Games" }
  // { value: 2, label: "Opposite no." },
  // { value: 3, label: "Opposition teams" },
  // { value: 4, label: "Positions" }
];

const PlayerDataPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const tabsRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const SeasonId = searchParams.get("seasonId");
  const [teamTabvalue, setTeamTabValue] = useState(0);
  const [headerData, setHeaderData] = useState({});
  const [playerDetailsLoader, setPlayerDetailsLoader] = useState(false);
  const [playerData, setPlayerData] = useState([]);
  const [playerSummary, setPlayerSummary] = useState({});
  const [playerRelation, setPlayerRelation] = useState([]);
  const [gamesPlayerRelation, setGamesPlayerRelation] = useState([]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleChange = (id, value) => {
    setTeamTabValue(id);
  };

  const routeName =
    params?.sportId === "4"
      ? "cricket"
      : params?.sportId === "12"
      ? "rugbyleague"
      : params?.sportId === "13"
      ? "rugbyunion"
      : params?.sportId === "10"
      ? "basketball"
      : params?.sportId === "15"
      ? "americanfootball"
      : params?.sportId === "9"
      ? "australianrules"
      : params?.sportId === "16"
      ? "golf"
      : params?.sportId === "7"
      ? "tennis"
      : params?.sportId === "11"
      ? "baseball"
      : params?.sportId === "17"
      ? "icehockey"
      : params?.sportId === "6"
      ? "boxing"
      : params?.sportId === "5"
      ? "mma"
      : params?.sportId === "8"
      ? "soccer"
      : "";

  useEffect(() => {
    fetchBreacrumnData();
    fetchPlayerDetails();
  }, [params, SeasonId]);

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header/?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&teamId=${params?.teamId}&playerId=${params?.playerId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchPlayerDetails = async () => {
    setPlayerDetailsLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/player/summary/${params?.playerId}?SportId=${
          params?.sportId
        }&tournamentId=${params?.tournamentId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`
      );
      if (status === 200) {
        setPlayerDetailsLoader(false);
        setPlayerSummary(data?.result?.player);
        setPlayerRelation(data?.result?.playerRelation);
        setPlayerData(data?.result?.tournament);
      } else {
        setPlayerDetailsLoader(false);
      }
    } catch (err) {
      setPlayerDetailsLoader(false);
    }
  };
  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" to="3">
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/${routeName}/odds/0/false`}
                  >
                    {headerData?.sport?.sportName}
                  </Link>
                  {/* <Link underline="hover" color="inherit" to="/">
                    [team Sport Data]
                  </Link> */}
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/sport/statistics/${params?.sportId}/${
                      params?.tournamentId
                    }/league-data?seasonId=${SeasonId ? SeasonId : ""}`}
                  >
                    {SeasonId
                      ? headerData?.season?.name
                      : headerData?.tournament?.name}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/sport/statistics/${params?.sportId}/${
                      params?.tournamentId
                    }/league-data/${params?.teamId}/team-data?seasonId=${
                      SeasonId ? SeasonId : ""
                    }`}
                  >
                    {headerData?.team?.name}
                  </Link>
                  <Typography>{headerData?.player?.name}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">{headerData?.player?.name}</Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={teamTabvalue}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details player-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {newsTitle.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == teamTabvalue ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
            {teamTabvalue === 0 || teamTabvalue === 1 ? (
              <>
                <Box className="tab-deatils">
                  <Box className="tab-details-grid">
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Born:
                      </Typography>
                      <Typography className="details-text">
                        {playerSummary?.dob
                          ? moment(playerSummary?.dob).format(
                              "dddd, Do MMMM, YYYY"
                            )
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Current Age:
                      </Typography>
                      <Typography className="details-text">
                        {playerSummary?.dob
                          ? moment().diff(moment(playerSummary?.dob), "years")
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Place of Birth:
                      </Typography>
                      <Typography className="details-text">
                        {playerSummary?.Country
                          ? playerSummary?.Country?.country
                          : "-"}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Sport:
                      </Typography>
                      {teamTabvalue === 0 ? (
                        <Typography className="details-text">
                          {_.uniqBy(playerRelation, function(e) {
                            return e?.toSport?.id;
                          }).map((item, index, array) => {
                            return (
                              (item?.toSport?.sportName ?? "") +
                              (index < array.length - 1 ? ", " : "")
                            );
                          })}
                        </Typography>
                      ) : (
                        <Typography className="details-text">
                          {_.uniqBy(gamesPlayerRelation, function(e) {
                            return e?.toSport?.id;
                          }).map((item, index, array) => {
                            return (
                              (item?.toSport?.sportName ?? "") +
                              (index < array.length - 1 ? ", " : "")
                            );
                          })}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {/* <Box className="team-deatils-wrap">
                    <Typography className="team-deatils">
                      All statistics shown in this section are based only on
                      data available in the RLP database, and are not
                      necessarily a complete and/or 100% accurate representation
                      of a player's career. This information should be used as a
                      guide only.
                    </Typography>
                  </Box> */}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>

          {/* All tab deatils add here condition add like teamTabvalue === 0 ? "" : "" */}

          {teamTabvalue === 0 ? (
            <PlayerSummaryDetails
              playerData={playerData}
              setPlayerDetailsLoader={setPlayerDetailsLoader}
              playerDetailsLoader={playerDetailsLoader}
            />
          ) : teamTabvalue === 1 ? (
            <PlayerGamesDetails
              setGamesPlayerRelation={setGamesPlayerRelation}
            />
          ) : (
            <Box className="no-data-wrap">
              <Typography className="text-align-center no-data">
                No data Available
              </Typography>
            </Box>
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default PlayerDataPage;
