import React, { useEffect } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Config } from "src/helpers/context";
import Avatar from "src/assets/images/user-avatar.png";
import { useState } from "react";
import moment from "moment-timezone";
import "./activity.scss";
import TipperChatModal from "../../UI/tipperChatModal";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import NoDataComp from "../../UI/NoData";

// const activityData = [
//   {
//     id: 1,
//     user: "Chloe Royle",
//     date: "2023-11-23T22:35:27.000Z"
//   },
//   {
//     id: 2,
//     user: "Chloe Royle",
//     date: "2023-11-23T22:35:27.000Z"
//   },
//   {
//     id: 3,
//     user: "Chloe Royle",
//     date: "2023-11-23T22:35:27.000Z"
//   }
// ];

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function ActivityTab({ params, typevalue, activityData, loading }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      <Box className="activity-box">
        {loading ? (
          <div className="allsport-loader-center app-loader">
            <Loader />
          </div>
        ) : activityData && activityData?.length > 0 ? (
          activityData?.map((item, index) => {
            return (
              <Box className="activity-sec" key={index}>
                <Box className="user-img">
                  <img src={Avatar} alt="avatar" />
                </Box>
                <Box className="activity-detail">
                  <Typography className="tip-txt">
                    <span className="user-name">
                      {item?.User?.firstName + " " + item?.User?.lastName}
                    </span>{" "}
                    tipped on{" "}
                    {moment
                      .utc(item?.createdAt)
                      .tz(timezone)
                      .format("dddd, DD MMM")}
                    .
                  </Typography>
                  <Typography className="time-txt">
                    {moment
                      .utc(item?.createdAt)
                      .tz(timezone)
                      .format("dddd")}
                    ,{" "}
                    {moment
                      .utc(item?.createdAt)
                      .tz(timezone)
                      .format("DD MMM")}{" "}
                    at{" "}
                    {moment
                      .utc(item?.createdAt)
                      .tz(timezone)
                      .format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              {/* No Data Available */}
              <NoDataComp />
            </Box>
          </>
        )}
        {}
      </Box>
      {/* <TipperChatModal
        open={openChatModal}
        handleClose={handleClose}
        title={"Chloe Royle"}
        closeIcon={true}
      /> */}
    </>
  );
}

export default ActivityTab;
