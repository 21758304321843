import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { Box, Grid, Button, TextField, Typography } from "@material-ui/core";
import Select, { components } from "react-select";
import { Config } from "src/helpers/context";
import AuthTextLogo from "src/assets/images/signup-img/auth-text-logo.webp";
import profileImage from "src/assets/images/profileImage.png";
import { ReactComponent as AddButton } from "src/assets/images/round-add-icon.svg";

import Loader from "src/components/Loader";
import { useNavigate } from "react-router";
import UploadImageModal from "../../UI/uploadImage";

const ProfileDetails = ({
  formvalues,
  setformvalues,
  registerV2,
  isLoading,
  handleOnScrollBottomCountry,
  handleCountryInputChange,
  isCountrySearch,
  searchCountry,
  country,
  setCountryId,
  isCountrySelectOpen,
  setisCountrySelectOpen,
  handleOnScrollBottomState,
  handleStateInputChange,
  isStateSearch,
  searchState,
  state,
  setisStateSelectOpen,
  setStateApiCount,
  fetchState,
  setState,
  setpageState,
  isStateSelectOpen,
  nextStep,
  fetchEditProfile,
  fetchSportData,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { ValueContainer, Placeholder } = components;
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [isGenderSelectOpen, setisGenderSelectOpen] = useState(null);

  useEffect(() => {
    fetchSportData();
  }, []);

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = (data) => {
    setCropImage(data);
    setformvalues((prevFormValues) => ({
      ...prevFormValues,
      mediaId: data?.id,
      mediaUrl: data?.filePath,
    }));
  };

  const genderOption = [
    {
      label: "Male",
      value: "m",
    },
    {
      label: "Female",
      value: "f",
    },
    {
      label: "Non-binary",
      value: "nb",
    },
    {
      label: "Prefer not to say",
      value: "pnts",
    },
  ];

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          nickName: formvalues?.nickName || "",
          // lastname:
          //     formvalues?.lastname && formvalues?.lastname !== ""
          //         ? formvalues?.lastname
          //         : "",
          gender:
            formvalues?.gender && formvalues?.gender !== ""
              ? formvalues?.gender
              : "",
          //   country:
          //     formvalues?.country && formvalues?.country !== ""
          //       ? formvalues?.country
          //       : "",
          //   state:
          //     formvalues?.state && formvalues?.state !== ""
          //       ? formvalues?.state
          //       : "",
        }}
        validationSchema={Yup.object().shape({
          nickName: Yup.string()
            .trim()
            .required(localesData?.validation?.required_message),
          // lastname: Yup.string()
          //     .trim()
          //     .required(localesData?.validation?.required_message)
          //     .test(
          //         "lastname",
          //         "Only letters are allowed for last name",
          //         (value) => value && value.match(/^[\p{L}]+$/gu)
          //     ),
          gender: Yup.string().required(
            localesData?.validation?.required_message
          ),
          //   country: Yup.string().required(
          //     localesData?.validation?.required_message
          //   ),
          //   state: Yup.string().required(
          //     localesData?.validation?.required_message
          //   ),
        })}
        onSubmit={() => nextStep()}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className="step1-container">
              {isLoading ? (
                <div className="auth-loader">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
              <Typography className="signup-header">
                {localesData?.register?.SIGNUP_WELCOME_MSG_1}
                <img src={AuthTextLogo} className="text-logo" alt="logo" />
                {localesData?.register?.SIGNUP_WELCOME_MSG_2}
              </Typography>
              <Box className="profile-image-name-wrap">
                <Box
                  className="profile-image-wrap"
                  onClick={() => handleImageModal()}
                >
                  <img
                    className="profile-image"
                    src={
                      cropImage?.filePath
                        ? Config?.countryMediaURL + cropImage?.filePath
                        : profileImage
                    }
                    alt="profile-image"
                  />
                  <AddButton className="add-button" />
                </Box>
                <Box
                  className="profile-image-text-wrap"
                  onClick={() => handleImageModal()}
                >
                  <Typography className="image-text">
                    {localesData?.register?.UPLOAD_PROFILE}
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    {/* <Typography className="textfield-text">
                                        {localesData?.register?.first_name}
                                    </Typography> */}
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      label="Username"
                      type="text"
                      name="nickName"
                      className="text-field"
                      error={Boolean(touched?.nickName && errors?.nickName)}
                      helperText={touched?.nickName ? errors?.nickName : ""}
                      onChange={(e) => {
                        handleChange(e);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          [e?.target?.name]: e?.target?.value,
                        }));
                      }}
                      value={values?.nickName}
                      onBlur={handleBlur}
                      // onKeyDown={(e) => {
                      //     if (!/[\p{L}.' \-]/gu.test(e.key)) e.preventDefault();
                      // }}
                      // value={formvalues?.nickName && formvalues?.nickName !== "" ? formvalues?.nickName : values?.nickName}
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                                    <Box className="textfield-sec">
                                     
                                        <TextField
                                            placeholder={""}
                                            variant="outlined"
                                            type="text"
                                            name="lastname"
                                            label="Last name"
                                            className="text-field"
                                            error={Boolean(touched?.lastname && errors?.lastname)}
                                            helperText={touched?.lastname ? errors?.lastname : ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setformvalues((prevFormValues) => ({
                                                    ...prevFormValues,
                                                    [e?.target?.name]: e?.target?.value,
                                                }));
                                            }}
                                            // values={formvalues?.lastname && formvalues?.lastname !== "" ? formvalues?.lastname : values?.lastname}
                                            value={values?.lastname}
                                            onBlur={handleBlur}
                                            onKeyDown={(e) => {
                                                if (!/[\p{L}]/gu.test(e.key)) e.preventDefault();
                                            }}
                                        />
                                    </Box>
                                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      placeholder={localesData?.register?.GENDER}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? -11
                              : "auto",
                          backgroundColor:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "white"
                              : "transparent",
                          transition: "top 2s, font-size 0.1s !important",
                          fontSize:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "12px !important",
                          color:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "#4455c7"
                              : "#a4a4a4",
                          padding:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "0px 3px",
                          paddingLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "1px !important",
                          marginLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "7px !important",
                          lineHeight:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "8px !important",
                        }),
                      }}
                      value={genderOption?.find((item) => {
                        const genderValue =
                          formvalues?.gender && formvalues?.gender !== ""
                            ? formvalues?.gender
                            : values?.gender;
                        return item?.value == genderValue;
                      })}
                      onChange={(e) => {
                        setFieldValue("gender", e?.value);

                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          gender: e?.value,
                        }));
                      }}
                      isFocused={isGenderSelectOpen}
                      onFocus={() => setisGenderSelectOpen(true)}
                      onBlur={() => setisGenderSelectOpen(false)}
                      options={genderOption}
                    />

                    <span className="text-danger">
                      {touched?.gender && errors?.gender ? errors?.gender : ""}
                    </span>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      onMenuScrollToBottom={(e) =>
                        handleOnScrollBottomCountry(e)
                      }
                      onInputChange={(e) => handleCountryInputChange(0, e)}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      placeholder={localesData?.register?.country_of_residence}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? -11
                              : "auto",
                          backgroundColor:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "white"
                              : "transparent",
                          transition: "top 2s, font-size 0.1s !important",
                          fontSize:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "12px !important",
                          color:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "#4455c7"
                              : "#a4a4a4",
                          padding:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "0px 3px",
                          paddingLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "1px !important",
                          marginLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "7px !important",
                          lineHeight:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "8px !important",
                        }),
                      }}
                      value={
                        isCountrySearch
                          ? searchCountry?.find((item) => {
                              return item?.value == country;
                            })
                          : country?.find((item) => {
                              const countryValue =
                                formvalues?.country &&
                                formvalues?.country !== ""
                                  ? formvalues?.country
                                  : values?.country;
                              return item?.value == countryValue;
                            })
                      }
                      onChange={(e) => {
                        setFieldValue("country", e?.value);
                        fetchState(e?.value, 0, true, 0);
                        setCountryId(e?.value);
                        setFieldValue("state", "");
                        setState([]);
                        setStateApiCount(0);
                        setpageState(0);
                        setisCountrySelectOpen(false);
                        // setstateValue("");
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          country: e?.value,
                        }));
                      }}
                      isFocused={isCountrySelectOpen}
                      onFocus={() => setisCountrySelectOpen(true)}
                      onBlur={() => setisCountrySelectOpen(false)}
                      options={isCountrySearch ? searchCountry : country}
                    />

                    <span className="text-danger">
                      {touched?.country && errors?.country
                        ? errors?.country
                        : ""}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="textfield-sec">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      onMenuScrollToBottom={(e) => handleOnScrollBottomState(e)}
                      onInputChange={(e) => handleStateInputChange(0, e)}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      placeholder={localesData?.register?.state}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? -11
                              : "auto",
                          backgroundColor:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "white"
                              : "transparent",
                          transition: "top 2s, font-size 0.1s !important",
                          fontSize:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "12px !important",
                          color:
                            state.hasValue ||
                            state.selectProps.inputValue ||
                            state.selectProps.isFocused
                              ? "#4455c7"
                              : "#a4a4a4",
                          padding:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "0px 3px",
                          paddingLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "1px !important",
                          marginLeft:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "7px !important",
                          lineHeight:
                            (state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused) &&
                            "8px !important",
                        }),
                      }}
                      value={
                        isStateSearch
                          ? searchState?.find((item) => {
                              return item?.value == state;
                            })
                          : (values?.state !== "" ||
                              formvalues?.state !== "") &&
                            state?.find((item) => {
                              const stateValue =
                                formvalues?.state && formvalues?.state !== ""
                                  ? formvalues?.state
                                  : values?.state;
                              return item?.value == stateValue;
                            })
                      }
                      onChange={(e) => {
                        setFieldValue("state", e?.value);
                        setisStateSelectOpen(false);
                        setStateApiCount(0);
                        // setstateValue(e?.value);
                        setformvalues((prevFormValues) => ({
                          ...prevFormValues,
                          state: e?.value,
                        }));
                      }}
                      isFocused={isStateSelectOpen}
                      onFocus={() => setisStateSelectOpen(true)}
                      onBlur={() => setisStateSelectOpen(false)}
                      options={isStateSearch ? searchState : state}
                    />
                    <span className="text-danger">
                      {touched?.state && errors?.state ? errors?.state : ""}
                    </span>
                  </Box>
                </Grid> */}
              </Grid>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                type="submit"
                className="submit-btn step1-btn stepper-next"
                // onClick={() => {
                //   nextStep();
                // }}
              >
                {localesData?.register?.NEXT}
              </Button>
              <Box
                className="finish-btn"
                onClick={() => {
                  fetchEditProfile("skip");
                }}
              >
                {" "}
                <a>{localesData?.register?.FINISH_MSG_STEPPER}</a>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <UploadImageModal
        open={imageModalOpen}
        handleClose={handleImageClose}
        title={"Upload Profile Picture"}
        closeIcon={false}
        getImage={getImage}
        //  listingFunction={() =>
        //    setTimeout(() => {
        //      getEventByID(stepperCount);
        //    }, 3000)
        //  }
      />
    </>
  );
};

export default ProfileDetails;
