import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Breadcrumbs,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Config } from "src/helpers/context";
import AdvertisingBannner from "../../advertisingBanner";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import AddToCalendar from "../AddToCalendar";
import Loader from "src/components/Loader";
import { ReactComponent as ArrowRightIcon } from "src/assets/images/eventcalendar/arrowRight.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import OurPartner from "src/views/component/ourPartners";
import moment from "moment-timezone";
import "./eventdetails.scss";
import AdBannner from "../../AdBanner/AdBanner";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const EventDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  // const [tabledata, setTabledata] = useState([{}, {}]);
  const [isLoading, setLoading] = useState(false);
  const [isEventLoading, setEventLoading] = useState(false);
  const [eventData, setEventData] = useState();
  const [relatedEventData, setRelatedEventData] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const geteventData = async (eId, sId) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/eventCalendar/getevent?sportId=${sId}&eventid=${eId}`
      );
      if (status === 200) {
        setLoading(false);
        setEventData(data?.result);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getRelatedeventData = async (sId, tId) => {
    setEventLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/eventCalendar/reletedEvent?sportId=${sId}&tournamentid=${tId}`
      );
      if (status === 200) {
        setEventLoading(false);
        data &&
          data?.result &&
          data?.result.length > 0 &&
          setRelatedEventData(data?.result.slice(0, 6));
      }
    } catch (err) {
      setEventLoading(false);
    }
  };
  useEffect(() => {
    if (params && params?.id && params?.SportId) {
      geteventData(params?.id, params?.SportId);
    }
    if (params?.SportId || params?.tournamentId) {
      var Tid =
        params?.tournamentId && params?.tournamentId !== null
          ? params?.tournamentId
          : "";
      var sid =
        params?.SportId !== null && params?.SportId ? params?.SportId : "";
      getRelatedeventData(sid, Tid);
    }
  }, [params?.id, params?.SportId, params?.tournamentId]);
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  return (
    <Box className="content-wrap">
      {/* <AdvertisingBannner src={banner} /> */}
      {fetchPageHeaderAds(
        "218px",
        Config?.release == "IN" ? bannerIndia : banner
      )}
      <Box className="league-data-wrap">
        <Box className="teamsport-data-header">
          <Box className="bredcrumn-deatils">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  Home
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/${params?.sportType}/event-calendar`}
                >
                  Events
                </Link>
                <Typography>
                  {eventData?.eventName ? eventData?.eventName : ""}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Box>
        </Box>
      </Box>
      {isLoading && !eventData ? (
        <Box style={{ textAlign: "center" }}>
          <Loader />
        </Box>
      ) : (
        <Box className="Event-details">
          {eventData && (
            <Box className="event-details-head">
              <Box className="event-details-left">
                <Typography className="event-title">
                  {eventData?.eventName ? eventData?.eventName : ""}
                </Typography>
                <Typography className="event-text">
                  {moment(eventData?.eventDate)
                    .tz(timezone)
                    .format("MMMM DD @h:mm A")}
                </Typography>
              </Box>
              <Box>
                <AddToCalendar event={eventData} />
              </Box>
            </Box>
          )}

          <Box>
            {eventData ? (
              <>
                {screenWidth > 560 ? (
                  <Box className="event-calendar-table-design">
                    <Table aria-label="simple table" className="event-table">
                      <TableHead className="event-table-head">
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            Details
                          </TableCell>
                          <TableCell className="event-table-cell">
                            Venue
                          </TableCell>
                          <TableCell className="event-table-cell">
                            Organiser
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="event-table-body">
                        {/* {tabledata &&
                      tabledata.length > 0 &&
                      tabledata.map(t => {
                        return ( */}
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            <Typography className="cell-text  mb-8">
                              <Typography
                                className="cell-text cell-text-bold"
                                variant="span"
                              >
                                {" "}
                                Date :{" "}
                              </Typography>
                              <Typography className="cell-text" variant="span">
                                {" "}
                                {moment(eventData?.eventDate)
                                  .tz(timezone)
                                  .format("MMMM DD")}
                              </Typography>
                            </Typography>
                            <Typography className="cell-text mb-8">
                              <Typography
                                className="cell-text cell-text-bold"
                                variant="span"
                              >
                                {" "}
                                Time :{" "}
                              </Typography>
                              <Typography className="cell-text" variant="span">
                                {moment(eventData?.eventDate)
                                  .tz(timezone)
                                  .format("h:mm A")}
                              </Typography>
                            </Typography>
                            {eventData?.sportName ? (
                              <Typography className="cell-text">
                                <Typography
                                  className="cell-text cell-text-bold"
                                  variant="span"
                                >
                                  {" "}
                                  Sport :{" "}
                                </Typography>
                                <Typography
                                  className="cell-text cell-text-blue"
                                  variant="span"
                                >
                                  {" "}
                                  {eventData?.sportName}
                                </Typography>
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell className="event-table-cell">
                            <Typography className="cell-text cell-text-blue">
                              {eventData?.venues ? eventData?.venues : ""}
                            </Typography>
                          </TableCell>
                          <TableCell className="event-table-cell">
                            <Typography className="cell-text cell-text-blue">
                              {eventData?.tournamentname
                                ? eventData?.tournamentname
                                : ""}
                            </Typography>
                            {/* <Typography className="cell-text cell-text-blue">
                          View Organiser Website
                        </Typography> */}
                          </TableCell>
                        </TableRow>
                        {/* );
                      })} */}
                      </TableBody>
                    </Table>
                  </Box>
                ) : (
                  <Box className="event-calendar-table-design">
                    <Table aria-label="simple table" className="event-table">
                      {/* {tabledata &&
                tabledata.length > 0 &&
                tabledata.map((t, index) => {
                  return (
                    <> */}
                      {/* {index === 0 && ( */}
                      <TableHead className="event-table-head">
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {/* )} */}
                      <TableBody className="event-table-body">
                        {/* {tabledata &&
                      tabledata.length > 0 &&
                      tabledata.map((t, index) => { 
                        return (*/}
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            <Typography className="cell-text">
                              <Typography
                                className="cell-text cell-text-bold"
                                variant="span"
                              >
                                {" "}
                                Date :{" "}
                              </Typography>
                              <Typography className="cell-text" variant="span">
                                {" "}
                                {moment(eventData?.eventDate)
                                  .tz(timezone)
                                  .format("MMMM DD")}
                              </Typography>
                            </Typography>
                            <Typography className="cell-text">
                              Time :{" "}
                              <Typography className="cell-text" variant="span">
                                {moment(eventData?.eventDate)
                                  .tz(timezone)
                                  .format("h:mm A")}
                              </Typography>
                            </Typography>
                            {eventData?.sportName ? (
                              <Typography className="cell-text">
                                <Typography
                                  className="cell-text cell-text-bold"
                                  variant="span"
                                >
                                  {" "}
                                  Sport :{" "}
                                </Typography>
                                <Typography
                                  className="cell-text cell-text-blue"
                                  variant="span"
                                >
                                  {" "}
                                  {eventData?.sportName}
                                </Typography>
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                        {/* );
                      })} */}
                      </TableBody>
                      {/* {index === 0 && ( */}
                      <TableHead className="event-table-head">
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            Venue
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {/* )} */}
                      <TableBody className="event-table-body">
                        {/* {tabledata &&
                      tabledata.length > 0 &&
                      tabledata.map((t, index) => {
                        return ( */}
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            <Typography className="cell-text cell-text-blue">
                              {eventData?.venues ? eventData?.venues : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {/* );
                      })} */}
                      </TableBody>
                      {/* {index === 0 && ( */}
                      <TableHead className="event-table-head">
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            Organiser
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {/* )} */}
                      <TableBody className="event-table-body">
                        {/* {tabledata &&
                      tabledata.length > 0 &&
                      tabledata.map((t, index) => {
                        return ( */}
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            <Typography className="cell-text cell-text-blue">
                              {eventData?.tournamentname
                                ? eventData?.tournamentname
                                : ""}
                            </Typography>
                            {/* <Typography className="cell-text cell-text-blue">
                          View Organiser Website
                        </Typography> */}
                          </TableCell>
                        </TableRow>
                        {/* );
                      })} */}
                      </TableBody>
                      {/* </> */}
                      {/* );
                })} */}
                    </Table>
                  </Box>
                )}
              </>
            ) : (
              <Box style={{ textAlign: "center" }}>
                {" "}
                <Typography className="no-data-available">
                  No Event Data Available
                </Typography>{" "}
              </Box>
            )}

            {screenWidth < 991 && (
              <Box className="News-wrap">
                <Typography variant="h4" className="news-heading">
                  Related Events{" "}
                  {/* <span className='right-arrow'>
              <RightArrow />
            </span> */}
                </Typography>
                {isEventLoading && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {/* {!isEventLoading && relatedEventData?.length === 0 && (
                  <Box style={{ textAlign: "center" }}>
                    {" "}
                    <Typography className="no-data-available">
                      No Data Available
                    </Typography>{" "}
                  </Box>
                )} */}
                {!isEventLoading && relatedEventData?.length > 0 ? (
                  relatedEventData?.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        className="related-event cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/${params?.sportType}/event-calendar/details/${item?.eventId}/${item?.sportId}/${item?.tournamentId}`
                          )
                        }
                      >
                        <Box className="event-data">
                          <Typography className="event-title">
                            {item?.eventName}
                          </Typography>
                          <Typography className="event-text">
                            {moment(item?.eventDate)
                              .tz(timezone)
                              .format("MMMM DD @h:mm A")}
                          </Typography>
                        </Box>
                        <ArrowRightIcon />
                      </Box>
                    );
                  })
                ) : (
                  <Box style={{ textAlign: "center" }}>
                    {" "}
                    <Typography className="no-data-available">
                      No Data Available
                    </Typography>{" "}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
      <OurPartner />
    </Box>
  );
};

export default EventDetails;
