import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Collapse
} from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { IntlContext } from "src/App";
import { Config } from "src/helpers/context";
import Select, { components } from "react-select";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipShareToggle } from "src/helpers/store/Actions/BetSlipShareToggle";
import { ReactComponent as BetSlipArrow } from "../../assets/images/icons/betSlipArrow.svg";
import { ReactComponent as Notepad } from "../../assets/images/icons/notepad.svg";
import NotepadBig from "../../assets/images/notepadBig.png";
import RaceHorses from "../../assets/images/Sport/race_hourses.svg";
import RaceGreyhounds from "../../assets/images/Sport/races_greyhounds.svg";
import RaceHarness from "../../assets/images/Sport/race_harness.svg";
import { ReactComponent as AmericanFootball } from "../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as ShareSlip } from "src/assets/images/icons/shareSlip.svg";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { fetchPublicBookkeeperData } from "src/helpers/store/Actions/PublicBookkeeperData";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const options = [
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" }
  // { value: "3", label: "Win Tote" },
  // { value: "4", label: "Place Tote" }
  // { value: "4", label: "Starting Price" },
];

const BetSlipCollapse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const BetSlipToggleData = useSelector(
    state => state?.reduxData?.BetSlipToggle
  );
  const BetslipReduxData = useSelector(state => state?.reduxData?.BetSlipData);
  const restrictedUser = useSelector(state => state?.reduxData?.RestrictedData);
  const reduxBookkeeperData = useSelector(
    state => state?.reduxData?.BookkeeperData
  );
  const token = localStorage.getItem("auth_token");
  const [betSlipData, setBetSlipData] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [open, setOpen] = useState(false);
  const [progress, setprogress] = useState(false);
  const [cartKey, setCartKey] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (restrictedUser) {
      handleModal();
    }
  }, [restrictedUser]);

  useEffect(() => {
    token && fetchBetslipData();
    // fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (BetSlipToggleData) {
      fetchBetslipData();
    }
  }, [BetSlipToggleData]);
  useEffect(() => {
    // if (BetslipReduxData) {
    //   fetchBetslipData();
    // }
    BetslipReduxData &&
      BetslipReduxData?.length > 0 &&
      BetslipReduxData?.length !== betSlipData?.length &&
      fetchBetslipData();
  }, [BetslipReduxData]);

  const raceIcon = sportId => {
    if (sportId === 1) {
      return RaceHorses;
    } else if (sportId === 2) {
      return RaceHarness;
    } else if (sportId === 3) {
      return RaceGreyhounds;
    } else if (sportId === 15) {
      return <AmericanFootball />;
    } else if (sportId === 9) {
      return <AustralianRules />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MixedMartialArts />;
    } else if (sportId === 12) {
      return <RugbyLeague />;
    } else if (sportId === 13) {
      return <RugbyUnion />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };
  const handleBetSlipClose = () => {
    dispatch(fetchBetSlipToggle(false));
    document.body.classList.remove("no-scroll");
  };

  const fetchBetslipData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/betSlipCard/BetSlipCard?OddKeyId=1`
      );
      if (status === 200) {
        const editedData = data?.result?.map(item => {
          return {
            ...item,
            selectedMarket: 1,
            returns: item?.stack ? item?.stack * item?.odd : 0
          };
        });
        setCartKey(data?.cartKey);
        setBetSlipData(editedData);
        dispatch(fetchBetSlipData(editedData));
        dispatch(fetchBetSlipCount(editedData?.length));
      } else {
      }
    } catch (err) {}
  };

  const handleSelectedMarketChange = (value, item) => {
    let chageData = betSlipData?.map(obj => {
      if (obj?.id === item?.id) {
        return {
          ...obj,
          selectedMarket: Number(value),
          returns:
            Number(value) === 1
              ? (Number(item?.stack) || 0) * Number(item?.odd || 0)
              : (Number(item?.stack) || 0) * Number(item?.oddPlace || 0)
        };
      } else {
        return obj;
      }
    });
    dispatch(fetchBetSlipData(chageData));
    setBetSlipData(chageData);
  };
  const handleStackChange = (value, item) => {
    let chageData = betSlipData?.map(obj => {
      if (obj?.id === item?.id) {
        return {
          ...obj,
          returns:
            item?.selectedMarket === 1
              ? (Number(value) || 0) * Number(item?.odd || 0)
              : (Number(value) || 0) * Number(item?.oddPlace || 0),
          stack: value !== "" ? Number(value) : null
        };
      } else {
        return obj;
      }
    });
    dispatch(fetchBetSlipData(chageData));
    setBetSlipData(chageData);
    // handleUpdateBetslip(item, value);
  };
  const handleUpdateBetslip = async (item, value) => {
    try {
      const payload = {
        stack: value !== "" ? Number(value) : null
      };
      const { status, data } = await axiosInstance.put(
        `BetSlipCard/betSlipCard/${item?.id}`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {}
  };
  const handleDeleteBetslip = async item => {
    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${item?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = betSlipData?.filter(obj => obj?.id !== item?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(betSlipData?.length - 1));
        dispatch(fetchBetSlipShareToggle(true));
        setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  const handleClearAll = async item => {
    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?isAll=true`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        dispatch(fetchBetSlipData([]));
        dispatch(fetchBetSlipCount(0));
        dispatch(fetchBetSlipShareToggle(true));
        setBetSlipData([]);
      } else {
      }
    } catch (err) {}
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  const handleTotalData = () => {
    const totalTask = betSlipData?.reduce(
      (total, item) => total + (item?.stack || 0),
      0
    );
    const potentialReturn = betSlipData?.reduce(
      (total, item) => total + (item?.returns || 0),
      0
    );
    return (
      <>
        <Box className="betslip-details mb-4">
          <Typography className="details-text">Total Stake:</Typography>
          <Typography className="details-text">
            {totalTask ? totalTask : 0.0}
          </Typography>
        </Box>
        <Box className="betslip-details">
          <Typography className="details-text">Potential returns:</Typography>
          <Typography className="details-text">
            {potentialReturn ? formatter.format(potentialReturn) : 0.0}
          </Typography>
        </Box>
      </>
    );
  };
  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
        dispatch(fetchPublicBookkeeperData(data?.result));
      } else {
        dispatch(fetchPublicBookkeeperData([]));
      }
    } catch (err) {
      dispatch(fetchPublicBookkeeperData([]));
    }
  };

  const oddsicon = BookKeeperId => {
    let icon = reduxBookkeeperData?.filter(obj => obj?.id === BookKeeperId);
    let iconData = icon?.[0];

    return (
      <img
        className="bookmaker-thumb"
        src={
          iconData?.small_logo?.includes("uploads")
            ? Config.mediaURL + iconData?.small_logo
            : iconData?.small_logo
        }
        alt="Odds Icon"
      />
    );
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  const handleshareBetSlipNavigate = id => {
    navigate(`/social-betslipshare?cartKey=${id}`);
    dispatch(fetchBetSlipShareToggle(true));
    if (screenWidth < 799) {
      dispatch(fetchBetSlipToggle(false));
      document.body.classList.remove("no-scroll");
    }
  };

  const handleURLnavigate = data => {
    if (data?.url) {
      window.open(data.url + "?Referrer=SmartB", "_blank");
    } else {
      let bookkeeperDataID = reduxBookkeeperData?.filter(
        obj => obj?.id === data?.BookKeeperId
      );
      let logoLink = bookkeeperDataID?.[0];
      window.open(logoLink?.affiliate_link, "_blank");
    }
  };

  return (
    <Box className="bet-slip-wrap">
      <Box className="bet-slip-header" onClick={() => handleBetSlipClose()}>
        <Typography className="bet-slip-text">
          <BetSlipArrow />
          {localesData?.BET_SLIP?.BET_SLIP_HEADER}
        </Typography>
        <Notepad />
      </Box>
      {betSlipData?.length > 0 ? (
        <>
          <Box className="bet-slip-data">
            {betSlipData?.map(item => {
              return (
                <>
                  <Box className="bet-slip-single-data">
                    <Box className="bet-slip-header-details">
                      <Box className="bet-slip-details">
                        {item?.sportId === 1 ||
                        item?.sportId === 2 ||
                        item?.sportId === 3 ? (
                          <>
                            <Box className="sport-img">
                              <img src={raceIcon(item?.sportId)} alt="icon" />
                            </Box>
                            <Box className="text-details">
                              <Typography className="bet-slip-text mb-4 dark-text">
                                {item?.runnerNumber}
                                {"."} {item?.teamName} ({item?.barrierNumber})
                              </Typography>
                              <Typography className="bet-slip-text mb-4">
                                {"R" + item?.raceNumber} {item?.trackName}
                              </Typography>
                              <Typography className="bet-slip-text">
                                <span>
                                  {" "}
                                  {moment
                                    .utc(item?.eventTime)
                                    .local()
                                    .format("DD MMM YYYY")}{" "}
                                </span>
                                |
                                <span>
                                  {" "}
                                  {moment
                                    .utc(item?.eventTime)
                                    .local()
                                    .format("hh:mm a")}
                                </span>
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box className="sport-img">
                              {raceIcon(item?.sportId)}
                            </Box>
                            <Box className="text-details">
                              <Typography className="bet-slip-text mb-4 dark-text">
                                {item?.teamName}
                              </Typography>
                              <Typography className="bet-slip-text mb-4">
                                {item?.eventName}
                              </Typography>
                              <Typography className="bet-slip-text">
                                <span>
                                  {" "}
                                  {moment
                                    .utc(item?.eventTime)
                                    .local()
                                    .format("DD MMM YYYY")}{" "}
                                </span>
                                |
                                <span>
                                  {" "}
                                  {moment
                                    .utc(item?.eventTime)
                                    .local()
                                    .format("hh:mm a")}
                                </span>
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box
                        className="delete-box cursor-pointer"
                        onClick={() => handleDeleteBetslip(item)}
                      >
                        <Delete />
                      </Box>
                    </Box>
                    <Box
                      className={
                        item?.sportId === 1 ||
                        item?.sportId === 2 ||
                        item?.sportId === 3
                          ? "stake-details"
                          : "stake-details teamsports-stake-details"
                      }
                    >
                      {item?.sportId === 1 ||
                      item?.sportId === 2 ||
                      item?.sportId === 3 ? (
                        <>
                          <Select
                            className="React fixed-select"
                            // value={providerOption?.find(item => {
                            //   return item?.value === selectedGraphId;
                            // })}
                            onChange={e =>
                              handleSelectedMarketChange(e?.value, item)
                            }
                            options={options}
                            // isOptionDisabled={option => option.disabled}
                            classNamePrefix="select"
                            placeholder="Win Fixed"
                            isSearchable={false}
                            components={{ DropdownIndicator }}
                          />
                          <Box className="stake-field">
                            <Typography className="stake">Stake</Typography>
                            <TextField
                              id="standard-start-adornment"
                              className="stake-input-field"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              value={item?.stack ? item?.stack : null}
                              // onChange={(e)=>{}}
                              onChange={e =>
                                handleStackChange(e.target.value, item)
                              }
                              onBlur={e =>
                                handleUpdateBetslip(item, e.target.value)
                              }
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              inputProps={{ maxLength: 7 }}
                            />
                          </Box>
                        </>
                      ) : (
                        <Box className="stake-field teamsport-stake-field">
                          <Typography className="stake">Stake</Typography>
                          <TextField
                            id="standard-start-adornment"
                            className="stake-input-field"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                            value={item?.stack ? item?.stack : null}
                            onChange={e =>
                              handleStackChange(e.target.value, item)
                            }
                            onBlur={e =>
                              handleUpdateBetslip(item, e.target.value)
                            }
                            inputProps={{ maxLength: 7 }}
                            onKeyPress={e => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box className="odds-returns-wrap">
                      <Box className="odds-returns-top">
                        <Typography className="odds-wrap odds-returns-text">
                          Odd
                        </Typography>
                        <Typography className="returns-wrap odds-returns-text">
                          Potential Returns
                        </Typography>
                      </Box>
                      <Box className="odds-returns-bottom">
                        <Box className="left-wrap">
                          {oddsicon(item?.BookKeeperId)}
                          <Typography className="betslip-odds">
                            {item?.selectedMarket === 1
                              ? item?.odd
                              : item?.oddPlace}
                          </Typography>
                        </Box>
                        <Box className="right-wrap">
                          <Typography>
                            {item?.returns
                              ? formatter?.format(item?.returns?.toFixed(2))
                              : "N/A"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="bet-now-btn">
                      <Button
                        variant="contained"
                        className="button-bet-now"
                        onClick={() => handleURLnavigate(item)}
                      >
                        Bet Now
                      </Button>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box className="betslip-total">
            <Box className="share-betslip">
              <Typography
                className="share-deatils"
                onClick={() => handleshareBetSlipNavigate(cartKey)}
              >
                Share Betslip <ShareSlip />
              </Typography>
            </Box>
            <Box className="betslip-total-deatils">
              {handleTotalData()}

              <Box className="clear-btn" onClick={() => handleClearAll()}>
                <Button className="clear-bet-btn">Clear bets</Button>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box className="bet-slip-empty">
          <img src={NotepadBig} alt="notpad" />
          <Typography className="bet-slip-empty-header">
            Your Bet slip is empty.
          </Typography>
          <Typography className="bet-slip-empty-header empty-text">
            Start placing your bets on today's races and sports events.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BetSlipCollapse;
