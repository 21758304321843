import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Home from "src/views/component/Home/Home";
import HomeV2 from "src/views/component/Home/HomeV2";
import HomeV3 from "src/views/component/Home/HomeV3";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fff",
    height: "100%"
  }
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <Page
      title="SmartB | Smartest Online Sports News and Odds Comparison Platform"
      description="SmartB is Australia’s best resource for the latest sports news, updates, fixtures, form guides and the tools you need to back more winners in any game"
    >
      {/* <Home /> */}
      {/* <HomeV2 /> */}
      <HomeV3 />
    </Page>
  );
};

export default HomePage;
