import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";
import { IntlContext } from "src/App";
import moment from "moment-timezone";
import Loader from "../../../../../components/Loader";
import TabHorses from "../../../../../assets/images/Sport/tab_horse.svg";
import TabGreyhounds from "../../../../../assets/images/Sport/tab_greyhounds.svg";
import TabHarness from "../../../../../assets/images/Sport/tab_harness.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import "./racingList.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config, fetchFromStorage } from "src/helpers/context";
import NoDataComp from "src/views/component/UI/NoData";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/icons/rightArrow.svg";
import RacingFutureTable from "./racingFutureTable";

const futuresData = [
  {
    id: 1,
    date: "05/11/2022",
    location: "VIC",
    event: "Champions Mile 2022",
  },
  {
    id: 2,
    date: "05/11/2022",
    location: "VIC",
    event: "Champions Sprint 2022",
  },
  {
    id: 3,
    date: "05/11/2022",
    location: "VIC",
    event: "COOlmore stud Stakes 2022",
  },
  {
    id: 4,
    date: "29/10/2022",
    location: "VIC",
    event: "Empire rose stakes 2022",
  },
  {
    id: 5,
    date: "29/10/2022",
    location: "NSW",
    event: "golden eagle 2022",
  },
];
const dummyObj = [];

function RacingFuturesList({ selectedDate,
  filterRaceType,
  filterCountry }) {
  let navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  let searchParamsType = query.get("type");
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [RaceType, setRaceType] = useState([
    {
      id: 1,
      title: "Horses",
      icon: TabHorses,
    },
    {
      id: 3,
      title: "Greyhounds",
      icon: TabGreyhounds,
    },
    {
      id: 2,
      title: "Harness",
      icon: TabHarness,
    },
  ]);
  const [HorseRaceData, setHorseRaceData] = useState([]);
  const [GreyhoundsRaceData, setGreyhoundsRaceData] = useState([]);
  const [HarnessRaceData, setHarnessRaceData] = useState([]);
  const [isThreeRaceLoading, setisThreeRaceLoading] = useState(false);
  const [acordianLoader, setisacordianLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [RaceLoader, setisRaceLoader] = useState(true);
  const [sortType, setSortType] = useState("");
  const [sortDate, setSortDate] = useState(false);
  const [sortEvent, setSortEvent] = useState(false);
  const [sortTime, setSortTime] = useState(false);

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    fetchThreeRacing();

  }, [selectedDate, filterRaceType, filterCountry]);

  const fetchThreeRacing = async () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    setisThreeRaceLoading(true);
    setisacordianLoader(true);
    let date_to_pass = selectedDate;

    let country_to_pass = "";
    let state_to_pass = "";

    // Get Sport Id  and Country From Sport FIlter
    let SelectedSportId = filterRaceType;
    let SelectedState = filterCountry;
    try {
      const { status, data } = await axiosInstance.get(
        // `events/trackList/?todate=${date_to_pass}&sportId=${SelectedSportId}&MeetingState=${SelectedState}&countryId=${country_to_pass // isAustralianRacing ? 13 :
        // }&stateId=${state_to_pass}&timezone=${timezone}`
        `v2/events/trackList/?todate=${date_to_pass}&sportId=${SelectedSportId}&MeetingState=${SelectedState}&countryId=${country_to_pass // isAustralianRacing ? 13 :
        }&stateId=${state_to_pass}&timezone=${timezone}`
      );
      if (status === 200) {
        let data_pass =
          data?.events?.length > 0
            ? // Remove raceNumber === 0 and duplicate raceNumber
            data?.events?.map((obj) => {
              return {
                ...obj,
                race: Array.from(new Set(obj?.race.map((a) => a?.raceNumber)))
                  .map((id) => {
                    return obj?.race.find((a) => a?.raceNumber === id);
                  })
                  .filter((race) => race?.raceNumber !== 0),
              };
            })
            : [];

        // setRaceData(data_pass);

        // Filter For Horse Aus / intl racing
        let horsesrace = data_pass.filter((item) => {
          return item.sportId == 1;
        });
        let greyhounds = data_pass.filter((item) => {
          return item.sportId == 3;
        });
        let harness = data_pass.filter((item) => {
          return item.sportId == 2;
        });
        // Set horsesrace
        let singlehorsesraceData = horsesrace?.map((obj) => {
          let data = obj?.race;
          let a = data.map((a) => a.raceNumber) || [];

          // for (var i = 1; i <= 12; i++) {
          //   if (a.indexOf(i) == -1) {
          //     let dummy_obj = { startTimeDate: null, raceNumber: i };
          //     data.push(dummy_obj);
          //   }
          // }

          obj["race"] = data.sort((a, b) => {
            return a?.raceNumber - b?.raceNumber;
          });
          return { ...obj };
        });
        setHorseRaceData(singlehorsesraceData);

        // Set greyhounds

        let singlegreyhoundsraceData = greyhounds?.map((obj) => {
          let data = obj?.race;
          let a = data.map((a) => a.raceNumber) || [];

          for (var i = 1; i <= 12; i++) {
            if (a.indexOf(i) == -1) {
              let dummy_obj = { startTimeDate: null, raceNumber: i };
              data.push(dummy_obj);
            }
          }

          obj["race"] = data.sort((a, b) => {
            return a?.raceNumber - b?.raceNumber;
          });
          return { ...obj };
        });
        setGreyhoundsRaceData(singlegreyhoundsraceData);

        // Set harness

        let singleharnessraceData = harness?.map((obj) => {
          let data = obj?.race;
          let a = data.map((a) => a.raceNumber) || [];

          for (var i = 1; i <= 12; i++) {
            if (a.indexOf(i) == -1) {
              let dummy_obj = { startTimeDate: null, raceNumber: i };
              data.push(dummy_obj);
            }
          }

          obj["race"] = data.sort((a, b) => {
            return a?.raceNumber - b?.raceNumber;
          });
          return { ...obj };
        });
        setHarnessRaceData(singleharnessraceData);
        setisThreeRaceLoading(false);
        setisacordianLoader(false);
        setisRaceLoader(false);
      }
    } catch (err) {
      setisThreeRaceLoading(false);
      setisacordianLoader(false);
      setisRaceLoader(false);
    }
  };

  //  Filter For Horse Aus/Nz and Intl Racing
  let newHorseRaceData = {
    ausData: HorseRaceData?.filter((item) => {
      return item?.track?.Country?.id == 13 || item?.track?.Country?.id == 157;
    }),
    intlData: HorseRaceData?.filter((item) => {
      return item?.track?.Country?.id != 13 && item?.track?.Country?.id != 157;
    }),
  };

  //  Filter For GreyhoundsRaceData Aus/Nz and Intl Racing
  let newGreyhoundsRaceData = {
    ausData: GreyhoundsRaceData?.filter((item) => {
      return item?.track?.Country?.id == 13 || item?.track?.Country?.id == 157;
    }),
    intlData: GreyhoundsRaceData?.filter((item) => {
      return item?.track?.Country?.id != 13 && item?.track?.Country?.id != 157;
    }),
  };
  //  Filter For HarnessRaceData Aus/Nz and Intl Racing
  let newHarnessRaceData = {
    ausData: HarnessRaceData?.filter((item) => {
      return item?.track?.Country?.id == 13 || item?.track?.Country?.id == 157;
    }),
    intlData: HarnessRaceData?.filter((item) => {
      return item?.track?.Country?.id != 13 && item?.track?.Country?.id != 157;
    }),
  };
  // push All Racing Filterd Data in RaceType Object
  RaceType[0]["RaceData"] = newHorseRaceData;
  RaceType[1]["RaceData"] = newGreyhoundsRaceData;
  RaceType[2]["RaceData"] = newHarnessRaceData;
  console.log("raceData", RaceType)
  return (
    <>
      {
        // screenWidth > 799
        //   ?
        RaceType?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Box className="racing-colleps" key={index}>
                {item?.RaceData?.ausData.length > 0 ||
                  item?.RaceData?.intlData.length > 0 ? (
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <img src={item?.icon} alt="" />
                        <Typography>{item?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {acordianLoader ? (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        ) : (
                          <Box>

                            <Box className="race-detail">
                              <Typography className="country-title">
                                Australia
                              </Typography>
                              <RacingFutureTable raceData={item.RaceData.ausData} sortType={sortType} sortDate={sortDate} sortEvent={sortEvent} sortTime={sortTime} />
                              {/* {item.RaceData.ausData?.length > 0 ? (
                                <TableContainer className="racing-table-container tracklist-container future-table-container">
                                  <Table
                                    className="racing-data"
                                    aria-label="customized table"
                                    style={{ minWidth: 700 }}
                                  >
                                    <TableHead className="racing-table-head">
                                      <TableRow>
                                        <TableCell
                                        >
                                          <Box className="sorting-cell">
                                            {localesData?.sport?.DATE}
                                            <span className="sorting-arrow-wrap">
                                              <SortingArrow
                                                className={`${sortType == "date" &&
                                                  sortDate == true
                                                  ? "active"
                                                  : ""
                                                  }`}
                                              />
                                              <SortingArrow
                                                className={`${sortType == "date" &&
                                                  sortDate == false
                                                  ? "active"
                                                  : ""
                                                  } down-arrow`}
                                              />
                                            </span>
                                          </Box>
                                        </TableCell>

                                        <TableCell
                                          className="event-header"
                                        >
                                          <Box className="sorting-cell">
                                            {localesData?.sport?.EVENT}
                                            <span className="sorting-arrow-wrap">
                                              <SortingArrow
                                                className={`${sortType == "event" &&
                                                  sortEvent == true
                                                  ? "active"
                                                  : ""
                                                  }`}
                                              />
                                              <SortingArrow
                                                className={`${sortType == "event" &&
                                                  sortEvent == false
                                                  ? "active"
                                                  : ""
                                                  } down-arrow`}
                                              />
                                            </span>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          className="time-header"
                                        >
                                          <Box className="sorting-cell">
                                            {localesData?.sport?.TIME_REMAINING}
                                            <span className="sorting-arrow-wrap">
                                              <SortingArrow
                                                className={`${sortType == "time" &&
                                                  sortTime == true
                                                  ? "active"
                                                  : ""
                                                  }`}
                                              />
                                              <SortingArrow
                                                className={`${sortType == "time" &&
                                                  sortTime == false
                                                  ? "active"
                                                  : ""
                                                  } down-arrow`}
                                              />
                                            </span>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          className="odds-header"
                                        >
                                          {localesData?.sport?.ODDS}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody className="future-table-body">
                                      {item.RaceData.ausData?.map((event, i) => {
                                        return (
                                          <>
                                            <TableRow key={i}>
                                              <TableCell>
                                                <Box className="flag-date-wrap">

                                                  {event?.track?.Country?.country_flag?.includes(
                                                    "uploads"
                                                  ) ? (
                                                    <img
                                                      src={
                                                        Config.mediaURL +
                                                        event?.track
                                                          ?.Country
                                                          ?.country_flag
                                                      }
                                                      alt="Race Contry"
                                                      className="flag-icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={
                                                        event?.track
                                                          ?.Country
                                                          ?.country_flag
                                                      }
                                                      alt="Race Contry"
                                                      className="flag-icon"
                                                    />
                                                  )}

                                                  {moment(event?.race?.[0]?.startTimeDate).tz(timezone).format("DD/MM/YYYY")}
                                                </Box>
                                              </TableCell>

                                              <TableCell
                                                // onClick={() =>
                                                //   navigate("/racing/futures")
                                                // }
                                                className="future-name"

                                              >
                                                {event?.eventName}
                                              </TableCell>
                                              <TableCell className="time-remaining">
                                                {event?.time}
                                                2 weeks 3 days
                                              </TableCell>
                                              <TableCell>
                                                <Box className="compare-odds">
                                                  {localesData?.sport?.COMPARE_ODDS}
                                                  <RightArrow />
                                                </Box>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) :
                                <TableContainer className="racing-table-container tracklist-container">
                                  <Table>
                                    <TableRow
                                      style={{
                                        border: "transparent",
                                        height: "1px",
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#E7E9EC",
                                        }}
                                        colSpan={21}
                                      >
                                        {localesData?.sport?.NO_DATA}
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </TableContainer>} */}
                            </Box>


                            <Box className="race-detail">
                              <Typography className="country-title">
                                International
                              </Typography>
                              <RacingFutureTable raceData={item.RaceData.intlData} sortType={sortType} sortDate={sortDate} sortEvent={sortEvent} sortTime={sortTime} />

                            </Box>

                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>

                  </>
                ) : !acordianLoader && searchParamsType ? (
                  searchParamsType == item?.id &&
                    searchParamsType?.length > 1 ? (
                    <p style={{ textAlign: "center" }} className="no-race">
                      {/* No Race Available */}
                      <NoDataComp />
                    </p>
                  ) : index == 0 &&
                    newHorseRaceData?.ausData?.length <= 0 &&
                    newGreyhoundsRaceData?.ausData?.length <= 0 &&
                    newHarnessRaceData?.ausData?.length <= 0 &&
                    newHorseRaceData?.intlData?.length <= 0 &&
                    newGreyhoundsRaceData?.intlData?.length <= 0 &&
                    newHarnessRaceData?.intlData?.length <= 0 ? (
                    <>
                      <NoDataComp />
                    </>
                  ) : (
                    ""
                  )
                ) : index == 0 &&
                  newHorseRaceData?.ausData?.length <= 0 &&
                  newGreyhoundsRaceData?.ausData?.length <= 0 &&
                  newHarnessRaceData?.ausData?.length <= 0 &&
                  newHorseRaceData?.intlData?.length <= 0 &&
                  newGreyhoundsRaceData?.intlData?.length <= 0 &&
                  newHarnessRaceData?.intlData?.length <= 0 ? (
                  <>
                    <NoDataComp />
                  </>
                ) : (
                  ""
                )}
              </Box>
            </React.Fragment>
          );
        })
        // RaceType?.map((item) => {
        //   return (
        //     <>
        //       <Box className="racing-colleps">
        //         <Accordion defaultExpanded>
        //           <AccordionSummary
        //             expandIcon={<ExpandMoreIcon />}
        //             aria-controls="panel1a-content"
        //             id="panel1a-header"
        //           >
        //             <img src={item?.icon} alt="icon" />
        //             <Typography>{item?.title}</Typography>
        //           </AccordionSummary>
        //           <AccordionDetails>
        //             {acordianLoader ? (
        //               <div className="allsport-loader-center">
        //                 <Loader />
        //               </div>
        //             ) : (
        //               <Box>


        //                 <Box className="race-detail">
        //                   <Typography className="country-title">
        //                     Australia
        //                   </Typography>
        //                   <TableContainer className="racing-table-container tracklist-container">
        //                     <Table
        //                       className="racing-data"
        //                       aria-label="customized table"
        //                       style={{ minWidth: 700 }}
        //                     >
        //                       <TableHead className="racing-table-head">
        //                         <TableRow>
        //                           <TableCell
        //                             style={{ minWidth: "165px" }}
        //                           >
        //                             {localesData?.sport?.DATE}
        //                           </TableCell>
        //                           <TableCell
        //                             style={{ minWidth: "165px" }}
        //                           >
        //                             {localesData?.sport?.LOCATION}
        //                           </TableCell>
        //                           <TableCell
        //                             style={{ minWidth: "690px" }}
        //                           >
        //                             {localesData?.sport?.EVENT}
        //                           </TableCell>
        //                         </TableRow>
        //                       </TableHead>
        //                       <TableBody>
        //                         {futuresData?.map((type, i) => {
        //                           return (
        //                             <>
        //                               <TableRow key={i}>
        //                                 <TableCell>
        //                                   {type?.date}
        //                                 </TableCell>
        //                                 <TableCell>
        //                                   {type?.location}
        //                                 </TableCell>
        //                                 <TableCell
        //                                   onClick={() =>
        //                                     navigate("/racing/futures")
        //                                   }
        //                                   style={{ cursor: "pointer" }}
        //                                 >
        //                                   {type?.event}
        //                                 </TableCell>
        //                               </TableRow>
        //                             </>
        //                           );
        //                         })}
        //                       </TableBody>
        //                     </Table>
        //                   </TableContainer>
        //                 </Box>

        //                 <Box className="race-detail">
        //                   <Typography className="country-title">
        //                     International
        //                   </Typography>
        //                   {dummyObj > 0 ? (
        //                     <>
        //                       <TableContainer className="racing-table-container tracklist-container">
        //                         <Table
        //                           className="racing-data"
        //                           aria-label="customized table"
        //                           style={{ minWidth: 700 }}
        //                         >
        //                           <TableHead className="racing-table-head">
        //                             <TableRow>
        //                               <TableCell>
        //                                 {localesData?.sport?.DATE}
        //                               </TableCell>
        //                               <TableCell>
        //                                 {localesData?.sport?.LOCATION}
        //                               </TableCell>
        //                               <TableCell>
        //                                 {localesData?.sport?.EVENT}
        //                               </TableCell>
        //                             </TableRow>
        //                           </TableHead>
        //                           <TableBody>
        //                             {futuresData?.map((type, i) => {
        //                               return (
        //                                 <>
        //                                   <TableRow key={i}>
        //                                     <TableCell>
        //                                       {type?.date}
        //                                     </TableCell>
        //                                     <TableCell>
        //                                       {type?.location}
        //                                     </TableCell>
        //                                     <TableCell>
        //                                       {type?.event}
        //                                     </TableCell>
        //                                   </TableRow>
        //                                 </>
        //                               );
        //                             })}
        //                           </TableBody>
        //                         </Table>
        //                       </TableContainer>
        //                     </>
        //                   ) : (
        // <TableContainer className="racing-table-container tracklist-container">
        //   <Table>
        //     <TableRow
        //       style={{
        //         border: "transparent",
        //         height: "1px",
        //       }}
        //     >
        //       <TableCell
        //         style={{
        //           textAlign: "center",
        //           backgroundColor: "#E7E9EC",
        //         }}
        //         colSpan={21}
        //       >
        //         {localesData?.sport?.NO_DATA}
        //       </TableCell>
        //     </TableRow>
        //   </Table>
        // </TableContainer>
        //                   )}
        //                 </Box>


        //               </Box>
        //             )}
        //           </AccordionDetails>
        //         </Accordion>
        //       </Box>
        //     </>
        //   );
        // })
        // : RaceType?.map((item) => {
        //   return (
        //     <>
        //       <Box className="racing-colleps">
        //         <Accordion defaultExpanded>
        //           <AccordionSummary
        //             expandIcon={<ExpandMoreIcon />}
        //             aria-controls="panel1a-content"
        //             id="panel1a-header"
        //           >
        //             <img src={item?.icon} alt="icon" />
        //             <Typography>{item?.title}</Typography>
        //           </AccordionSummary>
        //           <AccordionDetails>
        //             {acordianLoader ? (
        //               <div className="allsport-loader-center">
        //                 <Loader />
        //               </div>
        //             ) : (
        //               <Box>
        //                 {item?.id === 1 ? (
        //                   <>
        //                     <Box className="race-detail">
        //                       <Typography className="country-title">
        //                         Australia
        //                       </Typography>
        //                       <TableContainer className="racing-table-container tracklist-container">
        //                         <Table
        //                           className="racing-data racing-futures-table"
        //                           aria-label="customized table"
        //                         // style={{ minWidth: 700 }}
        //                         >
        //                           <TableHead className="racing-table-head racing-futures-head">
        //                             <TableRow>
        //                               <TableCell
        //                                 style={{ minWidth: "210px" }}
        //                               >
        //                                 {localesData?.sport?.EVENT}
        //                               </TableCell>
        //                               <TableCell>
        //                                 {localesData?.sport?.DATE}/
        //                                 {localesData?.sport?.LOCATION}
        //                               </TableCell>
        //                             </TableRow>
        //                           </TableHead>
        //                           <TableBody className="racing-futures-body">
        //                             {futuresData?.map((type, i) => {
        //                               return (
        //                                 <>
        //                                   <TableRow key={i}>
        //                                     <TableCell
        //                                       onClick={() =>
        //                                         navigate("/racing/futures")
        //                                       }
        //                                       style={{ cursor: "pointer" }}
        //                                     >
        //                                       {type?.event}
        //                                     </TableCell>
        //                                     <TableCell>
        //                                       <Box className="futures-date-location">
        //                                         <span className="futures-date">
        //                                           {type?.date}
        //                                         </span>
        //                                         <span> {type?.location}</span>
        //                                       </Box>
        //                                     </TableCell>
        //                                   </TableRow>
        //                                 </>
        //                               );
        //                             })}
        //                           </TableBody>
        //                         </Table>
        //                       </TableContainer>
        //                     </Box>

        //                     <Box className="race-detail">
        //                       <Typography className="country-title">
        //                         International
        //                       </Typography>
        //                       {dummyObj > 0 ? (
        //                         <>
        //                           <TableContainer className="racing-table-container tracklist-container">
        //                             <Table
        //                               className="racing-data"
        //                               aria-label="customized table"
        //                               style={{ minWidth: 700 }}
        //                             >
        //                               <TableHead className="racing-table-head">
        //                                 <TableRow>
        //                                   <TableCell>
        //                                     {localesData?.sport?.EVENT}
        //                                   </TableCell>
        //                                   <TableCell>
        //                                     {localesData?.sport?.DATE}/
        //                                     {localesData?.sport?.LOCATION}
        //                                   </TableCell>
        //                                 </TableRow>
        //                               </TableHead>
        //                               <TableBody>
        //                                 {futuresData?.map((type, i) => {
        //                                   return (
        //                                     <>
        //                                       <TableRow key={i}>
        //                                         <TableCell>
        //                                           {type?.event}
        //                                         </TableCell>
        //                                         <TableCell>
        //                                           {type?.date}{" "}
        //                                           {type?.location}
        //                                         </TableCell>
        //                                       </TableRow>
        //                                     </>
        //                                   );
        //                                 })}
        //                               </TableBody>
        //                             </Table>
        //                           </TableContainer>
        //                         </>
        //                       ) : (
        //                         <TableContainer className="racing-table-container tracklist-container">
        //                           <Table>
        //                             <TableRow
        //                               style={{
        //                                 border: "transparent",
        //                                 height: "1px",
        //                               }}
        //                             >
        //                               <TableCell
        //                                 style={{
        //                                   textAlign: "center",
        //                                   backgroundColor: "#E7E9EC",
        //                                   padding: "13px 0px 12px",
        //                                 }}
        //                                 colSpan={21}
        //                               >
        //                                 {localesData?.sport?.NO_DATA}
        //                               </TableCell>
        //                             </TableRow>
        //                           </Table>
        //                         </TableContainer>
        //                       )}
        //                     </Box>
        //                   </>
        //                 ) : (
        //                   ""
        //                 )}
        //               </Box>
        //             )}
        //           </AccordionDetails>
        //         </Accordion>
        //       </Box>
        //     </>
        //   );
        // })
      }
    </>
  );
}

export default RacingFuturesList;
