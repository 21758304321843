import React, { useContext, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import AuthTitleLogo from "src/assets/images/signup-img/auth-title-logo.webp";
import AuthTextLogo from "src/assets/images/signup-img/auth-text-logo.webp";
import leftimage from "src/assets/images/signup-img/auth-left.webp";
import logo from "src/assets/images/logo.svg";
import { useState } from "react";
import { loginUser } from "src/helpers/store/Actions/auth";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import "../auth.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import Loader from "src/components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import "react-lazy-load-image-component/src/effects/blur.css";
import { values } from "lodash";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";

export default function SignIn({ handleModal }) {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const location = useLocation();
  let dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);
  const [formvalues, setformvalues] = useState({});
  const [errormsg, seterrormsg] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isVerify, setisVerify] = useState(true);
  const [isOtpError, setOtpError] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const handleClose = () => {
    let prev_auth_route = localStorage.getItem("prev_authroute");
    navigate(prev_auth_route ? prev_auth_route : "/");
    dispatch(fetchRestrictedRoute(false));
    localStorage.removeItem("prev_authroute");
  };

  const handleLogin = async () => {
    setisLoading(true);
    let auth = {
      auth: {
        username: formvalues?.email,
        password: formvalues?.password,
      },
    };
    try {
      const { status, data } = await axiosInstance.post(`user/login`, {}, auth);
      if (status === 200) {
        let { isVerified } = data;
        setisLoading(false);
        if (isVerified) {
          let { access_token } = data;
          let Type = localStorage.getItem("type");
          let Url = localStorage.getItem("url");
          // dispatch(fetchRestrictedRoute(false));

          if (Type && Url) {
            localStorage.removeItem("type");
            localStorage.removeItem("url");
            window.location.href = Url;
            localStorage.setItem("auth_token", JSON.stringify(access_token));
            localStorage.setItem("smartb_user", formvalues?.email);
            dispatch(loginUser(access_token));
            dispatch(fetchRestrictedRoute(false));
          } else {
            let prev_auth_route = localStorage.getItem("prev_authroute");
            const intervalId = localStorage.getItem("popup_intervalId");
            setTimeout(() => {
              clearTimeout(parseInt(intervalId, 10));
              navigate(prev_auth_route ? prev_auth_route : "/");
              localStorage.removeItem("popup_intervalId");
            }, 1000);

            localStorage.setItem("auth_token", JSON.stringify(access_token));
            dispatch(loginUser(access_token));
            dispatch(fetchRestrictedRoute(false));
            localStorage.removeItem("prev_authroute");
          }
        } else {
          toast.success(localesData?.register?.OTP_SENT, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          setisVerify(false);
        }
      }
    } catch (err) {
      setisLoading(false);
      seterrormsg(err?.response?.data?.message);
    }
  };
  const handleVerifyOtp = async (value) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `user/verifyOtp/${formvalues?.email}/${value?.otp}`
      );
      if (status === 200) {
        let Type = localStorage.getItem("type");
        let Url = localStorage.getItem("url");
        setisLoading(false);
        let { access_token } = data;
        setOtpError("");
        setisVerify(true);
        dispatch(fetchRestrictedRoute(false));
        if (Type && Url) {
          localStorage.setItem("smartb_user", formvalues?.email);
          localStorage.removeItem("type");
          localStorage.removeItem("url");
          window.location.href = Url;
          localStorage.setItem("auth_token", JSON.stringify(access_token));
          dispatch(loginUser(access_token));
        } else {
          let prev_auth_route = localStorage.getItem("prev_authroute");
          navigate(prev_auth_route ? prev_auth_route : "/");
          localStorage.removeItem("prev_authroute");
          localStorage.setItem("auth_token", JSON.stringify(access_token));
          dispatch(loginUser(access_token));
        }
      }
    } catch (err) {
      setisLoading(false);
      console.log("err", err?.response);
      setOtpError(err?.response?.data?.message);
    }
  };
  const handleResendOtp = async () => {
    setOtpError("");
    try {
      const { status } = await axiosInstance.get(
        `user/resendOtp/${formvalues?.email}`
      );
      if (status === 200) {
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log("err", err?.response);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleNavigateForgetPassword = (values) => {
    navigate("/forgot-password", { state: values?.email });
  };
  return (
    <>
      <Helmet>
        <title>SmartB | Sign-in To Your Account</title>
        <meta
          name="description"
          content="Sign in to your SmartB account to follow your favorite teams and sports. We offer latest sports news and updates."
        />
      </Helmet>
      <Box className="auth-sec">
        <Box item md={3} lg={4} className="left-sec hide-mobilemenu">
          <img
            src={leftimage}
            alt="left-banner"
            className="image-sec"
            width="100%"
          />
        </Box>
        <Box item xs={12} sm={12} md={9} lg={8} className="right-sec">
          {isVerify ? (
            <Formik
              enableReinitialize
              initialValues={{
                email:
                  location?.state?.loginEmail?.loginEmail ||
                  location?.state?.loginEmail ||
                  "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(localesData?.validation?.valid_email)
                  .max(255)
                  .required(localesData?.validation?.required_message),
                password: Yup.string()
                  .required(localesData?.validation?.required_message)
                  .min(6, localesData?.validation?.passowrd_length),
              })}
              onSubmit={() => handleLogin()}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  {isLoading ? (
                    <div className="auth-loader">
                      <Loader />
                    </div>
                  ) : (
                    <></>
                  )}
                  {screenWidth > 599 ? (
                    <>
                      <NavLink to="/" className="hide-mobilemenu">
                        <img
                          src={AuthTitleLogo}
                          style={{ maxWidth: " 240.56px" }}
                          className="App-logo-main"
                          alt="logo"
                        />
                      </NavLink>
                      <IconButton
                        disableRipple
                        className="dialog-close"
                        onClick={() => handleClose()}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <></>
                  )}
                  <Typography className="login-title">
                    {localesData?.auth_btn?.signin}
                  </Typography>
                  <Box className="sign-in-account-sec">
                    <Typography className="account-text">
                      {localesData?.register?.dont_have_account}
                    </Typography>
                    <Link
                      className="redirect-link"
                      // onClick={() => handleModal("signup")}
                      to="/sign-up"
                    >
                      {localesData?.auth_btn?.signup}
                    </Link>
                  </Box>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.email}
                    </Typography>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type="text"
                      name="email"
                      className="text-field email-text-field"
                      error={Boolean(touched?.email && errors?.email)}
                      helperText={touched?.email ? errors?.email : ""}
                      value={values?.email}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box className="textfield-sec">
                    <Typography className="textfield-text">
                      {localesData?.register?.password}
                    </Typography>
                    <TextField
                      placeholder={""}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      value={values?.password}
                      onChange={handleChange}
                      error={Boolean(touched?.password && errors?.password)}
                      helperText={touched?.password ? errors?.password : ""}
                      name="password"
                      className="text-field"
                      InputProps={{
                        endAdornment: !showPassword ? (
                          <UnVisiblePassword
                            onClick={() => setshowPassword(!showPassword)}
                          />
                        ) : (
                          <VisiblePassword
                            onClick={() => setshowPassword(!showPassword)}
                          />
                        ),
                      }}
                    />
                    <span className="text-danger text-danger-width">
                      {errormsg}
                    </span>
                  </Box>
                  {/* <Link
                  className="redirect-link"
                  // onClick={() => handleModal("signup")}
                  to="/forgot-password"
                >
                  <Typography
                    className="forgot-password-link"
                  // onClick={() => handleModal("forgotpassword")}
                  >
                    {localesData?.register?.forgot_password}
                  </Typography>
                </Link> */}
                  <Typography
                    className="redirect-link"
                    // onClick={() => handleModal("signup")}
                    // to="/forgot-password"
                    onClick={() => handleNavigateForgetPassword(values)}
                  >
                    <Typography
                      className="forgot-password-link"
                    // onClick={() => handleModal("forgotpassword")}
                    >
                      {localesData?.register?.forgot_password}
                    </Typography>
                  </Typography>
                  {screenWidth < 600 && (
                    <Box className="prev-next-btn">
                      <Button
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        // type="submit"
                        className="submit-btn"
                        onClick={() => handleClose()}
                      >
                        {localesData?.register?.auth_btn_prev}
                      </Button>
                    </Box>
                  )}
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    type="submit"
                    className="submit-btn"
                  >
                    {localesData?.auth_btn?.signin}
                  </Button>
                  {setformvalues(values)}
                </form>
              )}
            </Formik>
          ) : (
            <Box className="otp-msg-wrap">
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required(localesData?.validation?.required_message)
                    .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
                })}
                onSubmit={(values) => handleVerifyOtp(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {isLoading ? (
                      <div className="auth-loader">
                        <Loader />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Typography className="login-title login-heading">
                      {localesData?.register?.enter_otp}
                    </Typography>
                    <Typography className="otp-content">
                      We have sent an OTP to{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formvalues?.email}
                      </span>{" "}
                      Please check it and enter the OTP here
                    </Typography>
                    <Box className="textfield-sec">
                      <Typography className="textfield-text">
                        {localesData?.register?.otp}
                      </Typography>
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type="text"
                        name="otp"
                        className="text-field"
                        error={Boolean(touched?.otp && errors?.otp)}
                        helperText={touched?.otp ? errors?.otp : ""}
                        onChange={handleChange}
                        value={values?.otp}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 6,
                          },
                        }}
                      />
                      <span className="text-danger">{isOtpError}</span>
                      <Typography
                        className="forgot-password-link"
                        // onClick={() => handleResendOtp()}
                        onClick={() => {
                          resetForm();
                          handleResendOtp();
                        }}
                      >
                        {localesData?.register?.RESEND_OTP}
                      </Typography>
                    </Box>
                    <Button type="submit" className="submit-btn">
                      {localesData?.register?.VERIFY_OTP}
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
        {/* <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> */}
      </Box>
    </>
  );
}
