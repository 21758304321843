import { Box, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import "./news.scss";
import LeftNewsSiderBar from "./leftNewsSiderBar";
import RightNewsSideBar from "./rightNewsSideBar";
import ScriptLoader from "../scriptLoader/ScriptLoader";
import { useParams } from "react-router";
import { Config } from "src/helpers/context";

const NewsPage = () => {
  const params = useParams();
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [NewsAdsData, setNewsAdsData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
      let NewsSideAdsData = ReduxAdsData?.filter(item => item?.page_id === 1);
      setNewsAdsData(NewsSideAdsData);
    }
  }, [ReduxAdsData]);
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const fetchSideNewsAds = (position, height, placeholder) => {
    if (NewsAdsData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={NewsAdsData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;

  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      <Box>
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner
          )}
        </Box>
        <Grid container className="news-section">
          {/* {params?.id ? (
            <>
              {" "}
              <Grid item lg={9} className="left-section">
                <LeftNewsSiderBar />
              </Grid>
              <Grid item lg={3} className="right-section">
                <RightNewsSideBar fetchSideNewsAds={fetchSideNewsAds} />
              </Grid>
            </>
          ) : ( */}
          <Grid item lg={12} className="left-section all-cat-section">
            <LeftNewsSiderBar />
          </Grid>
          {/* )} */}
        </Grid>
      </Box>
    </>
  );
};

export default NewsPage;
