import React from "react";
import { Box, Typography } from "@material-ui/core";
import banner from "../../../assets/images/banner/smart-b-banner-primary.webp";
import AdvertisingBannner from "../../component/advertisingBanner";
import OurPartners from "../../component/ourPartners";
import Page from "src/components/Page";
import { ReactComponent as Facebook } from "../../../assets/images/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/images/icons/instagram.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/images/icons/twitter.svg";
import "./cominsoon.scss";

const ComingSoon = () => {
  return (
    <>
      <Page
        title="SmartB | You Have Come To The Right Place -Coming Soon"
        description="SmartB is the best Sports Comparison Platform and News Site. Check out or latest updates and news on upcoming events."
      >
        <Box className="content-wrap">
          <AdvertisingBannner src={banner} />
          <Box className="sport-wrap">
            <Box className="comingsoon-wrap">
              <Typography variant="h1">
                <span className="title-orange">SMARTB</span> FOR SMARTER BETS
              </Typography>
              <Typography variant="body2">
                It looks like you've come to a page that isn't quite ready yet.
                <b> We are building it and it will be available soon.</b>
              </Typography>
              <Typography variant="body2">
                Make sure you follow our social media channels so you can see
                new features when we add them.{" "}
                <span className="orange">SmartB </span>
                will soon display comparisons and information for all sports
                around the world.
              </Typography>
              <Box className="social-wrap">
                <a href="#">
                  <Facebook />
                </a>
                <a href="#">
                  <Instagram />
                </a>
                <a href="#">
                  <Linkedin />
                </a>
                <a href="#">
                  <Twitter />
                </a>
              </Box>
            </Box>
            <OurPartners />
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default ComingSoon;
