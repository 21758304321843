import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { IntlContext } from "src/App";

const internationalRaceData = [
  {
    id: 1,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Prix De L'arc De Triomphe",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 2,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Japan Cup",
    raceCourse: "Tokyo",
    distance: "2400M"
  },
  {
    id: 3,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Hong Kong Cup",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 4,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "King George VI Chase",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 5,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Cheltenham Gold Cup",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 6,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Dubai World Cup",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 7,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Grand National",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 8,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Kentucky Derby",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 9,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Epsom Derby",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 10,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Platinum Jubilee Stakes",
    raceCourse: "Longchamp",
    distance: "2400M"
  },
  {
    id: 11,
    date: "2/10/2022",
    type: "International",
    price: "€5 Million",
    race: "Singapore Cup",
    raceCourse: "Longchamp",
    distance: "2400M"
  }
];

const InternationalRacingDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <>
      <Box className="racing-details-wrap">
        <Typography className="fs-16 fw-400 color-black typography-header">
          {localesData?.INTERNATIONAL?.HISTORY_OF_RACE}
        </Typography>
        <Box className="international-racing-warp">
          <TableContainer className="international-table-container table-box-shadow">
            <Table
              style={{ minWidth: "max-content", height: "100%" }}
              className="international-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="bg-color-Light-grey in-head">
                    {localesData?.INTERNATIONAL?.DATE}
                  </TableCell>
                  <TableCell className="bg-color-Light-grey in-head">
                    {localesData?.INTERNATIONAL?.TYPE}
                  </TableCell>
                  <TableCell className="bg-color-Light-grey in-head">
                    {localesData?.INTERNATIONAL?.PRIZEMONEY}
                  </TableCell>
                  <TableCell className="bg-color-Light-grey in-head link-blue">
                    {localesData?.INTERNATIONAL?.RACENAME}
                  </TableCell>
                  <TableCell className="bg-color-Light-grey in-head">
                    {localesData?.INTERNATIONAL?.RACECOURSE}
                  </TableCell>
                  <TableCell className="bg-color-Light-grey in-head">
                    {localesData?.INTERNATIONAL?.DISTANCE}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {internationalRaceData?.map((item, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell>{item?.date}</TableCell>
                        <TableCell>{item?.type}</TableCell>
                        <TableCell>{item?.price}</TableCell>
                        <TableCell
                          className="link-blue cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/international-racing/${item?.race}/${item?.id}`
                            )
                          }
                        >
                          {item?.race}
                        </TableCell>
                        <TableCell>{item?.raceCourse}</TableCell>
                        <TableCell>{item?.distance}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default InternationalRacingDetails;
